import React from 'react';
import { BTButton } from '@btas/jasper';
import './WorkpaperLockedBar/styles.scss';
import { workpaper_status } from './shared/constants';

export default function WorkpaperLockedBar({ userName, workpaperStatus, onNeedsReload }) {
  return (
    <div className="wkp-top-bar wkp-locked-bar">
      {workpaperStatus === workpaper_status.Final
        ? `This workpaper has been marked as 'Final'. To unlock this workpaper, navigate to the Workflow panel and change
        the workpaper status to any status other than 'Final'.`
        : ` This workpaper is currently locked for editing by '${userName}'. You may view the workpaper, but not edit.${' '}`}

      {workpaperStatus !== workpaper_status.Final && (
        <BTButton btStyle="primary" id="wkp-locked-try-again-btn" onClick={onNeedsReload}>
          Try again
        </BTButton>
      )}
    </div>
  );
}
