export function getMockStateTaxRateData(jurisdiction, periodStartDate, taxableIncome) {
  let stateTaxRateData;
  let returnValue;
  if (
    (jurisdiction === 'Arizona' || jurisdiction === 'AZ') &&
    periodStartDate === '2012-02-02' &&
    taxableIncome === 500
  ) {
    returnValue = 200;
    stateTaxRateData = `{
              "id": "1",
              "raw_data_id": "2",
              "jurisdiction": {
                  "id": "6",
                  "jurisdiction": "Arizona",
                  "abbreviation": "AZ"
              },
              "default_1": "6.97%",
              "tax_rate": "6.97%",
              "income_start": null,
              "income_end": null,
              "incremental_tax": null,
              "start_date": "2010-01-01",
              "end_date": "2013-12-31",
              "industry_id": "1",
              "research_support": "Some support text",
              "created_at": "2020-03-05 03:03:00",
              "updated_at": "2020-03-05 03:03:00",
              "tax_law_summary": "The Arizona corporate income tax rate is 6.9%. BOLDAriz. Rev. Stat. Ann. § 43-1111(5).BOLD",
              "tax_law_summary_link": "https://www.bloomberglaw.com/product/tax/bbna/chart/2/10069/2da29c64705f4b58049cbd4062b2ef4f"
            }`;
  } else if (
    (jurisdiction === 'Arizona' || jurisdiction === 'AZ') &&
    periodStartDate === '2013-06-01' &&
    taxableIncome === 500
  ) {
    returnValue = 200;
    stateTaxRateData = `{
              "id": "2",
              "raw_data_id": "2",
              "jurisdiction": {
                  "id": "6",
                  "jurisdiction": "Arizona",
                  "abbreviation": "AZ"
              },
              "tax_rate": "6.97%",
              "income_start": null,
              "income_end": null,
              "incremental_tax": null,
              "start_date": "2014-01-01",
              "end_date": "2014-12-31",
              "industry_id": "1",
              "research_support": "Some support text",
              "created_at": "2020-03-05 03:03:00",
              "updated_at": "2020-03-05 03:03:00",
              "tax_law_summary": "The Arizona corporate income tax rate is 6.97%. BOLDAriz. Rev. Stat. Ann. § 43-1111(5).BOLD",
              "tax_law_summary_Link": "https://www.bloomberglaw.com/product/tax/bbna/chart/2/10069/2da29c64705f4b58049cbd4062b2ef4f",
              "upcomingRateChange": "6.50%",
              "upcomingRateChangeDate": "01/01/2022",
              "rate_type": "Fixed"
            }`;
  } else if (
    (jurisdiction === 'Alaska' || jurisdiction === 'AK') &&
    periodStartDate === '2021-02-02' &&
    taxableIncome === '60000'
  ) {
    returnValue = 200;
    stateTaxRateData = `{
              "id": "2",
              "raw_data_id": "2",
              "jurisdiction": {
                  "id": "6",
                  "jurisdiction": "Alaska",
                  "abbreviation": "AK"
              },
              "default_1": "3.50%",
              "tax_rate": "3.50%",
              "income_start": null,
              "income_end": null,
              "incremental_tax": null,
              "start_date": "2021-01-01",
              "end_date": "2011-12-31",
              "industry_id": "1",
              "research_support": "Some support text",
              "created_at": "2020-03-05 03:03:00",
              "updated_at": "2020-03-05 03:03:00",
              "tax_law_summary": "Alaska has a graduated corporate income tax. The effective rate for the income level of 60,000 is computed to be 3.50%",
              "tax_law_summary_Link": "https://www.bloomberglaw.com/product/tax/bbna/chart/2/10069/2da29c64705f4b58049cbd4062b2ef4f",
              "upcomingRateChangeDate": "01/01/2023",
              "rate_type": "NotFixed"
            }`;
  } else if (
    (jurisdiction === 'Arizona' || jurisdiction === 'AZ') &&
    periodStartDate === '2021-01-01' &&
    taxableIncome === 500
  ) {
    returnValue = 200;
    stateTaxRateData = `{
              "id": "2",
              "raw_data_id": "2",
              "jurisdiction": {
                  "id": "6",
                  "jurisdiction": "Arizona",
                  "abbreviation": "AZ"
              },
              "default_1": "6.97%",
              "tax_rate": "6.97%",
              "income_start": null,
              "income_end": null,
              "incremental_tax": null,
              "start_date": "2021-01-01",
              "end_date": "2024-12-31",
              "industry_id": "1",
              "research_support": "Some support text",
              "created_at": "2020-03-05 03:03:00",
              "updated_at": "2020-03-05 03:03:00",
              "tax_law_summary": "The Arizona corporate income tax rate is 6.9%. BOLDAriz. Rev. Stat. Ann. § 43-1111(5).BOLD",
              "tax_law_summary_Link": "https://www.bloomberglaw.com/product/tax/bbna/chart/2/10069/2da29c64705f4b58049cbd4062b2ef4f",
              "upcomingRateChange": "6.50%",
              "upcomingRateChangeDate": "01/01/2013",
              "rate_type": "Fixed"
            }`;
  } else if (
    (jurisdiction === 'North Carolina' || jurisdiction === 'NC') &&
    periodStartDate === '2020-02-02' &&
    taxableIncome === 500
  ) {
    returnValue = 200;
    stateTaxRateData = `{
              "id": "3",
              "raw_data_id": "2",
              "jurisdiction": {
                  "id": "8",
                  "jurisdiction": "North Carolina",
                  "abbreviation": "NC"
              },
              "default_1": "2.5%",
              "tax_rate": "2.5%",
              "income_start": null,
              "income_end": null,
              "incremental_tax": null,
              "start_date": "2019-01-01",
              "end_date": "2099-12-31",
              "industry_id": "1",
              "research_support": "Some support text",
              "created_at": "2020-03-05 03:03:00",
              "updated_at": "2020-03-05 03:03:00",
              "tax_law_summary": "The North Carolina corporate income tax rate is 6.9%. BOLDAriz. Rev. Stat. Ann. § 43-1111(5).BOLD",
              "tax_law_summary_Link": "https://www.bloomberglaw.com/product/tax/bbna/chart/2/10069/2da29c64705f4b58049cbd4062b2ef4f",
              "upcomingRateChange": "2.55%",
              "upcomingRateChangeDate": "01/01/2021",
              "rate_type": "Fixed"
          }`;
  } else if (
    (jurisdiction === 'Washington DC' || jurisdiction === 'DC') &&
    periodStartDate === '2021-01-01' &&
    taxableIncome === 500
  ) {
    returnValue = 200;
    stateTaxRateData = `{
              "id": "11",
              "raw_data_id": "2",
              "jurisdiction": {
                  "id": "11",
                  "jurisdiction": "Washington DC",
                  "abbreviation": "DC"
              },
              "default_1": "8.25%",
              "tax_rate": "8.25%",
              "income_start": null,
              "income_end": null,
              "incremental_tax": null,
              "start_date": "2019-01-01",
              "end_date": "2099-12-31",
              "industry_id": "1",
              "research_support": "Some support text",
              "created_at": "2020-03-05 03:03:00",
              "updated_at": "2020-03-05 03:03:00",
              "tax_law_summary": "The District of Columbia corporate income tax rate is 8.25%.BOLDD.C. Code Ann. § 47-1807.02(a)(6).BOLD Subject to availability of funding and in accordance with D.C. Code Ann. § 47-181, the general corporate tax rate may be reduced incrementally to 9.2%, 9%, 8.75%, 8.5%, or 8.25%.",
              "tax_Law_Summary_Link": "https://www.bloomberglaw.com/product/tax/bbna/chart/2/10069/76e2502068c9ee3b91601c2df3d8e805",
              "upcomingRateChangeDate": "06/01/2022",
              "rate_type": "NotFixed"
          }`;
  } else {
    returnValue = 400;
    stateTaxRateData = `{
            "code":	"JR0001",
            "message":	"Invalid Params",
            "target":	"Workpaper",
            "details":	[{
                            "code":	"JR0001",
                            "message":	"Invalid Params",
                            "target":	"Workpaper"
                        }]
            
          }`;
  }

  return {
    returnValue,
    stateTaxRateData,
  };
}
