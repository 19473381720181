import { TransformationElementType } from '../TransformationElementType';
import SortElementInspector from '../../SortElementInspector';
import { FILTER_COLOR } from '../../shared/colors';
import iconImage from '../icons/sort_block_icon.svg';
import hintImage from '../icons/sort_hint_img.svg';

export class SortElementType extends TransformationElementType {
  static TYPE = 'sort';

  static HELP_TEXT = `The sort block allows you to sort your rows in ascending or descending order based on the values in the column of your choice. <img src=${hintImage} alt="sort hint" />`;

  constructor() {
    super(SortElementType.TYPE, 'Sort', FILTER_COLOR, iconImage, SortElementType.HELP_TEXT);
  }

  get initialData() {
    return {
      name: this.label,
      type: this.type,
      sortedFields: [
        {
          name: '',
          type: '',
          order: '',
        },
      ],
    };
  }

  get maxCount() {
    return -1;
  }

  get inspectorComponent() {
    return SortElementInspector;
  }

  applySourceElements(elementData, sourceElements) {
    return super.applySourceElements(elementData, sourceElements);
  }

  extractTypeData(elementData) {
    this.sortedFieldsExpand(elementData);
    return {
      ...super.extractTypeData(elementData),
      sortedFields: elementData.sortedFields,
    };
  }

  sortedFieldsExpand(elementData) {
    //check if object needs to be modified when field name hashes are present
    if (elementData?.fields && elementData?.fields[0]?.original_name) {
      elementData.sortedFields.forEach((sortedField, index) => {
        const validationFieldName =
          elementData.fields.find(element => element.original_name === sortedField.name)?.name || sortedField.name;
        elementData.sortedFields[index].name = validationFieldName;
      });
    }
  }
}
