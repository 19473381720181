const MessageType = {
  RenderCustomCell: 'Render Custom Cell',
  LoadWorkbookDataReferences: 'Load Workbook Data Reference',
  ReloadTabOnly: 'Reload Tab Only',
  CellPosition: 'Cell Position',
  CellTag: 'Cell Tag',
  CutPasteReference: 'Cut Paste Data Reference',
  ClearReferenceCell: 'Clear Data Reference Cell',
};
export default MessageType;
