import GC from '../../../../../../../SpreadSheets';

class CustomCommand {
  constructor(canUndo, executor) {
    this.canUndo = canUndo;
    this.execute = function (spread, options, isUndo) {
      if (isUndo) {
        GC.Spread.Sheets.Commands.undoTransaction(spread, options);
        return true;
      } else {
        GC.Spread.Sheets.Commands.startTransaction(spread, options);
        spread.suspendPaint();
        executor(spread, options);
        spread.resumePaint();
        GC.Spread.Sheets.Commands.endTransaction(spread, options);
        return true;
      }
    };
  }
}

export const DIRECTION = {
  UP: 'UP',
  DOWN: 'DOWN',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
};

const nextCellDirectionAction = {
  [DIRECTION.UP]: ({ row, col }) => ({ row: row - 1, col }),
  [DIRECTION.DOWN]: ({ row, col }) => ({ row: row + 1, col }),
  [DIRECTION.LEFT]: ({ row, col }) => ({ row, col: col - 1 }),
  [DIRECTION.RIGHT]: ({ row, col }) => ({ row, col: col + 1 }),
};

// Function to check if the given cell is within the sheet bounds
const isWithinBounds = (sheet, { row, col }) => {
  const rowCount = sheet.getRowCount();
  const colCount = sheet.getColumnCount();
  return row >= 0 && row <= rowCount && col >= 0 && col <= colCount;
};

// Function to get the next cell based on the direction and the current cell
const getNextCell = (sheet, cell, direction) => {
  const { row, col } = nextCellDirectionAction[direction](cell);
  return sheet.getCell(row, col);
};

// Function to check if cell is empty, returns boolean
const isEmptyCell = (sheet, { row, col }) => {
  return sheet.getValue(row, col) === null;
};

// Function to calculate the target cell based on the starting cell and the direction
const calculateTargetCell = (sheet, startingCell, direction) => {
  let { row, col } = startingCell;
  let nextCell = getNextCell(sheet, startingCell, direction);

  const initialCellState = isEmptyCell(sheet, startingCell) ? true : isEmptyCell(sheet, nextCell);

  while (isWithinBounds(sheet, nextCell) && isEmptyCell(sheet, nextCell) === initialCellState) {
    ({ row, col } = nextCell);
    nextCell = getNextCell(sheet, { row, col }, direction);
  }

  if (isWithinBounds(sheet, nextCell) && !isEmptyCell(sheet, nextCell)) {
    ({ row, col } = nextCell);
  }

  return { row, col };
};

const calculateDistance = (startingCell, targetCell, direction) => {
  if (direction === DIRECTION.RIGHT || direction === DIRECTION.LEFT) {
    const col = Math.abs(targetCell.col - startingCell.col) + 1;
    return { row: 1, col };
  } else {
    const row = Math.abs(targetCell.row - startingCell.row) + 1;
    return { row, col: 1 };
  }
};

// Function to calculate the new selection based on the starting cell, target cell, and direction
const calculateSelection = (startingCell, targetCell, direction, rowCount, colCount) => {
  let distance = calculateDistance(startingCell, targetCell, direction);

  // If the direction is negative, we start from the target cell
  if (direction === DIRECTION.LEFT || direction === DIRECTION.UP) {
    return {
      row: targetCell.row,
      col: targetCell.col,
      rowCount: direction === DIRECTION.UP ? distance.row : distance.row + rowCount - 1,
      colCount: direction === DIRECTION.LEFT ? distance.col : distance.col + colCount - 1,
    };
  }

  return {
    row: startingCell.row,
    col: startingCell.col,
    rowCount: direction === DIRECTION.DOWN ? distance.row : distance.row + rowCount - 1,
    colCount: direction === DIRECTION.RIGHT ? distance.col : distance.col + colCount - 1,
  };
};

const calculateLastSelectedCell = ({ row, col, rowCount, colCount }, direction) => {
  const directionCalucationMap = {
    [DIRECTION.RIGHT]: { row, col: col + colCount - 1 },
    [DIRECTION.LEFT]: { row, col },
    [DIRECTION.DOWN]: { row: row + rowCount - 1, col },
    [DIRECTION.UP]: { row, col },
  };

  return directionCalucationMap[direction];
};

// Main function to select cells to the end in the given direction
export const selectToEnd = (spread, direction) => {
  const sheet = spread.getActiveSheet();
  const selections = sheet.getSelections();
  const { row, col, rowCount, colCount } = selections[0];

  if (!selections) return;

  let startingCell = { row: sheet.getActiveRowIndex(), col: sheet.getActiveColumnIndex() };

  let targetCell = calculateTargetCell(sheet, startingCell, direction);

  if (rowCount > 1 || colCount > 1) {
    let lastSelectedCell = calculateLastSelectedCell(selections[0], direction);

    targetCell = calculateTargetCell(sheet, lastSelectedCell, direction);

    if (direction === DIRECTION.LEFT) {
      startingCell = {
        ...startingCell,
        col: col + colCount - 1,
      };
    }

    if (direction === DIRECTION.UP) {
      startingCell = {
        ...startingCell,
        row: row + rowCount - 1,
      };
    }
  }

  const newSelection = calculateSelection(startingCell, targetCell, direction, rowCount, colCount);

  sheet.setSelection(newSelection.row, newSelection.col, newSelection.rowCount, newSelection.colCount);
};

export const selectToEndCommands = {
  // Ctrl + Shift + left arrow command
  controlShiftLeftArrow: new CustomCommand(false, spread => {
    const sheet = spread.getActiveSheet();
    const selections = sheet.getSelections();

    const { row, col } = selections[0];

    selectToEnd(spread, DIRECTION.LEFT);
    if (row === -1 || col === -1) {
      row === -1 && selectToEnd(spread, DIRECTION.DOWN);
      selectToEnd(spread, DIRECTION.LEFT);
    }
  }),

  // Ctrl + Shift + right arrow command
  controlShiftRightArrow: new CustomCommand(false, spread => {
    const sheet = spread.getActiveSheet();
    const selections = sheet.getSelections();

    const { row, col } = selections[0];

    selectToEnd(spread, DIRECTION.RIGHT);
    if (row === -1 || col === -1) {
      selectToEnd(spread, DIRECTION.RIGHT);
    }
  }),

  // Ctrl + Shift + up arrow command
  controlShiftUpArrow: new CustomCommand(false, spread => {
    const sheet = spread.getActiveSheet();
    const selections = sheet.getSelections();

    const { row, col } = selections[0];

    selectToEnd(spread, DIRECTION.UP);
    if (row === -1 || col === -1) {
      col === -1 && selectToEnd(spread, DIRECTION.RIGHT);
      selectToEnd(spread, DIRECTION.UP);
    }
  }),

  // Ctrl + Shift + down arrow command
  controlShiftDownArrow: new CustomCommand(false, spread => {
    const sheet = spread.getActiveSheet();
    const selections = sheet.getSelections();

    const { row, col } = selections[0];

    selectToEnd(spread, DIRECTION.DOWN);
    if (row === -1 || col === -1) {
      selectToEnd(spread, DIRECTION.DOWN);
    }
  }),
};

export const initShortcutAboutSelectingToEnd = commandManager => {
  const { controlShiftLeftArrow, controlShiftRightArrow, controlShiftUpArrow, controlShiftDownArrow } =
    selectToEndCommands;

  const { left, right, up, down } = GC.Spread.Commands.Key;

  commandManager.register('controlShiftLeftArrow', controlShiftLeftArrow, left, true, true, false, false);
  commandManager.register('controlShiftRightArrow', controlShiftRightArrow, right, true, true, false, false);
  commandManager.register('controlShiftUpArrow', controlShiftUpArrow, up, true, true, false, false);
  commandManager.register('controlShiftDownArrow', controlShiftDownArrow, down, true, true, false, false);
};
