import { SINGLE, TABULAR } from '../../../../_shared/data-flows/outputFormatTypes';
import { IN_OUT_COLOR } from '../../shared/colors';
import { TransformationElementType } from '../TransformationElementType';
import OutputElementInspector from '../../OutputElementInspector';
import iconImage from '../icons/output_block_icon.svg';
import hintImage from '../icons/output_hint_img.svg';
import { OUTPUT } from '../types/shared/typesConstants';

export const outputTags = [
  { key: 'entity', label: 'Entity' },
  { key: 'account', label: 'Account' },
  { key: 'accountDescription', label: 'Account description' },
];

export class OutputElementType extends TransformationElementType {
  static TYPE = OUTPUT;

  static HELP_TEXT = `The output block is the last step in a data transformation workflow. Its purpose is to configure how your data will appear in your workpapers. 
        You may choose to pull individual values into the desired workpaper cells, or pull the data in as a table on a new sheet.<img src=${hintImage} alt="output hint" />`;

  constructor() {
    super(OutputElementType.TYPE, 'Output', IN_OUT_COLOR, iconImage, OutputElementType.HELP_TEXT);
  }

  get initialData() {
    let data = {
      name: this.label,
      type: this.type,
      outputFormat: TABULAR,
      tabularOutput: {},
      singleOutput: null,
      filters: [],
    };

    return data;
  }

  get outPorts() {
    return null;
  }

  get inspectorComponent() {
    return OutputElementInspector;
  }

  applySourceElements(elementData, sourceElements) {
    const newElementData = super.applySourceElements(elementData, sourceElements);
    const { fields } = newElementData;

    this.reconcileOutputFormat(elementData, newElementData, fields);

    return {
      ...newElementData,
    };
  }

  populateFilters(fields) {
    return fields.map(field => field.name);
  }

  populateSingleOutput(fields) {
    const outputField = fields.find(field => field.type === 'numeric');
    return { ...outputField };
  }

  extractTypeData(elementData) {
    elementData.filters = this.populateFilters(elementData.fields);
    elementData.singleOutput = this.populateSingleOutput(elementData.fields);
    elementData.outputFormat = SINGLE;
    return {
      ...super.extractTypeData(elementData),
      outputFormat: elementData.outputFormat,
      singleOutput: elementData.singleOutput,
      tabularOutput: elementData.tabularOutput,
      filters: elementData.filters || [],
      export: elementData?.export,
    };
  }

  getPreviewColumns(elementData) {
    const fields = elementData.fields || [];

    switch (elementData.outputFormat) {
      case TABULAR:
        return fields.filter(f => !!elementData.tabularOutput[f.name]);
      case SINGLE:
        return fields.map(f => ({ ...f, isSelected: f.name === elementData.singleOutput?.name }));
      default:
        return [];
    }
  }

  reconcileOutputFormat(elementData, newElementData, fields) {
    const { singleOutput, tabularOutput } = elementData;

    newElementData.outputFormat = elementData.outputFormat || TABULAR;

    if (newElementData.outputFormat === SINGLE) {
      if (singleOutput && !fields.find(f => f.name === singleOutput.name)) {
        delete newElementData.singleOutput;
      }
    } else {
      newElementData.tabularOutput = fields.reduce((acc, sourceField) => {
        const { name } = sourceField;
        acc[name] = tabularOutput?.hasOwnProperty(name) ? tabularOutput[name] : true;
        return acc;
      }, {});
    }
  }
}
