import React from 'react';
import { BTEmptyState } from '@btas/jasper';
import newDataFlowIcon from '../../_shared/icons/new_data_flow.svg';
import './EmptyState/styles.scss';

export default function EmptyState({ onCreateDataFlowClick }) {
  return (
    <BTEmptyState
      buttonText="New data transformation workflow"
      className="wkp-data-flows-empty-container"
      emptyStateDescription="Data transformation workflows allow you to centralize your source data and automate your data transformation steps."
      icon={<img alt="New data transformation workflow icon" src={newDataFlowIcon} />}
      message="No data flows have been created."
      onButtonClick={onCreateDataFlowClick}
    />
  );
}
