import React from 'react';
import uploadIcon from './EmptyGrid/upload_icon.svg';

export default function EmptyGrid({ onBrowseClick }) {
  return (
    <>
      <h2>No workpapers have been imported.</h2>
      <div className="wkp-empty-state-container">
        <h2>
          <img alt="upload icon" src={uploadIcon} />
          <span id="wkp-browse-text" onClick={onBrowseClick}>
            Browse
          </span>{' '}
          or drop files here.
        </h2>
        <h4>Supported file formats: Excel (*.xlsx), comma separated (*.csv)</h4>
      </div>
    </>
  );
}
