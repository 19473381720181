import { useEffect, useState } from 'react';
import * as api from '../../_shared/fetch';

export function useMaintenanceMode() {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const defaultTimeout = 60_000;
  useEffect(() => {
    let timer = null;
    const fetchMaintenance = async () => {
      let nextCheckDelay;
      try {
        const headers = {
          pragma: 'no-cache',
          'cache-control': 'no-store',
        };
        const maintanance = await (await api.get('/maintenance.json', { headers })).json();
        nextCheckDelay = maintanance.nextCheckDelay;
        const mode = maintanance.maintenanceMode === 'true' || maintanance.maintenanceMode === true;
        setMaintenanceMode(mode);
      } finally {
        timer = setTimeout(fetchMaintenance, nextCheckDelay || defaultTimeout);
      }
    };

    fetchMaintenance();

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (maintenanceMode) window.location.replace(`${document.baseURI}views/site-unavailable.html`);
  }, [maintenanceMode]);
}
