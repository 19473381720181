import { initialize } from 'launchdarkly-js-client-sdk';
import { getUser } from './auth';
import { EnviromentName, launchDarklyClientID, usersServiceHostName } from '../../configs/params';
import { transformToCurrentFeatureFlags, updateFeatureFlags } from '../../utils/featureFlags';
import * as fetch from './fetch';

export default class LDClient {
  static instance = null;
  isInitDone = false;
  connection = undefined;
  flags = [];

  static getInstance() {
    if (LDClient.instance == null) {
      LDClient.instance = new LDClient();
    }
    return this.instance;
  }

  getFlags() {
    return this.flags;
  }

  async init(cb) {
    const userInfo = getUser();
    const parsedUserInfo = userInfo != null && (typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo);
    const { companyId = '', firstName = '', lastName = '', userId } = parsedUserInfo;

    const LDSingleKindContext = {
      kind: 'user',
      key: userId,
      firstName,
      lastName,
      companyId,
    };

    this.connection = initialize(launchDarklyClientID, LDSingleKindContext);
    await this.connection.waitForInitialization();

    // initialization succeeded, flag values are now available and events can be subscribed
    this.isInitDone = true;

    //subscribe on change event to auto reload page
    this.connection.on('change', settings => {
      const updateFlags = updateFeatureFlags(settings, this.flags);
      this.flags = updateFlags;

      cb(updateFlags);
    });
  }

  async setFlags() {
    const data = this.connection.allFlags();

    if (EnviromentName && EnviromentName === 'LOCAL') {
      // Get feature flag overrides

      try {
        const response = await fetch.get(`${usersServiceHostName}/api/v1/feature-flags/overrides`);
        if (!response.ok) {
          throw data.error;
        }
        const featureFlagOverrides = await response.json();

        if (featureFlagOverrides) {
          for (const overriddenFlagName in featureFlagOverrides) {
            data[overriddenFlagName] = featureFlagOverrides[overriddenFlagName];
          }
        }
      } catch (error) {
        // If error is generated then we use the LD default values
        console.error('Error when retrieving feature flag overrides: ', error);
      }
    }

    this.flags = transformToCurrentFeatureFlags(data);
  }
}
