import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/filter_block_icon.svg';
import hintImage from '../icons/filter_hint_img.svg';
import { FILTER_COLOR } from '../../shared/colors';
import FilterElementInspector from '../../FilterElementInspector';
import { FILTER } from '../types/shared/typesConstants';
import { generateFormulaWithInternalName, generateFormulaWithOriginalName } from '../../../shared/utils/FieldHashUtils';

const FilterTableOutput = Object.freeze({
  T: 'out',
  F: 'F',
});
export class FilterElementType extends TransformationElementType {
  static TYPE = FILTER;
  static HELP_TEXT = `The filter block allows you to filter the data set down to fewer rows based on the criteria provided.<img src=${hintImage} alt="Filter hint" />`;

  constructor() {
    super(FilterElementType.TYPE, 'Filter', FILTER_COLOR, iconImage, FilterElementType.HELP_TEXT, 'out');
  }

  get initialData() {
    return {
      name: this.label,
      type: this.type,
      formula: '',
      viewToggle: false,
      filterFields: { field: '', operator: '' },
      filterValue: '',
      advancedFormula: '',
      filterTableOutput: FilterTableOutput.T,
      AIGenerated: false,
    };
  }

  get maxCount() {
    return -1;
  }

  get inspectorComponent() {
    return FilterElementInspector;
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      formula: generateFormulaWithInternalName(elementData.formula, elementData.fields),
      viewToggle: elementData.viewToggle,
      filterCriteria: elementData.filterCriteria,
      advancedFormula: generateFormulaWithInternalName(elementData.advancedFormula, elementData.fields),
      outputPorts: this.outPorts.length,
      fieldsByPort: elementData.fieldsByPort,
      fields: elementData.fields,
      AIGenerated: elementData?.AIGenerated,
    };
  }

  get outPorts() {
    return [FilterTableOutput.T, FilterTableOutput.F];
  }

  applySourceElements(elementData, sourceElements) {
    let fields = elementData.fields || [];

    if (sourceElements.in) {
      const newElementData = super.applySourceElements(elementData, sourceElements);
      fields = newElementData.fields;
    }

    const { formula, advancedFormula } = this.generateReadableFormulas(
      elementData.formula || '',
      elementData.advancedFormula || '',
      elementData.fields || []
    );

    let fieldsByPort = {
      [FilterTableOutput.T]: fields,
      [FilterTableOutput.F]: fields,
    };

    return {
      ...elementData,
      fieldsByPort,
      fields: fields,
      formula,
      advancedFormula,
    };
  }

  generateReadableFormulas(normal, advanced, fields) {
    return {
      formula: generateFormulaWithOriginalName(normal, fields),
      advancedFormula: generateFormulaWithOriginalName(advanced, fields),
    };
  }

  addPortsToInstance(instance) {
    super.addPortsToInstance(instance);

    if (this.inPorts) {
      this.inPorts.forEach(inPort => {
        instance.addInPort(inPort);
      });
      instance.portProp('in', 'attrs', { '.port-label': { text: '' } });
    }

    if (this.outPorts) {
      this.outPorts.forEach(outPort => {
        instance.addOutPort(outPort);
      });
      instance.portProp('out', 'attrs', { '.port-label': { text: 'T' } });
    }
  }
}
