import React from 'react';
import { BTModal, BTButton, BTGrid } from '@btas/jasper';
import useGrid from './SourceFilesListModal/useGrid';
import { wijmoKey } from '../../../../configs/params';
import './SourceFilesListModal/styles.scss';

export default function SourceFilesModal({ dataFlowId, dataFlowName, show, onClose, onViewSourceData }) {
  const [{ isLoading, gridData }] = useGrid({ dataFlowId });

  const fileNameCell = ({ rowData }) =>
    rowData && (
      <BTButton btStyle="link" onClick={() => handleFileNameClick(rowData)}>
        {rowData.fileName}
      </BTButton>
    );

  const handleFileNameClick = rowData => {
    const { fileName, fileVersionId, importedDate } = rowData;
    const viewSourceData = {
      dataFlow: {
        id: dataFlowId,
        name: dataFlowName,
      },
      fileName,
      fileVersionId,
      importedDate,
    };

    onViewSourceData(viewSourceData);
  };

  return (
    <BTModal
      className="wkp-imported-source-files-modal"
      show={show}
      size="modal-lg"
      title={`${dataFlowName} - Source files`}
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <BTGrid data={gridData} gridHeight={350} isLoading={isLoading} loadingType="spinner" wijmoKey={wijmoKey}>
          <BTGrid.Header key="fileName" binding="fileName" cellTemplate={fileNameCell} minWidth={140} width={250}>
            File name
          </BTGrid.Header>
          <BTGrid.Header key="input" binding="input" minWidth={140} width="*">
            Input
          </BTGrid.Header>
          <BTGrid.Header key="importedDate" binding="importedDate" minWidth={140} width="*">
            Imported
          </BTGrid.Header>
          <BTGrid.Header key="lastModifiedUser" binding="lastModifiedUser" minWidth={140} width="*">
            Imported by
          </BTGrid.Header>
        </BTGrid>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton btStyle="primary" onClick={onClose}>
          Close
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
