import React from 'react';
import { useParams } from 'react-router-dom';
import { HistoryItem } from './HistoryList/HistoryItem';
import { getFormattedDate } from '../../../../../_shared/dateFormatting';

export default function HistoryList({ versions, onVersionNameChange, setReadOnlyModeDisplayName }) {
  const { versionId } = useParams();

  const getItemDisplayName = ({ name, lastModifiedBy, lastModifiedDateUtc }) =>
    name || `${lastModifiedBy} | ${getFormattedDate(lastModifiedDateUtc)}`;

  const getItemInitials = displayName => {
    return !displayName
      ? ''
      : displayName
          .split(' ')
          .map(n => n[0])
          .join('')
          .substring(0, 2)
          .toUpperCase();
  };

  return (
    <div className="list-group wkp-history-list">
      {versions.map(item => {
        const { id, latest, lastModifiedBy } = item;
        const active = (!versionId && latest) || id === versionId;
        const itemDisplayName = `${getItemDisplayName(item)} ${latest ? '(latest)' : ''}`;
        const itemInitials = getItemInitials(lastModifiedBy);

        return (
          <HistoryItem
            key={id}
            active={active}
            displayName={itemDisplayName}
            getItemDisplayName={getItemDisplayName}
            initials={itemInitials}
            item={item}
            setReadOnlyModeDisplayName={setReadOnlyModeDisplayName}
            onVersionNameChange={onVersionNameChange}
          />
        );
      })}
    </div>
  );
}
