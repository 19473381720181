import { useEffect, useState } from 'react';
import { isLoading, Status } from '../../shared/Status';
import { getRunningDataFlowInfo } from '../../DataFlowsPage/apis';

export default function useDataFlowRunStatus(dataFlowState) {
  const [isRunning, setIsRunning] = useState(false);
  const [dataFlowRun, setDataFlowRun] = useState();

  useEffect(() => {
    let isCancelled = false;
    let isRunning;

    if (dataFlowRun && dataFlowRun.startTimestamp >= dataFlowState.lastModifiedDate) {
      isRunning = isLoading(dataFlowRun?.status);
      setIsRunning(isRunning);
    }

    if (isRunning) {
      refreshRunningStatus();
    } else if (dataFlowRun?.status === Status.Failed && !isCancelled) {
      setIsRunning(false);
    }

    async function refreshRunningStatus() {
      await new Promise(resolve => setTimeout(resolve, 1000));

      if (!isCancelled) {
        try {
          const refreshedDataFlowRun = await getRunningDataFlowInfo(dataFlowRun.id);
          if (!isCancelled) {
            // Update dataflow run data state
            setDataFlowRun(refreshedDataFlowRun);
          }
        } catch (error) {
          setIsRunning(false);
        }
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [dataFlowRun, setDataFlowRun, dataFlowState.lastModifiedDate]);

  return [isRunning, dataFlowRun, setDataFlowRun];
}
