import React, { useMemo } from 'react';
import ElementInspector from './shared/ElementInspector';
import { BTAlert } from '@btas/jasper';

export const MultiMergeElementInspector = ({ elementData, elementType, updateData, elementId, isDirty }) => {
  const connectedInputs = (inputCount, inputNames) => {
    if (inputCount > 0) {
      return inputNames.map(inputName => <p>{inputName}</p>);
    }
    return (
      <p>
        No inputs are connected. Connect one or more blocks to the input port to merge their rows. Note that field names
        and their respective data types must match among all inputs. The multi merge block allows up to 50 inputs to be
        connected.{' '}
      </p>
    );
  };

  const findUpstramElementWithDifferences = upstreamElementsConnected => {
    // To create a key for comparision based on attributes
    const createComparisonKey = obj => `${obj.name}:${obj.type}`;

    // To check if two arrays if objects are different based on attributes
    const areDifferent = (elementLeft, elementRight) => {
      const leftSet = new Set(elementLeft?.fields?.map(createComparisonKey));
      const rightSet = new Set(elementRight?.fields?.map(createComparisonKey));

      if (leftSet.size !== rightSet.size) return true;

      for (let key of leftSet) {
        if (!rightSet.has(key)) return true;
      }
      return false;
    };

    const result = [];

    // Compare each element with every other element
    for (let i = 0; i < upstreamElementsConnected.length; i++) {
      for (let j = i + 1; j < upstreamElementsConnected.length; j++) {
        if (areDifferent(upstreamElementsConnected[i], upstreamElementsConnected[j])) {
          result.push(upstreamElementsConnected[i], upstreamElementsConnected[j]);
        }
      }
    }

    // Remove duplicates from the result
    return Array.from(new Set(result));
  };

  const alerts = useMemo(() => {
    const differences = findUpstramElementWithDifferences(elementData.upstreamElementsConnected);
    if (differences.length > 0) {
      return [
        {
          style: 'danger',
          content:
            'The number of fields, the field names, and their respective data types must match among all inputs.',
        },
      ];
    }
  }, [elementData]);

  return (
    <>
      <ElementInspector {...{ alerts, elementData, elementId, elementType, updateData, isDirty }}>
        <div className="wkp-merge-element-inspector">
          <div>
            <p>
              <strong>Connected Inputs ({elementData.inputCount})</strong>
            </p>
            {connectedInputs(elementData.inputCount, elementData.inputNames)}
          </div>
        </div>
      </ElementInspector>
      {elementData?.upstreamElementsConnected?.length === 50 && (
        <div className="wkp-multimerge-inspector-footer">
          <BTAlert key="multimerge-max-element-info" btStyle="info">
            The multi merge block allows a maximum of 50 inputs.
          </BTAlert>
        </div>
      )}
    </>
  );
};
