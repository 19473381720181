import React, { useContext } from 'react';
import { BTButton, BTIcon } from '@btas/jasper';
import { startJob } from '../../_shared/jobs/apis';
import { WORKPAPER_RESTORE_RECYCLE_BIN_JOB_TYPE } from '../../_shared/jobs/jobTypes';
import useJobsOverlay from '../common/JobsOverlayWindow/useJobsOverlay';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { restoreWorkpapers } from './apis';
import UserPermissionsContext from '../../_shared/UserPermissionsContext';
import { eventTrackTagNames } from '../../_shared/EventTrackingContext/constants';

export default function WorkpapersActionButtons({ actions, cleanGridSelection, config, loadJobs, selection }) {
  const { fetchWorkpapers } = actions;
  const { userPermissions } = useContext(UserPermissionsContext);

  const { gridFilters } = config;
  const { bindOnJobCompleted } = useJobsOverlay();

  const onRestoreClick = async selection => {
    let workpapersList = [];

    for (const item of selection) {
      const { id: workpaperId, name: fileName } = item;
      const { jobId } = await startJob({
        workpaperId,
        jobType: WORKPAPER_RESTORE_RECYCLE_BIN_JOB_TYPE,
        payload: {
          fileName,
        },
      });
      bindOnJobCompleted(
        jobId,
        function (startTime) {
          CustomLogger.pushLog(CustomLogger.operations.RESTORE.WORKPAPER, {
            duration: (Date.now() - startTime).toString(),
            workpaperId,
            fileName,
          });
        },
        Date.now()
      );
      await loadJobs();
      workpapersList.push({ workpaperId, jobId });
      await loadJobs();
    }
    cleanGridSelection();
    await restoreWorkpapers(workpapersList);
    await fetchWorkpapers({ filters: gridFilters });
  };

  const noItemsSelected = selection.length === 0;

  return (
    <div className="wkp-grid-actions">
      {userPermissions.includes('create_workpaper') && (
        <BTButton
          btStyle="link"
          btType="icon"
          data-track-tag={eventTrackTagNames.interactive}
          disabled={noItemsSelected}
          icon={<BTIcon icon="undo" />}
          id="restore-button"
          onClick={() => onRestoreClick(selection)}
        >
          Restore
        </BTButton>
      )}
    </div>
  );
}
