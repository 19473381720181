import React from 'react';
import { ReactComponent as FixedAssetsIcon } from './icon_fixed_assets.svg';
import { ReactComponent as ProvisionIcon } from './icon_provision.svg';
import { ReactComponent as ResearchIcon } from './icon_research.svg';
import { fixedAssetsLoginPageUrl } from '../../../../configs/params';

const iconSize = 22;

export const applications = [
  {
    link: `${fixedAssetsLoginPageUrl}/fa`,
    name: 'Fixed Assets',
    icon: <FixedAssetsIcon height="100%" width={iconSize} />,
  },
  {
    link: 'https://provision.bloombergtax.com/',
    name: 'Provision',
    icon: <ProvisionIcon height="100%" width={iconSize} />,
  },
  {
    link: 'https://bloombergtax.com/',
    name: 'Research',
    icon: <ResearchIcon height="100%" width={iconSize} />,
  },
];
