import { useEffect, useRef } from 'react';

export function useInterval(callback, delay) {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay !== null) {
      intervalRef.current = setInterval(tick, delay);
      return async () => {
        await clearInterval(intervalRef.current);
        intervalRef.current = null;
      };
    }
  }, [delay]);
  return intervalRef.current;
}
