import { generatePresignedUrls, createNodeObject, existedNode, updateNodeObject } from './apis';
import { IntegrationType, ResourceType } from './constants';

export async function sendToFA(
  currentRun,
  outputsExportedFromModal,
  id,
  dataFlowName,
  taxPeriod,
  dataFlowServiceHostName
) {
  const outputsUsed = outputsExportedFromModal ? outputsExportedFromModal : currentRun;

  const filteredOutputDataLocations = Object.fromEntries(
    Object.entries(outputsUsed.outputDataLocations).filter(([, value]) => value.export)
  );

  const outputInfo = [];

  for (const [key, value] of Object.entries(filteredOutputDataLocations)) {
    const { location } = value;
    outputInfo.push({ outputId: key, location });
  }

  const presignedResult = await generatePresignedUrls(outputInfo);

  const filesData = [];

  for (const [key, value] of Object.entries(filteredOutputDataLocations)) {
    const fileData = {
      name: `${currentRun.configuration.elements[key].name}.csv`,
      location: value.location,
      presignedUrl: presignedResult.presignedUrls.find(output => output[key])?.[key],
      destination: value.destination,
    };

    filesData.push(fileData);
  }

  const objectPayload = {
    resourceId: id,
    name: dataFlowName,
    resourceUrl: `${dataFlowServiceHostName}/data-flows/${id}/editor`,
    resourceTaxPeriod: taxPeriod,
    resourceType: ResourceType.Dataflow,
    files: filesData,
    canSendEvent: true,
    integrationType: IntegrationType.FixedAssets,
  };

  const nodeExisted = await existedNode(id);

  const createResult = nodeExisted?.existed
    ? await updateNodeObject(objectPayload)
    : await createNodeObject(objectPayload);

  return createResult;
}
