import * as fetch from '../../../_shared/fetch';
import { dataFlowServiceHostName } from '../../../../configs/params';

export async function getDataFlowSourceFileVersion({ dataFlowId, sourceFileVersionId, limit }) {
  const response = await fetch.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-file-versions/${sourceFileVersionId}/records?limit=${limit}`
  );
  const data = await response.json();

  return data;
}
