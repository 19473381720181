// function to validate both column name and value fields (generalized to column name)
import { useCallback, useState } from 'react';
import { getOriginalName } from '../../shared/utils/FieldHashUtils';

export default function useColumnValidation() {
  const [columnError, setColumnNameError] = useState('');

  const validateColumn = useCallback((columnName, columnValue, checkedFields, inputFields) => {
    const columnNameInLowerCase = columnName.trim().toLowerCase();

    if (
      columnValue.trim().toLowerCase() === columnNameInLowerCase ||
      inputFields.find(
        f => getOriginalName(f).trim().toLowerCase() === columnNameInLowerCase && checkedFields[f.name] === false
      )
    ) {
      setColumnNameError('Column name must be unique.');
    } else {
      setColumnNameError('');
    }
  }, []);

  return { validateColumn, columnError };
}
