import { useReducer } from 'react';
import reducer, {
  initialEditorState,
  IS_COMMANDS_SAVING,
  TOGGLE_ON_SAVE,
  TRIGGER_SAVE,
} from './reducers/editorReducer';

/**
 * Custom hook created to be able to create and manage the differents dispatch actions.
 * @returns {editorState, actions}
 */
export default function useEditorReducer() {
  const [editorState, dispatch] = useReducer(reducer, initialEditorState);

  const handleOnToggleSave = value => {
    dispatch({ type: TOGGLE_ON_SAVE, payload: value });
  };

  const handleTriggerSave = value => dispatch({ type: TRIGGER_SAVE, payload: value });

  const handleIsSaving = value => dispatch({ type: IS_COMMANDS_SAVING, payload: value });

  return { editorState, actions: { handleOnToggleSave, handleTriggerSave, handleIsSaving } };
}
