import { ElementType } from './ElementType';
import { LINK_COLOR } from '../shared/colors';
import iconImage from './icons/annotation_block_icon.svg';
import { ANNOTATION } from './types/shared/typesConstants';
import { dia } from '@clientio/rappid';

class Rectangle extends dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: ANNOTATION,
      size: {
        width: 170,
        height: 30,
      },
      attrs: {
        body: {
          width: 'calc(w)',
          height: 'calc(h)',
          fill: 'transparent',
          stroke: 'none',
          strokeDasharray: '5,5',
        },
        label: {
          text: 'Type something',
          fontSize: 16,
          fontFamily: 'AvenirNextP2ForBBG, Arial, Helvetica, sans-serif',
          fill: 'black',
          type: 'annotation',

          textVerticalAnchor: 'middle',
          textAnchor: 'start',

          //16px X 1.2em aprox
          lineHeight: 19,
          y: 'calc(.5 * h)',
          x: 2,
          //necessary to break the words when rezising
          textWrap: {
            width: -4,
            height: -5,
            ellipsis: true,
          },
        },
      },
    };
  }

  preinitialize() {
    this.markup = [
      {
        tagName: 'rect',
        selector: 'body',
        className: `df-block-canvas df-${ANNOTATION}-block-canvas`,
      },
      {
        tagName: 'text',
        selector: 'label',
      },
    ];
  }
}

export class AnnotationElementType extends ElementType {
  static TYPE = ANNOTATION;
  static LABEL = 'Text';
  static BLOCK_COLOR = LINK_COLOR;
  static ICON_IMAGE = iconImage;
  static HELP_TEXT = 'Add text to the canvas to annotate your data transformation workflow.';

  constructor() {
    const { TYPE, LABEL, BLOCK_COLOR, ICON_IMAGE, HELP_TEXT } = AnnotationElementType;

    super(TYPE, LABEL, BLOCK_COLOR, ICON_IMAGE, HELP_TEXT);
  }

  createGraphInstance() {
    return new Rectangle();
  }

  canPreview() {
    return false;
  }

  getPreviewColumns() {
    return false;
  }

  restoreInstance({ id, bounds, name, text }) {
    const instance = this.createGraphInstance();

    instance.resize(bounds.width, bounds.height);
    instance.attr('label/text', text);
    instance.position(bounds.left, bounds.top);
    instance.prop('id', id);
    instance.attr('.name/text', name);

    return instance;
  }

  applySourceElements(elementData) {
    return elementData;
  }

  extractTypeData(elementData) {
    return elementData;
  }
}
