import React from 'react';
import { BTModal, BTButton } from '@btas/jasper';

export const DataFlowConfirmationModal = ({ modalState }) => {
  const { show, title, message, confirmLabel, cancelLabel, modalStyle, onConfirm, onCancel } = modalState;
  return (
    <BTModal btStyle={modalStyle || ''} show={show} title={title || 'Confirm'} onCloseClick={onCancel}>
      <BTModal.Body>{message || 'Are you sure?'}</BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onCancel}>{cancelLabel || 'Cancel'}</BTButton>
        <BTButton btStyle="primary" onClick={onConfirm}>
          {confirmLabel || 'Continue'}
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};
