import React from 'react';
import './WorkpapersCategoryComponent/styles.scss';
import { BTButton } from '@btas/jasper';

const WorkpapersCategoryComponent = ({ icon, text, className = '', onClick, ...props }) => (
  <BTButton
    btStyle="link"
    className={`wkp-button ${className}`}
    icon={<img alt={`${text} icon`} className="wkp-button-icon" src={icon} />}
    onClick={onClick}
    {...props}
  >
    <label className="wkp-button-label">{text} </label>
  </BTButton>
);

export default WorkpapersCategoryComponent;
