import React, { useState, useEffect, useContext } from 'react';
import { BTForm, BTComboBox } from '@btas/jasper';
import { getComboboxData, getWorkflowStatus, putWorkflowStatus } from './api';
import { BTAlert } from '@btas/jasper';
import EditorContext from '../../../../EditorContext';
import ChangeFinalStatusModal from './changeFinalStatusModal';
import { workpaper_status } from '../../../shared/constants';
import UserPermissionsContext from '../../../../../../_shared/UserPermissionsContext';
import { eventNames } from '../../../../../../_shared/EventTrackingContext/constants';
import { EventTrackingContext } from '../../../../../../_shared/EventTrackingContext';
import { getFakeEvent, itemTypes, setPropValues } from '../../../../../../_shared/EventTrackingContext/utils';

export default function Status({ id }) {
  const { isLocked } = useContext(EditorContext);
  const { trackEvent } = useContext(EventTrackingContext);
  const [status, setStatus] = useState('');
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [showAlert, setAlert] = useState({
    show: false,
    msg: 'Success',
    status: 'success',
  });
  const [comboboxOptions, setComboboxOptions] = useState([]);
  const { userPermissions } = useContext(UserPermissionsContext);

  useEffect(() => {
    async function get() {
      const createComboboxItem = text => (text ? { value: text, label: text } : undefined);
      const data = await getWorkflowStatus(id);
      setStatus(createComboboxItem(data?.status));
    }
    get();
  }, [id]);

  useEffect(() => {
    async function get() {
      const data = await getComboboxData();
      setComboboxOptions(data?.statuses.map(e => ({ value: e, label: e })));
    }
    get();
  }, []);

  useEffect(() => {
    if (showAlert.show) {
      setTimeout(() => {
        setAlert({ show: false });
      }, 2000); // 2 seconds
    }
  }, [showAlert.show]);

  const handleComboboxChange = async event => {
    setStatus(event);
    const newStatus = event?.value || undefined;

    if (status.value === workpaper_status.Final && newStatus !== workpaper_status.Final) {
      setShowChangeStatusModal(true);
    } else {
      updateWorkflowStatus(newStatus);
    }
  };

  const updateWorkflowStatus = async newStatus => {
    try {
      await putWorkflowStatus({
        id: id,
        status: newStatus,
      });

      setAlert({ show: true, msg: 'Success', status: 'success' });

      if (newStatus === workpaper_status.Final) window.location.reload();
    } catch (error) {
      const errors = error.filter(e => e.target).reduce((prev, curr) => ({ ...prev, [curr.target]: curr.message }), {});
      setAlert({ show: true, msg: errors, status: 'danger' });
    }
  };

  const handleChangeStatusModalClose = () => setShowChangeStatusModal(false);

  const handleChangeStatus = async () => {
    await updateWorkflowStatus(status.value);
    setShowChangeStatusModal(false);
    window.location.reload();
  };

  return (
    <>
      <BTForm className="wkp-meta-form-fields">
        <BTForm.FormGroup
          data-testid="workflow-status-field"
          htmlFor="workflow-status"
          id="workpaper-status-dropdown"
          label="Status"
        >
          <BTComboBox
            isSearchable
            disabled={
              !userPermissions.includes('edit_workpaper_status') ||
              (!(status?.value === workpaper_status.Final) && isLocked)
            }
            id="workflow-status"
            maxMenuHeight={100}
            options={comboboxOptions}
            value={status}
            onChange={e => {
              // e.target does not exist, use the fake event with the specified tag
              const fakeEvent = getFakeEvent(itemTypes.DIV, 'workpaper-status-dropdown');

              // Track the event
              trackEvent(eventNames.interactiveClick, setPropValues(fakeEvent, eventNames.interactiveClick, null));

              // Handler
              handleComboboxChange(e);
            }}
          />
        </BTForm.FormGroup>
      </BTForm>

      {showAlert.show && <BTAlert btStyle={showAlert.status}>Saved</BTAlert>}
      {showChangeStatusModal && (
        <ChangeFinalStatusModal
          show={showChangeStatusModal}
          onClose={handleChangeStatusModalClose}
          onConfirmChange={handleChangeStatus}
        />
      )}
    </>
  );
}
