import { BTForm, BTSwitch } from '@btas/jasper';
import { React, useCallback, useMemo, useState, useRef, useContext } from 'react';
import { BOOLEAN } from './shared/fieldTypes';
import ElementInspector from './shared/ElementInspector';
import { useDebouncedEffect } from './shared/useDebouncedEffect';
import FieldsToFilter from './FilterElementInspector/FilterCriteria';
import generateFormula from './FilterElementInspector/filterCriteriaToFormula';
import useFormulaValidation from './FormulaElementInspector/useFormulaValidation';
import FilterTextArea from './shared/FilterTextArea';
import AILabModal from './ExpressionGeneratorModal/AILabModal';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import UserPreferencesContext from '../../_shared/UserPreferencesContext';
import { WKP_AI_FORMULA_GENERATION } from '../../../constants/featureFlags';
import DisclaimerModal from './ExpressionGeneratorModal/ExpressionGeneratorDisclaimer';
import ExpressionGeneratorModal from './ExpressionGeneratorModal/ExpressionGeneratorModal';
import { getOriginalName, getOriginalNames } from '../shared/utils/FieldHashUtils';
import './FilterElementInspector/styles.scss';
import AiGenerated from '../../_shared/AiGenerated';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';

const FilterElementInspector = ({ elementData, elementType, updateData, elementId, inputElements, isDirty }) => {
  const { userPreferences, setUserPreferences } = useContext(UserPreferencesContext);
  const { formula, viewToggle, filterCriteria } = elementData;
  const { validateFormula, formulaError } = useFormulaValidation(filterCriteria);
  const [disclaimer, setDisclaimer] = useState(false);
  const [isDisclaimerReadOnly, setIsDisclaimerReadOnly] = useState(false);
  const [isAiGenEnabled, setIsAiGenEnabled] = useState(false);
  const { hasAiEnabled: aiEnabled } = userPreferences;
  const prevPromptRef = useRef('');

  const fields = useMemo(() => {
    return inputElements.in?.elementData?.fields || [];
  }, [inputElements]);

  useDebouncedEffect(() => validateFormula(formula, fields, [BOOLEAN]), 350, [validateFormula, fields, formula]);

  const autoCompletefields = useMemo(() => (fields ? getOriginalNames(fields) : []), [fields]);

  const generateInputArray = function (fields) {
    return fields.map(field => ({
      label: getOriginalName(field),
      value: getOriginalName(field),
    }));
  };

  const fieldOptions = useMemo(() => {
    if (fields?.length === 0 && formula && elementData?.filterCriteria) {
      // filterCriteria has been defined but fields are empty, meaning block has been disconnected from input and should display filter values
      const criteriaArr = elementData.filterCriteria.map(criteria => {
        return {
          label: criteria.field,
          value: criteria.field,
        };
      });
      // remove possible duplicates from filterCriteria
      const uniqueArr = criteriaArr.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          criteriaArr.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      return uniqueArr;
    } else if (
      fields.length !== 0 &&
      formula &&
      elementData?.filterCriteria &&
      !elementData?.filterCriteria.every(r => fields.some(e => e.name === r.field))
    ) {
      //filter criteria and fields are defined but not matching, meaning block has been connected to a new input block without updating the filter fields
      const criteriaArr = elementData?.filterCriteria.map(criteria => ({
        label: criteria.field,
        value: criteria.field,
      }));

      const inputArr = generateInputArray(fields);

      const concat = inputArr.concat(criteriaArr);
      //remove any duplicate column names
      const uniqueArr = concat.filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          concat.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          })
        );
      });
      return uniqueArr;
    } else {
      return generateInputArray(fields);
    }
  }, [fields, formula, elementData]);

  const constructFormula = useCallback(
    filterCriteria => {
      return generateFormula(filterCriteria, fields);
    },
    [fields]
  );

  const handleFormulaChange = value => {
    if (elementData?.AIGenerated && elementData?.advancedFormula !== value) {
      setAiGenerated(false);
    }
    updateData({ advancedFormula: value, formula: value });
  };

  const onUIToggle = viewToggle => {
    updateData({ viewToggle: !viewToggle });

    if (viewToggle) {
      updateData({ formula: constructFormula(filterCriteria) });
    } else {
      if (elementData.advancedFormula === undefined || elementData.advancedFormula === '') {
        updateData({ advancedFormula: constructFormula(filterCriteria) });
      }
      updateData({ formula: elementData.advancedFormula || elementData?.formula });
    }
  };

  const handleDescribe = () => {
    aiEnabled ? setIsAiGenEnabled(true) : setDisclaimer(true);
  };

  const handleAiAggregment = () => {
    setUserPreferences({
      ...userPreferences,
      hasAiEnabled: true,
    });

    setDisclaimer(false);
    setIsAiGenEnabled(true);
  };

  const toggleDisclaimer = () => {
    setDisclaimer(d => !d);
  };

  const setAiGenerated = value => {
    updateData({ AIGenerated: value });
  };

  const advancedFilterUI = () => {
    return (
      <div className="wkp-advanced-filter-ui">
        {disclaimer && (
          <DisclaimerModal
            acceptDisclaimer={handleAiAggregment}
            readOnly={isDisclaimerReadOnly}
            setReadOnlyDisclaimer={setIsDisclaimerReadOnly}
            toggleModal={toggleDisclaimer}
          />
        )}
        {isAiGenEnabled ? (
          <ExpressionGeneratorModal
            blockType={'filter'}
            fields={fields.map(field => field.name) || []}
            handleFormulaChange={handleFormulaChange}
            isAIGenerated={setAiGenerated}
            prevPromptRef={prevPromptRef}
            setReadOnlyDisclaimer={setIsDisclaimerReadOnly}
            setShowDisclaimer={setDisclaimer}
            setShowExpressionGeneratorModal={setIsAiGenEnabled}
          />
        ) : (
          <>
            <BTForm.FormGroup errorText={formulaError} hasError={!!formulaError} label="Provide the filter criteria">
              <div className="text-filter-area">
                <FilterTextArea
                  fields={autoCompletefields}
                  maxLength="10000"
                  value={elementData.advancedFormula}
                  onChange={handleFormulaChange}
                />
                {elementData?.AIGenerated && (
                  <span>
                    <AiGenerated uniqueId={'filter-ai-generated'} />
                  </span>
                )}
              </div>
            </BTForm.FormGroup>
            {isFeatureFlagEnabled(WKP_AI_FORMULA_GENERATION) && canEditWorkflow && (
              <div style={{ marginBottom: '20px' }}>
                <AILabModal
                  blockType="filter criteria"
                  expressionGenerationHandler={handleDescribe}
                  isAIGenerated={elementData?.AIGenerated}
                />
              </div>
            )}
            <BTForm.FormGroup>
              <p>
                Reference columns by wrapping their name in square brackets. To combine multiple conditions, use the AND
                and OR functions, passing each condition separated by a comma. Additionally, you can use the NOT
                function to negate an expression. Examples:
              </p>
              <code className="wkp-formula-example">[Account]="113000"</code>
              <code className="wkp-formula-example">AND([Account]&gt;="10000",[Account]&lt;"20000")</code>
              <code className="wkp-formula-example">
                AND( <br />
                &nbsp;&nbsp;OR([State]="Nebraska",[State]="New York"), <br />
                &nbsp;&nbsp;[Account]="113000" <br />)
              </code>
              <code className="wkp-formula-example">NOT([State]="New York")</code>
              <b>
                <a href="https://workpapers.bloombergtax.com/help/index.html?article=expressionSyntax" target="blank">
                  View full list of functions and expression syntax
                </a>
              </b>
            </BTForm.FormGroup>
          </>
        )}
      </div>
    );
  };

  const onCriteriaItemAdd = useCallback(
    filterCriteriaToUpdate => {
      filterCriteriaToUpdate.push({});
      updateData({
        filterCriteria: filterCriteriaToUpdate,
        formula: constructFormula(filterCriteriaToUpdate),
      });
    },
    [updateData, constructFormula]
  );

  const onCriteriaItemUpdate = useCallback(
    (itemToUpdate, filterCriteriaToUpdate, updateIndex) => {
      filterCriteriaToUpdate[updateIndex] = itemToUpdate;
      updateData({
        filterCriteria: filterCriteriaToUpdate,
        formula: constructFormula(filterCriteriaToUpdate),
      });
    },
    [updateData, constructFormula]
  );

  const onCriteriaItemRemove = useCallback(
    filterCriteriaToUpdate => {
      updateData({
        filterCriteria: filterCriteriaToUpdate,
        formula: constructFormula(filterCriteriaToUpdate),
      });
    },
    [updateData, constructFormula]
  );

  const simpleFilterUI = () => {
    return (
      <div className="wkp-simple-filter-ui">
        <h5>Filter Criteria</h5>
        <BTForm.FormGroup>
          <FieldsToFilter
            fieldOptions={fieldOptions}
            fields={fields}
            filterCriteria={filterCriteria || [{}]}
            onAdd={onCriteriaItemAdd}
            onRemove={onCriteriaItemRemove}
            onUpdate={onCriteriaItemUpdate}
          />
        </BTForm.FormGroup>
      </div>
    );
  };

  const getFilterUI = () => {
    if (viewToggle) {
      return advancedFilterUI();
    } else {
      return simpleFilterUI();
    }
  };
  const canEditWorkflow = useCanEditWorkflow();
  return (
    <ElementInspector {...{ elementData, elementId, elementType, updateData, isDirty }}>
      <div className="wkp-formula-element-inspector">
        <BTForm onSubmit={e => e.preventDefault()}>
          <div className="wkp-filter-toggle-container">
            <BTForm.FormGroup>
              <BTSwitch
                checked={viewToggle}
                disabled={!canEditWorkflow}
                id="advanced-filter-toggle"
                onChange={() => onUIToggle(viewToggle)}
              />
            </BTForm.FormGroup>
            Advanced filter
          </div>
          <div className="filter-ui">{getFilterUI()}</div>
        </BTForm>
      </div>
    </ElementInspector>
  );
};

export default FilterElementInspector;
