import React from 'react';
import { dataFlowPanelItems } from './dataFlowPanelItems';
import DataFlowConfigurationPanel from './DataFlowConfigurationPanel';
import DataFlowWorkflowPanel from './DataFlowWorkflowPanel';

export default function DataFlowSideBar({
  handleSideBarIconClick,
  activeElement,
  openedSidePanel,
  handleOnPanelClose,
}) {
  const panels = {
    configuration: <DataFlowConfigurationPanel onClose={handleOnPanelClose} />,
    workflow: <DataFlowWorkflowPanel onClose={handleOnPanelClose} />,
  };

  return (
    <div id="wkp-df-side-bar">
      <div className="wkp-df-right-nav">
        <nav>
          <ul className="nav">
            {dataFlowPanelItems.map(({ id, title, label, name, children }) => (
              <li
                key={name}
                className={
                  openedSidePanel === name || (activeElement && name === (openedSidePanel ?? 'configuration'))
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <button
                  aria-label={title}
                  className="nav-link btn btn-link"
                  id={id}
                  title={title}
                  type="button"
                  onClick={handleSideBarIconClick(name)}
                >
                  {children}
                  <span className="sr-only">{label}</span>
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {openedSidePanel && panels[openedSidePanel]}
    </div>
  );
}
