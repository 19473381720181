import { initShortcutAboutRowsAndColumns } from './action.row.column';
import { initShortcutAboutCalc } from './action.calc';
import { initShortcutAboutCell } from './action.cell';
import { initShortcutAboutSelection } from './action.selection';
import { initShortcutAboutStyle } from './action.style';
import { initShortcutAboutSelectingToEnd } from './action.selectToEnd';
import { initShortcutAboutUndo } from './action.undo';
export function addSpreadShortcut(spread, args) {
  var commandManager = spread.commandManager();
  initShortcutAboutRowsAndColumns(commandManager);
  initShortcutAboutSelection(commandManager);
  initShortcutAboutCalc(commandManager);
  initShortcutAboutCell(commandManager);
  initShortcutAboutStyle(commandManager);
  initShortcutAboutSelectingToEnd(commandManager);
  initShortcutAboutUndo(commandManager, args);
}
