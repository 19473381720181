import * as fetch from '../../../../../_shared/fetch';
import { generalErrorMessage } from '../../../../../_shared/messages';
import { spreadsheetServiceHostName, nodeAPIServiceHostName } from '../../../../../../configs/params';

export async function getComboboxData() {
  const response = await fetch.get(`${spreadsheetServiceHostName}/spreadsheet/metadata`);
  return response.json();
}

export async function getMetadata(id) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/metadata/${id}`;
  const response = await fetch.get(url);
  return response.json();
}

export async function putMetadata({ id, ...rest }) {
  try {
    const url = `${spreadsheetServiceHostName}/spreadsheet/metadata/${id}`;

    const response = await fetch.put(url, {
      body: JSON.stringify(rest),
    });

    const data = await response.json();
    if (!response.ok) {
      throw data;
    }

    return data;
  } catch (error) {
    let mappedError = [];
    if (error && error.invalidParams) {
      const invalidParams = JSON.parse(error.invalidParams);
      for (const item in invalidParams) {
        mappedError.push({
          target: item,
          message: invalidParams[item][0],
        });
      }
    } else {
      mappedError.push({ code: 'SERVER_ERROR', message: generalErrorMessage });
    }

    throw mappedError;
  }
}

export async function putIsGlobalTemplate({ id, ...rest }) {
  try {
    const url = `${spreadsheetServiceHostName}/spreadsheet/workpapers/global-template/${id}/flag`;

    const response = await fetch.put(url, {
      body: JSON.stringify(rest),
    });

    const data = await response.json();
    if (!response.ok) {
      throw data;
    }

    return data;
  } catch (error) {
    let mappedError = [];
    if (error && error.invalidParams) {
      const invalidParams = JSON.parse(error.invalidParams);
      for (const item in invalidParams) {
        mappedError.push({
          target: item,
          message: invalidParams[item][0],
        });
      }
    } else {
      mappedError.push({ code: 'SERVER_ERROR', message: generalErrorMessage });
    }

    throw mappedError;
  }
}

export async function getAllTags(resourceId) {
  let url = `${nodeAPIServiceHostName}/node/tags`;
  if (resourceId) {
    url = `${url}?resourceId=${resourceId}`;
  }
  const response = await fetch.get(url);
  if (!response.ok) {
    throw response.json();
  }
  return response.json();
}

export async function createOrUpdateTags(tagPayLoad) {
  try {
    const url = `${nodeAPIServiceHostName}/node/tags`;

    const response = await fetch.post(url, {
      body: JSON.stringify(tagPayLoad),
    });

    const data = await response.json();
    if (!response.ok) {
      throw data;
    }

    return data;
  } catch (error) {
    let mappedError = [];

    if (error && error.error) {
      mappedError.push({ code: error.error.code, message: error.error.message });
    }

    throw mappedError;
  }
}
