import LDClient from '../components/_shared/launchDarklyClient';

export function isFeatureFlagEnabled(flagName, featureFlags = LDClient.getInstance().getFlags() || []) {
  // Workaround to mock feature flags (BPMP-3385)
  return (
    mockLocalFeatureFlags(flagName) || (featureFlags.find(f => f.name === flagName && f.active === true) ? true : false)
  );
}

function mockLocalFeatureFlags(flagName) {
  const storageItem = localStorage.getItem('local-ffs');

  if (!storageItem) return;

  const mockLocalFlags = storageItem.split(',');
  if (mockLocalFlags.indexOf(flagName) === -1) return;

  return true;
}

/**
 * Update the current feature flag status by new incoming flag setting object
 * flagSetting object format
 * {
    "flag-name": {
        "current": false,
        "previous": true
      }
    }
 * @param {*} flagSetting 
 * @param {*} featureFlags 
 * @returns 
 */
export function updateFeatureFlags(flagSetting, featureFlags = LDClient.getInstance().getFlags() || []) {
  if (featureFlags) {
    const flagNames = Object.keys(flagSetting);
    flagNames.forEach(flagName => {
      let flag = featureFlags.find(f => f.name === flagName);
      flag['active'] = flagSetting[flagName].current;
    });

    return featureFlags;
  }
}

/**
 * create the same format for current feature flags by transform LD all flag sets object
 * Example LD flag sets
 * {
    'flag-name-1': true,
    'flag-name-2': false
   }
 * 
   Current format:
   [{name: 'flag-name-1', active: true}
    ... 
   ]
 * @param {*} inputData 
 * @returns 
 */
export function transformToCurrentFeatureFlags(inputData = []) {
  let newData = [];
  Object.entries(inputData).map(([key, val]) => newData.push({ name: key, active: val }));
  return newData;
}
