import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/select_block_icon.svg';
import hintImage from '../icons/select_hint_img.svg';
import { FILTER_COLOR } from '../../shared/colors';
import { SelectElementInspector } from '../../SelectElementInspector';
import { SELECT } from '../types/shared/typesConstants';

export class SelectElementType extends TransformationElementType {
  static TYPE = SELECT;

  static HELP_TEXT = `The Select block allows you to rename or remove columns and override data types in your data transformation workflow.<img src=${hintImage} alt="select hint" />`;

  constructor() {
    super(SelectElementType.TYPE, 'Select', FILTER_COLOR, iconImage, SelectElementType.HELP_TEXT);
  }

  get maxCount() {
    return -1;
  }

  /**
   *
   * @param {object} elementData
   * @param {object} sourceElements
   *
   * @typedef {Object} SelectElement
   * @property {Array} fields - Should be the array with all the final changes for the fields,
   * it is supposed to have the renames, overrides and any other change that might have been done
   * @property {Array} updatedField - is supposed to have the same fields as the upstream block
   * but with the types changed as configured in the select inspector (if configured).
   * Each field has a boolean "overriden" attribute to check if it was previously changed
   *
   * @returns {SelectElement}
   */
  applySourceElements(elementData, sourceElements) {
    const { fields: sourceFields } = super.applySourceElements(elementData, sourceElements);

    const selectedFields = this.produceSelectedFields(sourceFields || [], elementData.selectedFields || {});

    const updatedFields = this.produceUpdatedFields(
      elementData.updatedFields,
      elementData.fields || sourceFields,
      elementData.renamedFields || {}
    );

    const overridenFields = this.produceTypeOverrides(updatedFields, sourceFields);

    const renamedFields = this.produceRenamedFields(overridenFields, elementData.renamedFields || {});

    const fields = this.produceFinalFields(overridenFields, renamedFields, selectedFields);

    return {
      ...elementData,
      fields,
      updatedFields: overridenFields,
      renamedFields: renamedFields || {},
      selectedFields,
    };
  }

  get inspectorComponent() {
    return SelectElementInspector;
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      updatedFields: elementData.updatedFields,
      selectedFields: elementData.selectedFields,
      renamedFields: elementData.renamedFields,
    };
  }

  getPreviewColumns(elementData) {
    return elementData.fields;
  }

  produceSelectedFields(sourceFields = [], prevSelection = {}) {
    if (sourceFields.length === 0) {
      return prevSelection;
    }

    return (sourceFields || []).reduce((acum, field) => {
      if (prevSelection.hasOwnProperty(field.name)) {
        acum[field.name] = prevSelection[field.name];
      } else {
        acum[field.name] = true;
      }

      return acum;
    }, {});
  }

  produceUpdatedFields(updatedFields, fields, renames) {
    if (updatedFields) {
      return updatedFields;
    }

    renames = renames || {};

    return (fields || []).map((field, i) => {
      if (renames[i]) {
        const [rename] = Object.entries(renames[i]);

        if (rename) {
          return {
            ...field,
            name: rename[0],
          };
        }

        return field;
      }

      return field;
    });
  }

  produceTypeOverrides(fields, sourceFields) {
    if ((sourceFields || []).length === 0) {
      return fields || [];
    }

    return (sourceFields || []).map((sourceField, i) => {
      const field = (fields || []).find(f => f.name === sourceField.name);

      if (field) {
        return {
          ...sourceField,
          type: field.overriden === undefined || field.overriden ? field.type : sourceField.type,
          overriden: field.overriden !== undefined ? field.overriden : field.type !== sourceField.type,
        };
      }

      return {
        ...sourceField,
        overriden: false,
      };
    });
  }

  produceRenamedFields(fields, renamedFields) {
    renamedFields = renamedFields || {};

    return (fields || []).reduce((acum, field, i) => {
      const renameIndex = Object.keys(renamedFields).find(j => renamedFields[j][field.name]);

      if (renameIndex) {
        acum[i] = { [field.name]: renamedFields[renameIndex][field.name] };
      }

      return acum;
    }, {});
  }

  produceFinalFields(fields, renamed, selected) {
    return (fields || [])
      .filter(field => selected && selected[field.name])
      .map(field => {
        const renameIndex = Object.keys(renamed || {}).find(i => renamed[i][field.name]);

        if (renameIndex) {
          return {
            ...field,
            overriden: undefined,
            name: renamed[renameIndex][field.name],
          };
        }

        return {
          ...field,
          overriden: undefined,
        };
      });
  }
}
