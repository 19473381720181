import React from 'react';
import { Link } from 'react-router-dom';
import './ImpactedWorkpapers/styles.scss';

function getUniqueWorkpapers(workpapers) {
  const workpapersMap = workpapers.reduce((map, workpaper) => {
    const key = workpaper.id;

    map.has(key) || map.set(key, workpaper);

    return map;
  }, new Map());
  return [...workpapersMap.values()];
}

const ImpactedWorkpapers = ({ cachedResults }) => {
  const allWorkpapers = (cachedResults || [])
    .flatMap(result => result.resultWorkpapers || [])
    .map(value => value.workpaper);
  const workpapers = getUniqueWorkpapers(allWorkpapers);
  if (workpapers.length === 0) {
    return <div>No workpapers impacted</div>;
  }

  return (
    <div>
      <div>Impacted Workpapers:</div>
      <ul className="wkp-recent-activity-workpapers">
        {workpapers.map(({ id, name }) => (
          <li key={id}>
            <Link className="wkp-recent-activity-workpaper" to={`/editor/${id}`}>
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ImpactedWorkpapers;
