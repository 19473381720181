import React, { useRef } from 'react';
import { useNavigator } from './DataFlowNavigator/useNavigator';
import './DataFlowNavigator/styles.scss';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_DATAFLOW_SIDEBAR } from '../../../constants/featureFlags';

export const DataFlowNavigator = () => {
  const navigatorContainer = useRef(null);

  useNavigator(navigatorContainer);
  const navigatorContainerClass = isFeatureFlagEnabled(WKP_DATAFLOW_SIDEBAR)
    ? 'wkp-navigator-container-panel'
    : 'wkp-navigator-container';

  return <div ref={navigatorContainer} className={navigatorContainerClass} />;
};
