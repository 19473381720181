import { BTButton, BTModal, BTScrollContainer, P } from '@btas/jasper';

const ExpressionGeneratorDisclaimer = ({ acceptDisclaimer, toggleModal, readOnly, setReadOnlyDisclaimer }) => {
  const handleClose = () => {
    toggleModal();
    if (readOnly) {
      setReadOnlyDisclaimer(false);
    }
  };

  return (
    <BTModal
      id="AI-Expression-Generator-disclaimer-modal"
      show={true}
      size="modal-lg"
      title="Welcome to the Bloomberg Tax AI Expression Generator"
      onCloseClick={handleClose}
    >
      <BTModal.Body className="feedbackForm">
        <BTScrollContainer id="scroll-container">
          <BTScrollContainer.Content>
            <P>
              The Bloomberg Tax AI Expression Generator leverages Large Language Models (“LLMs”) to understand user
              inquiries and generate responses. Such LLMs may be hosted by Bloomberg Industry Group or third parties,
              but in all events, your inputs remain your confidential information and will never be used to train our,
              or any third-party models.
            </P>
            <P>
              It is important to remember that the LLM’s translation of your natural language query into formula
              expression is based solely on the embedded prompt instructions, without access to any external data or
              context. Consequently, there are some inherent limitations to be aware of:
            </P>
            <ol>
              <li>
                The quality of the responses that LLMs generate will be dependent upon the availability and relevancy of
                the expression syntax.
              </li>
              <li>LLMs may still make errors, misinterpret, or oversimplify complex information.</li>
              <li>
                While an LLM may make relevant inferences based on the inputs supplied, it may occasionally generate
                incorrect or unrelated outputs.
              </li>
              <li>
                In cases of ambiguity in the inputs supplied, an LLM might not ask clarifying questions and may make
                assumptions to generate outputs.
              </li>
              <li>
                Given the experimental nature of this feature, your examination and validation of the output formula is
                necessary to ensure its accuracy.
              </li>
            </ol>
            <p>Just a few other notes about the Bloomberg Tax AI Expression Generator: </p>
            <ol>
              <li>
                Products are experimental in nature, may undergo frequent updates, and may be discontinued at any time
                for any or no reason.
              </li>
              <li>As part of your access to the feature, you may be contacted about your experience.</li>
              <li>
                Privacy. Please review our Privacy Policy available at{' '}
                <a className="link" href="https://www.bloombergindustry.com/privacy-policy/">
                  <b>https://www.bloombergindustry.com/privacy-policy/</b>
                </a>{' '}
                for information on how we collect and use personal data.
              </li>
            </ol>
          </BTScrollContainer.Content>
        </BTScrollContainer>
      </BTModal.Body>
      <BTModal.Footer>
        <>
          {!readOnly && (
            <BTButton btStyle="primary" onClick={acceptDisclaimer}>
              Proceed
            </BTButton>
          )}
          <BTButton btStyle="secondary" onClick={readOnly ? handleClose : toggleModal}>
            Cancel
          </BTButton>
        </>
      </BTModal.Footer>
    </BTModal>
  );
};

export default ExpressionGeneratorDisclaimer;
