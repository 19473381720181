/**
 * Registers a tooltip
 *
 * @param {import('../../tooltip').TooltipManager} tooltipManager
 * @param {number} x The leftmost point of the tooltip listener
 * @param {number} y The uppermost point of the tooltip listener
 * @param {{number, number}} listenerRange An object {width, height} containing the
 * width and height of the tooltip listener
 * @param {{number, number}} tooltipPosition An object {x, y} containing the relative
 * position that the tooltip should point at
 * @param {*} tooltipId
 * @param {*} tooltipContent
 * @param {*} isHTML optional
 * @param {() => boolean | undefined} activeCellValidator
 */
export function registerTooltip(
  tooltipManager,
  x,
  y,
  listenerRange,
  tooltipPosition,
  tooltipId,
  tooltipContent,
  isHTML,
  activeCellValidator
) {
  const designerPos = document.getElementById('designer').getBoundingClientRect();
  const spreadWrapperPos = document.getElementsByClassName('spreadWrapper')[0].getBoundingClientRect();
  const canvasX = designerPos.left + x;
  const canvasY = designerPos.top + y;

  const tooltipX = spreadWrapperPos.left + x + tooltipPosition.x;
  const tooltipY = spreadWrapperPos.top + y + tooltipPosition.y;

  tooltipManager.registerTooltip({
    id: tooltipId,
    originX: canvasX,
    originY: canvasY,
    destX: canvasX + listenerRange.width,
    destY: canvasY + listenerRange.height,
    content: tooltipContent,
    isHTML,
    position: {
      x: tooltipX,
      y: tooltipY,
    },
    activeCellValidator,
  });
}

/**
 * Unregister a tooltip
 *
 * @param {import('../../tooltip').TooltipManager} tooltipManager
 * @param {string} id The id of the registered tooltip
 */
export function unregisterTooltip(tooltipManager, id) {
  tooltipManager.unregisterTooltip({ id });
}

/**
 * Calculates tooltip's X coordinate
 *
 * @param {number} tooltipOriginalX Original x of the tooltip
 * @param {number} tooltipWidth Tooltip width
 * @returns Tooltip's X coordinate
 */
export const getTooltipX = (tooltipOriginalX, tooltipWidth) => tooltipOriginalX - tooltipWidth / 2;

/**
 * Calculates the updated tooltip's X coordinate. This is used in case the tooltip is cut out
 *
 * @param {number} tooltipOriginalX Original x of the tooltip
 * @param {number} tooltipWidth Tooltip width
 * @returns Updated tooltip's X coordinate
 */
export function getUpdatedTooltipX(tooltipOriginalX, tooltipWidth) {
  const viewportWidth = window.innerWidth;
  const tooltipOffset = 5;
  let updatedTooltipX = tooltipOffset;
  const tooltipX = getTooltipX(tooltipOriginalX, tooltipWidth);

  if (surpassRightBorder(tooltipX, tooltipWidth, viewportWidth)) {
    updatedTooltipX = viewportWidth - tooltipWidth - tooltipOffset;
  }

  return updatedTooltipX;
}

/**
 * Checks if any part of the tooltip is cut out
 *
 * @param {number} tooltipOriginalX Original x of the tooltip
 * @param {number} tooltipWidth Tooltip width
 * @returns True if the tooltip is cut out
 */
export function isTooltipOutOfBounds(tooltipOriginalX, tooltipWidth) {
  const viewportWidth = window.innerWidth;
  const tooltipX = getTooltipX(tooltipOriginalX, tooltipWidth);

  return surpassRightBorder(tooltipX, tooltipWidth, viewportWidth) || surpassLeftBorder(tooltipX);
}

/**
 * Gets the zone where the tooltip was originally positioned
 *  - left: it was cut out by the left border of th screen
 *  - center: it is not cut out
 *  - right: it was cut out by the right border of th screen
 *
 * @param {number} tooltipOriginalX Original x of the tooltip
 * @param {number} tooltipWidth Tooltip width
 * @returns {'left' | 'center' | 'right'} The zone where the tooltip was originally positioned
 */
export function getTooltipZone(tooltipOriginalX, tooltipWidth) {
  const viewportWidth = window.innerWidth;
  const tooltipX = getTooltipX(tooltipOriginalX, tooltipWidth);
  let zone;

  if (surpassRightBorder(tooltipX, tooltipWidth, viewportWidth)) {
    zone = 'right';
  } else if (surpassLeftBorder(tooltipX)) {
    zone = 'left';
  } else {
    zone = 'center';
  }

  return zone;
}

/**
 * Sets the horizontal position of the tooltip arrow element
 *
 * @param {number} tooltipOriginalX Original x of the tooltip
 * @param {number} tooltipWidth Tooltip width
 * @param {htmlElement} tooltipArrowElement
 */
export function setTooltipArrowX(tooltipOriginalX, tooltipWidth, tooltipArrowElement) {
  switch (getTooltipZone(tooltipOriginalX, tooltipWidth)) {
    case 'left':
      tooltipArrowElement.style.left = '25%';
      break;
    case 'right':
      tooltipArrowElement.style.left = '75%';
      break;
    default:
      tooltipArrowElement.style.left = '50%';
      break;
  }
}

/**
 * Checks if the tooltip is cut out by the right border
 *
 * @param {number} tooltipX Tooltip's x coordinate
 * @param {number} tooltipWidth Tooltip width
 * @param {number} viewportWidth
 * @returns If the tooltip is cut out by the right border
 */
const surpassRightBorder = (tooltipX, tooltipWidth, viewportWidth) => viewportWidth - tooltipX < tooltipWidth;

/**
 * Checks if the tooltip is cut out by the left border
 *
 * @param {number} tooltipX Tooltip's x coordinate
 * @returns If the tooltip is cut out by the left border
 */
const surpassLeftBorder = tooltipX => tooltipX < 0;
