import UserPermissionsContext from '../../UserPermissionsContext';
import { useContext, useCallback } from 'react';
import CustomLogger from '../../Logger/CustomLogger';

export const useResetLockedState = () => {
  const permissionsContext = useContext(UserPermissionsContext);

  const reset = useCallback(() => {
    if (permissionsContext) {
      const { userPermissions } = permissionsContext;
      userPermissions.resetLockedState();
    }
  }, [permissionsContext]);
  return reset;
};
export const useLoadWorkflowStatusFromApi = () => {
  const permissionsContext = useContext(UserPermissionsContext);

  const updateLockedWorkflowStatus = useCallback(
    (status, dataFlowId, lastModified) => {
      if (!permissionsContext) {
        return false;
      } else {
        const { userPermissions } = permissionsContext;
        userPermissions.status = status;
        return userPermissions.updateLockedState(status, dataFlowId, lastModified);
      }
    },
    [permissionsContext]
  );

  return updateLockedWorkflowStatus;
};

export const useLockedWorkflowAlertIsVisible = () => {
  const permissionsContext = useContext(UserPermissionsContext);
  if (!permissionsContext) {
    return false;
  } else {
    const { userPermissions } = permissionsContext;
    return userPermissions.lockedWorkflowAlertIsVisible();
  }
};

export const useDismissLockedWorkflowAlert = () => {
  const permissionsContext = useContext(UserPermissionsContext);

  const dismissLockedWorkflowAlert = useCallback(() => {
    if (!permissionsContext) {
      CustomLogger.pushLog(
        CustomLogger.operations.LOCK_WORKFLOW,
        'Cannot dismiss locked workflow alert because permission context is invalid'
      );
    } else {
      const { userPermissions } = permissionsContext;
      userPermissions.dismissLockedWorkflowAlert();
    }
  }, [permissionsContext]);
  return dismissLockedWorkflowAlert;
};

export const useUpdateWorkflowStatus = () => {
  const permissionsContext = useContext(UserPermissionsContext);

  const updateLockedWorkflowStatus = useCallback(
    (status, dataFlowId) => {
      if (!permissionsContext) {
        CustomLogger.pushLog(
          CustomLogger.operations.LOCK_WORKFLOW,
          'Cannot update locked workflow status because permission context is invalid'
        );
      } else {
        const { userPermissions } = permissionsContext;
        userPermissions.status = status;
        return userPermissions.updateLockedState(status, dataFlowId);
      }
    },
    [permissionsContext]
  );

  return updateLockedWorkflowStatus;
};
