import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BTButton, BTModal, BTInput, BTIcon, BTInputGroup } from '@btas/jasper';
import { v4 as uuidv4 } from 'uuid';
import WorkpapersCategoryComponent from './CreateWorkpapersModal/WorkpapersCategoryComponent';
import new_blank_workpaper from './CreateWorkpapers/new_blank_workpaper.svg';
import import_template from './CreateWorkpapersModal/WorkpapersCategoryComponent/import_template.svg';
import './CreateWorkpapersModal/styles.scss';
import { createFromGlobalTemplate, getGlobalTemplatesList, getTemplatesList } from './apis';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import {
  WKP_TEMPLATES_LIST,
  WKP_GLOBAL_TEMPLATES_LIST,
  WKP_GLOBAL_TEMPLATES_FILTER,
} from '../../../constants/featureFlags';
import Spinner from '../../_shared/Spinner';
import { setDataToLocalStorage } from '../../_shared/storage';
import { WORKPAPER_GLOBAL_TEMPLATE_JOB_TYPE } from '../../_shared/jobs/jobTypes';
import { startJob } from '../../_shared/jobs/apis';
import { getUser } from '../../_shared/auth';
import { useSearch } from './useSearch';
import { EventTrackingContext } from '../../_shared/EventTrackingContext';
import { eventNames } from '../../_shared/EventTrackingContext/constants';
import { getFakeEvent, itemTypes, setPropValues } from '../../_shared/EventTrackingContext/utils';

const TEMPLATES = 'templates';
const GLOBAL_TEMPLATES = 'global-templates';
const TEMPLATES_DISABLED = 'disabled';

const CreateWorkpapersModal = ({ onClose, onOuterClick, onCreateDone, loadJobs }) => {
  const [disableNewBlankWorkpaper, setDisableNewBlankWorkpaper] = useState(false);
  const [templatesList, setTemplatesList] = useState([]);
  const [templatesMode, setTemplatesMode] = useState(null);
  const [searchText, setSearchText] = useState('');
  const { data } = useSearch(searchText);
  const { trackEvent } = useContext(EventTrackingContext);

  useEffect(() => {
    if (isFeatureFlagEnabled(WKP_GLOBAL_TEMPLATES_LIST)) {
      setTemplatesMode(GLOBAL_TEMPLATES);
    } else if (isFeatureFlagEnabled(WKP_TEMPLATES_LIST)) {
      setTemplatesMode(TEMPLATES);
    } else {
      setTemplatesMode(TEMPLATES_DISABLED);
    }
  }, []);

  useEffect(() => {
    /**
     * @returns {Promise<{name:string; templates: {name: string; sourceWkpId?: string}[]}[]>}
     */
    const fetchTemplates = async () => {
      const response = await getTemplatesList();
      return response.categories.map(({ name, templates }) => ({
        name,
        templates: templates.map(tpl => ({ name: tpl })),
      }));
    };

    /**
     * @returns {Promise<{name:string; templates: {name: string; sourceWkpId?: string}[]}[]>}
     */
    const fetchGlobalTemplates = async () => {
      const response = await getGlobalTemplatesList();
      return [
        {
          name: 'Templates', // This is the default name of the category
          ...response,
        },
      ];
    };

    if (templatesMode === TEMPLATES) {
      fetchTemplates().then(setTemplatesList);
    } else if (templatesMode === GLOBAL_TEMPLATES) {
      fetchGlobalTemplates().then(setTemplatesList);
    } else {
      setTemplatesList([]);
    }
  }, [templatesMode]);

  const newTemplateWorkpaperHandler = useCallback(
    async ({ categoryName, templateName, sourceWkpId }) => {
      if (templatesMode === TEMPLATES) {
        setDisableNewBlankWorkpaper(true);
        const newId = uuidv4();
        const templateData = {
          newTemplateWorkpaper: true,
          newEmptyWorkpaper: false,
          categoryName,
          templateName,
        };
        setDataToLocalStorage(newId, JSON.stringify(templateData));
        window.open(`/editor/${newId}`, '_blank');

        setDisableNewBlankWorkpaper(false);
        // Event target does not exist, use the fake event with the specified tag
        const fakeEvent = getFakeEvent(itemTypes.BUTTON, `new-workpaper-template-selection: ${templateName}`);

        // Track the event
        trackEvent(eventNames.interactiveClick, setPropValues(fakeEvent, eventNames.interactiveClick, null));

        // Handler
        onCreateDone(newId);
      } else if (templatesMode === GLOBAL_TEMPLATES) {
        const { userId, firstName, companyId } = getUser();

        const userInfo = {
          companyId,
          userId,
          firstName,
        };

        const { jobId } = await startJob({
          workpaperId: sourceWkpId,
          jobType: WORKPAPER_GLOBAL_TEMPLATE_JOB_TYPE,
          payload: {
            fileName: templateName,
          },
        });

        await loadJobs();
        await createFromGlobalTemplate(sourceWkpId, jobId, userInfo);
        await loadJobs();
        // Event target does not exist, use the fake event with the specified tag
        const fakeEvent = getFakeEvent(itemTypes.BUTTON, `new-workpaper-template-selection: ${templateName}`);

        // Track the event
        trackEvent(eventNames.interactiveClick, setPropValues(fakeEvent, eventNames.interactiveClick, null));
        onCreateDone(sourceWkpId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templatesMode, onCreateDone]
  );

  useEffect(() => {
    const handleClick = event => {
      const modalOuterLayer = document.querySelector('.newWorkpaperModal');

      if (event.target === modalOuterLayer) {
        onOuterClick();
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [onOuterClick]);

  const newBlankWorkpaperHandler = async () => {
    setDisableNewBlankWorkpaper(true);
    const newId = uuidv4();

    setDataToLocalStorage(newId, JSON.stringify({ newEmptyWorkpaper: true }));
    setDataToLocalStorage(`sessionId-${newId}`, uuidv4());
    window.open(`/editor/${newId}`, '_blank');

    setDisableNewBlankWorkpaper(false);
    // Event target does not exist, use the fake event with the specified tag
    const fakeEvent = getFakeEvent(itemTypes.BUTTON, 'new-workpaper-blank');

    // Track the event
    trackEvent(eventNames.interactiveClick, setPropValues(fakeEvent, eventNames.interactiveClick, null));

    // Handler
    onCreateDone(newId);
  };

  const handleSearchGlobalTemplate = async searchText => {
    setSearchText(searchText);
  };

  useEffect(() => {
    if (isFeatureFlagEnabled(WKP_GLOBAL_TEMPLATES_FILTER)) setTemplatesList([{ name: 'Templates', ...data }]);
  }, [data]);

  return (
    <BTModal
      className="newWorkpaperModal"
      id="create-modal-container"
      show={true}
      size="modal-lg"
      title="New Workpaper"
      onCloseClick={onClose}
    >
      <BTModal.Body className="modalBody" id="create-modal-body">
        <WorkpapersCategoryComponent
          disabled={disableNewBlankWorkpaper}
          icon={new_blank_workpaper}
          text="Blank workpaper"
          onClick={newBlankWorkpaperHandler}
        />
        {isFeatureFlagEnabled(WKP_GLOBAL_TEMPLATES_FILTER) && (
          <div>
            <BTInputGroup>
              <BTInputGroup.Addon>
                <BTIcon icon="magnify" />
              </BTInputGroup.Addon>
              <BTInput
                name="name"
                placeholder="Search templates by name or keyword"
                onChange={e => handleSearchGlobalTemplate(e.target.value)}
              />
            </BTInputGroup>
          </div>
        )}
        <div className="body-modal-height">
          {!!templatesList ? (
            templatesList.map((category, indexCategory) => (
              <div key={indexCategory}>
                <div key={indexCategory + '-' + category}>
                  <label className="dialog-subtitle">{category.name}</label>
                  <hr className="dialog-title-delimiter"></hr>
                </div>
                {category.templates?.map(({ name: templateName, sourceWkpId }, index) => (
                  <div key={index}>
                    <WorkpapersCategoryComponent
                      disabled={disableNewBlankWorkpaper}
                      icon={import_template}
                      text={templateName}
                      onClick={() =>
                        newTemplateWorkpaperHandler({
                          categoryName: category.name,
                          templateName,
                          sourceWkpId,
                        })
                      }
                    />
                  </div>
                ))}
              </div>
            ))
          ) : (
            <Spinner />
          )}
        </div>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton btStyle={'primary'} onClick={onClose}>
          Cancel
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

export default CreateWorkpapersModal;
