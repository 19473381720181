import React, { useEffect, useState } from 'react';
import { BTLabel, BTComboBox } from '@btas/jasper';
import Row from 'react-bootstrap/lib/Row';
import './styles.scss';

export default function TaxPeriodSelect({
  actions,
  config,
  setIsTaxPeriodSelected,
  isSortingFromGrid,
  setIsSortingFromGrid,
}) {
  const { gridData, gridFilters, initialTaxPeriod } = config;
  const { setFilter, setTaxPeriod } = actions;
  const { filterOptions } = gridData;
  const [parentTaxPeriod, setParentTaxPeriod] = useState(null);

  useEffect(() => {
    if (initialTaxPeriod) {
      setIsTaxPeriodSelected(true);
    }
    // only once on mount, irrlevant exaustive deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSortingFromGrid) setParentTaxPeriod(gridFilters.taxPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridFilters.taxPeriod]);

  const TaxPeriodFilterName = 'taxPeriod';

  const handleFilterChange = value => {
    setIsSortingFromGrid(false);
    setTaxPeriod(value);
    if (value === null) {
      setFilter(TaxPeriodFilterName, null);
      setIsTaxPeriodSelected(false);
    } else {
      setFilter(TaxPeriodFilterName, value);
      setIsTaxPeriodSelected(true);
    }
  };

  return (
    <>
      <Row className="wkp-tax-period-select-container">
        <BTLabel className="wkp-tax-period-select-label">Tax Period</BTLabel>
        <BTComboBox
          isClearable
          inputWidth={200}
          maxMenuHeight={100}
          name="taxPeriod"
          options={filterOptions.taxPeriods}
          value={parentTaxPeriod}
          onChange={handleFilterChange}
        />
      </Row>
    </>
  );
}
