export const removeBarcodePlugin = config => {
  const ribbonIndex = config.ribbon.map(({ id }) => id).indexOf('insert');
  const buttonGroupIndex = config.ribbon[ribbonIndex].buttonGroups.map(({ label }) => label).indexOf('Charts');

  const insertBarCodeIndex =
    config.ribbon[ribbonIndex].buttonGroups[buttonGroupIndex].commandGroup.children.indexOf('insertBarCode');

  config.ribbon[ribbonIndex].buttonGroups[buttonGroupIndex].commandGroup.children[insertBarCodeIndex] = '';
  config.ribbon = config.ribbon.filter(({ id }) => id !== 'barcodeDesignId');

  return config;
};
