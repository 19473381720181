export const WKP_INTEGRATION_TYPE = 'WKP';
export const WKP_IMPORT_INTEGRATION_TYPE = 'WKP-IMPORT';
export const FA_INTEGRATION_TYPE = 'FA';
export const SFTP_INTEGRATION_TYPE = 'SFTP';
export const MAX_SIZE_FILE = 2 * 1024 * 1024 * 1024; // 2 GB in bytes;
export const INTEGRATIONS_TYPE = {
  SFTP: 0,
  TP: 1,
  FIXED_ASSETS: 2,
};
export const SFTP_REGEX = /shared-transfer-server/;
