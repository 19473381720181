import GC from '../../../../../SpreadSheets';

const SHEET_NAME_MAX_LENGTH = 31;

export function attachSheetNameValidation(spread) {
  spread.bind(GC.Spread.Sheets.Events.SheetTabDoubleClick, () =>
    document.querySelector('.ss.gc-no-user-select').addEventListener('keydown', sheetNameValidation, true)
  );
  spread.bind(GC.Spread.Sheets.Events.SheetNameChanged, () =>
    document.querySelector('.ss.gc-no-user-select').removeEventListener('keydown', sheetNameValidation, true)
  );
}

function sheetNameValidation({ target }) {
  if (target.className.includes('gc-sheetTabEditor') && target.maxLength !== SHEET_NAME_MAX_LENGTH) {
    target.maxLength = SHEET_NAME_MAX_LENGTH;
  }
}
