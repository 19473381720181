import { useReducer, useCallback, useEffect, useState } from 'react';
import { maxCommandQueueRetries, maxCommandFailsRetries } from '../../../../configs/params';
import { saveBatchCommand } from './useCommandsQueue/apis';
import usePubSub from '../../EditorPage/PubSub/usePubSub';
import MessageType from '../../../_shared/PubSub/pubSubMessageType';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { isEmptyObject } from '../DataReference/dataReferenceHelper';

let retries = maxCommandQueueRetries;

const initialState = {
  commandsQueue: [],
  isCommandsSaving: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'addCommand':
      return { ...state, commandsQueue: [...state.commandsQueue, action.payload] };
    case 'startProcessQueue':
      return { ...state, isCommandsSaving: true };
    case 'endProcessQueue':
      return { ...state, isCommandsSaving: false, commandsQueue: state.commandsQueue.slice(1) };
    case 'errorProcessQueue':
      return { ...state, isCommandsSaving: false };
    default:
      throw new Error();
  }
}

export default function useCommandsQueue({ spreadRef, syncDatareferences }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [workpaperId, setWorkpaperId] = useState();
  const [retryCommand, setRetryCommand] = useState(false);
  const [commandFailed, setCommandFailed] = useState(false);
  const [maxFails, setMaxFails] = useState(false);
  const [maxFailsRetries, setMaxFailsRetries] = useState(maxCommandFailsRetries);
  const [onEmptyQueueCallbacks, setOnEmptyQueueCallbacks] = useState([]);
  const { publish } = usePubSub();

  const allCommandsProcessedAsync = useCallback(() => {
    if (state.commandsQueue.length === 0 && !state.isCommandsSaving) {
      return Promise.resolve();
    }
    return new Promise(resolve => {
      setOnEmptyQueueCallbacks(prevCallbacks => [...prevCallbacks, resolve]);
    });
  }, [state.commandsQueue.length, state.isCommandsSaving]);

  const syncDatareferencesPositionsData = async commands => {
    commands.forEach(command => {
      publish({
        body: { command: JSON.parse(command.commandText), sheet: spreadRef.current.getActiveSheet() },
        message: MessageType.CellPosition,
        callback: syncDatareferences?.trackDataReferencesAction.current,
      });
    });
  };

  const saveCommands = async () => {
    const operationStartTime = Date.now();
    let error;
    const sheetName = spreadRef.current?.getActiveSheet()?.name();
    if (state.commandsQueue.length > 0 && !state.isCommandsSaving) {
      dispatch({ type: 'startProcessQueue' });
      try {
        setRetryCommand(false);
        syncDatareferencesPositionsData(state.commandsQueue[0]);
        const currentCommand = state.commandsQueue[0];
        if (currentCommand?.length) {
          const response = await saveBatchCommand(workpaperId, sheetName, currentCommand);
          if (response.ok || response.status === 504) {
            dispatch({ type: 'endProcessQueue' });
            try {
              CustomLogger?.pushLog(CustomLogger.operations.PROCESS_COMMAND, {
                duration: (Date.now() - operationStartTime).toString(),
                workpaperId,
                commands: JSON.stringify(currentCommand),
                sheetName,
              });
            } catch {}
            // eslint-disable-next-line no-useless-escape
            if (currentCommand.filter(x => x.commandText.includes('{"cmd":"renameSheet"'))?.length) {
              publish({
                body: workpaperId,
                message: MessageType.CellPosition,
                callback: syncDatareferences?.loadDataReferencesAction.current,
              });
            }
          } else {
            throw new Error(response.message);
          }
        }
      } catch (commandSaveException) {
        if (retries--) {
          setRetryCommand(true);
          dispatch({ type: 'errorProcessQueue' });
        } else {
          setMaxFailsRetries(maxFailsRetries - 1);
          if (!maxFailsRetries) {
            setMaxFails(true);
            error = commandSaveException;
          }
          setCommandFailed(true);
          retries = maxCommandQueueRetries;
          dispatch({ type: 'endProcessQueue' });
        }
      } finally {
        if (error && !isEmptyObject(error)) {
          CustomLogger.pushLog(CustomLogger.operations.PROCESS_COMMAND, {
            error: JSON.stringify(error),
            duration: (Date.now() - operationStartTime).toString(),
            workpaperId,
            commands: JSON.stringify(state.commandsQueue),
            sheetName,
          });
        }
      }
    }
  };

  const handleEmptyQueue = () => {
    try {
      onEmptyQueueCallbacks[0]();
    } finally {
      setOnEmptyQueueCallbacks(([, ...rest]) => rest);
    }
  };

  useEffect(() => {
    if (state.commandsQueue.length > 0) {
      saveCommands();
    } else if (onEmptyQueueCallbacks.length > 0 && state.commandsQueue.length === 0 && !state.isCommandsSaving) {
      handleEmptyQueue();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, retryCommand, onEmptyQueueCallbacks]);

  const enqueueCommands = commands => {
    dispatch({ type: 'addCommand', payload: commands });
  };
  return {
    isCommandsSaving: state.isCommandsSaving,
    commandsQueue: state.commandsQueue,
    enqueueCommands,
    allCommandsProcessedAsync,
    commandFailed,
    maxFails,
    setWorkpaperId,
  };
}
