import { useState } from 'react';
import AiLabIcon from './assets/AI_Lab.svg';
import { BTAccordion, BTAlert, BTButton, BTForm, BTIcon, BTInput, BTSpinner, H2, H3, P } from '@btas/jasper';
import AiGenerated from '../../AiGenerated';

const TaxResearchModal = ({
  toggleAiOff,
  toggleModal,
  inputValue,
  setInputValue,
  handleSubmit,
  isSaving,
  aiSummary,
  setReadOnlyDisclaimer,
  error,
}) => {
  const handleClick = e => {
    e.stopPropagation();
  };

  const handleChange = ({ target }) => {
    setInputValue(target.value);
  };

  const showReadOnlyDisclaimer = () => {
    toggleModal();
    setReadOnlyDisclaimer(true);
  };

  return (
    <div className="research-dropdown" onClick={handleClick}>
      <div className="research-header">
        <div className="research-icon">
          <img alt="Ai Lab Icon" src={AiLabIcon} />
        </div>
        <P>Check out our new lab for AI generated summaries.</P>
        <button className="toggle-ai" onClick={toggleAiOff}>
          <span>Turn Off AI</span>
          <div
            style={{
              fontSize: '18px',
              lineHeight: 0,
            }}
          >
            <BTIcon icon="close" />
          </div>
        </button>
        <button
          className="dropdown-button"
          style={{
            fontSize: '20px',
            color: 'white',
            lineHeight: 0,
          }}
          title="AI Disclaimer"
          onClick={showReadOnlyDisclaimer}
        >
          <BTIcon icon="info-circle-alt" />
        </button>
      </div>
      <div className="research-body">
        <div className="title-box">
          <H2 className="title">Bloomberg Tax Research</H2>
          <div
            className="dropdown-button"
            id="dropdown-close"
            style={{
              fontSize: '24px',
              cursor: 'pointer',
            }}
            onClick={toggleModal}
          >
            <BTIcon icon="close" />
          </div>
        </div>
        <BTForm className="search-box" onSubmit={handleSubmit}>
          <BTForm.FormGroup required htmlFor="search">
            <BTInput
              aria-label="Input"
              id="search"
              maxLength="2000"
              name="search"
              placeholder="Ask a question in Bloomberg Tax Research"
              value={inputValue}
              onChange={handleChange}
              onSubmit={handleSubmit}
            />
          </BTForm.FormGroup>
          <BTButton className="search-icon" disabled={isSaving || !inputValue.length} onClick={handleSubmit}>
            <BTIcon icon="magnify" />
          </BTButton>
        </BTForm>
        {isSaving ? (
          <div className="spinner-container">
            <BTSpinner />
          </div>
        ) : (
          aiSummary?.summary && (
            <div className="scroll-container">
              <div className="research-summary">
                <AiGenerated uniqueId={'qna-ai-generated'} />
                <TruncatedSummary summary={aiSummary?.summary} />
                <div className="summary-disclaimer">
                  <p>
                    The Bloomberg Tax Al Lab is experimental in nature, and users are strongly advised to corroborate
                    the information obtained, exercise professional judgment, and seek out additional sources of
                    information as deemed necessary.
                  </p>
                </div>
              </div>

              <div className="references">
                <H3 className="title-md">References</H3>
                <ReferenceList references={aiSummary.answers} />
              </div>
            </div>
          )
        )}

        {error && <ErrorMessage type={error} />}
      </div>
    </div>
  );
};

export default TaxResearchModal;

const TruncatedSummary = ({ summary }) => {
  const [isExpanded, setIsExpanded] = useState(summary?.length < 600);
  const truncatedText = isExpanded ? summary : summary.slice(0, 600);
  return (
    <div className="summary-content">
      <P>
        {truncatedText}
        {!isExpanded && '...'}
      </P>

      {summary?.length > 600 && (
        <button className="btn-text" onClick={() => setIsExpanded(state => !state)}>
          {!isExpanded ? 'Show Full Summary' : 'View Less'}
        </button>
      )}
    </div>
  );
};

const ReferenceList = ({ references }) => {
  const [showAll, setShowAll] = useState(false);
  const displayCount = showAll ? references.length : 3;

  const toggleDisplay = () => {
    setShowAll(!showAll);
  };

  return (
    <>
      <BTAccordion>
        {references?.slice(0, displayCount).map(reference => (
          <BTAccordion.Panel header={reference.docTitle}>
            {reference.text}
            <a
              className="btn-text"
              href={`https://www.bloomberglaw.com/product/tax/document/${reference.docId}`}
              rel="noreferrer"
              target="_blank"
            >
              View full document
            </a>
          </BTAccordion.Panel>
        ))}
      </BTAccordion>
      {references.length > 3 && (
        <button className="btn-text" onClick={toggleDisplay}>
          View {showAll ? 'Less' : 'More'} References
        </button>
      )}
    </>
  );
};

const ErrorMessage = ({ type }) => {
  return (
    <div className="error-container">
      {type === 'info' ? (
        <BTAlert btStyle="info">AI Summary - There is no AI summary available for this query</BTAlert>
      ) : (
        <BTAlert btStyle="danger">
          The application has encountered a problem. If you continue to receive this error message, contact Customer
          Support by phone (1-800-424-2938) or email (appsupport@bloombergtax.com)
        </BTAlert>
      )}
    </div>
  );
};
