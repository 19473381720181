import React from 'react';
import { Link } from 'react-router-dom';
import leftArrow from './shared/arrow-left.svg';
import './ReadOnlyVersionBar/styles.scss';

export default function ReadOnlyVersionBar({ workpaperId, displayName }) {
  return (
    <div className="wkp-top-bar wkp-read-only-version-area list-group">
      <div className="list-group-item">
        <Link to={`/editor/${workpaperId}`}>
          <strong>
            <img alt="Return to Workpaper" src={leftArrow} />
            <span>Return to Workpaper</span>
          </strong>
        </Link>
      </div>
      <div className="list-group-item main-item">
        <span>{displayName} (read only)</span>
      </div>
      <div className="list-group-item" />
    </div>
  );
}
