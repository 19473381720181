export function overrideShortcuts(spread) {
  let commandManager = spread.commandManager();
  commandManager.setShortcutKey(
    'navigationNextSheet',
    34 /*pgDn*/,
    false /* ctrl */,
    false /* shift */,
    true /* alt */,
    false /* meta */
  );

  commandManager.setShortcutKey(
    'navigationPreviousSheet',
    33 /* pgUp*/,
    false /* ctrl */,
    false /* shift */,
    true /* alt */,
    false /* meta */
  );
}
