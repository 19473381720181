import React, { useContext } from 'react';
import { BTActionMenu, BTInput } from '@btas/jasper';
import useHistoryItem from './useHistoryItem';
import UserPermissionsContext from '../../../../../../_shared/UserPermissionsContext';

export function HistoryItem({
  active,
  displayName,
  initials,
  item,
  getItemDisplayName,
  onVersionNameChange,
  setReadOnlyModeDisplayName,
}) {
  const { userPermissions } = useContext(UserPermissionsContext);
  const {
    versionNameInputRef,
    showVersionNameInput,
    isValidName,
    versionName,
    workpaperId,
    versionId,
    latest,
    showVersionNameOptions,
    setShowVersionNameOptions,
    onVersionNameInputChange,
    onVersionNameKeyUp,
    openVersionItem,
    renameVersionItem,
  } = useHistoryItem({
    active,
    displayName,
    item,
    getItemDisplayName,
    onVersionNameChange,
    setReadOnlyModeDisplayName,
  });

  return (
    <div
      className="list-group-item"
      onMouseEnter={() => setShowVersionNameOptions(true)}
      onMouseLeave={() => setShowVersionNameOptions(false)}
    >
      <div className="wkp-history-item-main-row">
        <div className="wkp-history-initials wkp-initials-color">{initials}</div>
        <div
          className={`wkp-history-item-main ${!showVersionNameInput && !active ? 'wkp-history-item-clickable' : ''} ${
            active ? 'wkp-history-item-selected' : ''
          }`}
          title={displayName}
        >
          {showVersionNameInput ? (
            <BTInput
              ref={versionNameInputRef}
              className={isValidName ? 'bt-input' : 'has-error'}
              hasError={!isValidName}
              maxLength="100"
              type="text"
              value={versionName}
              onChange={onVersionNameInputChange}
              onKeyUp={onVersionNameKeyUp}
            />
          ) : (
            <span
              title={displayName}
              onClick={() => {
                openVersionItem(workpaperId, versionId, displayName);
              }}
            >
              {displayName}
            </span>
          )}
        </div>

        {!latest &&
          showVersionNameOptions &&
          !showVersionNameInput &&
          userPermissions.includes('edit_workpaper_history') && (
            <BTActionMenu
              appendTo={document.body}
              boundary="viewport"
              placement="bottom-end"
              title="Version name options"
            >
              <BTActionMenu.MenuItem onClick={renameVersionItem}>
                {item.name ? 'Rename version' : 'Name version'}
              </BTActionMenu.MenuItem>
            </BTActionMenu>
          )}
      </div>
    </div>
  );
}
