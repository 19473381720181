import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getMetadata as get, putMetadata as update } from './apis';
export function useMetadata({ workpaperId }) {
  const queryKey = ['metadata', workpaperId];
  const queryClient = useQueryClient();

  const metadataQuery = useQuery({
    queryKey,
    queryFn: () => get(workpaperId),
  });

  async function getMetadata() {
    const data = await queryClient.fetchQuery(queryKey);

    return data;
  }

  const { mutateAsync: putMetadata } = useMutation({
    mutationFn: data => update(data),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  return { getMetadata, metadataQuery, putMetadata };
}
