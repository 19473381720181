import CustomLogger from '../../Logger/CustomLogger';
export class LockedWorkflowState {
  static LOCKED_WORKFLOW_STATUS = Object.freeze('Final');

  static LOCKED_WORKFLOW_ALERT_STATES = Object.freeze({
    hidden: 'hidden',
    visible: 'visible',
    dismiss: 'dismiss',
  });

  static WORKFLOW_STATES = Object.freeze(
    new Set(['Not started', 'In preparation', 'Ready for review', 'In review', this.LOCKED_WORKFLOW_STATUS])
  );

  constructor() {
    this.resetState();
  }

  resetState() {
    this.lockedWorkflowAlertState = LockedWorkflowState.LOCKED_WORKFLOW_ALERT_STATES.hidden;
    this.activeWorkflowId = '';
    this.workflowIsLocked = false;
    this.lastStatusUpdateTime = -1;
  }

  updateLockedState(status, workflowId, statusChangeTime) {
    if (!statusChangeTime || statusChangeTime <= 0) {
      statusChangeTime = Date.now();
    }

    if (typeof statusChangeTime === 'string') {
      statusChangeTime = this._convertDateStringToTimestamp(statusChangeTime);
    }

    if (this.activeWorkflowId === workflowId && statusChangeTime <= this.lastStatusUpdateTime) {
      CustomLogger.pushLog(
        CustomLogger.operations.LOCK_WORKFLOW,
        `Outdated request to lock ${this.activeWorkflowId}. The status will not be modified.`
      );
      return;
    }

    if (!this._validStatus(status)) {
      CustomLogger.pushLog(
        CustomLogger.operations.LOCK_WORKFLOW,
        `Cannot change lock state for workflow ${workflowId} with invalid status ${status}`
      );
      return;
    }
    this.activeWorkflowId = workflowId;

    if (this.isLockingWorkflow(status)) {
      this.lastStatusUpdateTime = statusChangeTime;
      this.lockedWorkflowAlertState = LockedWorkflowState.LOCKED_WORKFLOW_ALERT_STATES.visible;
      this.workflowIsLocked = true;
      return;
    }
    if (this.isUnlockingWorkflow(status)) {
      this.lastStatusUpdateTime = statusChangeTime;
      this.lockedWorkflowAlertState = LockedWorkflowState.LOCKED_WORKFLOW_ALERT_STATES.dismiss;
      this.workflowIsLocked = false;
      return;
    }
  }

  activeWorkflowLocked() {
    return this.workflowIsLocked;
  }

  isLockingWorkflow(status) {
    return !this.workflowIsLocked && status === LockedWorkflowState.LOCKED_WORKFLOW_STATUS;
  }

  isUnlockingWorkflow(status) {
    return this.workflowIsLocked && status !== LockedWorkflowState.LOCKED_WORKFLOW_STATUS;
  }

  showLockedWorkflowAlert() {
    if (this.lockedWorkflowAlertState === LockedWorkflowState.LOCKED_WORKFLOW_ALERT_STATES.hidden) {
      this.lockedWorkflowAlertState = LockedWorkflowState.LOCKED_WORKFLOW_ALERT_STATES.visible;
    } else {
      CustomLogger.pushLog(
        CustomLogger.operations.LOCK_WORKFLOW,
        `Cannot show workflow alert: Workflow has an invalid alert state of ${this.lockedWorkflowAlertState}`
      );
    }
  }

  dismissLockedWorkflowAlert() {
    if (this.lockedWorkflowAlertState === LockedWorkflowState.LOCKED_WORKFLOW_ALERT_STATES.visible) {
      this.lockedWorkflowAlertState = LockedWorkflowState.LOCKED_WORKFLOW_ALERT_STATES.dismiss;
    } else {
      CustomLogger.pushLog(
        CustomLogger.operations.LOCK_WORKFLOW,
        `Cannot dismiss workflow alert: Workflow alert has an invalid alert state of ${this.lockedWorkflowAlertState}`
      );
    }
  }

  hideLockedWorkflowAlert() {
    this.lockedWorkflowAlertState = LockedWorkflowState.LOCKED_WORKFLOW_ALERT_STATES.hidden;
  }

  lockedWorkflowAlertIsVisible() {
    return this.lockedWorkflowAlertState === LockedWorkflowState.LOCKED_WORKFLOW_ALERT_STATES.visible;
  }

  lockedWorkflowAlertIsDismissed() {
    return this.lockedWorkflowAlertState === LockedWorkflowState.LOCKED_WORKFLOW_ALERT_STATES.dismiss;
  }

  _convertDateStringToTimestamp(dateString) {
    let timestamp = 0;
    try {
      const lastModified = new Date(dateString);
      timestamp = lastModified.getTime();
    } catch (e) {
      CustomLogger.pushLog(
        CustomLogger.operations.LOCKED_WORKFLOW_STATUS,
        `Invalid value ${dateString} for last modified date`
      );
    }
    return timestamp;
  }

  _validStatus(status) {
    return LockedWorkflowState.WORKFLOW_STATES.has(status);
  }
}
