import { ElementsActions } from './elementsReducer';

export const initialLinksState = {
  activeLink: null,
  links: [],
};

export const LinksActions = {
  setActiveLink: 'set-active-link',
  clearActiveLink: 'clear-active-link',
  addLink: 'add-link',
  updateLinkVertices: 'update-link-vertices',
  removeLink: 'remove-link',
};

export const linksActions = dispatch => ({
  setActiveLink: cell => dispatch({ type: LinksActions.setActiveLink, cell }),
  clearActiveLink: () => dispatch({ type: LinksActions.clearActiveLink }),
  addLink: (cell, restore) => dispatch({ type: LinksActions.addLink, cell, restore }),
  updateLinkVertices: (linkId, vertices) => dispatch({ type: LinksActions.updateLinkVertices, linkId, vertices }),
  removeLink: (linkId, restore) => dispatch({ type: LinksActions.removeLink, linkId, restore }),
});

export const linksReducer = (state, action) => {
  switch (action.type) {
    case LinksActions.setActiveLink:
      const linkCell = action.cell;

      const link = state.links.find(link => link.id === linkCell.id);
      highlightActiveLink(link);
      return { ...state, activeLink: link };

    case ElementsActions.setActiveElement:
    case LinksActions.clearActiveLink: {
      const { activeLink, ...rest } = state;
      removeActiveLinkHighlight(activeLink);
      return rest;
    }

    case LinksActions.updateLinkVertices: {
      const linkIndex = state.links.findIndex(link => link.id === action.linkId);

      if (linkIndex === -1) {
        return state;
      }

      const updatedLinks = state.links;
      updatedLinks[linkIndex].vertices = action.vertices;
      return { ...state, links: updatedLinks };
    }
    case LinksActions.addLink: {
      const linkCell = action.cell;
      const linkInfo = createLinkInfo(state.graph, linkCell);
      return { ...state, links: [...state.links, linkInfo] };
    }

    case LinksActions.removeLink:
      const newLinks = state.links.filter(link => link.id !== action.linkId);
      return { ...state, links: newLinks };

    case ElementsActions.removeElement: {
      const { elementId } = action;

      // Remove element links
      const newLinks = state.links.filter(link => link.sourceId !== elementId && link.targetId !== elementId);
      return { ...state, links: newLinks };
    }

    default:
      return state;
  }
};

function createLinkInfo(graph, cell) {
  const elements = graph.getElements();
  const source = elements.find(element => element.id === cell.attributes.source.id);
  const target = elements.find(element => element.id === cell.attributes.target.id);

  return {
    id: cell.id,
    cell: cell,
    sourceId: source.id,
    sourcePort: cell.attributes.source?.port,
    targetId: target.id,
    targetPort: cell.attributes.target?.port,
    vertices: cell.attributes.vertices || [],
  };
}

function highlightActiveLink(link) {
  if (link) {
    link.cell.attr('line/stroke', '#0073FF');
  }
  return link;
}

function removeActiveLinkHighlight(link) {
  if (link) {
    link.cell.attr('line/stroke', '#333333');
  }
  return link;
}
