import { NewElementDataConfig } from './NewElementDataConfig';

const updateElementDataConfig = (dataFlowStateLinks, dataFlowStateElements, dataFlowStateWorkingElement, mapping) => {
  const dataFlowId = dataFlowStateWorkingElement?.id;

  let currentElement = { id: dataFlowId, type: 'input' };
  let elementsToVisit = [currentElement];

  let updatedElementConfig = {};

  // Will loop until all down stream blocks are re configured as needed
  while (elementsToVisit.length > 0) {
    //Will filter links for all blocks that have sourceId as previous block
    const elementId = currentElement.id;
    const filteredElements = dataFlowStateLinks.filter(element => element.sourceId === elementId);
    let downStreamElements = filteredElements;

    if (
      currentElement?.elementData?.type === 'lookup' &&
      currentElement?.elementData?.lookupTableInput === currentElement?.sourcePort
    ) {
      downStreamElements = filteredElements.filter(element => element.sourcePort === 'T');
    }
    if (currentElement?.elementData?.type === 'join') {
      let portToIgnore = currentElement?.sourcePort === 'A' ? 'b' : 'a';
      downStreamElements = filteredElements.filter(element => element.sourcePort !== portToIgnore);
    }

    if (downStreamElements.length > 0) {
      downStreamElements.forEach(element => {
        const targetElementId = element?.targetId;
        const dataFlowState = dataFlowStateElements[targetElementId];
        const targetElement = dataFlowState['elementData'];
        const targetType = targetElement.type;
        const targetPort = element?.targetPort;
        let elementToVisit = { id: targetElementId, elementData: targetElement, sourcePort: targetPort };

        if (targetType !== 'output') {
          // Will create a new object with an updated ElementData that require a rename
          const updateDataFlowState = new NewElementDataConfig(targetElement, targetType, targetPort, mapping);
          const newConfig = updateDataFlowState.updateConfigurations();
          // If no newConfig is found, we will exclude from list of updated Config
          if (newConfig) {
            updatedElementConfig[targetElementId] = newConfig;
          }
        }

        elementsToVisit.push(elementToVisit);
        elementsToVisit.shift();
      });
      currentElement = elementsToVisit[0];
    } else {
      elementsToVisit.shift();
    }
  }

  return updatedElementConfig;
};
export default updateElementDataConfig;
