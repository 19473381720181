import { useQuery } from '@tanstack/react-query';
import { searchGlobalTemplate } from './apis';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_GLOBAL_TEMPLATES_FILTER } from '../../../constants/featureFlags';

export const useSearch = searchText => {
  return useQuery(['search template', searchText], () => searchGlobalTemplate(searchText), {
    enabled: isFeatureFlagEnabled(WKP_GLOBAL_TEMPLATES_FILTER),
    cacheTime: 5 * 60 * 1000,
    staleTime: 5 * 50 * 1000,
  });
};
