import { transformToConfiguration } from '../useSaveDataFlow/transformToConfiguration';

export const DataFlowSideBarActions = {
  setWorkflowStatus: 'set-workflow-status',
};

export const dataFlowSideBarActions = dispatch => ({
  setWorkflowStatus: workflowConfiguration =>
    dispatch({ type: DataFlowSideBarActions.setWorkflowStatus, workflowConfiguration }),
});

export const dataFlowSideBarReducer = (state, action) => {
  switch (action.type) {
    case DataFlowSideBarActions.setWorkflowStatus:
      const { workflowConfiguration } = action;
      const newConfig = { ...transformToConfiguration(state.elements, state.links), ...workflowConfiguration };
      return { ...state, configuration: { ...newConfig }, ...workflowConfiguration };
    default:
      return state;
  }
};
