import { useCallback, useState, useContext } from 'react';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { getOriginalName } from '../../shared/utils/FieldHashUtils';
import { operators } from './filterOperators';

export function useSimpleCriteriaValidation() {
  const [simpleCriteriaError, setSimpleCriteriaError] = useState('');
  const { IsBlank, IsNotBlank, IsError, IsNotError } = operators;

  const validateSimpleCriteriaFields = useCallback(
    (filterField, runValidate) => {
      if (runValidate) {
        const noEmptyFields = filterField && filterField?.field && filterField?.operator && filterField?.filterValue;
        const noInputOperators = [IsBlank, IsNotBlank, IsError, IsNotError];
        if (!noEmptyFields && !noInputOperators.includes(filterField?.operator)) {
          setSimpleCriteriaError('Field, operator, and value are required.');
        } else {
          setSimpleCriteriaError('');
        }
      } else {
        setSimpleCriteriaError('');
      }
    },
    [IsBlank, IsNotBlank, IsError, IsNotError]
  );
  return { validateSimpleCriteriaFields, simpleCriteriaError };
}

export function useFieldRequiredValidation() {
  const [fieldError, setFieldError] = useState('');

  const validateField = useCallback((filterValue, displayErrorMessage) => {
    if (displayErrorMessage) {
      if (!filterValue) {
        setFieldError('Field is required.');
      } else {
        setFieldError('');
      }
    } else {
      setFieldError('');
    }
  }, []);
  return { validateField, fieldError };
}

export function useOperatorRequiredValidation() {
  const [operatorError, setOperatorError] = useState('');

  const validateOperator = useCallback((filterValue, displayErrorMessage) => {
    if (displayErrorMessage) {
      if (!filterValue) {
        setOperatorError('Operator is required.');
      } else {
        setOperatorError('');
      }
    } else {
      setOperatorError('');
    }
  }, []);
  return { validateOperator, operatorError };
}

export function useValueRequiredValidation() {
  const [valueError, setValueError] = useState('');

  const validateValue = useCallback((filterValue, displayErrorMessage) => {
    if (displayErrorMessage) {
      if (!filterValue) {
        setValueError('Value is required.');
      } else {
        setValueError('');
      }
    } else {
      setValueError('');
    }
  }, []);
  return { validateValue, valueError };
}

export function useFieldMatchesInputValidation() {
  const [fieldMatchInputError, setFieldMatchInputError] = useState('');
  const { dataFlowState } = useContext(DataFlowEditorContext);
  const { activeElement, links } = dataFlowState;

  const validateFieldMatchInput = useCallback(
    filterValue => {
      const inputFields = activeElement?.elementData?.fields;
      if (filterValue && !inputFields.some(field => getOriginalName(field) === filterValue)) {
        // enters this if no inputFields match the filterValue, meaning block has been disconnected
        setFieldMatchInputError(`Unknown field '${filterValue}'`);
      } else if (filterValue && links.every(link => link.targetId !== activeElement?.id)) {
        // If its not connected and has values set
        setFieldMatchInputError(`Unknown field '${filterValue}'`);
      } else {
        setFieldMatchInputError('');
      }
    },
    [activeElement, links]
  );
  return { validateFieldMatchInput, fieldMatchInputError };
}
