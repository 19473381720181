import { useCallback, useMemo, useContext } from 'react';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { BTButton, BTComboBox, BTForm, BTIcon } from '@btas/jasper';
import './LeftRightFieldsSelector/styles.scss';
import { useInputElements } from '../DataFlowInspector/useInputElements';
import { SUPPORTED_FIELDS_SELECTOR } from './constants/blocks.constant';
import { LookupTableInput } from '../LookupElementInspector';
import { MergeInput } from '../MergeElementInspector';
import { getOriginalName } from '../../shared/utils/FieldHashUtils';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';

export const LeftRightFieldsSelector = ({
  mappings,
  setMappings,
  leftFields,
  rightFields,
  disableSelected = false,
  leftLabel = 'Left',
  rightLabel = 'Right',
  allMappings,
  onAddMapping,
  onDeleteMapping,
  onFieldChange,
}) => {
  const mapFieldsToOptions = useCallback(
    (fields, side) => {
      const selected = disableSelected
        ? (allMappings || mappings).filter(m => m[side]).map(m => m[side]?.original_name || m[side].name)
        : [];
      return (fields || []).map(f => ({
        label: f.original_name || f.name,
        value: f,
        isDisabled: selected.includes(f?.original_name || f.name),
      }));
    },
    [disableSelected, mappings, allMappings]
  );
  const leftOptions = useMemo(() => mapFieldsToOptions(leftFields, 'left'), [leftFields, mapFieldsToOptions]);
  const rightOptions = useMemo(() => mapFieldsToOptions(rightFields, 'right'), [rightFields, mapFieldsToOptions]);
  const canEditWorkflow = useCanEditWorkflow();

  const handleAddClick = () => {
    if (onAddMapping) {
      onAddMapping({ left: null, right: null });
    } else {
      setMappings([...mappings, { left: null, right: null }]);
    }
  };

  const handleDeleteClick = field => {
    if (onDeleteMapping) {
      return onDeleteMapping(field);
    }

    if (mappings.length === 1) {
      setMappings([{ left: null, right: null }]);
      return;
    }

    const newMappings = mappings.filter(f => f !== field);
    setMappings(newMappings);
  };

  const handleFieldChange = (keyFieldIndex, side, option) => {
    if (onFieldChange) {
      return onFieldChange(keyFieldIndex, side, option.value);
    }

    const newMappings = [...mappings];
    // Autopopulate right fields by left field names (case-insensitive)
    if (side === 'left' && option.value && !mappings[keyFieldIndex].right) {
      const left = option.value;
      const matchingRight = rightFields.find(
        r => r.name?.localeCompare(left.name, undefined, { sensitivity: 'accent' }) === 0 && r.type === left.type
      );
      newMappings[keyFieldIndex] = { left, right: matchingRight || null };
    } else {
      newMappings[keyFieldIndex] = { ...mappings[keyFieldIndex], [side]: option.value };
    }
    setMappings(newMappings);
  };

  const { dataFlowState, previewState } = useContext(DataFlowEditorContext);
  const { activeElement, workingElement, links } = dataFlowState;

  const { validationErrors } = previewState;

  const dataTypeMismatchFields = useMemo(() => {
    const parsedValidationErrors = validationErrors?.filter(
      v => v.targetDetails !== undefined && v.elementId === activeElement.id
    );

    parsedValidationErrors?.forEach(validationError => {
      validationError.targetDetails =
        validationError.targetDetails instanceof Object || !validationError.targetDetails
          ? validationError.targetDetails
          : JSON.parse(validationError.targetDetails);
    });

    return parsedValidationErrors?.map(v => ({ message: v.message, field: v.targetDetails }));
  }, [activeElement.id, validationErrors]);

  const { inputElements } = useInputElements(workingElement);

  const elementType = activeElement?.type;

  const leftElementData = useMemo(
    () => (elementType === 'merge' ? inputElements?.left?.elementData : inputElements?.A?.elementData),
    [elementType, inputElements]
  );
  const rightElementData = useMemo(
    () => (elementType === 'merge' ? inputElements?.right?.elementData : inputElements?.B?.elementData),
    [elementType, inputElements]
  );

  const isLeftUpstreamLinked = useMemo(() => {
    switch (elementType) {
      case 'lookup':
      case 'join':
        return links.some(l => l.targetId === activeElement?.id && l.targetPort === LookupTableInput.A);
      default:
        return links.some(l => l.targetId === activeElement?.id && l.targetPort === MergeInput.A);
    }
  }, [links, activeElement, elementType]);

  const isRightUpstreamLinked = useMemo(() => {
    switch (elementType) {
      case 'lookup':
      case 'join':
        return links.some(l => l.targetId === activeElement?.id && l.targetPort === LookupTableInput.B);
      default:
        return links.some(l => l.targetId === activeElement?.id && l.targetPort === MergeInput.B);
    }
  }, [links, activeElement, elementType]);

  const selectorHasUnknownFieldError = (mapping, side) => {
    return side === 'left'
      ? SUPPORTED_FIELDS_SELECTOR.includes(activeElement?.type) &&
          mapping.left?.name !== undefined &&
          (!isLeftUpstreamLinked ||
            (leftElementData &&
              !leftElementData.fields?.some(field => {
                if (field?.original_name === undefined) {
                  return field.name === mapping.left?.name;
                } else {
                  return field.original_name === mapping.left?.original_name || mapping.left?.name;
                }
              })))
      : SUPPORTED_FIELDS_SELECTOR.includes(activeElement?.type) &&
          mapping.right?.name !== undefined &&
          (!isRightUpstreamLinked ||
            (rightElementData &&
              !rightElementData.fields?.some(field => {
                if (field?.original_name === undefined) {
                  return field.name === mapping.right?.name;
                } else {
                  return field.original_name === mapping.right?.original_name || mapping.right?.name;
                }
              })));
  };

  const validationMessage = (mapping, side) => {
    if (selectorHasUnknownFieldError(mapping, side)) {
      return `Unknown field '${getOriginalName(mapping[side] || {})}'`;
    }

    const foundMisMatch = dataTypeMismatchFields?.find(f => f.field[side]?.name === mapping[side]?.name);
    if (foundMisMatch) {
      return foundMisMatch.message;
    }
  };

  return (
    <div className="wkp-fields-selector">
      {mappings.map((mapping, index) => (
        <div key={`${index}${mapping.left?.name}${mapping.right?.name}`} className="wkp-field-row">
          <BTForm.FormGroup
            errorText={validationMessage(mapping, 'left')}
            hasError={
              selectorHasUnknownFieldError(mapping, 'left') ||
              dataTypeMismatchFields?.find(
                f =>
                  f.field['left']?.name === mapping['left']?.name && f.field['right']?.name === mapping['right']?.name
              )
            }
            label={index === 0 ? leftLabel : null}
          >
            <BTComboBox
              popOutMenu
              aria-label={`combobox-left-${index}`}
              disabled={!canEditWorkflow}
              maxMenuHeight={100}
              options={leftOptions}
              value={leftOptions.find(o => {
                if (o.value?.original_name === undefined) {
                  return o.value.name === mapping.left?.name;
                } else {
                  if (mapping.left?.original_name) {
                    return o.value.original_name === mapping.left?.original_name;
                  } else {
                    return o.value.original_name === mapping.left?.name;
                  }
                }
              })}
              onChange={handleFieldChange.bind(null, index, 'left')}
            />
          </BTForm.FormGroup>
          <BTForm.FormGroup
            errorText={validationMessage(mapping, 'right')}
            hasError={
              selectorHasUnknownFieldError(mapping, 'right') ||
              dataTypeMismatchFields?.find(
                f =>
                  f.field['left']?.name === mapping['left']?.name && f.field['right']?.name === mapping['right']?.name
              )
            }
            label={index === 0 ? rightLabel : null}
          >
            <BTComboBox
              popOutMenu
              aria-label={`combobox-right-${index}`}
              disabled={!canEditWorkflow}
              maxMenuHeight={100}
              options={rightOptions}
              value={rightOptions.find(o =>
                o.value.original_name === undefined
                  ? o.value.name === mapping.right?.name
                  : mapping.right?.original_name
                    ? o.value.original_name === mapping.right?.original_name
                    : o.value.original_name === mapping.right?.name
              )}
              onChange={handleFieldChange.bind(null, index, 'right')}
            />
          </BTForm.FormGroup>
          <BTForm.FormGroup>
            {canEditWorkflow ? (
              <BTButton
                aria-label={`wkp-delete-btn-${index}`}
                btStyle="link"
                btType="icon"
                className="wkp-delete-btn"
                icon={<BTIcon icon="trash" />}
                onClick={handleDeleteClick.bind(null, mapping)}
              />
            ) : (
              <></>
            )}
          </BTForm.FormGroup>
        </div>
      ))}
      {canEditWorkflow ? (
        <div>
          <BTButton btStyle="link" icon={<BTIcon icon="plus" />} onClick={handleAddClick}>
            Add another field
          </BTButton>
        </div>
      ) : (
        <div style={{ marginBottom: '30px' }} />
      )}
    </div>
  );
};
