export const automatedUsers = [
  '50013721',
  '50012216',
  '33424541',
  '50017960',
  '50014844',
  '50013820',
  '33424550',
  '50067390',
  '50027938',
  '30101015',
  '50133166',
  '50027961',
  '50022708',
  '30105016',
  '50018482',
  '50018481',
  '50144409',
  '50143295',
];
