import { spreadsheetServiceHostName, nodeAPIServiceHostName } from '../../../configs/params';
import * as api from '../../_shared/fetch';

export async function getNextValidWorkpaperName(workpaperId) {
  const response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workbook/${workpaperId}/next-valid-name`);
  const { name } = await response.json();
  return name;
}

export async function saveAsWorkpaper(workpaperId, jobId, name, taxPeriod) {
  const response = await api.post(`${spreadsheetServiceHostName}/spreadsheet/files/copy`, {
    body: JSON.stringify({ workpaperId, jobId, name, taxPeriod }),
  });
  const { workpaperId: newId } = await response.json();
  return newId;
}

export async function validateMetadata(workpaperId, name, taxPeriod) {
  const response = await api.post(`${spreadsheetServiceHostName}/spreadsheet/metadata/${workpaperId}/validate`, {
    body: JSON.stringify({
      name,
      taxPeriod,
    }),
  });
  const { isValid } = await response.json();
  return isValid;
}

export async function saveAsTags(fromResourceId, toResourceId, resourceType) {
  const response = await api.post(`${nodeAPIServiceHostName}/node/tags/resource/copy`, {
    body: JSON.stringify({ fromResourceId, toResourceId, resourceType }),
  });
  const data = await response.json();
  if (!response.ok) {
    throw data;
  }
  return data;
}
