export const WORKPAPER_IMPORT_JOB_TYPE = 1;
export const WORKPAPER_COPY_JOB_TYPE = 2;
export const WORKPAPER_EXPORT_JOB_TYPE = 3;
export const WORKPAPER_TAX_PROVISION_EXPORT_JOB_TYPE = 4;
export const WORKPAPER_COPY_WORKSHEET_JOB_TYPE = 5;
export const WORKPAPER_ROLLFORWARD_JOB_TYPE = 6;
export const WORKPAPER_GLOBAL_TEMPLATE_JOB_TYPE = 7;
export const WORKPAPER_RECOVERY_JOB_TYPE = 8;
export const WORKPAPER_RESTORE_RECYCLE_BIN_JOB_TYPE = 9;
export const DATAFLOW_RAW_FILE_UPLOAD_JOB_TYPE = 10;
export const DATAFLOW_FILE_IMPORT_FILE_JOB_TYPE = 11;
export const DATAFLOW_FILE_METADATA_JOB_TYPE = 12;
