import GC from '../../../../../SpreadSheets';
import { formulaHelpers } from '.';
import { gcExpressionTypes } from './spreadHelpers';

export const stateTaxRateFnName = 'STATETAXRATE';

/**
 * Transforms the argument into a string date in case it is a Date. Otherwise, just transforms into string the argument
 * @param {*} arg
 * @returns
 */
export function transformIntoString(arg) {
  let result;

  if (arg instanceof Date) {
    const year = arg.toLocaleString('default', { year: 'numeric' });
    const mm = arg.toLocaleString('default', { month: '2-digit' });
    const dd = arg.toLocaleString('default', { day: '2-digit' });
    result = `${year}-${mm}-${dd}`;
  } else {
    result = arg.toString();
  }

  return result;
}

/**
 * Gets formula arguments or undefined if it can't find an expression with arguments
 * @param {*} sheet Sheet that contains {@link formula}
 * @param {*} formula Formula that might contains an expression with arguments
 * @param {string} fnName Name of the function that you are searching
 * @returns Formula arguments or undefined if it can't find an expression with arguments
 */
export function getFunctionArgumentValues(sheet, formula, fnName) {
  const formulaExpression = getFunctionExpression(
    fnName,
    GC.Spread.Sheets.CalcEngine.formulaToExpression(sheet, formula, 0, 0)
  );

  return formulaExpression?.arguments
    ? formulaHelpers.getArgumentValuesFromExpression(formulaExpression, sheet)
    : undefined;
}

/**
 * Searches for a given function expression contained in {@link expression} or undefined if it doesn't exist
 * @param {*} expression Expression that might contain a given function expression
 * @returns function expression contained in {@link expression} or undefined if it doesn't exist
 */
export function getFunctionExpression(fnName, genExpression) {
  let expression;
  const { type, functionName: name } = genExpression;

  if (type !== gcExpressionTypes.function && name !== fnName) {
    const expressionIsStateTaxRate = isExpressionTheFunction(fnName);
    expression = Object.values(genExpression).find(expressionIsStateTaxRate);
  } else if (type === gcExpressionTypes.function && name === fnName) {
    expression = genExpression;
  }

  return expression;
}

/**
 * Given an expression and a function name validates if the expression
 *  is of that function
 * @param {string} functionName the function nameto validate
 * @returns {(expression: any) => boolean}
 */
export function isExpressionTheFunction(functionName) {
  return expression =>
    typeof expression === 'object' &&
    expression.hasOwnProperty('type') &&
    expression.hasOwnProperty('functionName') &&
    expression.type === gcExpressionTypes.function &&
    expression.functionName === functionName;
}
