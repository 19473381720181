import * as fetch from '../fetch';
import { userDataAPIPath } from '../../../configs/params';

export async function getUserPreferences() {
  try {
    let res = await fetch.get(`${userDataAPIPath}/preferences`);

    if (res.status === 404) {
      return null;
    }

    return await res.json();
  } catch (error) {
    console.log(`Error while fetching user preferences: ${error}`);
  }
}

export async function setUserPreferences(preferences) {
  await fetch.post(`${userDataAPIPath}/preferences`, {
    body: JSON.stringify({ preferences }),
  });
}
