import React from 'react';
import Spinner from '../../_shared/Spinner';
import Activity from './RecentActivity/Activity';
import './RecentActivity/styles.scss';

const RecentActivity = ({ runs, error, isLoading, onViewSourceData }) => {
  return (
    <div className="wkp-recent-activity">
      <h4 className="wkp-recent-activity-title">Recent Activity</h4>
      <div className="wkp-recent-activity-items">
        {isLoading ? (
          <Spinner size="2x" />
        ) : error ? (
          <p className="text-danger">{error.message}</p>
        ) : runs.length === 0 ? (
          <p>No Activities</p>
        ) : (
          runs.map(run => <Activity key={run.id} data={run} onViewSourceData={onViewSourceData} />)
        )}
      </div>
    </div>
  );
};

export default RecentActivity;
