import * as fetch from '../../../../../_shared/fetch';
import { spreadsheetServiceHostName } from '../../../../../../configs/params';

export async function getWorkpaperVersions(id, namedVersionsOnly) {
  const url = `${spreadsheetServiceHostName}/spreadsheet/workbook/${id}/versions/${namedVersionsOnly}`;
  const response = await fetch.get(url);

  const data = await response.json();
  return data;
}

export async function updateWorkpaperVersionName({ versionId, versionName }) {
  const response = await fetch.patch(
    `${spreadsheetServiceHostName}/spreadsheet/workbook/${versionId}/update-version-name`,
    {
      body: JSON.stringify({ versionName }),
    }
  );
  const data = await response.json();
  return data;
}
