import GC from '../../../../../../../../SpreadSheets';

var Util = (function () {
  function Util() {}
  Util.parseFont = function (font) {
    var fontStyle = '',
      fontWeight = 'normal',
      lineHeight = 'normal';
    var elements = font.split(/\s+/);
    for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
      var element = elements_1[_i];
      switch (element) {
        case 'normal':
          break;
        case 'italic':
          if (fontStyle.indexOf('bold') !== -1) {
            fontStyle = 'bold italic';
          } else {
            fontStyle = element;
          }
          break;
        case 'bold':
          if (fontStyle.indexOf('italic') !== -1) {
            fontStyle = 'bold italic';
          } else {
            fontStyle = element;
          }
          fontWeight = 'bold';
          break;
        case '100':
        case '200':
        case '300':
        case '400':
        case '500':
        case '600':
        case '700':
        case '800':
        case '900':
          fontWeight = element;
          break;
        case 'lighter':
          fontWeight = 'lighter';
          break;
        default:
          break;
      }
    }
    if (!fontStyle) {
      fontStyle = 'normal';
    }
    return {
      fontStyle: fontStyle,
      fontWeight: fontWeight,
      lineHeight: lineHeight,
    };
  };
  Util.getActiveCellStyle = function (sheet) {
    return sheet.getActualStyle(sheet.getActiveRowIndex(), sheet.getActiveColumnIndex());
  };
  Util.getInvisibleInfo = function (infos) {
    var result = [];
    for (var i = 0; i < infos.length; i++) {
      var info = infos[i];
      if (info) {
        result.push(i);
      }
    }
    return result;
  };
  Util.getNextStartIndex = function (arr, index) {
    var num = arr[index];
    do {
      index++;
      num++;
    } while (num === arr[index]);
    return num;
  };
  Util.getContinuousVisibleInterval = function (totalLength, array) {
    var result = [];
    array = array.sort(function (first, last) {
      return first - last;
    });
    var startIndex = 0,
      endIndex = 0;
    while (endIndex < totalLength) {
      if (array.indexOf(endIndex) > -1) {
        result.push({
          startIndex: startIndex,
          endIndex: endIndex - 1,
        });
        var nextStartIndex = Util.getNextStartIndex(array, array.indexOf(endIndex));
        startIndex = endIndex = nextStartIndex;
      } else {
        endIndex++;
      }
    }
    if (startIndex < endIndex) {
      result.push({
        startIndex: startIndex,
        endIndex: endIndex - 1,
      });
    }
    return result;
  };
  Util.getInvisibleRange = function (sheet) {
    var result = [];
    var rowInfos = this.getAxisInfo(sheet, true);
    var colInfos = this.getAxisInfo(sheet, false);
    var invisibleCols = this.getInvisibleInfo(colInfos);
    var invisibleRows = this.getInvisibleInfo(rowInfos);
    if (invisibleCols.length !== 0 || invisibleRows.length !== 0) {
      var continuousInvisibleRows = this.getContinuousVisibleInterval(sheet.getRowCount(), invisibleRows);
      var continuousInvisibleColumns_1 = this.getContinuousVisibleInterval(sheet.getColumnCount(), invisibleCols);
      continuousInvisibleRows.forEach(function (invisibleRowInterval) {
        continuousInvisibleColumns_1.forEach(function (invisibleColInterval) {
          var startRowIndex = invisibleRowInterval.startIndex,
            rowCount = invisibleRowInterval.endIndex - startRowIndex + 1;
          var startColIndex = invisibleColInterval.startIndex,
            colCount = invisibleColInterval.endIndex - startColIndex + 1;
          var range = new GC.Spread.Sheets.Range(startRowIndex, startColIndex, rowCount, colCount);
          result.push(range);
        });
      });
    }
    return result;
  };
  Util.getRangeType = function (range) {
    var row = range.row;
    var col = range.col;
    if (row === -1) {
      if (col === -1) {
        return 3 /* RangeType.entireSheet */;
      } else {
        return 2 /* RangeType.fullColumn */;
      }
    } else {
      if (col === -1) {
        return 1 /* RangeType.fullRow */;
      } else {
        return 0 /* RangeType.singleRange */;
      }
    }
  };
  Util.getAxisInfo = function (sheet, isRow) {
    var result = [];
    if (isRow) {
      var rowCount = sheet.getRowCount();
      for (let i = 0; i < rowCount; i++) {
        var rowHeight = sheet.getRowHeight(i);
        result.push(rowHeight === 0);
      }
    } else {
      var colCount = sheet.getColumnCount();
      for (let i = 0; i < colCount; i++) {
        var colWidth = sheet.getColumnWidth(i);
        result.push(colWidth === 0);
      }
    }
    return result;
  };
  Util.isFirefox = function () {
    return !!navigator.userAgent.match(/Firefox/g);
  };
  Util.getBrowserCulture = function () {
    return navigator.language;
  };
  Util.getNeedAdjustSelection = function (selections, rowIndex, colIndex) {
    var sel = null;
    for (var _i = 0, selections_1 = selections; _i < selections_1.length; _i++) {
      var selItem = selections_1[_i];
      if (selItem.contains(rowIndex, colIndex, 1, 1)) {
        sel = selItem;
      }
    }
    return sel;
  };
  Util.getCellPositionInRangeByCol = function (range, activeColIndex) {
    var activePosition;
    if (activeColIndex === range.col || (range.col === -1 && activeColIndex === 0)) {
      activePosition = 0 /* ActiveCellPositionInRange.start */;
    } else if (
      activeColIndex === range.col + range.colCount - 1 ||
      (range.col === -1 && activeColIndex === range.colCount - 1)
    ) {
      activePosition = 2 /* ActiveCellPositionInRange.end */;
    } else {
      activePosition = 1 /* ActiveCellPositionInRange.middle */;
    }
    return activePosition;
  };
  Util.getCellPositionInRangeByRow = function (range, activeRowIndex) {
    var activePosition;
    if (activeRowIndex === range.row || (range.row === -1 && activeRowIndex === 0)) {
      activePosition = 0 /* ActiveCellPositionInRange.start */;
    } else if (
      activeRowIndex === range.row + range.rowCount - 1 ||
      (range.row === -1 && activeRowIndex === range.rowCount - 1)
    ) {
      activePosition = 2 /* ActiveCellPositionInRange.end */;
    } else {
      activePosition = 1 /* ActiveCellPositionInRange.middle */;
    }
    return activePosition;
  };
  Util.findPreviousNotNullColIndex = function (sheet, fixRow, colIndex) {
    var lastVisibleColumn = colIndex,
      stop = 0;
    while (colIndex >= stop) {
      if (sheet.getColumnVisible(colIndex)) {
        lastVisibleColumn = colIndex;
        if (sheet.getValue(fixRow, colIndex) !== null) {
          break;
        }
      }
      colIndex--;
    }
    if (colIndex < stop) {
      colIndex = stop;
    }
    return sheet.getColumnVisible(colIndex) ? colIndex : lastVisibleColumn;
  };
  Util.findNextNotNullColIndex = function (sheet, fixRow, colIndex) {
    var lastVisibleColumn = colIndex,
      stop = sheet.getColumnCount();
    while (colIndex < stop) {
      if (sheet.getColumnVisible(colIndex)) {
        lastVisibleColumn = colIndex;
        if (sheet.getValue(fixRow, colIndex) !== null) {
          break;
        }
      }
      colIndex++;
    }
    if (colIndex >= stop) {
      colIndex = stop - 1;
    }
    return sheet.getColumnVisible(colIndex) ? colIndex : lastVisibleColumn;
  };
  Util.findPreviousNotNullRowIndex = function (sheet, fixCol, rowIndex) {
    var lastVisibleRow = rowIndex,
      stop = 0;
    while (rowIndex >= stop) {
      if (sheet.getRowVisible(rowIndex)) {
        lastVisibleRow = rowIndex;
        if (sheet.getValue(rowIndex, fixCol) !== null) {
          break;
        }
      }
      rowIndex--;
    }
    if (rowIndex < stop) {
      rowIndex = stop;
    }
    return sheet.getRowVisible(rowIndex) ? rowIndex : lastVisibleRow;
  };
  Util.findNextNotNullRowIndex = function (sheet, fixCol, rowIndex) {
    var lastVisibleRow = rowIndex,
      stop = sheet.getRowCount();
    while (rowIndex < stop) {
      if (sheet.getRowVisible(rowIndex)) {
        lastVisibleRow = rowIndex;
        if (sheet.getValue(rowIndex, fixCol) !== null) {
          break;
        }
      }
      rowIndex++;
    }
    if (rowIndex >= stop) {
      rowIndex = stop - 1;
    }
    return sheet.getRowVisible(rowIndex) ? rowIndex : lastVisibleRow;
  };
  Util.findLastNotNullColIndexInRange = function (sheet, fixRow, colIndex) {
    var lastVisibleColumn = colIndex,
      stop = sheet.getColumnCount();
    while (colIndex < stop) {
      if (sheet.getColumnVisible(colIndex)) {
        if (sheet.getValue(fixRow, colIndex) !== null) {
          lastVisibleColumn = colIndex;
        } else {
          colIndex--;
          break;
        }
      }
      colIndex++;
    }
    if (colIndex >= stop) {
      colIndex = stop - 1;
    }
    return sheet.getColumnVisible(colIndex) ? colIndex : lastVisibleColumn;
  };
  Util.findFirstNotNullColIndexInRange = function (sheet, fixRow, colIndex) {
    var lastVisibleColumn = colIndex,
      stop = 0;
    while (colIndex >= stop) {
      if (sheet.getColumnVisible(colIndex)) {
        if (sheet.getValue(fixRow, colIndex) !== null) {
          lastVisibleColumn = colIndex;
        } else {
          colIndex++;
          break;
        }
      }
      colIndex--;
    }
    if (colIndex < stop) {
      colIndex = stop;
    }
    return sheet.getColumnVisible(colIndex) ? colIndex : lastVisibleColumn;
  };
  Util.findLastNotNullRowIndexInRange = function (sheet, fixCol, rowIndex) {
    var lastVisibleRow = rowIndex,
      stop = sheet.getRowCount();
    while (rowIndex < stop) {
      if (sheet.getRowVisible(rowIndex)) {
        if (sheet.getValue(rowIndex, fixCol) !== null) {
          lastVisibleRow = rowIndex;
        } else {
          rowIndex--;
          break;
        }
      }
      rowIndex++;
    }
    if (rowIndex >= stop) {
      rowIndex = stop - 1;
    }
    return sheet.getRowVisible(rowIndex) ? rowIndex : lastVisibleRow;
  };
  Util.findFirstNotNullRowIndexInRange = function (sheet, fixCol, rowIndex) {
    var lastVisibleRow = rowIndex,
      stop = 0;
    while (rowIndex >= stop) {
      if (sheet.getRowVisible(rowIndex)) {
        if (sheet.getValue(rowIndex, fixCol) !== null) {
          lastVisibleRow = rowIndex;
        } else {
          rowIndex++;
          break;
        }
      }
      rowIndex--;
    }
    if (rowIndex < stop) {
      rowIndex = stop;
    }
    return sheet.getRowVisible(rowIndex) ? rowIndex : lastVisibleRow;
  };
  return Util;
})();
export { Util };
