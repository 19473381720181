import {
  uploadServiceHostName,
  spreadsheetServiceHostName,
  workpapersSpreadsheetModelHostName,
} from '../../../configs/params';
import { generalErrorMessage } from '../../_shared/messages';
import { getFormattedDateForGrid } from '../../_shared/dateFormatting';
import * as api from '../../_shared/fetch';
import { PreSignedUrlFileTypes } from '../../../constants/preSignedUrlFileTypes';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { SJS_API } from '../../../constants/featureFlags';

export async function getList({ sorting, filters }) {
  let response;
  const filtersAndSortingToSend = removeEmptyFilters({
    sortByColumn: sorting.binding,
    sortDirection: sorting.direction,
    ...filters,
  });
  const bodyRequest = buildSortAndFiltersWorkpapersRequest(filtersAndSortingToSend);
  response = await api.post(`${spreadsheetServiceHostName}/spreadsheet/workpapers/list`, {
    body: JSON.stringify(bodyRequest),
  });

  if (!response.ok) {
    throw response.error;
  }

  const data = await response.json();

  return {
    ...data,
    items: data.items.map(d => ({
      ...d,
      lastModifiedDate: getFormattedDateForGrid(d.lastModifiedDate),
      workpaperSignOffs: formatSignoffs(d.workpaperSignOffs),
    })),
  };
}

function buildSortAndFiltersWorkpapersRequest(obj) {
  return Object.entries(obj).reduce((prev, [k, v]) => {
    if (k === 'taxPeriod') {
      const taxPeriodValue = v === null ? 'unassigned' : v;
      return { ...prev, [k]: taxPeriodValue };
    } else {
      return {
        ...prev,
        filters: { ...prev['filters'], [k]: v },
      };
    }
  }, {});
}

function removeEmptyFilters(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => {
      if (k === 'taxPeriod') {
        //This is needed since when the filter is in unassigned the taxPeriod value is null an need to query in the backend.
        //When is no taxPeriod filtering selection the value is undefined
        return v === null || v != null;
      }
      return v != null;
    })
  );
}

/**
 * The max amount of sign-offs that could appear in the popover are 10, that's the reason why we get only the
 * first 10
 */
function formatSignoffs(signOffs) {
  signOffs = signOffs.slice(0, 10);
  signOffs = signOffs.map(signOff => {
    return { ...signOff, signOffDate: getFormattedDateForGrid(signOff.signOffDate) };
  });
  return signOffs;
}

export async function getFilterOptions() {
  const response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers/list/filters`);
  const data = await response.json();

  return data;
}

function upload({ url, progress, success, failure, formData, method }) {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.open(method, url, true);

  xhr.setRequestHeader('Authorization', 'Bearer token');
  //todo handle 401 for token expired as we do with fetch

  xhr.upload.onprogress = e =>
    progress({
      percentComplete: Math.ceil((e.loaded / e.total) * 100),
    });
  xhr.onload = function () {
    success({
      statusCode: this.status,
      response: this.response ? JSON.parse(this.response) : undefined,
      error: this.status !== 200 ? { code: 'SERVER_ERROR', message: generalErrorMessage } : undefined,
    });
  };
  xhr.onerror = function () {
    failure({
      error: { code: 'SERVER_ERROR', message: generalErrorMessage },
    });
  };
  xhr.send(formData);
}

export function postFiles({ file, progress, success, failure }) {
  const url = `${uploadServiceHostName}/api/v1/workpapers/upload`;
  const formData = new FormData();
  formData.append('file', file, file.name);

  upload({
    url,
    formData,
    method: 'POST',
    progress,
    success,
    failure,
  });
}

export function replaceFiles({ file, fileType, progress, success, failure }) {
  const url = `${uploadServiceHostName}/api/v1/workpapers/upload`;

  const formData = new FormData();
  formData.append('file', file, file.name);

  upload({
    url,
    formData,
    method: 'PUT',
    progress,
    success,
    failure,
  });
}

export function keepFiles({ file, progress, success, failure }) {
  const url = `${uploadServiceHostName}/api/v1/workpapers/upload/keep`;

  const formData = new FormData();
  formData.append('file', file, file.name);

  upload({
    url,
    formData,
    method: 'POST',
    progress,
    success,
    failure,
  });
}

export async function exportWorkpapers({ id, jobId }) {
  const downloadResult = await api.get(`${spreadsheetServiceHostName}/spreadsheet/files/download/${id}?jobId=${jobId}`);
  const { presignedUrl } = await downloadResult.json();
  return [presignedUrl];
}

export async function getImportPresignedUrl(fileName, workpaperId) {
  const filenameUrlEncoded = encodeURIComponent(fileName);
  const presignedUrlResult = await api.get(
    `${spreadsheetServiceHostName}/spreadsheet/files/presigned-url/${filenameUrlEncoded}?targetId=${workpaperId}&fileType=${PreSignedUrlFileTypes.SourceFile}`
  );
  const presignedUrlData = await presignedUrlResult.json();
  if (!presignedUrlResult.ok) {
    throw presignedUrlData.error;
  }
  return presignedUrlData;
}

export async function uploadFileToBucket(presignedUrl, file) {
  const response = await fetch(presignedUrl, {
    method: 'PUT',
    body: file,
  });
  if (!response.ok) {
    throw new Error('Failed to upload source file. Please check your internet connection and try again.');
  }
}

export async function runImportFile(id, jobId, key, fileName) {
  return await api.put(`${spreadsheetServiceHostName}/spreadsheet/files/upload`, {
    body: JSON.stringify({ id, jobId, fileName, key }),
  });
}

export async function runSjsUpload({ workpaperId, jobId, fileName, key }) {
  return await api.post(`${workpapersSpreadsheetModelHostName}/file/upload/${workpaperId}`, {
    body: JSON.stringify({ workpaperId, jobId, fileName, key }),
  });
}

export async function createTemplateWorkpaper(workpaperId, categoryName, templateName) {
  return await api.post(`${spreadsheetServiceHostName}/spreadsheet/files/create-from-template`, {
    body: JSON.stringify({ workpaperId, categoryName, templateName }),
  });
}

export async function copyWorkpapers(id, jobId) {
  const btasWkpSjsEnabled = isFeatureFlagEnabled(SJS_API);
  const baseUrl = btasWkpSjsEnabled
    ? `${workpapersSpreadsheetModelHostName}/file/copy/${id}`
    : `${spreadsheetServiceHostName}/spreadsheet/files/copy`;
  return await api.post(`${baseUrl}`, {
    body: JSON.stringify({ workpaperId: id, jobId, btasWkpSjsEnabled }),
  });
}

export async function getStatusCountList(taxPeriod) {
  const response = await api.get(
    `${spreadsheetServiceHostName}/spreadsheet/workpapers/status-count${
      taxPeriod ? '?taxPeriod=' + taxPeriod.value : ''
    }`
  );
  const data = await response.json();
  return data;
}

/**
 * @returns {Promise<{category: {name: string; templates: string[];}[]}>}
 */
export async function getTemplatesList() {
  const response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers/templates`);
  const data = await response.json();
  return data;
}

/**
 * @returns {Promise<{templates: {name: string; sourceWkpId: string;}[]}>}
 */
export async function getGlobalTemplatesList() {
  const response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers/global-templates`);
  const data = await response.json();
  return data;
}

export async function createFromGlobalTemplate(templateSourceWkpId, userJobId, userInfo) {
  const workpaperId = templateSourceWkpId;
  try {
    const response = await api.post(`${spreadsheetServiceHostName}/spreadsheet/templates`, {
      body: JSON.stringify({ workpaperId, userJobId, userInfo }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw data.error;
    }

    return data;
  } catch (error) {
    const mappedError = error?.details ? error.details : [{ code: 'SERVER_ERROR', message: generalErrorMessage }];
    throw mappedError;
  }
}

export async function getStaticVersionStatus(id) {
  const staticVersionStatusResponse = await api.get(
    `${spreadsheetServiceHostName}/spreadsheet/static-version/${id}/status`
  );
  return await staticVersionStatusResponse.json();
}

export async function generateStaticVersion(id) {
  const generateVersionRequest = await api.get(
    `${spreadsheetServiceHostName}/spreadsheet/static-version/${id}/generate`
  );
  if (generateVersionRequest.status !== 200) {
    throw Error('An error has ocurred');
  }
  const data = await generateVersionRequest.json();
  return data.presignedUrl;
}

export async function recoverWorkpaper({ workpaperId, jobId }) {
  const response = await api.post(`${workpapersSpreadsheetModelHostName}/file/recover/${workpaperId}`, {
    body: JSON.stringify({ jobId }),
  });

  if (!response.ok) throw new Error(`Failed to trigger workpaper recover for ${workpaperId}`);

  return await response.text();
}

export async function searchGlobalTemplate(search) {
  const searchText = encodeURIComponent(search);
  const response = await api.get(
    `${spreadsheetServiceHostName}/spreadsheet/workpapers/global-templates/filter?searchText=${searchText}`
  );
  return await response.json();
}
