import React, { useState, useEffect, useContext } from 'react';
import { BTForm, BTComboBox } from '@btas/jasper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getDataFlowAvailableValuesByFilter } from './apis';
import {
  buildCellReferenceError,
  buildDropdownOption,
  removeQuotationMarks,
} from '../../_spreadsheets/sourceDataConnectionHelper';
import UnderlinedLegend from '../../../../../_shared/UnderlinedLegend';
import ConnectionFilterCriteria from './WkpConnectionFilters/ConnectionFilterCriteria';
import { getValueFromCellReference, isCalcError, isCellReference } from '../../_spreadsheets';
import EditorContext from '../../../EditorContext';
import { eventTrackTagNames } from '../../../../../_shared/EventTrackingContext/constants';

export default function WkpConnectionFilters({ filtersList, formData, readOnly, updateParent }) {
  const [filters, setFilters] = useState([]);
  const [availableOptionFilters, setAvailableOptionFilters] = useState([]);
  const [filterTypes, setFilterTypes] = useState({});
  const { spreadRef } = useContext(EditorContext);

  const spread = spreadRef.current;

  useEffect(() => {
    setAvailableOptionFilters(filtersList.map(({ name }) => buildDropdownOption({ value: name, withQuotes: true })));
    updateParent({
      ...formData,
      outputField: formData.outputField,
    });

    const types = {};
    filtersList.forEach(({ name, type }) => {
      types[name] = type;
    });
    setFilterTypes(types);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersList]);

  useEffect(() => {
    if (formData.selectedFilters && Object.keys(formData.selectedFilters).length) {
      const newFilters = [];
      for (const filterName in formData.selectedFilters) {
        const {
          labelReference,
          cell: { value, reference },
        } = formData.selectedFilters[filterName];
        newFilters.push({
          key: { value: filterName, reference: labelReference },
          value: reference ?? value,
          isReference: !!reference,
        });
      }
      setFilters(newFilters);
    } else {
      setFilters([
        {
          key: { value: '', reference: undefined },
          value: '',
          isReference: false,
        },
      ]);
    }
  }, [formData.selectedFilters]);

  const valueGetters = {
    fieldToSum: value => {
      const isValueAnAvailableOption = optionValues.find(option => removeQuotationMarks(option.value) === value);
      return value ? buildDropdownOption({ value, withQuotes: isValueAnAvailableOption }) : value;
    },
  };

  const handleChangeOutputField = event => {
    if (!event) {
      updateParent({
        ...formData,
        outputField: undefined,
      });
      setFilters([
        {
          key: { value: '', reference: undefined },
          value: '',
          isReference: false,
        },
      ]);
      return;
    }
    const outputField = removeQuotationMarks(event.value);
    const isReference = event.isNew && isCellReference(spread, outputField);

    let { fieldErrors } = formData;
    let referenceError;
    if (isReference) {
      const cellValue = getValueFromCellReference(spread, outputField);
      referenceError = isCalcError(cellValue) ? buildCellReferenceError([outputField]) : undefined;
    }
    fieldErrors.outputField = referenceError;

    updateParent({
      ...formData,
      fieldErrors,
      outputField,
    });
  };

  /**
   * Updates formData.selectedFilters property
   * @param {{key: {value: string, reference: string}, value: string, isReference: boolean}[]} filters New list of filters
   */
  const updateFilters = filters => {
    setFilters(filters);
    const newSelectedFilters = {};
    let { fieldErrors } = formData;
    let referenceErrors = [];

    if (filters.length && filters[0].key.value !== '') {
      filters.forEach(({ key, value, isReference }) => {
        const reference = isReference ? value : undefined;
        const cellValue = isReference ? getValueFromCellReference(spread, value) : value;

        if (key.reference && isCalcError(getValueFromCellReference(spread, key.reference)))
          referenceErrors.push(key.reference);
        if (isReference && isCalcError(cellValue)) referenceErrors.push(reference);

        newSelectedFilters[key.value] = {
          labelReference: key.reference,
          cell: { value: cellValue, reference },
        };
      });
    }

    fieldErrors.criteria = referenceErrors.length ? buildCellReferenceError(referenceErrors) : undefined;

    updateParent({
      ...formData,
      fieldErrors,
      selectedFilters: newSelectedFilters,
    });
  };

  const onAddAnotherClick = () => {
    setFilters([
      ...filters,
      {
        key: { value: '', reference: undefined },
        value: '',
        isReference: false,
      },
    ]);
  };

  const optionValues = filtersList
    .filter(filter => filter.type === 'numeric')
    .map(({ name }) => buildDropdownOption({ value: name, withQuotes: true }));

  const fetchCriteriaValues = async (filter, page, limit, search) =>
    await getDataFlowAvailableValuesByFilter(formData.dataFlow.id, formData.output, filter, { page, limit, search });

  const fieldToSumLabel = 'Field to SUM/lookup';
  const fieldToSumId = 'field-to-sum';
  const Criteria = 'Criteria';
  return (
    <>
      <UnderlinedLegend>{'SUMIF(S)'}</UnderlinedLegend>
      <BTForm.FormGroup
        key={fieldToSumLabel}
        required
        data-testid="sdc-field-to-sum"
        errorText={formData.fieldErrors.outputField}
        hasError={typeof formData.fieldErrors.outputField === 'string'}
        htmlFor={fieldToSumId}
        label={fieldToSumLabel}
      >
        <BTComboBox
          canCreateNew
          createText="Add"
          disabled={readOnly}
          hasError={!!formData.fieldErrors.outputField}
          id={fieldToSumId}
          isClearable={true}
          maxMenuHeight={100}
          options={optionValues}
          placeholder="Select a field or type a cell reference"
          value={valueGetters.fieldToSum(formData.outputField)}
          onChange={handleChangeOutputField}
        />
      </BTForm.FormGroup>
      <div className="wkp-connection-filter">
        <BTForm.FormGroup
          key={Criteria}
          errorText={formData.fieldErrors.criteria}
          hasError={typeof formData.fieldErrors.criteria === 'string'}
          htmlFor={Criteria}
          label={Criteria}
        >
          {filters.map((element, index) => (
            <ConnectionFilterCriteria
              key={index}
              availableOptionFilters={availableOptionFilters}
              data-track-tag={eventTrackTagNames.interactive}
              element={element}
              fetchCriteriaValues={fetchCriteriaValues}
              filters={filters}
              index={index}
              readOnly={readOnly}
              types={filterTypes}
              updateFilters={updateFilters}
            />
          ))}

          <div
            className={`add-another ${
              readOnly || filters.find(e => e.key.value === '') || availableOptionFilters.length === filters.length
                ? 'is-disabled'
                : ''
            }`}
            onClick={onAddAnotherClick}
          >
            <FontAwesomeIcon className="fa-plus" icon={faPlus} />
            Add Another
          </div>
        </BTForm.FormGroup>
      </div>
    </>
  );
}
