import React, { useState, useEffect } from 'react';
import { BTButton } from '@btas/jasper';
import Spinner from '../../../../../_shared/Spinner';
import leftArrow from '../../shared/arrow-left.svg';
import CloseButton from '../shared/CloseButton';
import SingleSelection from './TopicsDetail/SingleSelection';
import MultipleSelection from './TopicsDetail/MultipleSelection';
import { getTaxLawTopicsArticles } from './TopicsDetail/apis';
import './TopicsDetail/styles.scss';

export default function TopicsDetail({ isLocked, selectedItems, onBackToTopicsSelection, onClose }) {
  const [topics, setTopics] = useState(null);

  useEffect(() => {
    async function load() {
      const response = await getTaxLawTopicsArticles(selectedItems);
      setTopics(response);
    }

    load();
  }, [selectedItems]);

  const getSectionHeader = () => (
    <>
      <div className="wkp-right-pane-header">
        <h2 className="wkp-topic-header">
          {!isLocked && (
            <BTButton
              btStyle="link"
              className="wkp-back-topic-selection"
              title="Back to select topics"
              onClick={onBackToTopicsSelection}
            >
              <img alt="Select topics" src={leftArrow} />
            </BTButton>
          )}
          <span>{topics && topics.length === 1 ? topics[0].title : 'Tax Law Research'}</span>
        </h2>
        <CloseButton onClose={onClose} />
      </div>
    </>
  );

  const getSectionContent = () => {
    if (topics) {
      return topics.length === 1 ? <SingleSelection topic={topics[0]} /> : <MultipleSelection topics={topics} />;
    }

    return <Spinner />;
  };

  return (
    <>
      {getSectionHeader()}
      {getSectionContent()}
    </>
  );
}
