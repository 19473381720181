import GC from '../../../SpreadSheets';
function formatLinkUrl(url) {
  const worksheetUrlComponent = document.createElement('textarea');
  if (worksheetUrlComponent) {
    worksheetUrlComponent.innerHTML = url;
    const decodedUrl = worksheetUrlComponent.textContent;
    const formatedUrl = decodedUrl.replace(/&amp;/g, '&');
    worksheetUrlComponent.remove();
    return formatedUrl;
  }
}

export function decodeWorksheetUrl(eventInfo) {
  if (eventInfo?.sheet) {
    const activeSheet = eventInfo.sheet;
    const { row, col } = eventInfo;
    var link = activeSheet.getHyperlink(row, col, GC.Spread.Sheets.SheetArea.viewport);
    if (link && link?.url.startsWith('sjs://')) {
      const decodedUrl = formatLinkUrl(link.url);
      if (decodedUrl) {
        activeSheet.setHyperlink(
          row,
          col,
          { url: decodedUrl, tooltip: link.tooltip },
          GC.Spread.Sheets.SheetArea.viewport
        );
      }
    }
  }
}
