import dataTransformationConfigIcon from '../shared/icons/Data_Transformation_Config_Pane_Icon.svg';
export const dataFlowPanelItems = [
  {
    id: 'wkp-df-config-icon',
    title: 'Configuration',
    label: 'configuration',
    name: 'configuration',
    children: <img alt="Config Icon" src={dataTransformationConfigIcon} />,
  },
  {
    id: 'wkp-workflow-btn',
    title: 'Workflow',
    label: 'workflow',
    name: 'workflow',
    children: <i className={'fas fa-lg fa-fw fa-tasks'} />,
  },
];
