import { useEffect, useMemo, useState } from 'react';
import { getDataFlowSourceFileVersion } from './apis';

export default function useGrid({ dataFlow, fileVersionId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [sourceFileVersion, setSourceFileVersion] = useState({});
  const [sort, setSort] = useState({ binding: '', direction: 'ASC' });

  useEffect(() => {
    if (!dataFlow) {
      return;
    }

    async function load() {
      const data = await getDataFlowSourceFileVersion({
        dataFlowId: dataFlow.id,
        sourceFileVersionId: fileVersionId,
        limit: 500,
      });

      setSort({ binding: data.fields[0].name, direction: 'ASC' });
      setSourceFileVersion(data);
      setIsLoading(false);
    }

    setIsLoading(true);
    load();
  }, [dataFlow, fileVersionId]);

  const gridData = useMemo(() => {
    const { binding, direction } = sort;

    return sourceFileVersion.records?.sort((a, b) => {
      const directionFactor = direction === 'ASC' ? 1 : -1;

      if (a[binding] > b[binding]) {
        return 1 * directionFactor;
      }

      if (a[binding] < b[binding]) {
        return -1 * directionFactor;
      }

      return 0;
    });
  }, [sourceFileVersion, sort]);

  const columns = useMemo(
    () => sourceFileVersion.fields?.map(field => ({ key: field.name, width: field.type === 'text' ? '2*' : '*' })),
    [sourceFileVersion]
  );

  return [{ isLoading, columns, gridData, sort }, { setSort }];
}
