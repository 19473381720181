import { useContext, useEffect } from 'react';
import { dia, shapes, ui } from '@clientio/rappid';

import { LINK_COLOR } from '../shared/colors';
import { getElementType } from '../shared/graphUtils';
import { DataFlowEditorContext } from '../DataFlowEditorContext';

const PAPER_WIDTH = 2000;
const PAPER_HEIGHT = 1500;

export const usePaper = containerRef => {
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const { graph } = dataFlowState;

  useEffect(() => {
    const containerEl = containerRef.current;

    if (containerEl && graph) {
      const paper = createPaper(graph);
      const paperScroller = createPaperScroller(containerEl, paper);

      dataFlowActions.setPaper(paper, paperScroller, {
        width: containerEl.clientWidth,
        height: containerEl.clientHeight,
      });
    }
  }, [containerRef, graph, dataFlowActions]);
};

const segmentsElementView = dia.ElementView.extend({
  dragStart: function (evt, x, y) {
    dia.ElementView.prototype.dragStart.call(this, evt, x, y);

    const initialLinks = {};

    const { model: element, paper } = this;
    const { model: graph } = paper;

    graph.getConnectedLinks(element).forEach(link => {
      let vertices = link.vertices();
      let sourceSide, targetSide;
      const linkView = link.findView(paper);
      const [bbox, otherBBox] =
        link.target().id === element.id
          ? [linkView.targetBBox, linkView.sourceBBox]
          : [linkView.sourceBBox, linkView.targetBBox];

      if (vertices.length > 0) {
        sourceSide = bbox.sideNearestToPoint(vertices[0]);
        targetSide = bbox.sideNearestToPoint(vertices[vertices.length - 1]);
      } else {
        sourceSide = targetSide = bbox.sideNearestToPoint(otherBBox.center());
      }
      let sourceAnchor = link.prop('source/anchor/args');
      if (!sourceAnchor) {
        const sourceBBox = linkView.sourceBBox;
        const defaultAnchor = {
          dx: sourceBBox.width / 2,
          dy: sourceBBox.height / 2,
        };
        link.prop('source/anchor', { name: 'topLeft', args: defaultAnchor });
        sourceAnchor = defaultAnchor;
      }
      let targetAnchor = link.prop('target/anchor/args');
      if (!targetAnchor) {
        const targetBBox = linkView.targetBBox;
        const defaultAnchor = {
          dx: targetBBox.width / 2,
          dy: targetBBox.height / 2,
        };
        link.prop('target/anchor', { name: 'topLeft', args: defaultAnchor });
        targetAnchor = defaultAnchor;
      }

      initialLinks[link.id] = {
        vertices,
        sourceSide,
        targetSide,
        sourceAnchor,
        targetAnchor,
      };
    });

    this.eventData(evt, {
      initialLinks,
    });
  },

  drag: function (evt, x, y) {
    const { model: element, paper } = this;
    const { model: graph } = paper;

    const prevPosition = element.position();

    dia.ElementView.prototype.drag.call(this, evt, x, y);

    const position = element.position();

    const diff = position.difference(prevPosition);

    if (diff.x === 0 && diff.y === 0) return;

    const { initialLinks } = this.eventData(evt);

    // MOVING SOURCE OF LINKS
    graph.getConnectedLinks(element, { outbound: true }).forEach(link => {
      if (link.hasLoop()) {
        return;
      }
      const { vertices, sourceSide, sourceAnchor, targetAnchor } = initialLinks[link.id];
      const linkView = link.findView(paper);
      const bbox = linkView.sourceBBox;
      const magnetPosition = bbox.topLeft();
      const [vertex, ...restVertices] = vertices;
      // NO VERTICES
      if (!vertex) {
        const target = link.getTargetCell();
        if (!target) return;

        const targetBBox = linkView.targetBBox;
        const sourceAnchorAbs = {
          x: magnetPosition.x + sourceAnchor.dx,
          y: magnetPosition.y + sourceAnchor.dy,
        };
        switch (sourceSide) {
          case 'top':
          case 'bottom': {
            if (targetBBox.x <= sourceAnchorAbs.x && targetBBox.x + targetBBox.width >= sourceAnchorAbs.x) {
              link.vertices([]);
              link.prop('target/anchor/args', {
                dx: sourceAnchorAbs.x - targetBBox.x,
              });
            } else {
              const point = {
                x: sourceAnchorAbs.x,
                y: targetBBox.y + targetAnchor.dy,
              };
              if (bbox.containsPoint(point)) {
                link.vertices([]);
                link.prop('source/anchor/args', { dy: point.y - bbox.y });
              } else {
                link.vertices([point]);
                link.prop('target/anchor/args', targetAnchor);
              }
            }
            break;
          }
          case 'left':
          case 'right': {
            if (targetBBox.y <= sourceAnchorAbs.y && targetBBox.y + targetBBox.height >= sourceAnchorAbs.y) {
              link.vertices([]);
              link.prop('target/anchor/args', {
                dy: sourceAnchorAbs.y - targetBBox.y,
              });
            } else {
              const point = {
                x: targetBBox.x + targetAnchor.dx,
                y: sourceAnchorAbs.y,
              };
              if (bbox.containsPoint(point)) {
                link.vertices([]);
                link.prop('source/anchor/args', { dx: point.x - bbox.x });
              } else {
                link.vertices([point]);
                link.prop('target/anchor/args', targetAnchor);
              }
            }
            break;
          }
          default:
            break;
        }
        return;
      }
      // AT LEAST SINGLE VERTEX
      if (bbox.containsPoint(vertex)) {
        link.vertices(restVertices);
        let dx, dy;
        switch (sourceSide) {
          case 'top':
          case 'bottom': {
            dy = vertex.y - magnetPosition.y;
            vertex.x += diff.x;
            break;
          }
          case 'left':
          case 'right': {
            dx = vertex.x - magnetPosition.x;
            vertex.y += diff.y;
            break;
          }
          default:
            break;
        }
        link.prop('source/anchor/args', { dx, dy });
        return;
      }
      let { x, y } = vertex;
      switch (sourceSide) {
        case 'top':
        case 'bottom': {
          x += diff.x;
          break;
        }
        case 'left':
        case 'right': {
          y += diff.y;
          break;
        }
        default:
          break;
      }
      vertex.x = x;
      vertex.y = y;
      // EXACTLY ONE VERTEX
      if (vertices.length === 1) {
        const targetBBox = linkView.targetBBox;
        if (targetBBox?.containsPoint(vertex)) {
          link.vertices([]);
          switch (sourceSide) {
            case 'top':
            case 'bottom': {
              link.prop('target/anchor/args', { dx: x - targetBBox.x });
              break;
            }
            case 'left':
            case 'right': {
              link.prop('target/anchor/args', { dy: y - targetBBox.y });
              break;
            }
            default:
              break;
          }
          return;
        } else {
          link.prop('target/anchor/args', targetAnchor);
        }
      }

      link.vertices([{ x, y }, ...restVertices]);
      link.prop('source/anchor/args', sourceAnchor);
    });
    // MOVING TARGET OF LINKS
    graph.getConnectedLinks(element, { inbound: true }).forEach(link => {
      if (link.hasLoop()) {
        return;
      }
      const { vertices, targetSide, targetAnchor, sourceAnchor } = initialLinks[link.id];
      const linkView = link.findView(paper);
      const bbox = linkView.targetBBox;
      const magnetPosition = bbox.topLeft();
      const [vertex, ...restVertices] = vertices.slice().reverse();
      // NO VERTICES
      if (!vertex) {
        const source = link.getSourceCell();
        if (!source) return;
        const sourceBBox = linkView.sourceBBox;
        const targetAnchorAbs = {
          x: magnetPosition.x + targetAnchor.dx,
          y: magnetPosition.y + targetAnchor.dy,
        };
        switch (targetSide) {
          case 'top':
          case 'bottom': {
            if (sourceBBox.x <= targetAnchorAbs.x && sourceBBox.x + sourceBBox.width >= targetAnchorAbs.x) {
              link.vertices([]);
              link.prop('source/anchor/args', {
                dx: targetAnchorAbs.x - sourceBBox.x,
              });
            } else {
              const point = {
                x: targetAnchorAbs.x,
                y: sourceBBox.y + sourceAnchor.dy,
              };
              if (bbox.containsPoint(point)) {
                link.vertices([]);
                link.prop('target/anchor/args', { dy: point.y - bbox.y });
              } else {
                link.vertices([point]);
                link.prop('source/anchor/args', sourceAnchor);
              }
            }
            break;
          }
          case 'left':
          case 'right': {
            if (sourceBBox.y <= targetAnchorAbs.y && sourceBBox.y + sourceBBox.height >= targetAnchorAbs.y) {
              link.vertices([]);
              link.prop('source/anchor/args', {
                dy: targetAnchorAbs.y - sourceBBox.y,
              });
            } else {
              const point = {
                x: sourceBBox.x + sourceAnchor.dx,
                y: targetAnchorAbs.y,
              };
              if (bbox.containsPoint(point)) {
                link.vertices([]);
                link.prop('target/anchor/args', { dx: point.x - bbox.x });
              } else {
                link.vertices([point]);
                link.prop('source/anchor/args', sourceAnchor);
              }
            }
            break;
          }
          default:
            break;
        }
        return;
      }
      // AT LEAST SINGLE VERTEX
      if (bbox.containsPoint(vertex)) {
        link.vertices(restVertices.slice().reverse());
        let dx, dy;
        switch (targetSide) {
          case 'top':
          case 'bottom': {
            dy = vertex.y - magnetPosition.y;
            vertex.x += diff.x;
            break;
          }
          case 'left':
          case 'right': {
            dx = vertex.x - magnetPosition.x;
            vertex.y += diff.y;
            break;
          }
          default:
            break;
        }
        link.prop('target/anchor/args', { dx, dy });
        return;
      }
      let { x, y } = vertex;
      switch (targetSide) {
        case 'top':
        case 'bottom': {
          x += diff.x;
          break;
        }
        case 'left':
        case 'right': {
          y += diff.y;
          break;
        }
        default:
          break;
      }

      vertex.x = x;
      vertex.y = y;
      // EXACTLY ONE VERTEX
      if (vertices.length === 1) {
        const sourceBBox = linkView.sourceBBox;
        if (sourceBBox?.containsPoint(vertex)) {
          link.vertices([]);
          switch (targetSide) {
            case 'top':
            case 'bottom': {
              link.prop('source/anchor/args', { dx: x - sourceBBox.x });
              break;
            }
            case 'left':
            case 'right': {
              link.prop('source/anchor/args', { dy: y - sourceBBox.y });
              break;
            }
            default:
              break;
          }
          return;
        } else {
          link.prop('source/anchor/args', sourceAnchor);
        }
      }

      link.vertices([{ x, y }, ...restVertices].reverse());
      link.prop('target/anchor/args', targetAnchor);
    });
  },
});

function createPaper(graph) {
  const linkStyle = {
    stroke: LINK_COLOR,
    strokeWidth: 1,
    targetMarker: {
      type: 'path',
      stroke: LINK_COLOR,
      fill: LINK_COLOR,
    },
  };

  const defaultPaperProps = {
    width: PAPER_WIDTH,
    height: PAPER_HEIGHT,
    padding: 0,
    gridSize: 10,
    model: graph,
    defaultLink: new shapes.standard.Link({
      line: linkStyle,
    }),
    validateConnection: (cellViewS, magnetS, cellViewT, magnetT) => {
      const targetModel = cellViewT.model;
      const targetCellType = getElementType(targetModel);

      // Ensure source and target magnets exist
      if (!magnetS || !magnetT) {
        return false;
      }

      return targetCellType.canAcceptLinkFrom(graph, targetModel, magnetT, cellViewS.model, magnetS);
    },
    validateMagnet: (cellView, magnet) => {
      const { model } = cellView;
      const elementType = getElementType(model);

      return elementType.canCreateLink(graph, magnet, model);
    },
    snapLinks: { radius: 50 },
    multiLinks: false,
    linkPinning: false,
    perpendicularLinks: true,
    background: { color: '#eeeeee' },
    async: true,
    clickThreshold: 1,
    markAvailable: true,
    defaultRouter: {
      name: 'manhattan',
      step: 10,
      padding: 15,
    },
    defaultConnector: {
      name: 'rounded',
    },
  };

  const paperWithVerticesProps = {
    sorting: dia.Paper.sorting.APPROX,
    interactive: { linkMove: false },
    elementView: () => segmentsElementView,
  };

  const getPaperProps = () => {
    return {
      ...defaultPaperProps,
      ...paperWithVerticesProps,
      defaultRouter: { name: 'normal' },
      defaultLink: new shapes.standard.Link({
        line: linkStyle,
        router: {
          name: 'manhattan',
          step: 10,
          padding: 15,
        },
      }),
    };
  };

  const paper = new dia.Paper(getPaperProps());

  return paper;
}

function createPaperScroller(containerEl, paper) {
  const paperScroller = new ui.PaperScroller({
    autoResizePaper: true,
    paper: paper,
    cursor: 'grab',
  });

  paper.freeze();
  containerEl.appendChild(paperScroller.el);
  paperScroller.render().center();
  paper.unfreeze({
    afterRender() {
      paper.hideTools();
      paper.unfreeze();
    },
  });

  paper.on('blank:pointerdown', ev => {
    paperScroller.startPanning(ev);

    document.addEventListener('mouseup', paperScroller.stopPanning, { once: true });
  });

  return paperScroller;
}
