import { useEffect } from 'react';

export default function useHidePanelOnClick() {
  const parentElement = document.getElementsByClassName('full-panels');
  const sidePanelElement = document.getElementsByClassName('gc-sidePanel-item');
  const menuPanelItemClasses = ['file-menu-watermark', 'gc-list-control-item'];
  const fileButtonClass = 'fileButton';
  useEffect(() => {
    document.addEventListener('click', function (event) {
      const isTargetClassNameString = typeof event.target.className === 'string';
      if (parentElement.length > 0 && isTargetClassNameString) {
        const isFileMenuButtonClicked = !event.target.className.match(fileButtonClass);
        const isClickedOutsidePanel = !parentElement[0].contains(event.target);
        const isMenuItemClicked = menuPanelItemClasses.some(element => event.target.className.includes(element));
        if ((isFileMenuButtonClicked && isClickedOutsidePanel) || isMenuItemClicked) {
          parentElement[0].style.visibility = 'hidden';
          sidePanelElement[0].style.visibility = 'hidden';
        } else {
          parentElement[0].style.visibility = 'visible';
          sidePanelElement[0].style.visibility = 'visible';
        }
      }
    });
  });
}
