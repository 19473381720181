import React from 'react';
import { BTModal, BTButton } from '@btas/jasper';
import './WorkpaperSendToTPModal/styles.scss';
import { TAX_PROVISION_CLASSIC_URL, TAX_PROVISION_URL } from './shared/constants';
import { getUser } from '../../../_shared/auth';

export default function WorkpaperSendToTPSuccessModal({ show, onClose }) {
  const userInfo = getUser();
  const hasProvSubscription = userInfo?.subscriptions && userInfo.subscriptions.includes('PROV');

  const openTaxProvision = () => {
    window.open(hasProvSubscription ? TAX_PROVISION_URL : TAX_PROVISION_CLASSIC_URL, '_blank');
  };

  return (
    <BTModal
      className="workpaper-send-to-tp-modal"
      id="workpaper-send-to-tax-provision-success-modal"
      show={show}
      size="modal-lg"
      title="Continue to Bloomberg Tax Provision"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <p>
          The workpaper sheet was sent to Bloomberg Tax Provision.
          <br />
          <b>Open Bloomberg Tax Provision</b> to review and accept the entries.
        </p>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton id="cancel" onClick={onClose}>
          Cancel
        </BTButton>
        <BTButton btStyle="primary" id="open-tax-provision" onClick={openTaxProvision}>
          OPEN TAX PROVISION
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
