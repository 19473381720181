import { BTPagination } from '@btas/jasper';
import { INSPECTOR_PAGE_SIZE } from './constants/pagination';

export function PaginationContainer({ startIndex, endIndex, page, totalFields, setPage }) {
  const pageRange =
    startIndex + 1 === totalFields
      ? totalFields
      : `${startIndex + 1}-${endIndex > totalFields ? totalFields : endIndex}`;
  return (
    <div className="df-pagination-container">
      <hr className="df-pagination-line" />
      <BTPagination
        currentPage={page}
        pageSize={INSPECTOR_PAGE_SIZE}
        totalCount={totalFields}
        onPageChange={currPage => {
          setPage(currPage);
        }}
      />
      <div className="df-pagination-page"> {`${pageRange} of ${totalFields} columns`}</div>
    </div>
  );
}
