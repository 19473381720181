import { taxProvisionClassicURL, taxProvisionURL } from '../../../../../configs/params';

export const TAX_PROVISION_URL = taxProvisionURL;
export const workpaper_status = {
  NotStarted: 'Not started',
  InPreparation: 'In preparation',
  ReadyForReview: 'Ready for review',
  InReview: 'In review',
  Final: 'Final',
};
export const MAX_ALLOWED_CHARACTERS = '255';
export const TAX_PROVISION_CLASSIC_URL = taxProvisionClassicURL;
export const RENEW_LOCK_INTERVAL_MS = 10_000;
export const SOURCE_FILE_AREA = 'wkp-source-files';
