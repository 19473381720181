export function addItemToSessionStorage(key, value) {
  sessionStorage.setItem(key, value);
}

export function getItemFromSessionStorage(key) {
  return sessionStorage.getItem(key);
}

export function setDataToLocalStorage(key, value) {
  window.localStorage.setItem(key, value);
}

export function getDataFromLocalStorage(key) {
  return window.localStorage.getItem(key);
}

export function removeDataFromLocalStorage(key) {
  window.localStorage.removeItem(key);
}
