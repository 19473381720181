import React, { useState, useEffect, useCallback } from 'react';
import { BTEmptyState, BTSwitch } from '@btas/jasper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import HistoryList from './HistoryPanel/HistoryList';
import CloseButton from './shared/CloseButton';
import { getWorkpaperVersions, updateWorkpaperVersionName } from './HistoryPanel/apis';
import './HistoryPanel/styles.scss';
import Spinner from '../../../../_shared/Spinner';

export default function HistoryPanel({ workpaperId, onClose, setReadOnlyModeDisplayName }) {
  const [versions, setVersions] = useState([]);
  const [namedVersionsOnly, setNamedVersionsOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(workpaperId !== undefined);

  const fetchVersions = useCallback(
    async function fetchVersions() {
      setIsLoading(true);
      const versions = await getWorkpaperVersions(workpaperId, namedVersionsOnly);
      setVersions(versions);
      setIsLoading(false);
    },
    [workpaperId, namedVersionsOnly]
  );

  useEffect(() => {
    if (!workpaperId) {
      return;
    }

    fetchVersions();
  }, [workpaperId, namedVersionsOnly, fetchVersions]);

  const handleVersionNameChange = async ({ versionId, versionName }) => {
    setIsLoading(true);
    await updateWorkpaperVersionName({ versionId, versionName });

    setReadOnlyModeDisplayName(versionName);

    fetchVersions();
  };

  const handleCheckboxChange = () => setNamedVersionsOnly(prevState => !prevState);

  return (
    <div className="wkp-right-pane">
      <div className="wkp-right-pane-header">
        <h2>History</h2>
        <CloseButton onClose={onClose} />
      </div>

      <div className="wkp-named-version-switch">
        <BTSwitch checked={namedVersionsOnly} onChange={handleCheckboxChange} /> Named versions only
      </div>

      {isLoading && <Spinner />}

      {versions.length === 0 && namedVersionsOnly && (
        <BTEmptyState icon={<FontAwesomeIcon icon={faTag} />} message="There are no named versions." />
      )}

      {!isLoading && versions.length > 0 && (
        <HistoryList
          setReadOnlyModeDisplayName={setReadOnlyModeDisplayName}
          versions={versions}
          onVersionNameChange={handleVersionNameChange}
        />
      )}
    </div>
  );
}
