import GC from '../../../../../../../../SpreadSheets';
export var Commands = {};
var ShortcutActionBase = /** @class */ (function () {
  function ShortcutActionBase(spread, options) {
    this._spread = spread;
    this._options = options;
  }
  ShortcutActionBase.prototype.canExecute = function () {
    return true;
  };
  ShortcutActionBase.prototype.canUndo = function () {
    return true;
  };
  ShortcutActionBase.prototype.undoImp = function (context, options, isUndo) {
    context.suspendPaint();
    GC.Spread.Sheets.Commands.undoTransaction(context, options);
    context.resumePaint();
    return true;
  };
  ShortcutActionBase.prototype.execute = function (context, options, isUndo) {
    var self = this,
      flag = false;
    if (isUndo) {
      flag = self.undoImp.call(self, context, options, isUndo);
    } else if (self.canExecute()) {
      var commands = GC.Spread.Sheets.Commands;
      commands.startTransaction(context, options);
      context.suspendPaint();
      try {
        flag = self.executeImp();
      } finally {
        context.resumePaint();
        commands.endTransaction(context, options);
      }
    }
    return flag;
  };
  ShortcutActionBase.prototype.getSheet = function () {
    var self = this;
    return self._spread.getSheetFromName(self._options.sheetName);
  };
  ShortcutActionBase.prototype.getSpread = function () {
    return this._spread;
  };
  ShortcutActionBase.prototype.setCellStyle = function (func) {
    var self = this;
    var sheet = self.getSheet();
    var selections = sheet.getSelections();
    sheet.suspendPaint();
    selections.forEach(function (selection) {
      var row = selection.row,
        col = selection.col,
        rowCount = selection.rowCount,
        colCount = selection.colCount;
      var cellRange = sheet.getRange(row, col, rowCount, colCount);
      func(cellRange);
    });
    sheet.resumePaint();
  };
  ShortcutActionBase.prototype.getFontStyle = function (font, attribute, value) {
    var span = document.createElement('span');
    span.style.font = font;
    span.style[attribute] = value;
    return span.style.font;
  };
  return ShortcutActionBase;
})();
export { ShortcutActionBase };
export function executeCommand(context, action, options, isUndo) {
  var cmd = new action(context, options);
  return cmd.execute(context, options, !!isUndo);
}
