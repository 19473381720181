import GC from '../../../../../../../SpreadSheets';
import { Commands, executeCommand, ShortcutActionBase } from './shell/action.base';
import { Util } from './common/util';
var __extends =
  (this && this.__extends) ||
  (function () {
    var extendStatics = function (d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function (d, b) {
            d.__proto__ = b;
          }) ||
        function (d, b) {
          for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
        };
      return extendStatics(d, b);
    };
    return function (d, b) {
      if (typeof b !== 'function' && b !== null)
        throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
      extendStatics(d, b);
      function __() {
        this.constructor = d;
      }
      d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
    };
  })();
var SET_CELL_BOLD = 'setCellBold',
  SET_CELL_ITALICIZE = 'setCellItalicize',
  SET_CELL_UNDERLINE = 'setCellUnderline',
  SET_NUMBER_TO_PERCENT = 'setNumberToPercent';
var FONT_WEIGHT = 'font-weight',
  FONT_BOLD = 'bold',
  FONT_STYLE = 'font-style',
  FONT_NORMAL = 'normal',
  FONT_ITALIC = 'italic';
var VerticalPosition = GC.Spread.Sheets.VerticalPosition;
var HorizontalPosition = GC.Spread.Sheets.HorizontalPosition;
var SetCellStyleAction = /** @class */ (function (_super) {
  __extends(SetCellStyleAction, _super);
  function SetCellStyleAction() {
    return (_super !== null && _super.apply(this, arguments)) || this;
  }
  SetCellStyleAction.prototype.executeImp = function () {
    var self = this;
    var sheet = self.getSheet();
    var option = self._options;
    var handler = option.handler && option.handler.bind(self);
    var activeRow = sheet.getActiveRowIndex(),
      activeCol = sheet.getActiveColumnIndex();
    if (handler) {
      this.setCellStyle(handler);
    }
    sheet.showCell(activeRow, activeCol, VerticalPosition.nearest, HorizontalPosition.nearest);
    return true;
  };
  return SetCellStyleAction;
})(ShortcutActionBase);
Commands[SET_CELL_BOLD] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = SET_CELL_BOLD;
    var selections = sheet.getSelections();
    if (selections.length > 0) {
      var style = Util.getActiveCellStyle(sheet);
      var font = style && style.font;
      var boldState = false;
      if (font) {
        var fontWeight = Util.parseFont(font).fontWeight;
        if (fontWeight !== 'normal') {
          boldState = true;
        }
      }
      options.boldState = boldState;
      options.handler = function (cell) {
        var fontWeight = this._options.boldState;
        var cellFont = cell.font();
        var boldString = fontWeight ? FONT_NORMAL : FONT_BOLD;
        cellFont = this.getFontStyle(cellFont, FONT_WEIGHT, boldString);
        cell.font(cellFont);
      };
      return executeCommand(context, SetCellStyleAction, options, isUndo);
    }
    return false;
  },
};
Commands[SET_CELL_ITALICIZE] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = SET_CELL_ITALICIZE;
    var selections = sheet.getSelections();
    if (selections.length > 0) {
      var style = Util.getActiveCellStyle(sheet);
      var font = style && style.font,
        italicizeState = false;
      if (font) {
        var fontStyle = Util.parseFont(font).fontStyle;
        if (fontStyle.indexOf(FONT_ITALIC) !== -1) {
          italicizeState = true;
        }
      }
      options.italicizeState = italicizeState;
      options.handler = function (cellRange) {
        var fontStyle = this._options.italicizeState;
        var row = cellRange.row,
          col = cellRange.col,
          rowCount = cellRange.rowCount,
          colCount = cellRange.colCount;
        for (var rowIndex = 0; rowIndex < rowCount; rowIndex++) {
          for (var colIndex = 0; colIndex < colCount; colIndex++) {
            var cell = sheet.getCell(row + rowIndex, col + colIndex);
            var cellFont = cell.font();
            var fontStyleString = fontStyle ? FONT_NORMAL : FONT_ITALIC;
            cellFont = this.getFontStyle(cellFont, FONT_STYLE, fontStyleString);
            cell.font(cellFont);
          }
        }
      };
      return executeCommand(context, SetCellStyleAction, options, isUndo);
    }
    return false;
  },
};
Commands[SET_CELL_UNDERLINE] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = SET_CELL_UNDERLINE;
    var selection = sheet.getSelections();
    if (selection.length > 0) {
      var style = Util.getActiveCellStyle(sheet);
      var underline = style && style.textDecoration;
      var textDecorationType = GC.Spread.Sheets.TextDecorationType;
      if (underline) {
        options.underlineType = textDecorationType.none;
      } else {
        options.underlineType = textDecorationType.underline;
      }
      options.handler = function (cell) {
        var underType = this._options.underlineType;
        cell.textDecoration(underType);
      };
      return executeCommand(context, SetCellStyleAction, options, isUndo);
    }
    return false;
  },
};
Commands[SET_NUMBER_TO_PERCENT] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = SET_NUMBER_TO_PERCENT;
    var selections = sheet.getSelections();
    if (selections.length) {
      options.handler = function (cell) {
        cell.formatter('0%');
      };
      return executeCommand(context, SetCellStyleAction, options, isUndo);
    }
    return false;
  },
};
export function initShortcutAboutStyle(commands) {
  commands.register(
    SET_CELL_BOLD,
    Commands[SET_CELL_BOLD],
    66 /* B */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    SET_CELL_ITALICIZE,
    Commands[SET_CELL_ITALICIZE],
    73 /* I */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    SET_CELL_UNDERLINE,
    Commands[SET_CELL_UNDERLINE],
    85 /* U */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    SET_NUMBER_TO_PERCENT,
    Commands[SET_NUMBER_TO_PERCENT],
    53 /* % */,
    true /* ctrl */,
    true /* shift */,
    false /* alt */,
    false /* meta */
  );
}
