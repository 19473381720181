export const Status = {
  Preparing: 'preparingData',
  Running: 'running',
  Finished: 'finished',
  Failed: 'failed',
};

export const isLoading = status => [Status.Preparing, Status.Running].includes(status);
export const isFinished = status => [Status.Finished, Status.Failed].includes(status);

const inputFormatErrorCodes = ['WKP2001', 'WKP2002', 'WKP2006', 'WKP2007'];
export const getErrorMessage = code =>
  inputFormatErrorCodes.includes(code)
    ? 'Source file does not match the expected format for this data flow.'
    : 'The application has encountered a problem. If you continue to receive this error message, contact Customer Support by phone (1-800-424-2938) or email (appsupport@bloombergtax.com)';
