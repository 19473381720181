import React from 'react';
import { BTButton, BTModal } from '@btas/jasper';
import SubscriptionDemoImage from './assets/Ask_Research_Annotated_Screenshot.svg';

function AiSubscriptionModal({ toggleModal }) {
  return (
    <BTModal show={true} size="modal-lg" title="Bloomberg Tax Research Subscription Needed" onCloseClick={toggleModal}>
      <BTModal.Body>
        <div className="subscription-container">
          <p>To use this feature, you must be a Bloomberg Tax Research subscriber.</p>
          <div className="grid">
            <div className="wkp-annotations">
              <ol>
                <li>Ask a question to Bloomberg Tax Research and get a brief AI generated summary.</li>
                <li>
                  View reference documents determined to be the most relevant for your AI summary. The full document can
                  be opened in Bloomberg Tax Research for a deeper dive.
                </li>
              </ol>
            </div>
            <img alt="Demo" src={SubscriptionDemoImage} />
          </div>
        </div>
      </BTModal.Body>
      <BTModal.Footer>
        <>
          <BTButton btStyle="secondary" onClick={toggleModal}>
            Cancel
          </BTButton>
          <BTButton
            btStyle="primary"
            onClick={() =>
              window.open('https://pro.bloombergtax.com/request-demo/?trackingcode=BTXF24112068', '_blank')
            }
          >
            Learn More
          </BTButton>
        </>
      </BTModal.Footer>
    </BTModal>
  );
}

export default AiSubscriptionModal;
