import * as fetch from '../../../../../_shared/fetch';
import { dataFlowServiceHostName, spreadsheetsServiceHostName } from '../../../../../../configs/params';

export async function getLinkData({ workpaperId, linkName }) {
  const response = await fetch.get(
    `${spreadsheetsServiceHostName}/api/v1/spreadsheets/${workpaperId}/link-cell?name=${linkName}`
  );
  const data = await response.json();

  return {
    workpaperName: data.workpaperName,
    sheetName: data.sheetName,
    cell: data.reference?.split('!').pop().replaceAll('$', ''),
  };
}

export async function getDataFlowOutputs({ dataFlowId }) {
  const response = await fetch.get(`${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/outputs`);

  const data = await response.json();

  if (response.status === 404) {
    return {
      deleted: true,
    };
  }

  return {
    deleted: false,
    dataFlow: data,
  };
}
