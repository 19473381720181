import { useEffect, useState } from 'react';
import * as apis from './apis';
import * as status from '../shared/Status';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { DF_HIDE_RECENT_ACTIVITY_PANEL } from '../../../constants/featureFlags';
const UPDATE_DELAY = 5000;

export default function useDataFlowRuns() {
  const [runs, setRuns] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const refreshRuns = async () => {
    setError(null);
    setIsLoading(true);
    try {
      if (!isFeatureFlagEnabled(DF_HIDE_RECENT_ACTIVITY_PANEL)) {
        const newRuns = await apis.getDataFlowRuns();
        setRuns(newRuns);
      }
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refreshRuns();
  }, []);

  useEffect(() => {
    let timeout = null;

    async function updateData() {
      const running = runs.filter(r => status.isLoading(r.status));
      if (running.length === 0) {
        return;
      }

      try {
        const updatedRunning = await Promise.all(running.map(r => apis.getRunningDataFlowInfo(r.id)));
        const newRuns = runs.map(run => {
          const updatedRun = updatedRunning.find(r => r.id === run.id);
          if (updatedRun) {
            return updatedRun;
          }
          return run;
        });
        setRuns(newRuns);
      } catch (err) {
        setError(err);
      }
    }

    function runUpdateData() {
      timeout = setTimeout(updateData, UPDATE_DELAY);
    }

    runUpdateData();
    return () => timeout && clearTimeout(timeout);
  }, [runs]);

  const addRun = run => {
    const newRuns = [run, ...runs];
    setRuns(newRuns);
  };

  const runsState = { runs, error, isLoading };

  const runsActions = { addRun };

  return [runsState, runsActions];
}
