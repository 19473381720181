import React from 'react';
import { BTSpinner } from '@btas/jasper';

import './Spinner/styles.scss';

export default function Spinner({ description }) {
  return (
    <div className="wkp-spinner-wrapper">
      <BTSpinner />
      {description && <p>{description}</p>}
    </div>
  );
}
