import React from 'react';
import './styles.scss';
import JobItem from './JobStatusItem/JobItem';

export default function PanelBody({ jobs, hasProcessingJob, minimize }) {
  return (
    <div className={!minimize ? 'panel-body' : 'panel-body panel-body-minimize'}>
      <div className={hasProcessingJob ? 'wkp-jobs-list' : 'wkp-jobs-list wkp-jobs-list-expand'}>
        {jobs.map(({ name, status, jobType, jobOutput }) => (
          <JobItem fileName={name} jobOutput={jobOutput} status={status} type={jobType} />
        ))}
      </div>
    </div>
  );
}
