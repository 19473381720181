import React from 'react';
import { Link } from 'react-router-dom';
import { BTButton } from '@btas/jasper';
import { getFormattedDate } from '../../../_shared/dateFormatting';
import { getErrorMessage, isLoading, Status } from '../../shared/Status';
import ImpactedWorkpapers from './Activity/ImpactedWorkpapers';
import data_flow_source_file from '../../shared/icons/data_flow_source_file.svg';
import data_flow_icon from './Activity/data_flow_activity_data_flow.svg';
import './Activity/styles.scss';

export default function Activity({ data, onViewSourceData }) {
  const {
    endTimestamp,
    startTimestamp,
    userFirstName,
    userLastName,
    status,
    errorCode,
    dataFlow,
    importedFileVersion,
    cachedResults,
  } = data;
  const date = new Date(endTimestamp || startTimestamp);
  const fileName = importedFileVersion ? importedFileVersion.name : '';
  const fileVersionId = importedFileVersion ? importedFileVersion.id : '';
  const loading = isLoading(status);
  const isFailed = status === Status.Failed;
  return (
    <div className={`wkp-recent-activity-item wkp-recent-activity-${loading ? 'loading' : isFailed ? 'failed' : ''}`}>
      <div className="wkp-recent-activity-info">
        {getFormattedDate(date)} | {userFirstName} {userLastName}
      </div>
      <div className="wkp-recent-activity-data">
        <div className="wkp-recent-activity-icon">
          {loading ? (
            <i className="fa fa-spinner fa-1x fa-spin fa-fw" />
          ) : isFailed ? (
            <i className="fa fa-exclamation-circle fa-1x fa-fw" />
          ) : (
            <img alt="Data Flow" src={data_flow_icon} />
          )}
        </div>
        <Link
          className="wkp-recent-activity-text wkp-recent-activity-flow-title"
          to={`/data-flows/${dataFlow.id}/editor`}
        >
          {dataFlow.name}
        </Link>
      </div>
      {fileName && (
        <div className="wkp-recent-activity-data">
          <div className="wkp-recent-activity-text wkp-recent-activity-file-name">
            <BTButton
              btStyle="link"
              icon={<img alt="Source file icon" aria-hidden="true" src={data_flow_source_file} />}
              title={fileName}
              onClick={e => {
                e.preventDefault();
                onViewSourceData({ dataFlow, fileName, fileVersionId, importedDate: date });
              }}
            >
              {fileName}
            </BTButton>
          </div>
        </div>
      )}
      {!loading && <ImpactedWorkpapers cachedResults={cachedResults} />}
      {isFailed && <div className="wkp-recent-activity-error-msg">{getErrorMessage(errorCode)}</div>}
    </div>
  );
}
