import React, { useCallback } from 'react';

import { BTButton, BTComboBox, BTForm, BTInput } from '@btas/jasper';
import { convertInputToFilterValue, convertFilterValueToInput } from '../filterValueToComboxBoxValueConversion';
import { operatorTypes } from '../filterOperatorTypes';
import { useDebouncedEffect } from '../../shared/useDebouncedEffect';
import {
  useFieldMatchesInputValidation,
  useFieldRequiredValidation,
  useOperatorRequiredValidation,
  useSimpleCriteriaValidation,
  useValueRequiredValidation,
} from '../useFilterSimpleUIValidation';

import { operators } from '../filterOperators';
import './styles.scss';
import { useCanEditWorkflow } from '../../../../_shared/UserPermissionsContext';

export const FilterCriteriaItem = ({ fieldOptions, index, filterField, filterCriteria, onUpdate, onRemove }) => {
  const operatorOptions = operatorTypes.map(type => ({
    label: type,
    value: type,
  }));

  const { filterValue } = filterField;

  const { IsBlank, IsNotBlank, IsOneOf, IsError, IsNotError } = operators;
  const noInputOperators = [IsBlank, IsNotBlank, IsError, IsNotError];

  const { validateSimpleCriteriaFields, simpleCriteriaError } = useSimpleCriteriaValidation(filterField);
  const { validateField, fieldError } = useFieldRequiredValidation(filterField?.field);
  const { validateFieldMatchInput, fieldMatchInputError } = useFieldMatchesInputValidation(filterField?.field);
  const { validateOperator, operatorError } = useOperatorRequiredValidation(filterField?.operator);
  const { validateValue, valueError } = useValueRequiredValidation(filterValue);
  useDebouncedEffect(
    () => {
      let displayErrorMessage = false;
      if (filterField?.field || filterField?.operator || filterValue) {
        displayErrorMessage = true;
      }
      validateSimpleCriteriaFields(filterField, displayErrorMessage);
      validateOperator(filterField?.operator, displayErrorMessage);
      validateField(filterField?.field, displayErrorMessage);
      validateValue(filterValue, displayErrorMessage);
      validateFieldMatchInput(filterField?.field);
    },
    350,
    [
      validateOperator,
      validateField,
      validateFieldMatchInput,
      validateValue,
      validateSimpleCriteriaFields,
      filterField,
      filterValue,
    ]
  );

  const fieldValue = fieldOptions.find(option => option.value === filterField?.field) || '';
  const operatorValue = operatorOptions.find(option => option.value === filterField?.operator) || '';

  const onChange = key => option => {
    onUpdate({
      ...filterField,
      [key]: option.value,
    });
  };

  const onValueChangeOneValue = ({ target: { value } }) => {
    onUpdate({
      ...filterField,
      filterValue: value,
    });
  };

  const onValueChangeMultipleValues = useCallback(
    selectedOptions => {
      onUpdate({
        ...filterField,
        filterValue: convertInputToFilterValue(selectedOptions),
      });
    },
    [filterField, onUpdate]
  );

  let valueInput;
  if (noInputOperators.includes(operatorValue.value)) {
    valueInput = <BTInput disabled={true} value={''} onChange={onValueChangeOneValue} />;
  } else if (operatorValue.value !== IsOneOf) {
    valueInput = <BTInput value={filterValue || ''} onChange={onValueChangeOneValue} />;
  } else {
    valueInput = (
      <BTComboBox
        canCreateNew={true}
        createText={'Enter'}
        isMulti={true}
        isSearchable={true}
        maxMenuHeight={100}
        noResultsMessage={'Enter a value'}
        value={convertFilterValueToInput(filterValue)}
        onChange={onValueChangeMultipleValues}
      />
    );
  }
  const canEditWorkflow = useCanEditWorkflow();
  return (
    <div>
      {index > 0 && (
        <div className="wkp-filter-criteria-divider">
          <hr />
        </div>
      )}
      <BTForm.FormGroup
        className="wkp-simple-criteria-item"
        errorText={!!simpleCriteriaError ? simpleCriteriaError : fieldMatchInputError}
        hasError={!!simpleCriteriaError ? !!simpleCriteriaError : !!fieldMatchInputError}
      >
        <div className="wkp-field-to-filter-row">
          <div className="wkp-field-to-filter">
            <BTForm.FormGroup required hasError={!!fieldError ? !!fieldError : !!fieldMatchInputError} label="Field">
              <BTComboBox
                popOutMenu
                disabled={!canEditWorkflow}
                maxMenuHeight={100}
                options={fieldOptions}
                value={fieldValue}
                onChange={onChange('field')}
              />
            </BTForm.FormGroup>
          </div>
          <div className="wkp-operator-to-filter">
            <BTForm.FormGroup required hasError={!!operatorError} label="Operator">
              <BTComboBox
                popOutMenu
                disabled={!canEditWorkflow}
                maxMenuHeight={100}
                options={operatorOptions}
                value={operatorValue}
                onChange={onChange('operator')}
              />
            </BTForm.FormGroup>
          </div>
          <div className="wkp-filter-critieria-ui-last-col"></div>
        </div>

        <BTForm.FormGroup required label="Value">
          <div className="wkp-value-to-filter-row">
            <div className="wkp-value-to-filter-item">
              <BTForm.FormGroup disabled={!canEditWorkflow} hasError={!!valueError}>
                {valueInput}
              </BTForm.FormGroup>
            </div>
            <div className="wkp-filter-critieria-ui-last-col">
              {canEditWorkflow && (
                <BTButton btStyle="link" btType="delete" disabled={filterCriteria.length === 1} onClick={onRemove}>
                  {' '}
                </BTButton>
              )}
            </div>
          </div>
        </BTForm.FormGroup>
      </BTForm.FormGroup>
    </div>
  );
};

export default FilterCriteriaItem;
