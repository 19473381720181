import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import GC from '../../../../SpreadSheets';
import Spinner from '../../../_shared/Spinner';
import { addCustomCommands } from './_spreadsheets';
import spreadConfigV151 from './SpreadView/spreadConfig-v15.1.json';
import readOnlySpreadConfig from './SpreadView/readOnlySpreadConfig.json';
import './SpreadView/gc.spread.sheets.bna.10.1.0.scss';
import './SpreadView/btas-spreadjs-designer-styles.css';
import './SpreadView/btas-spreadjs-icons-styles.css';
import { registerWorkpapersCustomFileMenu } from './_spreadsheets/fileMenuTemplate';
import { removeBarcodePlugin } from './SpreadView/customizeSpreadConfig';

const spinnerWrapperStyle = {
  height: '100%',
  position: 'absolute',
  width: '100%',
  top: 0,
  zIndex: 1000,
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  opacity: 0.8,
};

export default function SpreadView({
  isLoading,
  readOnly,
  setCommandsVisibleContext,
  setHasPendingChanges,
  workpaperId,
  onWorkbookInit,
  onWorkpaperCopySheetModalOpen,
  handleFileMenuAction,
  onWorkpaperSendToTPModalOpen,
  onWorkpaperSendToSFTPModalOpen,
  cellReviewFunctionManager,
  addToHistory,
}) {
  const designer = useRef(null);
  const history = useHistory();

  const heightDesigner = readOnly ? { height: 'calc(100vh - 75.13px)' } : { height: 'calc(100vh - 70.141px)' };

  const getCustomizedSpreadConfig = config => {
    let customizedSpreadConfig = config;

    if (!readOnly) customizedSpreadConfig = removeBarcodePlugin(customizedSpreadConfig);

    return customizedSpreadConfig;
  };

  const designerRef = useCallback(node => {
    if (node !== null) {
      let res = GC.Spread.Sheets.Designer.getResources();
      res.title = '';
      // BPMP-3896 Modify "Remove Duplicate" warning to no longer reference workpaper as "Spread"
      res.removeDuplicatesWarning.tips1 = res.removeDuplicatesWarning.tips1.replace(
        'Spread',
        'Bloomberg Tax Workpapers'
      );
      // BPMP-8228 Rename "move or copy" to just "Move Sheet" in context menu
      res.contextMenu.moveOrCopy = 'Move Sheet';
      GC.Spread.Sheets.Designer.setResources(res);
      GC.Spread.Sheets.SR.en.Exp_SheetNameInvalid =
        'The sheet name cannot be blank or contain these characters: * : [ ] ? \\ /';
      registerWorkpapersCustomFileMenu(GC, handleFileMenuAction);

      designer.current = new GC.Spread.Sheets.Designer.Designer(node, getCustomizedSpreadConfig(config));

      const spread = designer.current.getWorkbook();

      spread.bind(GC.Spread.Sheets.Events.TopRowChanged, function () {
        if (window.scrollTimer) {
          clearTimeout(window.scrollTimer);
        }

        const sheet = spread.getActiveSheet();
        sheet.options.allowCellOverflow = true;

        window.scrollTimer = window.setTimeout(function () {
          sheet.options.allowCellOverflow = true;
          window.scrollTimer = null;
        }, 100);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (designer.current !== null) {
      return () => designer.current.destroy();
    }
  }, []);

  const config = useMemo(() => {
    if (readOnly) {
      return readOnlySpreadConfig;
    }

    return addCustomCommands(spreadConfigV151, {
      workpaperId,
      history,
      setCommandsVisibleContext,
      setHasPendingChanges,
      onWorkpaperCopySheetModalOpen,
      onWorkpaperSendToTPModalOpen,
      onWorkpaperSendToSFTPModalOpen,
      cellReviewFunctionManager,
      addToHistory,
    });

    // Disabled warning because adding onWorkpaperLinkModalOpen as dependency will give React flush errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workpaperId, readOnly, history]);

  useEffect(() => {
    if (designer.current !== null) {
      // tag items are removed after first initialization of the designer because if not designer.setTag command is not triggered when needed
      config.contextMenu = config.contextMenu.filter(
        item => item !== 'sheetTag' && item !== 'rowTag' && item !== 'colTag' && item !== 'cellTag'
      );

      designer.current.setConfig(config);
      onWorkbookInit(designer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  useEffect(() => {
    if (!isLoading && designer.current !== null) {
      designer.current.refresh();
    }
  }, [isLoading]);

  return (
    <div id="spreadsheet-designer">
      {isLoading && (
        <div className="wkp-provider-container" style={spinnerWrapperStyle}>
          <Spinner />
        </div>
      )}
      <div ref={designerRef} className="designer" id="designer" style={heightDesigner}></div>
    </div>
  );
}
