export const initialInfoState = {
  name: '',
  id: '',
};

export const InfoActions = {
  setName: 'info-set-name',
};

export const infoActions = dispatch => ({
  setDataFlowName: (name, restore) => dispatch({ type: InfoActions.setName, name, restore }),
});

export const infoReducer = (state, action) => {
  switch (action.type) {
    case InfoActions.setName:
      return { ...state, name: action.name };

    default:
      return state;
  }
};
