import { spreadsheetServiceHostName, HardDelete } from '../../../../../configs/params';
import { RECYCLE_BIN_ENABLED } from '../../../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../../../utils/featureFlags';
import * as fetch from '../../../../_shared/fetch';

export async function deleteWorkpapers(workpaperIds) {
  await fetch.remove(`${spreadsheetServiceHostName}/spreadsheet/workpapers`, {
    body: JSON.stringify({
      workpaperIds,
      hardDelete: (HardDelete && HardDelete === 'E2E') || isFeatureFlagEnabled(RECYCLE_BIN_ENABLED) === false,
    }),
  });
}

export async function workpapersLockStatus(workpaperIds) {
  const response = await fetch.post(`${spreadsheetServiceHostName}/spreadsheet/workpapers/lock/workpapersLockStatus`, {
    body: JSON.stringify(workpaperIds),
  });
  return response.json();
}
