/**
 * This file overrides the paint method, keeping the older paint methods hierarchy untouched.
 *
 * The changes here are only related to Cell Review.
 */

import GC from '../../../../../../SpreadSheets';
import {
  existBrokenSourceDataFormula,
  getCellFormula,
  getReviewedCellMessage,
  isReviewedCell,
  getWidthForAutoFit,
  isCellOnScreenValidator,
} from './cellReview/utils';
import { registerTooltip, unregisterTooltip } from '../tooltipHelper';
import { exportIcons } from './cellReview/exportIcons';

const { checkIcon, checkExclamationIcon } = exportIcons();

export function cellReviewDefinition(tooltipManager) {
  const oldPaint = GC.Spread.Sheets.CellTypes.Base.prototype.paint;
  GC.Spread.Sheets.CellTypes.Base.prototype.getAutoFitWidth = function (_, text, cellStyle, __, context) {
    return getWidthForAutoFit(text, cellStyle, context.sheet.tag(), context.row, context.col);
  };

  GC.Spread.Sheets.CellTypes.Base.prototype.paint = function (ctx, _, x, y, __, ___, ____, context) {
    if (!ctx) {
      oldPaint.apply(this, arguments);
      return;
    }

    const { row, col, sheet } = context;
    const cell = sheet.getCell(row, col);
    const formula = sheet.getFormula(row, col);
    const formulaValue = cell.value();

    const reviewedCell = isReviewedCell(row, col, sheet.tag());
    const cellValue = cell.text() || '';
    const brokenFormula = existBrokenSourceDataFormula(formula, formulaValue);

    // clean last drawing and tooltip in case the cell has been un-reviewed
    const tooltipId = `wkp_cell_review_row_${row}_col_${col}`;
    const unregister = () => unregisterTooltip(tooltipManager, tooltipId);

    unregister();

    oldPaint.apply(this, arguments);

    // if the source data formula is broken, we only want the broken formula icon drawn
    if (!reviewedCell || brokenFormula) {
      return;
    }

    const { originalValue } = reviewedCell;
    const cellFormula = getCellFormula(cell);
    const reviewedValueChanged = cellValue !== originalValue;

    // reviewed icon is drawn (depending on whether the original value was modified or not, different icons are rendered)
    const { image } = reviewedValueChanged ? checkExclamationIcon : checkIcon;
    ctx.drawImage(image, x + checkIcon.marginLeft, y + checkIcon.marginTop);

    // the tooltip is registered
    const tooltipContent = getReviewedCellMessage(reviewedCell, cellValue, cellFormula, reviewedValueChanged);
    const { height, width } = checkIcon.image;

    registerTooltip(
      tooltipManager,
      x,
      y,
      { height, width },
      { x: width / 2 + checkIcon.marginLeft + 1, y: 5 },
      tooltipId,
      tooltipContent,
      false,
      isCellOnScreenValidator({ GC, sheet, row, col, onNotActive: unregister })
    );
  };
}
