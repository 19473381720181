import { useContext, useEffect, useState } from 'react';
import { DataFlowEditorContext } from '../DataFlowEditorContext';

export const useInputElements = workingElement => {
  const { dataFlowState } = useContext(DataFlowEditorContext);
  const { elements, links } = dataFlowState;
  const [currentInputElementData, setCurrentInputElementData] = useState({
    inputElements: {},
  });
  const workingElementId = workingElement?.id;

  useEffect(() => {
    if (workingElementId !== currentInputElementData.workingElementId || links !== currentInputElementData.links) {
      const inputLinks = links.filter(link => link.targetId === workingElementId);

      const inputElements = inputLinks.reduce((acc, link) => {
        const inputElement = elements[link.sourceId];
        const sourceElementData = inputElement.elementData;
        const sourceFields = sourceElementData.fieldsByPort
          ? sourceElementData.fieldsByPort[link.sourcePort]
          : sourceElementData.fields;
        acc[link.targetPort] = { ...inputElement, elementData: { ...sourceElementData, fields: sourceFields } };
        return acc;
      }, {});

      setCurrentInputElementData({ workingElementId, inputElements, links });
    }
  }, [currentInputElementData, setCurrentInputElementData, workingElementId, elements, links]);

  return {
    inputElementsForId: currentInputElementData.workingElementId,
    inputElements: currentInputElementData.inputElements,
  };
};
