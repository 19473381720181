import React from 'react';
import ErrorHandler from '../../App/ErrorHandler';
import Spinner from '../_shared/Spinner';
import { useAuth } from './hooks/useAuth';
import { displayApplicationBrowserConsoleLogs } from '../_shared/consoleLogger';

export default React.memo(function AuthLoginPage() {
  displayApplicationBrowserConsoleLogs();
  const { isError } = useAuth();
  console.log('Error?', isError);
  if (isError) {
    return <ErrorHandler />;
  }
  return <Spinner />;
});
