import React, { useRef } from 'react';
import { useStencil } from './DataFlowStencil/useStencil';
import { useRefreshStencil } from './DataFlowStencil/useRefreshStencil';
import { useRefreshStencilTooltips } from './DataFlowStencil/useRefreshStencilTooltips';
import './DataFlowStencil/styles.scss';

export const DataFlowStencil = () => {
  const stencilContainer = useRef(null);

  useStencil(stencilContainer);

  useRefreshStencil();

  useRefreshStencilTooltips();

  return <div ref={stencilContainer} className="wkp-stencil-container" />;
};
