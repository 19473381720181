import React from 'react';

import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import Frame from '../_shared/Frame';
import Spinner from '../_shared/Spinner';
import { useDataFlow } from './DataFlowEditorPage/useDataFlow';
import DataFlowEditorPageContent from './DataFlowEditorPage/DataFlowEditorPageContent';
import './DataFlowEditorPage/styles.scss';
import { DataFlowJobsContextProvider } from './DataFlowJobsContext';
import {
  useLoadWorkflowStatusFromApi,
  useResetLockedState,
} from '../_shared/UserPermissionsContext/LockedWorkflows/LockedWorkflowHooks';
export default function DataFlowEditorPage() {
  const prevId = useRef(0);
  const { id } = useParams();
  const [dataFlow, { isLoading, isError }] = useDataFlow(id);

  const updateStatus = useLoadWorkflowStatusFromApi();
  const resetLockedState = useResetLockedState();

  updateStatus(dataFlow?.status ? dataFlow.status : 'Not started', id, dataFlow?.lastModifiedDate);

  useEffect(() => {
    if (prevId.current !== id) {
      resetLockedState();
      prevId.current = id;
    }
  }, [id, resetLockedState]);

  return (
    <Frame activeItem="data-flows">
      {isLoading && <Spinner />}
      <DataFlowJobsContextProvider>
        {!isLoading && dataFlow && <DataFlowEditorPageContent dataFlow={dataFlow} />}
        {isError && <div className="wkp-load-general-error">Failed to load data flow</div>}
      </DataFlowJobsContextProvider>
    </Frame>
  );
}
