import { useState, useEffect } from 'react';
import { getWorkpaperSignOffs } from './apis';

export default function useSignOffList({ workpaperId, setIsLoading }) {
  const [signOffs, setSignOffs] = useState([]);

  async function fetchWorkpaperSignOffs() {
    try {
      setIsLoading(true);
      const data = await getWorkpaperSignOffs(workpaperId);

      setIsLoading(false);
      setSignOffs(data);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchWorkpaperSignOffs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [signOffs, fetchWorkpaperSignOffs];
}
