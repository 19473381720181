import * as fetch from '../../../_shared/fetch';
import { dataFlowServiceHostName } from '../../../../configs/params';

export async function validateFormula(formula, inputFields, allowedTypes) {
  const response = await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows/formulas/validate/`, {
    body: JSON.stringify({
      formula,
      inputFields,
      allowedTypes,
    }),
  });

  return response.json();
}
