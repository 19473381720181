import React, { useEffect, useState } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Frame from '../_shared/Frame';
import DeletedWorkpapers from './RecycleBin/DeletedWorkpapers';
import JobsOverlayWindow from './common/JobsOverlayWindow/JobsOverlayWindow';
import useJobsOverlay from './common/JobsOverlayWindow/useJobsOverlay';
import useGrid from './RecycleBin/useGrid';
import { WORKPAPER_EXPORT_JOB_TYPE } from '../_shared/jobs/jobTypes';
import { WORKPAPER_JOB_STATUS_COMPLETED } from '../_shared/jobs/jobStatus';
import { getProductCodes } from '../_shared/getProductCodes';
import GeneralErrorMessage from '../_shared/GeneralErrorMessage';
import './RecycleBin/styles.scss';

export default function RecycleBin() {
  const {
    minimizeOverlay,
    processes,
    activeProcesses,
    hasProcess,
    hasFailedProcess,
    hasActiveProcess,
    loadJobs,
    setMinimizeOverlay,
    setCloseOverlay,
  } = useJobsOverlay();

  const [isSortingFromGrid, setIsSortingFromGrid] = useState(false);
  const [showProductAlert, setShowProductAlert] = useState(false);

  const [gridConfig, gridActions] = useGrid();
  const { gridFilters } = gridConfig;
  const { fetchWorkpapers, cleanGridSelection } = gridActions;

  useEffect(
    () => {
      const newItems = processes.filter(
        ({ status, jobType }) => status === WORKPAPER_JOB_STATUS_COMPLETED && jobType !== WORKPAPER_EXPORT_JOB_TYPE
      );

      if (newItems.length) {
        fetchWorkpapers({
          newItems: newItems.map(({ id }) => id),
          filters: gridFilters,
        });
      }
    },
    // eslint-disable-next-line
    [activeProcesses, hasActiveProcess]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      const productCode = getProductCodes();
      if (productCode === null) {
        setShowProductAlert(true);
      }
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Frame activeItem="recycle-bin">
      <div className="wkp-deleted-wkp-container">
        <Grid fluid>
          <DeletedWorkpapers
            actions={gridActions}
            cleanGridSelection={cleanGridSelection}
            config={gridConfig}
            isSortingFromGrid={isSortingFromGrid}
            loadJobs={loadJobs}
            setIsSortingFromGrid={setIsSortingFromGrid}
          />
        </Grid>
        <JobsOverlayWindow
          hasFailedJob={hasFailedProcess}
          hasJobs={hasProcess}
          hasProcessingJob={hasActiveProcess}
          jobs={processes}
          minimize={minimizeOverlay}
          processingJobs={activeProcesses}
          setMinimize={setMinimizeOverlay}
          setOnClose={setCloseOverlay}
        />
        <GeneralErrorMessage dismissible={true} visible={showProductAlert} />
      </div>
    </Frame>
  );
}
