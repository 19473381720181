import { useState, useCallback, useEffect } from 'react';
import { getSourceDataCells } from './apis';

const initialGridData = [];

export default function useGrid({ linkedOutputIds }) {
  const [isLoading, setIsLoading] = useState(true);
  const [gridData, setGridData] = useState(initialGridData);

  const fetchWorkpapers = useCallback(
    async function () {
      if (!linkedOutputIds) {
        return;
      }

      const gridData = await getSourceDataCells({ linkedOutputIds });
      setGridData(gridData);
      setIsLoading(false);
    },
    [linkedOutputIds]
  );

  useEffect(() => {
    fetchWorkpapers();
  }, [fetchWorkpapers]);

  const state = {
    isLoading,
    gridData,
  };

  return [state];
}
