import { useState } from 'react';
import usePubSub from '../PubSub/usePubSub';
import MessageType from '../../../_shared/PubSub/pubSubMessageType';
import { v4 as uuidv4 } from 'uuid';
import { saveCommands } from './useCommandsQueue/apis';
import { getDataFromLocalStorage } from '../../../_shared/storage';

export default function useCommandsQueue({ spreadRef, dataReferenceWorkpaperId, syncDatareferences }) {
  const [workpaperId, setWorkpaperId] = useState();
  const [isCommandsSaving, setIsCommandsSaving] = useState(false);
  const { publish } = usePubSub();

  const syncDatareferencesPositionsData = async commands => {
    commands.forEach(command => {
      publish({
        body: { command: JSON.parse(command.commandText), sheet: spreadRef.current.getActiveSheet() },
        message: MessageType.CellPosition,
        callback: syncDatareferences?.trackDataReferencesAction.current,
      });
    });
  };

  const sendCommands = async commands => {
    if (commands?.length) {
      const sheetName = spreadRef.current?.getActiveSheet()?.name();
      syncDatareferencesPositionsData(commands);

      const response = await saveCommands(dataReferenceWorkpaperId.current, sheetName, commands);

      if (response.ok || response.status === 504) {
        setIsCommandsSaving(false);
        // eslint-disable-next-line no-useless-escape
        if (commands.filter(x => x.commandText.includes('{"cmd":"renameSheet"'))?.length) {
          publish({
            body: workpaperId,
            message: MessageType.CellPosition,
            callback: syncDatareferences?.loadDataReferencesAction.current,
          });
        }
      } else {
        throw new Error(response.message);
      }
    }
  };

  const enqueueCommands = commands => {
    setIsCommandsSaving(true);
    commands.forEach(command => {
      command.commandId = uuidv4();
      command.creationDate = new Date().toISOString();
      command.sessionId = getDataFromLocalStorage(`sessionId-${dataReferenceWorkpaperId.current}`);
    });
    sendCommands(commands);
  };

  return {
    isCommandsSaving: isCommandsSaving,
    enqueueCommands,
    allCommandsProcessedAsync: () => {},
    setWorkpaperId,
  };
}
