import { isFeatureFlagEnabled } from './../../../../../../utils/featureFlags';
import { SEND_TO_TAX_PROVISION } from './../../../../../../constants/featureFlags';

export function createSendToTaxProvisionCommand({ onWorkpaperSendToTPModalOpen }) {
  const enableTPButton = () => {
    return !isFeatureFlagEnabled(SEND_TO_TAX_PROVISION);
  };

  const sendToTaxProvisionCommand = {
    text: 'Send sheet to Tax Provision',
    commandName: 'wkp.sendToTaxProvision',
    bigButton: '=ribbonHeight>toolbarHeight',
    canUndo: false,
    execute: async (_context, _, _contextMenuArgs) => {
      await onWorkpaperSendToTPModalOpen();
      return true;
    },
    iconClass: 'wkp-send-to-tax-provision',
    workArea: 'viewport',
    enableContext: enableTPButton(),
  };

  return {
    'wkp.sendToTaxProvision': sendToTaxProvisionCommand,
  };
}
