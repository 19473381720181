import React from 'react';
import ButtonsSection from '../../_shared/ButtonsSection';
import TileButton from '../../_shared/TileButton';
import { BTIcon } from '@btas/jasper';
import './StatusTiles/styles.scss';
import { workpaper_status } from '../../editor/EditorPage/Spreadsheet/shared/constants';

export default function StatusTiles({ statusCount, gridFilters, totalCount, onSetFilterChange }) {
  const handleChangeStatus = name => {
    const value = name === 'Unassigned' ? null : name;

    const StatusFilterName = 'status';
    // workaround until jasper fixes BTCombobox issue with value changing programmatically null to repopulate value from on
    // click event and undefined to fix bug that occurs when selecting multiple filters and then deleting status and then
    // clearing all filters (all this functionality needs to be changed once we fixed the BTComboBox on jasper side)
    if (gridFilters.status) {
      onSetFilterChange(StatusFilterName, null);
    }

    if (gridFilters.status === null) {
      onSetFilterChange(StatusFilterName, undefined);
    }

    setTimeout(() => {
      onSetFilterChange(StatusFilterName, { value, label: name });
    }, 0);
  };

  return (
    <ButtonsSection className="wkp-create-workpapers-buttons">
      {statusCount?.map(({ status, count, color }) => {
        return (
          <TileButton
            key={status}
            className="status-tile-button"
            disabled={!count}
            onClick={() => handleChangeStatus(status)}
          >
            <div className="tile-count">
              <p className="count-numerator">{count}</p> <p className="count-denominator">/{totalCount}</p>
            </div>

            <span className="status-color-dot" style={{ backgroundColor: color }} />
            {status}
            {status === workpaper_status.Final ? (
              <BTIcon
                icon="lock"
                style={{ marginLeft: '3px', marginBottom: '1px', color: '#646464', fontSize: '12px' }}
              />
            ) : null}
          </TileButton>
        );
      })}
    </ButtonsSection>
  );
}
