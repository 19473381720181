import { BTAlert } from '@btas/jasper';
import { getSendToFixedAssetsMessage } from '../../../../../constants/messages';
import { useEffect, useState } from 'react';

export function SendToFixedAssetsAlert({
  sendToFixedAssetsResult,
  faNotificationOpened,
  setFaNotificationOpened,
  setIsSendingToFixedAssets,
}) {
  const [showAlert, setShowAlert] = useState(false);

  const handleDismiss = () => {
    setFaNotificationOpened(false);
    setShowAlert(false);
  };

  useEffect(() => {
    if (faNotificationOpened) {
      setIsSendingToFixedAssets(true);
      setTimeout(() => {
        setShowAlert(faNotificationOpened);
        setIsSendingToFixedAssets(false);
      }, 5000);
    }
  }, [setIsSendingToFixedAssets, faNotificationOpened]);

  return (
    <BTAlert
      appear
      dismissible
      fixed
      btStyle={sendToFixedAssetsResult?.ok === true ? 'success' : 'danger'}
      visible={showAlert}
      onDismiss={handleDismiss}
    >
      {getSendToFixedAssetsMessage(sendToFixedAssetsResult)}
    </BTAlert>
  );
}
