import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function usePubSub() {
  const eventSubscriptions = useRef([]);
  function publish(message) {
    message.id = uuidv4();
    eventSubscriptions.current = [...eventSubscriptions.current, message];
    eventAggregator();
  }

  function unSubscribe(id) {
    eventSubscriptions.current = eventSubscriptions.current.filter(subscriber => subscriber.id !== id);
  }
  function eventAggregator() {
    if (eventSubscriptions.current) {
      eventSubscriptions.current.forEach(eventMessage => {
        eventSubscriber(eventMessage);
        unSubscribe(eventMessage.id);
      });
    }
  }

  function eventSubscriber(eventMessage) {
    if (eventMessage.callback) {
      if (eventMessage.body) {
        eventMessage.callback(eventMessage.body);
      } else {
        eventMessage.callback();
      }
    }
  }
  return {
    publish,
    eventSubscriptions,
  };
}
