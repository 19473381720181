import * as fetch from '../../../../_shared/fetch';
import { spreadsheetServiceHostName } from '../../../../../configs/params';

export async function getWorkpaperMetadata(id) {
  const response = await fetch.get(`${spreadsheetServiceHostName}/spreadsheet/metadata/${id}`);

  const data = await response.json();

  if (!response.ok) {
    throw data;
  }

  return data;
}
