import * as fetch from '../../../../../_shared/fetch';
import { spreadsheetServiceHostName } from '../../../../../../configs/params';

export async function getTaxLawTopics(filter) {
  const response = await fetch.get(`${spreadsheetServiceHostName}/spreadsheet/tax-law-guidance/topics/${filter}`);
  const result = await response.json();

  return result.items;
}

export async function getWorkpaperSelectedTopics(workpaperId) {
  const response = await fetch.get(`${spreadsheetServiceHostName}/spreadsheet/tax-law-guidance/${workpaperId}`);
  return response.json();
}

export async function saveWorkpaperSelectedTopics({ workpaperId, topics }) {
  await fetch.post(`${spreadsheetServiceHostName}/spreadsheet/tax-law-guidance/${workpaperId}`, {
    body: JSON.stringify({ topics }),
  });
}
