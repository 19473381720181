export function getMockJurisdictions() {
  let returnValue = 200;
  let jurisdictions = [
    {
      id: '6',
      jurisdiction: 'Arizona',
      abbreviation: 'AZ',
    },
    {
      id: '8',
      jurisdiction: 'North Carolina',
      abbreviation: 'NC',
    },
    {
      id: '11',
      jurisdiction: 'Washington DC',
      abbreviation: 'DC',
    },
  ];
  return { returnValue, jurisdictions };
}
