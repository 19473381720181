import { operators } from './filterOperators';

const {
  Equal,
  NotEqual,
  LessThan,
  GreaterThan,
  LessOrEqual,
  GreaterOrEqual,
  Contains,
  IsOneOf,
  IsBlank,
  IsNotBlank,
  IsError,
  IsNotError,
} = operators;

export const operatorTypes = [
  Equal,
  NotEqual,
  LessThan,
  LessOrEqual,
  GreaterThan,
  GreaterOrEqual,
  Contains,
  IsOneOf,
  IsBlank,
  IsNotBlank,
  IsError,
  IsNotError,
];
