import GC from '../../../../../SpreadSheets';
import { copyCache } from './commands/_shared/copyCache';

export function attachClipboardFunctionality({ spread, workpaperId }) {
  spread.bind(GC.Spread.Sheets.Events.ClipboardChanged, (_, args) => {
    const { sheet } = args;
    const col = sheet.getActiveColumnIndex();
    const row = sheet.getActiveRowIndex();
    const value = sheet.getValue(row, col);

    copyCache.set({ workpaperId, sheetName: sheet.name(), row, col, value });
  });
}
