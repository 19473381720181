import { BTForm, BTRadio } from '@btas/jasper';
import React, { useMemo, useRef } from 'react';
import ElementInspector from './shared/ElementInspector';
import { LeftRightFieldsSelector } from './shared/LeftRightFieldsSelector';
import { determineFieldOptions } from './elementType/types/shared/utils';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';
import { useMappingPagination } from './shared/useMappingPagination';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_CONFIG_PANEL_PAGINATION } from '../../../constants/featureFlags';
import { INSPECTOR_PAGE_SIZE } from './shared/constants/pagination';
import { PaginationContainer } from './shared/PaginationContainer';

export const LookupTableInput = Object.freeze({
  A: 'A',
  B: 'B',
});

export const LookupTableOutput = Object.freeze({
  T: 'T',
  F: 'F',
});

export const LookupElementInspector = ({ elementData, elementType, updateData, elementId, inputElements, isDirty }) => {
  const canEditWorkflow = useCanEditWorkflow();
  const isPaginationEnabled = useRef(isFeatureFlagEnabled(WKP_CONFIG_PANEL_PAGINATION));
  const [leftElementData, rightElementData] = [inputElements?.A?.elementData, inputElements?.B?.elementData];
  const [leftInputName, rightInputName] = [
    `Input A${leftElementData?.name ? ` (${leftElementData?.name})` : ''}`,
    `Input B${rightElementData?.name ? ` (${rightElementData?.name})` : ''}`,
  ];

  const [leftInputNameNotConnected, rightInputNameNotConnected] = [
    `Input A (Not connected)`,
    `Input B (Not connected)`,
  ];

  const lookupTableInput = elementData.lookupTableInput;
  const keyFields = elementData.keyFields ?? [];

  const { page, setPage, total, mappings, paginationProps } = useMappingPagination(
    keyFields,
    updateData,
    isPaginationEnabled.current,
    'keyFields'
  );

  const startIndex = (page - 1) * INSPECTOR_PAGE_SIZE;
  const endIndex = page * INSPECTOR_PAGE_SIZE;

  const keyFieldsLeft = keyFields.map(field => ({
    name: field.left?.name,
    type: field.left?.type,
  }));

  const keyFieldsRight = keyFields.map(field => ({
    name: field.right?.name,
    type: field.right?.type,
  }));

  const leftFieldOptions = determineFieldOptions(leftElementData?.fields, keyFieldsLeft);
  const rightFieldOptions = determineFieldOptions(rightElementData?.fields, keyFieldsRight);

  const areInputPortsConnected = Object.keys(inputElements).length === 2;
  const isInputAConnectedFirst = Object.keys(inputElements)[0] === 'A';

  const isInputBConnectedFirst = Object.keys(inputElements)[0] === 'B';

  const setKeyFields = newKeyFields => {
    updateData({ keyFields: newKeyFields });
  };

  const setLookupTableInput = inputName => {
    updateData({ lookupTableInput: inputName, fieldsByPort: { T: [], F: [] } });
  };

  const alerts = useMemo(() => {
    if (!areInputPortsConnected) {
      return [{ style: 'danger', content: 'Lookup block is not connected.' }];
    }
    return [];
  }, [areInputPortsConnected]);

  return (
    <ElementInspector {...{ elementData, elementId, elementType, updateData, isDirty, alerts }}>
      <div className="wkp-lookup-element-inspector">
        <BTForm>
          <BTForm.FormGroup disabled={!areInputPortsConnected} label="Which input contains the lookup table?">
            <BTRadio
              checked={lookupTableInput === LookupTableInput.A}
              disabled={!canEditWorkflow}
              label={isInputAConnectedFirst || areInputPortsConnected ? leftInputName : leftInputNameNotConnected}
              name="lookupTableInput"
              value={LookupTableInput.A}
              onChange={() => setLookupTableInput(LookupTableInput.A)}
            />
            <BTRadio
              checked={lookupTableInput === LookupTableInput.B}
              disabled={!canEditWorkflow}
              label={isInputBConnectedFirst || areInputPortsConnected ? rightInputName : rightInputNameNotConnected}
              name="lookupTableInput"
              value={LookupTableInput.B}
              onChange={() => setLookupTableInput(LookupTableInput.B)}
            />
          </BTForm.FormGroup>
        </BTForm>
        <div>
          <h5>Select Fields to Perform the Lookup</h5>
          <p>Select the field(s) that are common between the datasets.</p>
          <LeftRightFieldsSelector
            leftFields={leftFieldOptions}
            leftLabel={leftInputName}
            mappings={mappings}
            rightFields={rightFieldOptions}
            rightLabel={rightInputName}
            setMappings={setKeyFields}
            {...paginationProps}
          />
          {isPaginationEnabled.current && total > INSPECTOR_PAGE_SIZE && (
            <PaginationContainer
              endIndex={endIndex}
              page={page}
              setPage={setPage}
              startIndex={startIndex}
              totalFields={total}
            />
          )}
        </div>
      </div>
    </ElementInspector>
  );
};
