const BASE_CELL_WIDTH = 54;
const BASE_CELL_HEIGHT = 40;

export function restyleCell(cell) {
  const elementWidth = BASE_CELL_WIDTH;
  const rx = Math.max(1, 5 - Math.floor(elementWidth / BASE_CELL_WIDTH));

  cell.attr('.block/rx', rx);
  cell.size(BASE_CELL_WIDTH, BASE_CELL_HEIGHT); //resizes cells previously created to match standardized size
}

export function handleNameChange(cell) {
  const name =
    cell?.attr('.name/text').length > 60 ? cell?.attr('.name/text').substring(0, 60) + '...' : cell?.attr('.name/text');
  //can pass in prop 'ellipsis: true' to the textWrap to truncate text when it exceeds bounds of box
  cell.attr('.name', {
    textWrap: {
      text: name || '',
      width: 112,
      height: 100,
    },
  });
}

export function highlightElement(element) {
  if (element && element.cell) {
    element.cell.attr('.block/stroke', '#0073FF');
    element.cell.attr('.block/strokeWidth', 3);
  } else if (element && element.attr('label/type') === 'annotation') {
    element.attr('body/stroke', '#0073FF');
    element.attr('body/strokeWidth', 3);
  } else if (element && !element.cell) {
    element.attr('.block/stroke', '#0073FF');
    element.attr('.block/strokeWidth', 3);
  }
}

export function unhighlightElement(element) {
  if (element && element.cell) {
    element.cell.attr('.block/stroke', 'none');
    element.cell.attr('.block/strokeWidth', 0);
  } else if (element && element.attr('label/type') === 'annotation') {
    element.attr('body/stroke', 'none');
    element.attr('body/strokeWidth', 0);
  } else if (element && !element.cell) {
    element.attr('.block/stroke', 'none');
    element.attr('.block/strokeWidth', 0);
  }
}
