import { getUser } from '../../../../../_shared/auth';
import { setReviewCommandsBehavior } from '../commandsBehavior';
import { MARK_CELL_REVIEWED_COMMAND, UNMARK_CELL_REVIEWED_COMMAND } from './cellReview/constants';
import { addReviewedCells, getReviewedCells } from './cellReview/utils';

export function createCellReviewCommand({ setCommandsVisibleContext, setHasPendingChanges }) {
  const cellReviewcommand = {
    text: 'Mark reviewed',
    commandName: MARK_CELL_REVIEWED_COMMAND,
    execute(context, _, contextMenuArgs) {
      const { Spread: spread } = context;
      const commandManager = spread.commandManager();

      const user = getUser();
      let impactedCellsCount = 0;

      const sheet = getActiveSheet(spread, contextMenuArgs);
      const selections = sheet.getSelections();
      let reviewedCells = getReviewedCells(sheet);

      selections.forEach(selected => {
        for (let rowX = 0; rowX < selected.rowCount; rowX++) {
          for (let colY = 0; colY < selected.colCount; colY++) {
            const rowIdx = selected.row + rowX;
            const colIdx = selected.col + colY;

            if (user) {
              const cell = sheet.getCell(rowIdx, colIdx);

              reviewedCells = addReviewedCells(reviewedCells, user, cell, rowIdx, colIdx);

              impactedCellsCount++;
            }
          }
        }
      });

      if (impactedCellsCount > 0) {
        commandManager.execute({
          activeColIndex: sheet.getActiveColumnIndex(),
          activeRowIndex: sheet.getActiveRowIndex(),
          cmd: 'Designer.setTag',
          selections,
          sheetId: sheet._id,
          sheetName: contextMenuArgs.sheetName,
          tag: { ...sheet.tag(), reviewedCells },
          type: 'sheet',
        });

        setReviewCommandsBehavior(sheet, setCommandsVisibleContext);
      }

      return true;
    },
    workArea: 'viewport',
  };

  const unmarkCellReviewcommand = {
    text: 'Unmark reviewed',
    commandName: UNMARK_CELL_REVIEWED_COMMAND,
    execute(context, _, contextMenuArgs) {
      const { Spread: spread } = context;
      const commandManager = spread.commandManager();

      let impactedCellsCount = 0;

      const sheet = getActiveSheet(spread, contextMenuArgs);
      const selections = sheet.getSelections();
      const sheetTag = sheet.tag();
      let { reviewedCells } = sheetTag;

      selections.forEach(selected => {
        for (let rowX = 0; rowX < selected.rowCount; rowX++) {
          for (let colY = 0; colY < selected.colCount; colY++) {
            const rowIdx = selected.row + rowX;
            const colIdx = selected.col + colY;

            if (reviewedCells) {
              reviewedCells = reviewedCells.filter(cell => !(cell.row === rowIdx && cell.col === colIdx));
            }

            impactedCellsCount++;
          }
        }
      });

      if (impactedCellsCount > 0) {
        commandManager.execute({
          activeColIndex: sheet.getActiveColumnIndex(),
          activeRowIndex: sheet.getActiveRowIndex(),
          cmd: 'Designer.setTag',
          selections,
          sheetId: sheet._id,
          sheetName: contextMenuArgs.sheetName,
          tag: { ...sheetTag, reviewedCells },
          type: 'sheet',
        });

        setReviewCommandsBehavior(sheet, setCommandsVisibleContext);
      }

      return true;
    },
    workArea: 'viewport',
  };

  return {
    [MARK_CELL_REVIEWED_COMMAND]: {
      ...cellReviewcommand,
      visibleContext: 'ClickViewport || ClickCorner || ClickColHeader || ClickRowHeader',
      enableContext: '!CellLocked || !IsProtected',
    },
    [UNMARK_CELL_REVIEWED_COMMAND]: {
      ...unmarkCellReviewcommand,
      visibleContext: 'ClickViewport || ClickCorner || ClickColHeader || ClickRowHeader',
      enableContext: '!CellLocked || !IsProtected',
    },
  };
}

function getActiveSheet(spread, contextMenuArgs) {
  return contextMenuArgs ? spread.getSheetFromName(contextMenuArgs.sheetName) : spread.getActiveSheet();
}
