import * as fetch from '../../_shared/fetch';
import { dataFlowServiceHostName } from '../../../configs/params';

export async function getDataFlow(id) {
  const response = await fetch.get(`${dataFlowServiceHostName}/api/v1/data-flows/${id}/`);

  return response.json();
}

export async function getDataFlowElevated(id) {
  const response = await fetch.get(`${dataFlowServiceHostName}/api/v1/data-flows/${id}/elevated`);

  return response.json();
}

export async function saveAndPublishDataFlow(dataFlow) {
  const response = await fetch.put(`${dataFlowServiceHostName}/api/v1/data-flows/${dataFlow.id}/`, {
    body: JSON.stringify(dataFlow),
  });

  return response.json();
}

export async function saveDataFlow(dataFlow) {
  const response = await fetch.put(`${dataFlowServiceHostName}/api/v1/data-flows/${dataFlow.id}/save`, {
    body: JSON.stringify(dataFlow),
  });

  return response.json();
}

export async function validateDataFlow(id) {
  const response = await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows/${id}/validate`);

  return response.json();
}
