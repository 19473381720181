import * as fetch from '../../../_shared/fetch';
import { spreadsheetServiceHostName, dataFlowServiceHostName } from '../../../../configs/params';

export async function createWorkpaper(workpaperId) {
  const path = `${spreadsheetServiceHostName}/spreadsheet/files/create`;
  const response = await fetch.post(path, {
    body: JSON.stringify({
      workpaperId,
    }),
  });
  const data = await response.json();
  return data.id;
}

export async function createDataFlow() {
  const response = await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows`);

  const data = await response.json();

  return data.id;
}
