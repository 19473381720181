import * as fetch from '../../../../_shared/fetch';
import { dataFlowServiceHostName } from '../../../../../configs/params';

export async function copyDataFlow(configuration, name, taxPeriod, includeSourceFiles, copyFromDataFlowId) {
  const response = await fetch.post(`${dataFlowServiceHostName}/api/v1/data-flows/`, {
    body: JSON.stringify({ configuration, name, taxPeriod, includeSourceFiles, copyFromDataFlowId }),
  });

  return response.json();
}
