import React, { useRef, useEffect, useState, useCallback, useContext } from 'react';
import { useGraph } from './DataFlowCanvas/useGraph';
import { useDataFlowEventHandlers } from './DataFlowCanvas/useDataFlowEventHandlers';
import { usePaper } from './DataFlowCanvas/usePaper';
import { useLinkHalo } from './DataFlowCanvas/useLinkHalo';
import { useElementTools } from './DataFlowCanvas/useElementTools';
import { useRestoreCanvas } from './DataFlowCanvas/useRestoreCanvas';
import { restoreElements } from './DataFlowCanvas/restoreElementsHandler';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import { ui } from '@clientio/rappid';
import { useTooltips } from './DataFlowCanvas/useTooltips';
import './DataFlowCanvas/styles.scss';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_DATAFLOW_SIDEBAR } from '../../../constants/featureFlags';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';

export const DataFlowCanvas = ({ initialDataFlow, inspectorIsPresent, previewIsPresent, configInspectorIsPresent }) => {
  const { v4: uuidv4 } = require('uuid');
  const canvasContainer = useRef(null);
  const [canvasClass, setCanvasClass] = useState('wkp-data-flow-canvas');
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const { graph } = dataFlowState;
  const keyboard = useRef(new ui.Keyboard());
  const [clipboardBlock, setClipboardBlock] = useState(null);
  const canEditWorkflow = useCanEditWorkflow();

  // Initialize graph
  useGraph();

  // Initialize paper and paper scroller
  usePaper(canvasContainer);

  // Enable event handlers for Data Flow Editor
  useDataFlowEventHandlers();

  // Enable Halo component for active links
  useLinkHalo();

  // Enable element Tools component for active elements
  useElementTools();

  useRestoreCanvas(initialDataFlow.configuration);

  useTooltips();

  useEffect(() => {
    const inspectorClassToUse = isFeatureFlagEnabled(WKP_DATAFLOW_SIDEBAR)
      ? 'wkp-data-flow-canvas-no-inspector-sidebar'
      : 'wkp-data-flow-canvas-no-inspector';
    const inspectorClass =
      inspectorIsPresent || configInspectorIsPresent ? 'wkp-data-flow-canvas-inspector' : inspectorClassToUse;
    const previewClass = previewIsPresent ? 'wkp-data-flow-canvas-preview' : 'wkp-data-flow-canvas-no-preview';

    const newCanvasClass = `wkp-data-flow-canvas ${inspectorClass} ${previewClass}`;

    setCanvasClass(newCanvasClass);
  }, [inspectorIsPresent, previewIsPresent, configInspectorIsPresent]);

  // Handle Ctrl+C
  const handleCtrlC = useCallback(() => {
    if (
      canEditWorkflow &&
      dataFlowState.workingElement?.elementData?.type !== 'input' &&
      dataFlowState.workingElement?.elementData?.type !== 'output'
    ) {
      let newConfigObject;

      // Regular blocks
      if (dataFlowState.workingElement.elementData.type !== 'annotation') {
        newConfigObject = { ...dataFlowState.workingElement.elementData };
        newConfigObject['bounds'] = {
          height: 40,
          width: 54,
          top: +dataFlowState.workingElement?.cell?.attributes?.position?.y + 40,
          left: +dataFlowState.workingElement?.cell?.attributes?.position?.x + 40,
        };
      } else {
        if (dataFlowState.activeElement) {
          // Text blocks
          newConfigObject = { ...dataFlowState.workingElement?.elementData };
          newConfigObject['text'] = dataFlowState.activeElement?.cell?.attributes?.attrs?.label?.text;
          newConfigObject['bounds'] = {
            height: 30,
            width: 170,
            top: +dataFlowState.workingElement.cell.attributes.position.y + 40,
            left: +dataFlowState.workingElement.cell.attributes.position.x + 40,
          };
        }
      }

      setClipboardBlock(newConfigObject);
    }
  }, [dataFlowState, canEditWorkflow]);

  const handleCtrlV = useCallback(() => {
    if (
      canEditWorkflow &&
      dataFlowState.workingElement?.elementData?.type !== 'input' &&
      dataFlowState.workingElement?.elementData?.type !== 'output' &&
      clipboardBlock.bounds &&
      dataFlowState.elements
    ) {
      const newBlockUUID = uuidv4();
      clipboardBlock['id'] = newBlockUUID;

      const conflictFreeName = createConflictFreeName(clipboardBlock, dataFlowState.elements);
      clipboardBlock['name'] = conflictFreeName;

      restoreElements({ newBlockUUID: clipboardBlock }, graph, dataFlowActions, clipboardBlock, dataFlowState);
      dataFlowActions.syncElementState();
    }
  }, [dataFlowState, clipboardBlock, dataFlowActions, graph, uuidv4, canEditWorkflow]);

  useEffect(() => {
    const { paper } = dataFlowState;

    if (paper && keyboard) {
      const keyboardInstance = keyboard?.current;

      keyboardInstance.on({
        'ctrl+c': handleCtrlC,
        'ctrl+v': handleCtrlV,
      });

      const handleKeyDown = evt => {
        if (paper.el === evt.target || paper.el.contains(evt.target)) {
          keyboardInstance.onKeyDown(evt);
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        keyboardInstance.off('ctrl+c ctrl+v');
      };
    }
  }, [dataFlowState, handleCtrlC, handleCtrlV]);

  return <div ref={canvasContainer} className={canvasClass} />;
};

export function createConflictFreeName(clipboardBlock, dataFlowStateElements) {
  const selectedBlockName = clipboardBlock?.type?.charAt(0).toUpperCase() + clipboardBlock?.type?.slice(1);
  let initialValue = 1;
  let blockNames = [];

  for (const property in dataFlowStateElements) {
    blockNames.push(dataFlowStateElements?.[property]?.['elementData']?.['name']);
  }

  while (blockNames.includes(`${selectedBlockName} ${initialValue}`)) {
    initialValue++;
  }

  return `${selectedBlockName} ${initialValue}`;
}
