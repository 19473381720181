import { getFormattedDate } from '../../../../../../_shared/dateFormatting';
import { adjustCells, REVIEWED } from '../_shared/adjustCells';
import { isSourceDataFormulaMatch } from '../../formulas';

function getWidthForAutoFit(text, cellStyle, tags, row, col) {
  const canvasContext = document.createElement('canvas').getContext('2d');
  canvasContext.font = cellStyle.font;
  let textWidth = canvasContext.measureText(text).width;

  const reviewedCell = isReviewedCell(row, col, tags);
  const { newX } = adjustCells(textWidth, 0, reviewedCell ? REVIEWED : false);

  return newX;
}

/**
 * Registers a tooltip
 *
 * @param {*} tooltipManager
 * @returns A function:
 * @param {number} x The leftmost point of the tooltip listener
 * @param {number} y The uppermost point of the tooltip listener
 * @param {{number, number}} listenerRange An object {width, height} containing the
 * width and height of the tooltip listener
 * @param {{number, number}} tooltipPosition An object {x, y} containing the relative
 * position that the tooltip should point at
 * @param {*} tooltipId
 * @param {*} tooltipContent
 * @param {*} isHTML optional
 */
function handleRegisterTooltip(tooltipManager) {
  return (x, y, listenerRange, tooltipPosition, tooltipId, tooltipContent, isHTML) => {
    const designerPos = document.getElementById('designer').getBoundingClientRect();
    const spreadWrapperPos = document.getElementsByClassName('spreadWrapper')[0].getBoundingClientRect();
    const canvasX = designerPos.left + x;
    const canvasY = designerPos.top + y;

    const tooltipX = spreadWrapperPos.left + x + tooltipPosition.x;
    const tooltipY = spreadWrapperPos.top + y + tooltipPosition.y;

    tooltipManager.registerTooltip({
      id: tooltipId,
      originX: canvasX,
      originY: canvasY,
      destX: canvasX + listenerRange.width,
      destY: canvasY + listenerRange.height,
      content: tooltipContent,
      isHTML,
      position: {
        x: tooltipX,
        y: tooltipY,
      },
    });
  };
}

function existBrokenSourceDataFormula(formula, formulaValue) {
  return isSourceDataFormulaMatch(formula) && formulaValue === '';
}

function getCellFormula(cell) {
  const formula = cell.formula();
  return formula ? `=${formula}` : '';
}

function isReviewedCell(row, col, tags) {
  if (tags) {
    const { reviewedCells } = tags;
    return reviewedCells?.find(cellTag => cellTag.row === row && cellTag.col === col);
  }
  return false;
}

function getReviewedCellMessage(cellTag, cellValue, cellFormula, reviewedValueChanged) {
  const { date, user } = cellTag;
  let message = `Reviewed by ${user} ${getFormattedDate(date)}`;

  if (reviewedValueChanged) {
    const { originalFormula, originalValue } = cellTag;
    const originalValueMessage = originalFormula
      ? `'${originalFormula}', formula result '${originalValue}'`
      : `'${originalValue}'`;
    const cellValueMessage = cellFormula ? `'${cellFormula}', formula result '${cellValue}'` : `'${cellValue}'`;
    message += `\n \nValue changed from ${originalValueMessage} to ${cellValueMessage} since it was reviewed.`;
  }

  return message;
}

function getReviewedCells(sheet) {
  return sheet.tag() ? sheet.tag().reviewedCells : [];
}

function addReviewedCells(reviewedCells, user, cell, rowIdx, colIdx) {
  const originalValue = cell.text() || '';
  const originalFormula = getCellFormula(cell);

  return [
    ...reviewedCells,
    {
      user: `${user.firstName} ${user.lastName || ''}`,
      date: new Date().toISOString(),
      originalValue,
      originalFormula,
      row: rowIdx,
      col: colIdx,
    },
  ];
}

function isCellOnScreenValidator({ GC, sheet, row, col, onNotActive }) {
  return () => {
    let active = false;
    try {
      const { x, y, width, height } = sheet.getCellRect(row, col);
      active = x > 0 && y > 0 && width > 0 && height > 0;
      if (active) {
        const spread = GC.Spread.Sheets.findControl(document.querySelector('[gcuielement="gcSpread"]'));
        const activeSheet = spread.getActiveSheet();
        active = activeSheet.name() === sheet.name();
      }
    } catch (_) {}

    if (!active) {
      onNotActive();
    }

    return active;
  };
}

export {
  getWidthForAutoFit,
  handleRegisterTooltip,
  existBrokenSourceDataFormula,
  getCellFormula,
  isReviewedCell,
  getReviewedCellMessage,
  getReviewedCells,
  addReviewedCells,
  isCellOnScreenValidator,
};
