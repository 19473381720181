import { BTButton, BTModal, BTScrollContainer, P } from '@btas/jasper';
import AiLabIcon from './assets/AI_Lab.svg';

const DisclaimerModal = ({ acceptDisclaimer, toggleModal, readOnly, setReadOnlyDisclaimer }) => {
  const handleClose = () => {
    toggleModal();
    if (readOnly) {
      setReadOnlyDisclaimer(false);
    }
  };

  return (
    <BTModal
      id="research-disclaimer-modal"
      show={true}
      size="modal-lg"
      title="Welcome to the Bloomberg Tax AI Lab"
      onCloseClick={handleClose}
    >
      <BTModal.Body className="feedbackForm">
        <BTScrollContainer id="scroll-container">
          <BTScrollContainer.Content>
            <P>
              Many of the features that you will get to experience within the Bloomberg Tax AI Lab leverage Large
              Language Models (“LLMs”) to understand user inquiries and generate responses. Such LLMs may be hosted by
              Bloomberg Industry Group or third parties, but in all events, your inputs remain your confidential
              information and will never be used to train our, or any third-party models. We seek to enhance the
              accuracy and reliability of our products by providing the LLM with Bloomberg Tax’s vast corpus of
              up-to-date and highly reliable reference documentation.
            </P>
            <P>
              While this approach is designed to elevate the performance of LLMs, it is important to remember that the
              LLM’s understanding of the reference documentation is fundamentally based on pattern matching and
              statistical inferences from its training phase. Consequently, there are some inherent limitations to be
              aware of:
            </P>
            <ul>
              <li>
                <P>
                  The quality of the responses that LLMs generate will be dependent upon the availability and relevancy
                  of reference documentation.
                </P>
              </li>
              <li>
                <P>
                  LLMs may still make errors, misinterpret, or oversimplify complex information from the reference
                  materials.
                </P>
              </li>
              <li>
                <P>
                  Highly specialized tax knowledge from the reference materials may not always be correctly understood
                  or applied.
                </P>
              </li>
              <li>
                <P>
                  While an LLM may make relevant inferences based on the material/input supplied, it may occasionally
                  generate incorrect or unrelated outputs.
                </P>
              </li>
              <li>
                <P>
                  In cases of ambiguity in the documents or inputs, an LLM might not ask clarifying questions and may
                  make assumptions to generate responses.
                </P>
              </li>
            </ul>
            <P>
              Despite these limitations, providing the underlying LLM with reliable, up-to-date documents significantly
              improves the accuracy, depth, and timeliness of an LLM’s responses. Nevertheless, in acknowledging the
              capabilities and limitations of this service, it is critically important to underscore that the responses
              generated should not be viewed as an exclusive source of information. The Bloomberg Tax AI Lab is
              experimental in nature, and users are strongly advised to corroborate the information obtained, exercise
              professional judgment, and seek out additional sources of information as deemed necessary. The utilization
              of this service does not in any way obviate the essential need for independent verification, and prudent,
              professional decision-making; and should not be treated as a substitute for tax, legal or professional
              advice.
            </P>
            <p>Just a few other notes about the Bloomberg Tax AI Lab: </p>
            <ul>
              <li>
                Products are experimental in nature, may undergo frequent updates, and may be discontinued at any time
                for any or no reason.
              </li>
              <li>As part of your access to the Bloomberg Tax AI Lab, you may be contacted about your experience.</li>
              <li>
                Anytime you see{' '}
                <span className="disclaimer-ai-icon">
                  <img alt="Ai Lab Icon" src={AiLabIcon} />
                </span>
                , you’ll be using a feature that is a part of the Bloomberg Tax AI Lab.
              </li>
              <li>
                Privacy. Please review our Privacy Policy available at{' '}
                <a className="link" href="https://www.bloombergindustry.com/privacy-policy/">
                  https://www.bloombergindustry.com/privacy-policy/
                </a>{' '}
                for information in how we collect and use personal data.
              </li>
            </ul>
          </BTScrollContainer.Content>
        </BTScrollContainer>
      </BTModal.Body>
      <BTModal.Footer>
        <>
          <BTButton btStyle="secondary" onClick={readOnly ? handleClose : toggleModal}>
            Cancel
          </BTButton>
          {!readOnly && (
            <BTButton btStyle="primary" onClick={acceptDisclaimer}>
              Agree
            </BTButton>
          )}
        </>
      </BTModal.Footer>
    </BTModal>
  );
};

export default DisclaimerModal;
