import PropTypes from 'prop-types';

export const propTypes = {
  Component: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  maxOptions: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onRequestOptions: PropTypes.func,
  onSelect: PropTypes.func,
  changeOnSelect: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.string)]),
  regex: PropTypes.string,
  matchAny: PropTypes.bool,
  minChars: PropTypes.number,
  requestOnlyIfNoOptions: PropTypes.bool,
  spaceRemovers: PropTypes.arrayOf(PropTypes.string),
  spacer: PropTypes.string,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  value: PropTypes.string,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  passThroughEnter: PropTypes.bool,
  supportedFunctions: PropTypes.object,
  error: PropTypes.string,
  maxLength: PropTypes.string,
};

export const defaultProps = {
  Component: 'textarea',
  defaultValue: '',
  disabled: false,
  maxOptions: 0,
  onBlur: () => {},
  onChange: () => {},
  onKeyDown: event => {
    // this event will be triggered if function hint is disabled. autocomplete fields and
    // auto complete functions works as expected.
    if (event.keyCode === 13) {
      // If user presses Enter, an extra line to the Text Area won´t be added which was the
      // expecter behavior in ticket BPMP-3333 (comments about this in this ticket)
      event.preventDefault();
    }
  },
  onRequestOptions: () => {},
  onSelect: () => {},
  changeOnSelect: (trigger, slug) => trigger + slug,
  options: [],
  regex: '^[A-Za-z0-9,\\-_><=+/*]+$',
  matchAny: false,
  minChars: 0,
  requestOnlyIfNoOptions: true,
  spaceRemovers: ['.', '!', '?'],
  spacer: ' ',
  trigger: '@',
  offsetX: 10,
  offsetY: 5,
  value: null,
  passThroughEnter: false,
  supportedFunctions: {},
  maxLength: '10000',
};
