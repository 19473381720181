import { useCallback, useState } from 'react';
import { countBy, pickBy } from 'lodash';

export default function useValidateFieldNamesAreUnique() {
  const [fieldCounts, setFieldCounts] = useState({});
  const [hasDuplicateErrors, setHasDuplicateErrors] = useState(false);

  const getRenamedFieldNames = (picked, renamed, joinSide) => {
    const pickedFieldNames = Object.keys(pickBy(picked[joinSide], p => p));
    return pickedFieldNames.map(name => renamed[joinSide][name] || name);
  };

  const validateFieldNames = useCallback((picked, renamed) => {
    const fieldCounts = countBy([
      ...getRenamedFieldNames(picked, renamed, 'A'),
      ...getRenamedFieldNames(picked, renamed, 'B'),
    ]);

    setFieldCounts(fieldCounts);

    setHasDuplicateErrors(Object.values(fieldCounts).some(c => c > 1));
  }, []);

  const isFieldNameValid = useCallback(
    (fieldName, renamedTo) => (fieldCounts[renamedTo || fieldName] ?? 0) < 2,
    [fieldCounts]
  );

  return { validateFieldNames, isFieldNameValid, hasDuplicateErrors };
}
