import { useEffect, useState } from 'react';
import { getFile } from './apis';

export const useSFTPSourceFile = (elementId, onErrorCallback) => {
  const [isSFTPLoading, setIsSFTPLoading] = useState(false);
  const [openSourceFile, setOpenSourceFile] = useState(null);
  const [useSFTP, setUseSFTP] = useState(false);
  const [uploadedSFTPFile, setUploadedSFTPFile] = useState(null);
  const [selectedSFTPFile, setSelectedSFTPFile] = useState(null);
  const [selectedSFTPFileSheetData, setSelectedSFTPFileSheetData] = useState(null);

  async function loadSourceFile(elementId) {
    try {
      setIsSFTPLoading(true);
      const sftpFile = await getFile(openSourceFile, elementId);
      setUseSFTP(useSFTP);
      setUploadedSFTPFile(sftpFile?.uploadFile);
      setSelectedSFTPFile(sftpFile?.file);
      setSelectedSFTPFileSheetData(sftpFile?.sheetData);
    } catch (e) {
      setUploadedSFTPFile(null);
      setSelectedSFTPFile(null);
      setSelectedSFTPFileSheetData(null);
      onErrorCallback(e);
    } finally {
      setIsSFTPLoading(false);
    }
  }

  useEffect(() => {
    if (openSourceFile) {
      loadSourceFile(elementId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openSourceFile]);

  return {
    isSFTPLoading,
    openSourceFile,
    setOpenSourceFile,
    selectedSFTPFileSheetData,
    selectedSFTPFile,
    uploadedSFTPFile,
  };
};
