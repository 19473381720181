import React from 'react';
import { BTIcon } from '@btas/jasper';
export default function FailedJobItem({ jobOutput }) {
  return (
    <div className="wkp-job-item" title={`${jobOutput.errorTitle}`}>
      <span className="wkp-job-item-name">
        <b className="wkp-job-item-error-message">
          {jobOutput.errorTitle}
          {jobOutput.errorDescription ? <br /> : ''}
          {jobOutput.errorDescription}
        </b>
      </span>
      <span className="wkp-job-item-icon wkp-job-item-fail-icon">
        <BTIcon icon="exclamation-circle" />
      </span>
    </div>
  );
}
