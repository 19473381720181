import { useEffect } from 'react';
import { ui } from '@clientio/rappid';

export function useTooltips() {
  useEffect(() => {
    const tooltip = new ui.Tooltip({
      rootTarget: document.body,
      target: '[data-tooltip]',
      direction: ui.Tooltip.TooltipArrowPosition.Auto,
      trigger: 'hover',
      padding: 10,
    });
    return () => {
      tooltip.remove();
    };
  }, []);
}
