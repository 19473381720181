import * as fetch from '../../../_shared/fetch';
import { dataFlowServiceHostName } from '../../../../configs/params';

export async function removePendingData(dataFlowId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/pending`;
  const res = await fetch.remove(reqUri);

  return res.json();
}

export async function removeDataFlow(dataFlowId) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows`;
  const res = await fetch.remove(reqUri, {
    body: JSON.stringify({ ids: [dataFlowId] }),
  });

  return res.json();
}

export async function uploadOutputRecords(dataFlowRunId) {
  const response = await fetch.post(
    `${dataFlowServiceHostName}/api/v1/data-flows/data-flow-run/${dataFlowRunId}/upload-output`
  );
  if (!response.ok) {
    throw response.data;
  }
  return response.json();
}
