import { useCallback, useState, useRef } from 'react';
import { validateFormula } from './api';

export default function useFormulaValidation() {
  const [formulaError, setFormulaError] = useState('');
  const [fieldType, setFieldType] = useState(null);
  const lastCall = useRef(null);

  const validate = useCallback(async (formula, inputFields, allowedTypes, displayErrorMessage = false) => {
    const thisCall = Date.now();
    lastCall.current = thisCall;

    if (formula) {
      const validationResult = await validateFormula(formula, inputFields, allowedTypes);

      if (lastCall.current === thisCall) {
        if (!validationResult.isValid) {
          setFormulaError(validationResult.errorMessage);
          setFieldType(null);
        } else {
          setFormulaError('');
          setFieldType(validationResult.resultType);
        }
      }
    } else {
      displayErrorMessage ? setFormulaError('Formula is required.') : setFormulaError('');
      setFieldType(null);
    }
  }, []);

  return { validateFormula: validate, fieldType, formulaError };
}
