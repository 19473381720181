import React from 'react';
import { BTModal, BTButton } from '@btas/jasper';

export default function ChangeFinalStatusModal({ show, onClose, onConfirmChange }) {
  const handleConfirmation = () => onConfirmChange();
  return (
    <BTModal id="change-final-status-modal" show={show} title="Unlock Workpaper" onCloseClick={onClose}>
      <BTModal.Body>
        <p>
          Changing the status out of 'Final' will unlock the workpaper. Data connections and tax law values will be
          refreshed, which may cause changes to this workpaper. Are you sure?
        </p>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton btStyle={'default'} onClick={onClose}>
          Cancel
        </BTButton>
        <BTButton btStyle="primary" onClick={handleConfirmation}>
          Unlock
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
