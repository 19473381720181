import { throttle } from 'lodash';
import { getElementType } from '../../shared/graphUtils';
import * as utils from './autoconnectOnProximity/utilities';
import { g } from '@clientio/rappid';
import './autoconnectOnProximity/styles.scss';
import { getRouter } from '../router';

/**
 * @param {import('jointjs').dia.ElementView} draggingElementView
 * @returns
 */
function createTargetPortModel(paper, draggingElementView) {
  const maxXDistance = 150;
  const maxYDistance = 75;
  const maxAllowedDistance = utils.calculateDistance({ x: 0, y: 0 }, { x: maxXDistance, y: maxYDistance });
  const draggingElementInPorts = draggingElementView.model
    .getGroupPorts('in')
    .map(port => utils.createPortModel(draggingElementView, [], port));
  const compatiblePortsInPaperMap = draggingElementInPorts.reduce((map, port) => {
    map.set(port, utils.findCompatiblePortsForGivenMagnet(paper, draggingElementView, port.magnet));

    return map;
  }, new WeakMap());

  return {
    targetPort: null,
    getDraggingElementMagnetsInfo() {
      const coordinates = draggingElementInPorts.map(port => {
        const portBBox = utils.findElementBBoxRelativeToPaper(paper, port.magnet);
        const magnetCoordinates = {
          x: portBBox.center().x - portBBox.width / 2,
          y: portBBox.center().y,
        };

        return { port, magnetCoordinates, portBBox };
      });

      return coordinates;
    },
    getClosestPortInfo() {
      const draggingElementMagnetsCoordinates = this.getDraggingElementMagnetsInfo();

      const closestPort = draggingElementMagnetsCoordinates.reduce(
        (closest, deMagnet) => {
          const magnetCompatiblePaperPorts = compatiblePortsInPaperMap.get(deMagnet.port);

          const iterationClosest = magnetCompatiblePaperPorts.reduce((groupClosest, paperPort) => {
            const xdistance = Math.abs(deMagnet.magnetCoordinates.x - paperPort.center.x);
            const ydistance = Math.abs(deMagnet.magnetCoordinates.y - paperPort.center.y);
            const distance = utils.calculateDistance(deMagnet.magnetCoordinates, paperPort.center);
            const portsDoNotOverlap = !g
              .rect(deMagnet.portBBox)
              //adds margin of 1 to make it safer
              .inflate(paperPort.size.width / 2 + 1, paperPort.size.height / 2 + 1)
              .containsPoint(paperPort.center);
            const portsValidation = portsDoNotOverlap;

            if (
              paperPort.center.x < deMagnet.magnetCoordinates.x &&
              portsValidation &&
              xdistance < maxXDistance &&
              ydistance < maxYDistance &&
              distance < closest.distance
            ) {
              return {
                distance,
                sourcePort: paperPort,
                targetPort: deMagnet.port,
                targetPortCoordinates: deMagnet.magnetCoordinates,
              };
            }

            return groupClosest;
          }, closest);

          return iterationClosest;
        },
        { distance: maxAllowedDistance, sourcePort: null, targetPort: null }
      );

      return closestPort;
    },
  };
}

/**
 * @param {*} paper
 * @param {import('jointjs').dia.ElementView} draggingElementView
 */
function createAutoconnectionModel(paper, draggingElementView) {
  const targetPortInfo = createTargetPortModel(paper, draggingElementView);

  return {
    targetPort: targetPortInfo.targetPort,
    inRangePort: null,
    link: null,
    clearVisualAids() {
      if (this.inRangePort?.magnet) {
        utils.toggleMagnetHighlight([this.inRangePort.magnet] || [], false);
      }
      this.clearLink();
    },
    clearLink() {
      if (this.link !== null) {
        this.link.remove();
        this.link = null;
      }
    },
    onDraggingElementMove() {
      const closestPortInfo = targetPortInfo.getClosestPortInfo();

      if (closestPortInfo.sourcePort === null) {
        this.clearVisualAids();
      } else {
        if (this.link === null) {
          this.link = paper.options.defaultLink
            .clone()
            .attr({
              line: { strokeDasharray: 4 },
            })
            .addTo(paper.model);
        }

        this.inRangePort && utils.toggleMagnetHighlight([this.inRangePort.magnet, this.targetPort.magnet], false);
        utils.toggleMagnetHighlight([closestPortInfo.sourcePort.magnet, closestPortInfo.targetPort.magnet], true);

        // Need to map target point to local coordinates to account for scale and growing canvas
        const { targetPortCoordinates } = closestPortInfo;
        const localTarget = paper.paperToLocalPoint({
          x: targetPortCoordinates.x,
          y: targetPortCoordinates.y,
        });

        this.link
          .source({ id: closestPortInfo.sourcePort.elementId, port: closestPortInfo.sourcePort.portId })
          .target(localTarget);
      }

      this.inRangePort = closestPortInfo.sourcePort;
      this.targetPort = closestPortInfo.targetPort;
    },
  };
}

/**
 *
 * @param {import('jointjs').ui.stencil} stencil
 * @param {import('jointjs').dia.Paper} paper
 * @param {*} dataFlowActions
 */
export default function setupAutoconnectOnProximity(stencil, paper, dataFlowActions) {
  let autoconnectionModel;

  const onElementAdded = elementView => {
    const isElement = elementView.isElement();
    if (autoconnectionModel?.inRangePort && isElement) {
      const link = autoconnectionModel.link;
      const targetPortId = autoconnectionModel.targetPort.id;

      utils.toggleMagnetHighlight(
        [autoconnectionModel.inRangePort.magnet, autoconnectionModel.targetPort.magnet],
        false
      );
      autoconnectionModel = null;
      paper.requireView(elementView);

      paper.requireView(elementView);

      setTimeout(() => {
        link
          .attr({
            line: { strokeDasharray: 0 },
          })
          .target({
            id: elementView.id,
            port: targetPortId,
          });
        //sets temporary custom router

        link.router((vertices, opt, linkView) =>
          getRouter(vertices, { ...opt, step: 10, padding: 15, endDirections: ['left'] }, linkView)
        );

        const linkView = paper.requireView(link);
        const { model, route } = linkView;
        model.vertices(route);
        model.unset('router');

        dataFlowActions.addLink(link);

        link.on('change:vertices', function (link) {
          //updates  state when vertices are changed on new links
          link.id && dataFlowActions.updateLinkVertices(link.id, link.attributes.vertices);
        });

        link.toBack();
      });
    }
  };

  const onDragStart = cloneView => {
    const model = getElementType(cloneView.model);

    if (model?.inPorts?.length) {
      autoconnectionModel = createAutoconnectionModel(paper, cloneView);

      stencil.on('element:drag', onDrag);
    }
  };

  const onDrag = throttle(() => {
    autoconnectionModel && autoconnectionModel.onDraggingElementMove();
  }, 100);

  const onDragEnd = () => {
    stencil.off('element:drag', onDrag);
  };

  stencil.on('element:dragstart', onDragStart);
  stencil.on('element:dragend', onDragEnd);

  paper.model.on('add', onElementAdded);

  /**
   * NOTE: Workaround for fixing https://jira.bna.com/browse/BPMP-1374
   * In order to make the app show the preview for an added element
   *   this event handler must be called first so it doesn't collide with
   *   other handlers registered for the same event.
   * This will be changed as part of a future refactor.
   */
  const onAddEventHandler = paper.model._events.add.pop();

  paper.model._events.add = [onAddEventHandler, ...paper.model._events.add];

  const linksWithVerticesSettings = () => {
    stencil.off('element:dragstart', onDragStart);
    stencil.off('element:drag', onDrag);
    stencil.off('element:dragend', onDragEnd);
    stencil.off('element:drop', onElementAdded);
  };

  return linksWithVerticesSettings;
}
