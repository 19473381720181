import { getWorkbook } from '../editor/EditorPage/apis';
import { createTemplateWorkpaper } from '../home/HomePage/apis';
import { createWorkpaper } from '../home/HomePage/CreateWorkpapers/apis';

export default class WorkbookManager {
  static instance = null;

  workbookRequest = undefined;
  _statusRetryRef = null;

  /**
   * returns the shared instance of WorkbookManager
   * @returns {WorkbookManager}
   */
  static getInstance() {
    if (WorkbookManager.instance == null) {
      WorkbookManager.instance = new WorkbookManager();
      this.instance._statusRetryRef = { current: true };
    }

    return this.instance;
  }

  resetStatusRetry() {
    this._statusRetryRef.current = true;
  }

  stopStatusRetry() {
    this._statusRetryRef.current = false;
  }

  runWorkbookRequest(id, versionId) {
    this.workbookRequest = getWorkbook({ id, versionId, statusRetryRef: this._statusRetryRef });
  }

  runNewWorkbookRequest(id) {
    this.workbookRequest = createWorkpaper(id).then(() => {
      return getWorkbook({ id });
    });
  }

  runNewTemplateWorkbookRequest(id, categoryName, templateName) {
    this.workbookRequest = createTemplateWorkpaper(id, categoryName, templateName).then(() => {
      return getWorkbook({ id });
    });
  }

  getWorkbookRequest(id, versionId) {
    return (
      this.workbookRequest ||
      (this.workbookRequest = getWorkbook({ id, versionId, statusRetryRef: this._statusRetryRef }))
    );
  }
}
