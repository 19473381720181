import React, { useState } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Spinner from '../../_shared/Spinner';
import Grid from './DeletedWorkpapers/Grid';
import FeedBackDataflowModal from '../common/FeedbackDataFlowModal';
import './DeletedWorkpapers/styles.scss';

export default function DeletedWorkpapers({
  actions,
  cleanGridSelection,
  config,
  isSortingFromGrid,
  loadJobs,
  setIsSortingFromGrid,
}) {
  const { isLoading, gridData } = config;
  const { setFilter, setGridSorting, clearGridFilters } = actions;

  const [showFeedbackDataFlowModal, setShowFeedbackDataFlowModal] = useState(false);

  const showEmptyState = gridData.totalCount === 0;

  const handleFilterChange = (filter, value) => {
    setFilter(filter, value);
  };
  const handleSortChange = order => setGridSorting(order);

  return (
    <Row>
      <Col>
        <div className="wkp-deleted-wkp-section">
          {!showEmptyState && <h2>Deleted workpapers</h2>}
          <div className="wkp-grid-container">
            {showFeedbackDataFlowModal && (
              <FeedBackDataflowModal onCancelClick={() => setShowFeedbackDataFlowModal(false)} />
            )}

            {isLoading ? (
              <Spinner />
            ) : (
              <Grid
                actions={actions}
                cleanGridSelection={cleanGridSelection}
                config={config}
                isSortingFromGrid={isSortingFromGrid}
                loadJobs={loadJobs}
                setIsSortingFromGrid={setIsSortingFromGrid}
                onClearFilters={clearGridFilters}
                onFilterChange={handleFilterChange}
                onSortChange={handleSortChange}
              />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
}
