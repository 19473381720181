export default function InvalidCellReviewsPopover({ workpaperInvalidCellReviews }) {
  if (workpaperInvalidCellReviews?.length > 0) {
    return workpaperInvalidCellReviews
      .sort((a, b) => a.user.localeCompare(b.user))
      .map(({ user, invalidCount }) => (
        <p>
          {user} ({invalidCount})
        </p>
      ));
  }
  return null;
}
