export function createCopySheetCommand({ onWorkpaperCopySheetModalOpen }) {
  const copySheetCommand = {
    commandName: 'wkp.copySheet',
    execute(context, _, contextMenuArgs) {
      onWorkpaperCopySheetModalOpen();

      return true;
    },
    workArea: 'viewport',
  };

  // some buttons were permanently disabled. Refer to ticket BPMP-3924
  return {
    'wkp.copySheet': {
      text: 'Copy Sheet',
      ...copySheetCommand,
      visibleContext: 'TabStripSelected',
    },
  };
}
