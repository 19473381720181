import React, { useContext, useMemo, useCallback } from 'react';
import { BTButton, BTComboBox, BTForm } from '@btas/jasper';
import { aggregateTypes } from '../../shared/aggregateTypes';
import './FieldToAggregateRow/styles.scss';
import { DataFlowEditorContext } from '../../DataFlowEditorContext';
import { AggregateBlockErrorCodes } from '../../DataFlowOutputPreview/errorCodes';
import { useCanEditWorkflow } from '../../../../_shared/UserPermissionsContext';

const aggregateOptions = aggregateTypes.map(type => ({
  label: type,
  value: type,
}));

const FieldToAggregateRow = ({ aggregateFieldsOptions, elementId, fieldToAggregate, onUpdate, onRemove }) => {
  const { aggregateFunction } = fieldToAggregate;
  const fieldHasOriginalName = aggregateFieldsOptions.find(field => field.value === fieldToAggregate.field);
  const fieldValue = {
    label: fieldHasOriginalName ? fieldHasOriginalName.label : fieldToAggregate.field,
    value: fieldToAggregate.field,
  };
  const aggregateValue = aggregateOptions.find(option => option.value === aggregateFunction);
  const canEditWorkflow = useCanEditWorkflow();

  const { previewState } = useContext(DataFlowEditorContext);
  const { validationErrors } = previewState;

  const nonNumericFields = useMemo(
    () =>
      validationErrors
        ?.find(v => v.validationErrorCode === AggregateBlockErrorCodes.nonNumericField && v.elementId === elementId)
        ?.target.split(','),
    [elementId, validationErrors]
  );

  const isFieldNonNumeric = useMemo(
    () => nonNumericFields?.includes(fieldValue.label),
    [fieldValue.label, nonNumericFields]
  );

  const isFieldUnknown = useMemo(
    () =>
      fieldValue.label && validationErrors && !isFieldNonNumeric
        ? !aggregateFieldsOptions.some(option => option.label === fieldValue.label)
        : false,
    [aggregateFieldsOptions, fieldValue.label, isFieldNonNumeric, validationErrors]
  );

  const validationMessage = useCallback(
    value => {
      if (isFieldNonNumeric) {
        return 'Fields to aggregate must be numeric.';
      }

      if (isFieldUnknown) {
        return `Unknown field ‘${value}‘.`;
      }
      return '';
    },
    [isFieldNonNumeric, isFieldUnknown]
  );

  const onChange = key => option => onUpdate({ ...fieldToAggregate, [key]: option.value });

  return (
    <div className="wkp-field-to-aggregate-row">
      <div className="wkp-field-to-aggregate-field">
        <BTForm.FormGroup errorText={validationMessage(fieldValue.label)} hasError={isFieldNonNumeric | isFieldUnknown}>
          <BTComboBox
            popOutMenu
            disabled={!canEditWorkflow}
            hasError={isFieldNonNumeric | isFieldUnknown}
            isSearchable={true}
            maxMenuHeight={100}
            options={aggregateFieldsOptions}
            value={fieldValue}
            onChange={onChange('field')}
          />
        </BTForm.FormGroup>
      </div>
      <div className="wkp-field-to-aggregate-fn">
        <BTComboBox
          popOutMenu
          disabled={!canEditWorkflow}
          maxMenuHeight={100}
          options={aggregateOptions}
          value={aggregateValue}
          onChange={onChange('aggregateFunction')}
        />
      </div>
      {canEditWorkflow && (
        <BTButton btStyle="link" btType="delete" onClick={onRemove}>
          {' '}
        </BTButton>
      )}
    </div>
  );
};

export default FieldToAggregateRow;
