import { useContext, useEffect, useRef } from 'react';
import { dia, ui, connectionStrategies, linkTools, V } from '@clientio/rappid';
import { findUniqueElementName, getElementType } from '../shared/graphUtils';
import { restyleCell, handleNameChange } from '../shared/restyleCell';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { AnnotationElementType } from '../elementType/AnnotationElementType';
import autoconnectOnProximity from './useDataFlowEventHandlers/autoconnectOnProximity';
import { highlightElement, unhighlightElement } from '../shared/restyleCell';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';

export const useDataFlowEventHandlers = () => {
  const { dataFlowActions, dataFlowState } = useContext(DataFlowEditorContext);
  const { links, graph, paper, stencil } = dataFlowState;
  const textareaRef = useRef(null);
  const canEditWorkflow = useCanEditWorkflow();
  useEffect(() => {
    if (graph && paper) {
      const selection = new ui.Selection({
        paper: paper,
      });
      var currActiveElement;
      // Add element to state when it was added to the graph by dragging and dropping from the stencil
      graph.on('add', (cell, collection, opt) => {
        selection.collection.forEach(x => unhighlightElement(x));
        selection.collection.reset([]);
        if (opt.stencil) {
          // reset save menu state to dirty if element get drag from stencil
          dataFlowActions.resetSaveMenuDirty();
          const name = findUniqueElementName(graph, cell);
          const elementType = getElementType(cell);

          //updates dataflow state, neccesary when last active element is a text block
          dataFlowActions.clearActiveElement();

          //If textarea of any text block is displayed, we blur it
          if (textareaRef.current) {
            textareaRef.current.blur();
          }

          dataFlowActions.addElement(cell, { name });
          //sets '.name' to the cell attributes object
          //we use this to keep the element name unique among the paper
          cell.attr('.name/text', name);
          //updating the block class when dragging block from palette
          cell.attr({ '.block': { class: `block df-block-canvas df-${elementType.type}-block-canvas` } });
          setTimeout(() => {
            if (elementType.type !== AnnotationElementType.TYPE) {
              CustomLogger.start(CustomLogger.operations.BLOCK_TO_PREVIEW);

              restyleCell(cell);
            }

            if (elementType.type === AnnotationElementType.TYPE) {
              if (!canEditWorkflow) {
                return;
              }
              const cellView = paper.findViewByModel(cell);
              const freeTransform = new ui.FreeTransform({ cellView, allowRotation: false });

              freeTransform.render();
              //Updates dataflow state only for text blocks
              dataFlowActions.clearActiveElement();
            }

            dataFlowActions.setActiveElement(cell);
            currActiveElement = cell;
          });
        }
      });

      graph.on('change:attrs', (cell, _, opt) => {
        const elementType = getElementType(cell);

        if (!cell.isLink() && elementType?.type !== AnnotationElementType.TYPE) {
          handleNameChange(cell);
        } else if (elementType?.type === AnnotationElementType.TYPE) {
          if (!canEditWorkflow) {
            return;
          }
          dataFlowActions.setSaveStateDirty(true);
          dataFlowActions.resetSaveMenuDirty();
        }
      });

      graph.on('change:position change:target change:source', (cell, arrow, opt) => {
        if (currActiveElement == null) {
          // only for the link drag and move
          dataFlowActions.resetSaveMenuDirty();
        }

        if (cell.isLink() && arrow.id && arrow.port) {
          CustomLogger.start(CustomLogger.operations.BLOCK_TO_PREVIEW);
        }
      });

      graph.on('remove', function (cell, collection, opt) {
        // listen to any cell get remove
        dataFlowActions.resetSaveMenuDirty();

        if (!cell.isLink()) {
          const elementType = getElementType(cell);
          //deletes current textarea if we are deleting a text block
          if (elementType.type === AnnotationElementType.TYPE) {
            if (textareaRef.current) {
              textareaRef.current.blur();
            }
          }
        }
      });
      // Set active element when clicking on an element
      paper.on('element:pointerdown', function (cellView, evt) {
        if (evt.ctrlKey || evt.shiftKey || evt.metaKey) {
          selection.collection.add(cellView.model);
          //if there is an active element, we want to include it in the multi-select
          //then clear the active state and reset the currActiveElement var
          if (currActiveElement) {
            selection.collection.add(currActiveElement);
            ui.FreeTransform.clear(paper);
          }
          dataFlowActions.clearActiveElement();
          currActiveElement = null;
          //manually clear any Halos when using multi-select
          ui.Halo.clear(paper);
          selection.collection.forEach(x => highlightElement(x));
        } else {
          //searchs if the remove button tool is present in DOM
          let blockType;
          const removeButtonsInDOM = document.querySelectorAll(
            'g[class="joint-tool joint-theme-default"] > circle[joint-selector="button"]'
          );
          //if there is only one remove button in DOM
          if (removeButtonsInDOM?.length === 1) {
            //gets the block id that contains the remove button
            const blockId = removeButtonsInDOM[0].parentElement.getAttribute('model-id');
            //finds the element type of that block
            const elementWithButton = paper._views[blockId];
            blockType = elementWithButton.model.attributes.type;
          }
          //only dispatchs clearActiveElement action if
          //there is any text annotation showing the remove tool or
          //if there are more than one remove tool rendered
          if (removeButtonsInDOM.length > 1 || blockType === AnnotationElementType.TYPE) {
            //forces the dataflow state to change, and re render the element tools component
            //to be sure the remove button is hidden for text annotations
            dataFlowActions.clearActiveElement();
            currActiveElement = null;
          }
          selection.collection.forEach(x => unhighlightElement(x));
          selection.collection.reset([]);

          const elementType = getElementType(cellView.model);
          if (elementType.type === AnnotationElementType.TYPE) {
            if (!canEditWorkflow) {
              return;
            }
            dataFlowActions.setActiveElement(cellView.model);
            currActiveElement = cellView.model;

            const freeTransform = new ui.FreeTransform({ cellView, allowRotation: false });

            freeTransform.render();
          } else {
            CustomLogger.start(CustomLogger.operations.BLOCK_TO_PREVIEW);
            ui.FreeTransform.clear(paper);
          }
        }
      });

      paper.on('element:pointerup', function (cellView, evt) {
        const isEventKey = evt.ctrlKey || evt.shiftKey || evt.metaKey;
        const elementType = getElementType(cellView.model);
        //checks that block is not of type text annotations
        //we are already setting the active element on the element:pointerdown event for text blocks
        if (!isEventKey && !elementType.type !== AnnotationElementType.TYPE) {
          dataFlowActions.setActiveElement(cellView.model);
          currActiveElement = cellView.model;
        }
      });

      // Unselect elements with control or shift
      selection.on('selection-box:pointerdown', function (elementView, evt) {
        if (evt.ctrlKey || evt.shiftKey || evt.metaKey) {
          selection.collection.remove(elementView.model);
          unhighlightElement(elementView.model);
        }
      });

      // Set active link when clicking on a link
      paper.on('link:pointerclick', cellView => {
        if (!canEditWorkflow) {
          return;
        }
        dataFlowActions.clearActiveElement();
        currActiveElement = null;
        dataFlowActions.clearActiveLink();

        function getAbsAnchor(coords, view, magnet) {
          const end = connectionStrategies.pinAbsolute.call(paper, {}, view, magnet, coords, this.model);
          return end.anchor;
        }
        const toolsView = new dia.ToolsView({
          tools: [
            new linkTools.Segments({
              redundancyRemoval: true,
              segmentLengthThreshold: 10,
              anchor: getAbsAnchor,
            }),
          ],
        });
        cellView.addTools(toolsView);

        dataFlowActions.setActiveLink(cellView.model);
        ui.FreeTransform.clear(paper);
      });

      paper.on('link:pointerup', () => {
        dataFlowActions.setClickingCanvas(false);
      });

      paper.on('link:pointerdown', () => {
        dataFlowActions.setClickingCanvas(true);
      });

      paper.on('blank:pointerdown cell:pointerdown', function onPointerdown() {
        //filter paper views that are links and hide their tools
        const paperViews = Object.values(paper._views).filter(view => view.model.isLink());
        paperViews.forEach(el => el.hideTools());
        //If textarea is displayed, we blur it
        if (textareaRef.current) {
          textareaRef.current.blur();
        }

        dataFlowActions.clearActiveElement();
        currActiveElement = null;
      });

      // Clear active element/link when clicking directly on the canvas
      paper.on('blank:pointerdown', () => {
        dataFlowActions.setClickingCanvas(true);
        dataFlowActions.clearActiveElement();
        currActiveElement = null;

        selection.collection.forEach(x => unhighlightElement(x));
        selection.collection.reset([]);

        dataFlowActions.clearActiveLink();
      });
      paper.on('blank:pointerup', () => {
        dataFlowActions.setClickingCanvas(false);
      });

      paper.on('cell:pointermove', () => {
        if (!canEditWorkflow) {
          return;
        }
        dataFlowActions.setDraggingElementFromCanvas(true);
        dataFlowActions.setSaveStateDirty(true);
        dataFlowActions.resetSaveMenuDirty();
      });

      // Edits text when double click on annotation box
      paper.on('element:pointerdblclick', (elementView, { target }) => {
        if (!canEditWorkflow) {
          return;
        }
        const elementType = getElementType(elementView.model);

        if (elementType.type === AnnotationElementType.TYPE) {
          dataFlowActions.setActiveElement(elementView.model);
          currActiveElement = elementView.model;
          const element = elementView.model;
          const bbox = element.getBBox();
          //Creates temporary text area to enter text
          const textarea = document.createElement('textarea');
          textareaRef.current = textarea;
          // Position & Size
          textarea.style.position = 'absolute';
          textarea.style.boxSizing = 'border-box';
          textarea.style.width = bbox.width + 'px';
          textarea.style.height = bbox.height + 'px';

          // When the paper zoom is different than 1
          textarea.style.transform = V.matrixToTransformString(paper.matrix().translate(bbox.x, bbox.y));
          textarea.style.transformOrigin = '0 0';

          // Content
          const textPath = 'label/text';
          textarea.value = element.attr(textPath);

          // Styling
          textarea.style.fontSize = element.attr('label/fontSize') + 'px';
          textarea.style.fontFamily = element.attr('label/fontFamily');
          textarea.style.color = element.attr('label/fill');

          textarea.style.background = '#eeeeee';
          textarea.style.textAlign = 'left';
          textarea.style.resize = 'none';
          textarea.style.padding = '5px';

          paper.el.appendChild(textarea);
          textarea.focus();
          // Select all text
          textarea.setSelectionRange(0, textarea.value.length);
          //when user stops writing, we remove the textarea
          textarea.addEventListener('blur', function () {
            element.attr(textPath, textarea.value);
            textarea.remove();
          });

          //invoke blur function when press Enter or Escape
          textarea.addEventListener('keydown', evt => {
            if (evt.key === 'Enter' && !evt.shiftKey) {
              textarea.blur();
            }
            if (evt.key === 'Escape') {
              textarea.value = element.attr(textPath);
              textarea.blur();
            }
          });
        }
        dataFlowActions.setSaveStateDirty(true);
        dataFlowActions.resetSaveMenuDirty();
      });
    }
  }, [dataFlowActions, paper, graph, canEditWorkflow]);

  useEffect(() => {
    let currenTimeout = null;
    if (currenTimeout) {
      clearTimeout(currenTimeout);
    }
    if (paper) {
      // Add a new link
      paper.on('cell:pointerup', cellView => {
        dataFlowActions.setDraggingElementFromCanvas(false);
        if (cellView.model.isLink()) {
          const linkCell = cellView.model;
          if (linkCell.attributes.source?.port && linkCell.attributes.target?.port) {
            // BPMP-3895: For blocks with multiple outputs ports, we also need to verify if each port is
            //            already connected to a block with multiple input ports. If not then we add the link
            const linkExists = links.find(
              l =>
                l.sourceId === linkCell.attributes.source.id &&
                l.targetId === linkCell.attributes.target.id &&
                l.sourcePort === linkCell.attributes.source.port &&
                l.targetPort === linkCell.attributes.target.port
            );

            if (!linkExists) {
              const linkView = paper.requireView(linkCell);
              const { model, route } = linkView;
              model.vertices(route);
              model.unset('router');

              dataFlowActions.addLink(linkCell);

              linkCell.on('change:vertices', function (link) {
                //updates state when vertices are changed on new links
                dataFlowActions.updateLinkVertices(link.id, link.attributes.vertices);
              });

              linkCell.toBack();

              currenTimeout = setTimeout(() => {
                const targetModel = graph.getElements().find(e => e.id === linkCell?.attributes?.target?.id);
                dataFlowActions.setActiveElement(targetModel);
              }, 100);
            }
          }
        }
      });

      return () => {
        paper.off('cell:pointerup');
      };
    }
  }, [dataFlowActions, graph, links, paper]);

  useEffect(() => {
    if (dataFlowActions && paper && graph && stencil) {
      return autoconnectOnProximity(stencil, paper, dataFlowActions);
    }
  }, [dataFlowActions, paper, graph, stencil]);
};
