import React, { useEffect, useState, useRef, useMemo } from 'react';
import ElementInspector from './shared/ElementInspector';
import { BTForm, BTFormControlStatic, BTInput } from '@btas/jasper';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_CONFIG_PANEL_PAGINATION } from '../../../constants/featureFlags';
import { getPaginationProps } from './shared/utils/PaginationUtils';
import { PaginationContainer } from './shared/PaginationContainer';
import { INSPECTOR_PAGE_SIZE } from './shared/constants/pagination';

const ReorderElementInspector = ({ elementData, elementId, elementType, updateData, isDirty }) => {
  const [previewFields, setPreviewFields] = useState([]);
  const [page, setPage] = useState(1);
  const paginationEnabled = isFeatureFlagEnabled(WKP_CONFIG_PANEL_PAGINATION);
  const totalFields = elementData?.fields?.length;
  const { pageSize, startIndex, endIndex } = useMemo(() => {
    return getPaginationProps(totalFields, paginationEnabled, page);
  }, [page, totalFields, paginationEnabled]);
  const newOrder = useRef(null);

  useEffect(() => {
    if (elementData?.sourceDataFields.length === 0) {
      setPreviewFields([]);
    } else {
      if (newOrder.current && newOrder.current.length > 0) {
        setPreviewFields(newOrder.current.slice(startIndex, endIndex));
      } else if (elementData?.newFieldOrder && elementData?.newFieldOrder.length > 0) {
        setPreviewFields(elementData?.newFieldOrder.slice(startIndex, endIndex));
      } else {
        setPreviewFields(elementData?.fields.slice(startIndex, endIndex));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementData?.fields, elementData?.sourceDataFields, page, newOrder]);

  const handleFieldListRender = () => {
    if (newOrder.current.length !== 0) {
      setPreviewFields(newOrder.current.slice(startIndex, endIndex));
    }
  };

  const handleFieldOrder = (value, field, currentIndex) => {
    const currentFieldOrder = elementData?.newFieldOrder?.length > 0 ? elementData?.newFieldOrder : elementData?.fields;
    if (value <= 0 || value >= currentFieldOrder.length) {
      value = value <= 0 ? 1 : currentFieldOrder.length;
    }
    const newPosition = value === '' ? currentIndex : value - 1;

    const newFieldOrder = currentFieldOrder.filter(item => item?.name !== field?.name);
    newFieldOrder.splice(newPosition, 0, field);
    newOrder.current = newFieldOrder;
    updateData({ newFieldOrder: newFieldOrder });
  };

  const canEditWorkflow = useCanEditWorkflow();

  return (
    <ElementInspector {...{ elementData, elementId, elementType, updateData, isDirty }}>
      <BTForm>
        <BTForm.FormGroup>
          <p>Enter the desired position for each field.</p>
          {previewFields?.map((field, index) => {
            const fieldName = field?.original_name ? field?.original_name : field?.name;
            return (
              <div key={`${index}_${fieldName}`}>
                <BTFormControlStatic>
                  <BTInput
                    disabled={!canEditWorkflow}
                    inputType="number"
                    max={elementData?.fields?.length}
                    min={1}
                    placeholder={index + 1 + (page - 1) * pageSize}
                    size="compact"
                    onBlur={e => {
                      handleFieldListRender();
                      e.target.value = '';
                    }}
                    onChange={e => handleFieldOrder(e.target.value, field, index)}
                  />
                  {` ${fieldName}`}
                </BTFormControlStatic>
              </div>
            );
          })}
        </BTForm.FormGroup>
      </BTForm>
      {paginationEnabled && totalFields > INSPECTOR_PAGE_SIZE && (
        <PaginationContainer
          endIndex={endIndex}
          page={page}
          setPage={setPage}
          startIndex={startIndex}
          totalFields={totalFields}
        />
      )}
    </ElementInspector>
  );
};

export default ReorderElementInspector;
