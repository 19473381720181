import React, { useEffect, useMemo, useRef } from 'react';

import ElementInspector from './shared/ElementInspector';
import { LeftRightFieldsSelector } from './shared/LeftRightFieldsSelector';
import { useDebouncedEffect } from './shared/useDebouncedEffect';
import { ColumnMapping } from './JoinElementInspector/ColumnMapping';
import useValidateFieldNamesAreUnique from './JoinElementInspector/useValidateFieldNamesAreUnique';
import { determineFieldOptions } from './elementType/types/shared/utils';

import { getOriginalName } from '../shared/utils/FieldHashUtils';
import { BTCheckbox } from '@btas/jasper';
import Spinner from '../../_shared/Spinner';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';

export const JoinInput = Object.freeze({
  A: 'A',
  B: 'B',
});

export const JoinOutput = Object.freeze({
  A: 'a',
  J: 'J',
  B: 'b',
});

export const JoinElementInspector = ({ elementData, elementType, updateData, elementId, inputElements, isDirty }) => {
  const elementDataRef = useRef(null);
  const canEditWorkflow = useCanEditWorkflow();

  useEffect(() => {
    if (elementDataRef.current !== elementData) {
      elementDataRef.current = elementData;
    }
  }, [elementDataRef, elementData]);

  const leftFields = useMemo(() => inputElements?.A?.elementData?.fields ?? [], [inputElements]);
  const rightFields = useMemo(() => inputElements?.B?.elementData?.fields ?? [], [inputElements]);

  const keyFields = elementData.keyFields ?? [];
  const picked = elementData.picked ?? { A: {}, B: {} };
  const renamed = elementData.renamed ?? { A: {}, B: {} };
  const outputToUnion = elementData.outputToUnion ?? { A: true, B: true };

  let keyFieldsInLeft = [];
  let keyFieldsInRight = [];

  const isLoading = keyFields
    .map(field => {
      if (field.left && field.right) {
        keyFieldsInLeft.push(field.left.name);
        keyFieldsInRight.push(field.right.name);
        return 'noNull';
      } else {
        return null;
      }
    })
    .includes(null);

  const { validateFieldNames, isFieldNameValid, hasDuplicateErrors } = useValidateFieldNamesAreUnique();

  useDebouncedEffect(() => validateFieldNames(picked, renamed), 350, [elementData.picked, elementData.renamed]);

  const setKeyFields = newKeyFields => {
    updateData({ keyFields: newKeyFields });
  };

  const setPicked = (joinSide, fieldName, isPicked) => {
    const newPicked = {
      ...picked,
      [joinSide]: {
        ...picked[joinSide],
        [fieldName]: isPicked,
      },
    };
    updateData({ picked: newPicked });
  };

  const setRenamed = (joinSide, fieldName, renameTo) => {
    const newRenamed = {
      ...renamed,
      [joinSide]: {
        ...renamed[joinSide],
        [fieldName]: renameTo,
      },
    };
    updateData({ renamed: newRenamed });
  };

  const handleCheckboxUnionChange = ({ target }) => {
    const { id, checked } = target;
    const newValue = {
      ...outputToUnion,
      [id]: checked,
    };
    updateData({ outputToUnion: newValue });
  };

  const alerts = useMemo(() => {
    if (hasDuplicateErrors) {
      return [{ style: 'danger', content: 'Column name must be unique.' }];
    }
    return [];
  }, [hasDuplicateErrors]);

  const mappings = useMemo(
    () =>
      elementData.keyFields.reduce(
        (acum, mapping) => {
          acum.left.push({
            name: mapping.left?.name,
            original_name: mapping.left?.original_name,
            type: mapping.left?.type,
          });

          acum.right.push({
            name: mapping.right?.name,
            original_name: mapping.right?.original_name,
            type: mapping.right?.type,
          });

          return acum;
        },
        { left: [], right: [] }
      ),
    [elementData]
  );

  const leftFieldOptions = useMemo(() => determineFieldOptions(leftFields, mappings.left), [leftFields, mappings.left]);
  const rightFieldOptions = useMemo(
    () => determineFieldOptions(rightFields, mappings.right),
    [rightFields, mappings.right]
  );

  const renderColumnMapping = (field, joinSide) => (
    <ColumnMapping
      key={`${joinSide}${field.name}`}
      isPicked={picked[joinSide][field.name]}
      isValid={isFieldNameValid(getOriginalName(field), renamed[joinSide][field.name])}
      joinSide={joinSide}
      keyFieldsInLeft={keyFieldsInLeft}
      name={field.name}
      original_name={getOriginalName(field)}
      renameTo={renamed[joinSide][field.name]}
      setPicked={setPicked}
      setRenamed={setRenamed}
    />
  );

  const leftFieldsMapped = leftFields.map(field => renderColumnMapping(field, 'A'));

  const rightFieldsMapped = rightFields
    .filter(field => !keyFieldsInRight.includes(field.name))
    .map(field => renderColumnMapping(field, 'B'));

  const [leftInputName, rightInputName] = [
    `Input A${inputElements?.A?.elementData?.name ? ` (${inputElements?.A?.elementData?.name})` : ''}`,
    `Input B${inputElements?.B?.elementData?.name ? ` (${inputElements?.B?.elementData?.name})` : ''}`,
  ];

  const leftRightFieldsSelectors = useMemo(
    () => (
      <LeftRightFieldsSelector
        leftFields={leftFieldOptions}
        leftLabel={leftInputName}
        mappings={keyFields}
        rightFields={rightFieldOptions}
        rightLabel={rightInputName}
        setMappings={setKeyFields}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementData, leftFieldOptions, rightFieldOptions]
  );

  return (
    <ElementInspector {...{ elementData, elementId, elementType, updateData, isDirty, alerts }}>
      <div className="wkp-join-element-inspector">
        <div>
          <p>Select the field(s) which contain the common identifier between the two inputs.</p>
          {leftRightFieldsSelectors}
        </div>
        <div>
          <BTCheckbox
            checked={outputToUnion.A}
            disabled={!canEditWorkflow}
            id={'A'}
            label="Include records that exist in 'Output A' as part of 'Output J'"
            onChange={handleCheckboxUnionChange}
          />
          <BTCheckbox
            checked={outputToUnion.B}
            disabled={!canEditWorkflow}
            id={'B'}
            label="Include records that exist in 'Output B' as part of 'Output J'"
            onChange={handleCheckboxUnionChange}
          />
        </div>
        <h5>Output J Columns</h5>
        {isLoading ? (
          <Spinner />
        ) : (
          <table className="wkp-fields-list">
            <thead>
              <tr>
                <th />
                <th>Input</th>
                <th>Column</th>
                <th>Rename</th>
              </tr>
            </thead>
            <tbody>
              {leftFieldsMapped}
              {rightFieldsMapped}
            </tbody>
          </table>
        )}
      </div>
    </ElementInspector>
  );
};
