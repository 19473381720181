import checkCircle from '../cellReview/circle-check.svg';
import exclamationCircle from '../cellReview/circle-red-exclamation.svg';
import incomingValueIndicator from '../../cellOverrides/incoming-value-indicator.svg';

export function exportIcons() {
  const iconStyles = {
    marginLeft: 3,
    marginTop: 3,
    padding: '0 0 0 20',
  };

  const checkIconImage = new Image(13, 13);
  checkIconImage.src = checkCircle;

  const checkExclamationIconImage = new Image(13, 13);
  checkExclamationIconImage.src = exclamationCircle;

  const incomingValueIndicatorImage = new Image(30, 30);
  incomingValueIndicatorImage.src = incomingValueIndicator;

  const checkIcon = { ...iconStyles, image: checkIconImage };
  const checkExclamationIcon = { ...iconStyles, image: checkExclamationIconImage };
  const incomingValueIndicatorMark = { image: incomingValueIndicatorImage };

  return { checkIcon, checkExclamationIcon, incomingValueIndicatorMark };
}
