import * as fetch from '../../../../../_shared/fetch';
import { dataFlowServiceHostName, spreadsheetServiceHostName } from '../../../../../../configs/params';
import { generalErrorMessage } from '../../../../../_shared/messages';

/**
 * @returns {Promise<{
 *  name: string;
 *  dataFlows: {
 *    id: string;
 *    name: string;
 *    outputs: {
 *      id: string;
 *      name: string;
 *      sourceDataKeys: object;
 *      outputFormat: string;
 *      outputField: string;
 *    }[];
 *    inputs: {
 *      id: string;
 *      name: string;
 *    }[];
 *    taxPeriod: string
 *  }[]
 * }>}
 */
export async function getDataFlows() {
  const response = await fetch.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/published?excludeTabularConnections=true&parseDataFlowsForClient=true`
  );
  const data = await response.json();
  return data;
}

/**
 * @param {string} outputId
 * @returns {Promise<{
 *  dataFlowId: string,
 *  dataFlowName: string,
 *  taxPeriod: string,
 *  published: boolean
 * } | null>}
 */
export async function getDataFlowByOutputId(outputId) {
  const r = await fetch.get(`${dataFlowServiceHostName}/api/v1/data-flows/outputs/${outputId}/data-flow-info`);

  if (r.status === 404) {
    return {
      connectionError: true,
      nullOutputId: true,
    };
  } else if (r.status === 200) {
    const response = await r.json();
    return {
      connectionError: !response.published,
      ...response,
    };
  } else {
    return await r.json();
  }
}

export async function getDataFlowAvailableCriteria(dataFlowId, outputId) {
  const response = await fetch.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/outputs/${outputId}/available-criteria`
  );
  const data = await response.json();

  return data;
}

export async function getDataFlowAvailableValuesByFilter(dataFlowId, outputId, filterName, options) {
  const { page, limit, search } = options;
  let url = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/outputs/${outputId}/filter/${
    filterName && encodeURIComponent(filterName)
  }?page=${page}&limit=${limit}`;

  if (search.trim().length) {
    url += `&searchExpr=${encodeURIComponent(search)}`;
  }

  const response = await fetch.get(url);
  const data = await response.json();

  return data;
}

export async function putTaxPeriodMetadata({ id, taxPeriod }) {
  try {
    const url = `${spreadsheetServiceHostName}/spreadsheet/metadata/${id}`;

    const response = await fetch.put(url, {
      body: JSON.stringify({ taxPeriod }),
    });

    const data = await response.json();
    if (!response.ok) {
      throw data;
    }
    return data;
  } catch (error) {
    let mappedError = [];
    if (error && error.invalidParams) {
      const invalidParams = JSON.parse(error.invalidParams);
      for (const item in invalidParams) {
        mappedError.push({
          target: item,
          message: invalidParams[item][0],
        });
      }
    } else {
      mappedError.push({ code: 'SERVER_ERROR', message: generalErrorMessage });
    }
    throw mappedError;
  }
}
