import { getOriginalName } from '../../shared/utils/FieldHashUtils';
import { operators } from './filterOperators';

const { Equal, NotEqual, Contains, IsOneOf, IsBlank, IsNotBlank, IsError, IsNotError } = operators;

function formulaFromOneCriteria(criteriaItem, matchField) {
  let formulaExpression = '';
  if (criteriaItem.field) {
    formulaExpression += '[' + criteriaItem.field + ']';
  }
  if (criteriaItem.operator) {
    if (criteriaItem.operator.toUpperCase() === IsBlank.toUpperCase()) {
      return `""${Equal}${formulaExpression}`;
    } else if (criteriaItem.operator.toUpperCase() === IsNotBlank.toUpperCase()) {
      return `""${NotEqual}${formulaExpression}`;
    } else if (criteriaItem.operator.toUpperCase() === IsError.toUpperCase()) {
      return `ISERROR(${formulaExpression})`;
    } else if (criteriaItem.operator.toUpperCase() === IsNotError.toUpperCase()) {
      return `NOT(ISERROR(${formulaExpression}))`;
    } else {
      formulaExpression += criteriaItem.operator;
    }
  }
  if (criteriaItem.filterValue) {
    if (matchField?.type === 'text' || matchField?.type === 'date') {
      formulaExpression += '"' + criteriaItem.filterValue + '"';
    } else {
      formulaExpression += criteriaItem.filterValue;
    }
  }
  return formulaExpression;
}

function formulaFromIsOneOfCriteria(criteriaItem, matchField) {
  if (!criteriaItem.filterValue) {
    return '';
  }

  // The regex is to split the filter value by the commas while ignoring commas that
  // are part of one of the filter criteria.
  // Example '"123,456","789"' should be split up into the array ["123,456","789"]
  // https://stackoverflow.com/questions/11456850/split-a-string-by-commas-but-ignore-commas-within-double-quotes-using-javascript
  const orCriteria = criteriaItem.filterValue.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);

  if (orCriteria.length === 1 || !criteriaItem.filterValue.includes('","')) {
    if (!criteriaItem.filterValue.includes('","'))
      return formulaFromOneCriteria(
        {
          field: criteriaItem.field,
          operator: '=',
          filterValue: criteriaItem.filterValue,
        },
        matchField
      );
  }

  const formulaExpression = [];
  orCriteria.forEach(item => {
    const formula = formulaFromOneCriteria(
      {
        field: criteriaItem.field,
        operator: '=',
        filterValue: item.slice(1, -1),
      },
      matchField
    );
    formulaExpression.push(formula);
  });
  return 'OR(' + formulaExpression.join(',') + ')';
}

export default function generateFormula(filterCriteria, fields) {
  if (!filterCriteria || Object.keys(filterCriteria).length === 0) {
    return '';
  }

  const formulaCriteria = [];
  filterCriteria.forEach(criteriaItem => {
    const matchField = fields?.find(field => getOriginalName(field) === criteriaItem.field);
    let formulaExpression = '';

    if (criteriaItem?.operator && criteriaItem.operator.toUpperCase() === Contains.toUpperCase()) {
      formulaExpression += 'CONTAINS("' + criteriaItem.filterValue + '",[' + criteriaItem.field + '], TRUE)';
    } else if (criteriaItem?.operator && criteriaItem.operator.toUpperCase() === IsOneOf.toUpperCase()) {
      formulaExpression += formulaFromIsOneOfCriteria(criteriaItem, matchField);
    } else {
      formulaExpression += formulaFromOneCriteria(criteriaItem, matchField);
    }
    formulaCriteria.push(formulaExpression);
  });
  if (formulaCriteria.length > 1) {
    let listOfForumlas = [];
    formulaCriteria.forEach(formula => {
      if (formula) {
        listOfForumlas.push(formula);
      }
    });
    if (listOfForumlas.length <= 1) {
      return listOfForumlas[0];
    } else {
      return `AND(${listOfForumlas.join(',')})`;
    }
  }
  return formulaCriteria[0];
}
