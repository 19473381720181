import { useState, useCallback, useEffect, useRef, useContext } from 'react';
import UserPreferencesContext from '../../_shared/UserPreferencesContext';
import { getList, getFilterOptions, getStatusCountList } from './apis';

const defaultSort = { binding: 'name', direction: 'ASC' };
const initialFilterOptions = {
  workpaperTypes: [],
  entities: [],
  taxPeriods: [],
  lastModifiedUsers: [],
  jurisdictions: [],
  isGlobalTemplate: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
};
const initialGridData = { items: [], totalCount: 0, filterOptions: initialFilterOptions };

export default function useGrid(isSortingFromGrid) {
  const { userPreferences, setUserPreferences } = useContext(UserPreferencesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [gridData, setGridData] = useState(initialGridData);
  const [gridSorting, setGridSorting] = useState(() => {
    if (userPreferences.workpaperColumnSort) {
      return userPreferences.workpaperColumnSort;
    }
    return defaultSort;
  });
  const [statusCount, setStatusCount] = useState();
  const [totalCount, setTotalCount] = useState();

  const { workpapersTaxPeriod: initialTaxPeriod } = userPreferences;

  const initialFilters = {
    name: '',
    entity: null,
    taxPeriod: initialTaxPeriod,
    workpaperType: null,
    status: null,
    lastModifiedUser: null,
    lastModifiedDateFrom: null,
    lastModifiedDateTo: null,
    isGlobalTemplate: null,
  };

  const [gridFilters, setGridFilters] = useState(initialFilters);
  const timerHandleFilterChange = useRef();

  const fetchWorkpapers = useCallback(async function ({ newItems, sorting = defaultSort, filters = initialFilters }) {
    const gridData = await getList({
      sorting,
      filters: {
        ...filters,
        name: filters.name ? filters.name : null,
        entity: filters.entity?.value,
        taxPeriod: filters.taxPeriod?.value,
        workpaperType: filters.workpaperType?.value,
        status: filters.status?.value,
        lastModifiedUser: filters.lastModifiedUser?.value,
        jurisdiction: filters.jurisdiction?.value,
        isGlobalTemplate: filters.isGlobalTemplate?.value,
      },
    });

    if (!isSortingFromGrid) {
      getStatusCountList(filters.taxPeriod).then(statusObject => {
        setTotalCount(statusObject.total);
        setStatusCount(statusObject.categories);
      });
    }

    const filterOptionsData = await getFilterOptions();
    const unassignedFilter = { value: null, label: '[Unassigned]' };

    const filterOptions = {
      statuses: filterOptionsData.statuses.map(v => ({ value: v, label: v })),
      workpaperTypes: filterOptionsData.workpaperTypes.map(v => ({ value: v, label: v })),
      entities: filterOptionsData.entities.map(v => ({ value: v, label: v })),
      taxPeriods: filterOptionsData.taxPeriods.map(v => (v ? { value: v, label: v } : unassignedFilter)),
      lastModifiedUsers: filterOptionsData.lastModifiedUsers.map(v => ({ value: v, label: v })),
      jurisdictions: filterOptionsData.jurisdictions.map(v => ({ value: v, label: v })),
      isGlobalTemplate: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    };

    // sort array to show unassigned first if it has been added to the filters
    if (filterOptions.taxPeriods.includes(unassignedFilter)) {
      filterOptions.taxPeriods = filterOptions.taxPeriods.filter(({ value }) => value !== null);
      filterOptions.taxPeriods.unshift(unassignedFilter);
    }

    if (newItems) {
      setGridData({
        ...gridData,
        items: gridData.items.map(i => ({ ...i, isNew: newItems.indexOf(i.id) >= 0 })),
        filterOptions,
      });
    } else {
      setGridData({ ...gridData, filterOptions });
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timerHandleFilterChange.current) {
      clearTimeout(timerHandleFilterChange.current);
    }

    timerHandleFilterChange.current = setTimeout(() => {
      fetchWorkpapers({ sorting: gridSorting, filters: gridFilters });
      timerHandleFilterChange.current = null;
    }, 250);
    // intentionally I don't want to put gridSorting as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridFilters]);

  useEffect(() => {
    if (timerHandleFilterChange.current) {
      // clear timeout if trying to filter and sort at the same time
      clearTimeout(timerHandleFilterChange.current);
    }

    if (gridSorting) {
      fetchWorkpapers({ sorting: gridSorting, filters: gridFilters });
    }
    // intentionally I don't want to put gridFilters as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchWorkpapers, gridSorting]);

  const state = {
    isLoading,
    defaultSort,
    gridData,
    gridFilters,
    gridSorting,
    initialFilters,
    initialTaxPeriod,
  };

  const setFilter = (filter, value) => {
    setGridFilters(prev => ({ ...prev, [filter]: value }));
  };

  const clearGridFilters = () => {
    setGridFilters(initialFilters);
  };

  const cleanGridSelection = () => {
    setGridData({
      ...gridData,
      items: gridData.items.map(i => ({ ...i, timeStamp: new Date() })),
    });
  };

  const actions = {
    setFilter,
    clearGridFilters,
    setIsLoading,
    setGridSorting(order) {
      setGridSorting(order);
      setUserPreferences({
        ...userPreferences,
        workpaperColumnSort: order,
      });
    },
    fetchWorkpapers,
    setTaxPeriod(taxPeriod) {
      setUserPreferences({
        ...userPreferences,
        workpapersTaxPeriod: taxPeriod,
      });
    },
    cleanGridSelection,
  };

  return [state, actions, statusCount, totalCount];
}
