export const operators = {
  Equal: '=',
  NotEqual: '<>',
  LessThan: '<',
  GreaterThan: '>',
  LessOrEqual: '<=',
  GreaterOrEqual: '>=',
  Contains: 'Contains',
  IsOneOf: 'Is one of',
  IsBlank: 'Is blank',
  IsNotBlank: 'Is not blank',
  IsError: 'Is error',
  IsNotError: 'Is not error',
};
