import { BTModal, BTButton } from '@btas/jasper';
import { useHistory } from 'react-router-dom';
import FeedbackDataFlow from './FeedBackDataFlowModal/Feedback_Data_Flow.png';

export default function FeedBackDataflowModal({ onCancelClick }) {
  const history = useHistory();

  return (
    <BTModal id="wkp-modal-feedback" show={true} title={'Cannot Perform Action'} onCloseClick={onCancelClick}>
      <BTModal.Body className="wkp-modal-body">
        <p style={{ fontSize: '18px', textAlign: 'justify', margin: '5px' }}>
          The file you're attempting to upload is either too large (over {process.env.REACT_APP_MAX_FILE_SIZE} Mb) or
          too complex to process. For best results, we recommend using our <b>data transformation tool</b>, which is
          better suited for handling complex files.
        </p>
        <div style={{ textAlign: 'center', margin: '2rem' }}>
          <img alt="Data_Flow_FeedBack" src={FeedbackDataFlow} style={{ width: '100%' }}></img>
        </div>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onCancelClick}>CLOSE</BTButton>
        <BTButton btStyle="primary" onClick={() => history.push('/data-flows')}>
          GO TO DATA TRANSFORMATIONS
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
