export const LINK_COLOR = 'grey';
export const IN_OUT_COLOR = '#02851b';
export const PORT_COLOR = '#72add4';
export const INPUT_PORT_FILL_COLOR = 'white';
export const LABEL_COLOR = 'white';
export const FILTER_COLOR = '#004098';
export const FORMULA_COLOR = '#724190';
export const LOOKUP_COLOR = '#b09b00';
export const JOIN_COLOR = '#b09b00';
export const MERGE_COLOR = '#b09b00';
export const REORDER_COLOR = '#004098';
