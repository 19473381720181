import { useState, useCallback, useEffect, useRef, useContext } from 'react';
import UserPreferencesContext from '../../_shared/UserPreferencesContext';
import { getList, getFilterOptions } from './apis';

const defaultSort = { binding: 'name', direction: 'ASC' };
const initialFilterOptions = {
  workpaperTypes: [],
  entities: [],
  taxPeriods: [],
  lastModifiedUsers: [],
  jurisdictions: [],
  isGlobalTemplate: [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ],
};
const initialGridData = { items: [], totalCount: 0, filterOptions: initialFilterOptions };

export default function useGrid() {
  const { userPreferences } = useContext(UserPreferencesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [gridData, setGridData] = useState(initialGridData);
  const [gridSorting, setGridSorting] = useState(defaultSort);

  const { workpapersTaxPeriod: initialTaxPeriod } = userPreferences;

  const initialFilters = {
    name: '',
    period: initialTaxPeriod,
    deletedBy: '',
    deletedOn: null,
    pageNumber: 1,
    pageSize: 100,
  };

  const [gridFilters, setGridFilters] = useState(initialFilters);
  const [totalCount, setTotalCount] = useState();
  const timerHandleFilterChange = useRef();

  const fetchWorkpapers = useCallback(async function ({ newItems, sorting = defaultSort, filters = initialFilters }) {
    const gridData = await getList({
      sorting,
      filters: {
        ...filters,
        name: filters.name ?? null,
        period: filters.taxPeriod,
        deletedBy: filters.deletedBy,
        deletedOn: filters.deletedOn,
      },
    });

    setTotalCount(gridData.recycleItems.totalCount);

    const unassigenedValue = 'unassigned';
    const filterOptionsData = await getFilterOptions();
    const unassignedFilter = { value: unassigenedValue, label: '[Unassigned]' };

    const filterOptions = {
      taxPeriods: filterOptionsData.taxPeriods.map(v => (v ? { value: v, label: v } : unassignedFilter)),
    };

    // sort array to show unassigned first if it has been added to the filters
    if (filterOptions.taxPeriods.includes(unassignedFilter)) {
      filterOptions.taxPeriods = filterOptions.taxPeriods.filter(({ value }) => value !== unassigenedValue);
      filterOptions.taxPeriods.unshift(unassignedFilter);
    }

    if (newItems) {
      setGridData({
        ...gridData,
        items: gridData.items.map(i => ({ ...i, isNew: newItems.indexOf(i.id) >= 0 })),
        filterOptions,
      });
    } else {
      setGridData({ ...gridData, filterOptions });
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timerHandleFilterChange.current) {
      clearTimeout(timerHandleFilterChange.current);
    }

    timerHandleFilterChange.current = setTimeout(() => {
      fetchWorkpapers({ sorting: gridSorting, filters: gridFilters });
      timerHandleFilterChange.current = null;
    }, 250);
    // intentionally I don't want to put gridSorting as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridFilters]);

  useEffect(() => {
    if (timerHandleFilterChange.current) {
      // clear timeout if trying to filter and sort at the same time
      clearTimeout(timerHandleFilterChange.current);
    }

    if (gridSorting) {
      fetchWorkpapers({ sorting: gridSorting, filters: gridFilters });
    }
    // intentionally I don't want to put gridFilters as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchWorkpapers, gridSorting]);

  const state = {
    isLoading,
    defaultSort,
    gridData,
    gridFilters,
    gridSorting,
    initialFilters,
    initialTaxPeriod,
    totalCount,
  };

  const setFilter = (filter, value) => {
    const newFilters = { ...gridFilters, [filter]: value };
    setGridFilters(newFilters);
  };

  const clearGridFilters = () => {
    setGridFilters(initialFilters);
  };

  const cleanGridSelection = () => {
    setGridData({
      ...gridData,
      items: gridData.items.map(i => ({ ...i, timeStamp: new Date() })),
    });
  };

  const actions = {
    setFilter,
    clearGridFilters,
    setIsLoading,
    setGridSorting,
    fetchWorkpapers,
    cleanGridSelection,
  };

  return [state, actions];
}
