import React, { useContext, useState, useEffect, useMemo } from 'react';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import FilePropertiesPanel from '../FileSettings/FilePropertiesPanel';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { WKP_INGEST_REWRITE, WKP_INPUT_FILE_IMPORT } from '../../../../constants/featureFlags';
import { useHistory } from 'react-router-dom';
import FileDataDialog from '../FileDialog/FileDataDialog';
import GeneralErrorMessage from '../../../_shared/GeneralErrorMessage';
import {
  useJobProcessor,
  JOB_PROCESSOR_STATUS_COMPLETED,
  JOB_PROCESSOR_STATUS_FAILED,
} from '../../DataFlowJobsContext';
import { useInputSourceFiles } from './useInputSourceFiles';

const WKPImportSubInspector = ({ elementId, elementData, elementType, updateData, isDirty }) => {
  const history = useHistory();
  const { dataFlowState, sourceFileUpload, dataFlowActions } = useContext(DataFlowEditorContext);
  const { workingElement, id: dataflowId, filePropertiesDialog } = dataFlowState;
  const { uploadError, uploadedFilesByInput, setUploadedFilesByInput } = sourceFileUpload;
  const [generalError, setGeneralError] = useState();
  const { jobs } = useJobProcessor();

  const { sourceFiles, setSourceFiles } = useInputSourceFiles(
    dataFlowState.id,
    elementId,
    elementData?.pendingSourceFileVersionId,
    filePropertiesDialog?.reloadFiles
  );

  const filePropertiesPanel = useMemo(
    () => (
      <FilePropertiesPanel
        dataFlowActions={dataFlowActions}
        dataFlowState={dataFlowState}
        elementData={elementData}
        elementId={elementId}
        setSourceFiles={setSourceFiles}
        sourceFiles={sourceFiles}
        updateData={updateData}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementId, elementData, updateData, sourceFiles, setSourceFiles]
  );

  useEffect(() => {
    if (isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT)) {
      const uploadedFile = uploadedFilesByInput && uploadedFilesByInput[elementId];

      if (isFeatureFlagEnabled(WKP_INGEST_REWRITE)) {
        const uploadJob = jobs.find(job => job.processId === elementId);
        if (uploadJob && uploadedFile) {
          if (uploadedFile.status === 'uploading') {
            switch (uploadJob.status) {
              case JOB_PROCESSOR_STATUS_COMPLETED:
                uploadedFile.status = 'completed';
                break;
              case JOB_PROCESSOR_STATUS_FAILED:
                uploadedFile.status = 'error';
                break;
              default:
                break;
            }
          }
        }
      }

      //Add WKP-IMPORT integrationType to render FilePropertiesPanel
      if (uploadedFile && uploadedFile.status !== 'error') {
        updateData({
          ...elementData,
          integrationType: 'WKP-IMPORT',
          uploadedFile,
        });
      }

      if (uploadedFile && uploadedFile.status === 'error') {
        updateData({
          ...elementData,
          integrationType: '',
          uploadedFile: undefined,
        });

        const newUploadedFiles = uploadedFilesByInput;
        delete newUploadedFiles[workingElement.id];
        setUploadedFilesByInput(newUploadedFiles);

        history.replace(`/data-flows/${dataflowId}/editor`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFilesByInput, elementData, updateData, elementId, uploadError, setUploadedFilesByInput, workingElement]);

  return (
    <div className="wkp-input-element-inspector">
      {isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && elementData?.uploadedFile && filePropertiesPanel}
      {isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
        <FileDataDialog
          dataFlowActions={dataFlowActions}
          dataFlowState={dataFlowState}
          elementData={elementData}
          setGeneralError={setGeneralError}
        />
      )}
      {isFeatureFlagEnabled(WKP_INPUT_FILE_IMPORT) && (
        <GeneralErrorMessage dismissible={true} visible={!!generalError} />
      )}
    </div>
  );
};

export default WKPImportSubInspector;
