import React, { useRef } from 'react';
import { LeftRightFieldsSelector } from './shared/LeftRightFieldsSelector';
import ElementInspector from './shared/ElementInspector';
import { determineFieldOptions } from './elementType/types/shared/utils';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';
import { BTCheckbox } from '@btas/jasper';
import { PaginationContainer } from './shared/PaginationContainer';
import { INSPECTOR_PAGE_SIZE } from './shared/constants/pagination';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_CONFIG_PANEL_PAGINATION } from '../../../constants/featureFlags';
import { useMappingPagination } from './shared/useMappingPagination';

export const MergeInput = Object.freeze({
  A: 'left',
  B: 'right',
});

export const MergeElementInspector = ({ elementData, elementType, updateData, elementId, inputElements, isDirty }) => {
  const canEditWorkflow = useCanEditWorkflow();
  const leftFields = inputElements?.left?.elementData?.fields ?? [];
  const rightFields = inputElements?.right?.elementData?.fields ?? [];
  const checkPreviewUnmappedColumns = elementData?.previewUnmappedColumns;
  const [leftInputName, rightInputName] = [
    `Input A${inputElements?.left?.elementData?.name ? ` (${inputElements?.left?.elementData?.name})` : ''}`,
    `Input B${inputElements?.right?.elementData?.name ? ` (${inputElements?.right?.elementData?.name})` : ''}`,
  ];

  const isPaginationEnabled = useRef(isFeatureFlagEnabled(WKP_CONFIG_PANEL_PAGINATION));
  const allMappings = elementData.mappings || [];

  const { page, setPage, total, mappings, paginationProps } = useMappingPagination(
    allMappings,
    updateData,
    isPaginationEnabled.current
  );

  const startIndex = (page - 1) * INSPECTOR_PAGE_SIZE;
  const endIndex = page * INSPECTOR_PAGE_SIZE;

  const setMappings = newMappings => {
    updateData({ mappings: newMappings });
  };

  const mappingsLeft = allMappings.map(field => ({
    name: field.left?.original_name || field.left?.name,
    type: field.left?.type,
  }));

  const mappingsRight = allMappings.map(field => ({
    name: field.right?.original_name || field.right?.name,
    type: field.right?.type,
  }));

  const leftFieldOptions = determineFieldOptions(leftFields, mappingsLeft);
  const rightFieldOptions = determineFieldOptions(rightFields, mappingsRight);

  const handleUnmappedColumns = ({ target }) => {
    updateData({ previewUnmappedColumns: target.checked });
  };

  return (
    <ElementInspector {...{ elementData, elementId, elementType, updateData, isDirty }}>
      <div className="wkp-merge-element-inspector">
        <p>
          Map the fields between the two inputs. The values in the mapped fields will be combined into a single column.
          The column headings will be drawn from Input A.
        </p>
        <LeftRightFieldsSelector
          disableSelected={true}
          leftFields={leftFieldOptions}
          leftLabel={leftInputName}
          mappings={mappings}
          rightFields={rightFieldOptions}
          rightLabel={rightInputName}
          setMappings={setMappings}
          {...paginationProps}
        />
        {isPaginationEnabled.current && total > INSPECTOR_PAGE_SIZE && (
          <PaginationContainer
            endIndex={endIndex}
            page={page}
            setPage={setPage}
            startIndex={startIndex}
            totalFields={total}
          />
        )}
      </div>
      <div>
        <BTCheckbox
          checked={checkPreviewUnmappedColumns}
          disabled={!canEditWorkflow}
          id={'includeAll'}
          label={'Include unmapped columns from both inputs'}
          onChange={handleUnmappedColumns}
        />
      </div>
    </ElementInspector>
  );
};
