import React, { createRef, useState } from 'react';
import { BTTextarea } from '@btas/jasper';
import getCaretCoordinates from 'textarea-caret';
import getInputSelection, { setCaretPosition } from 'get-input-selection';
import { defaultProps, propTypes } from './props';
import FunctionHint from './FunctionHint';
import HelperList from './HelperList';
import { triggerStrings } from './constants';
import {
  canTriggerFunction,
  changeToUpperCase,
  getSelectedFunction,
  getOptionsToTrigger,
  getTriggers,
  hasMatchParenthesis,
  getActiveFunctionParamPosition,
  getMatchForFields,
} from './utils';
import './styles.scss';

const KEY_LEFT = 37;
const KEY_RIGHT = 39;
const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_RETURN = 13;
const KEY_ENTER = 14;
const KEY_ESCAPE = 27;
const KEY_TAB = 9;
const REGEX_FOR_FUNCTION = new RegExp('^[A-Za-z]+$');

const OPTION_LIST_Y_OFFSET = 10;
const OPTION_LIST_MIN_WIDTH = 300;

const AutocompleteTextField = props => {
  const [helperVisible, setHelperVisible] = useState(false);
  const [leftState, setLeftState] = useState(0);
  const [topState, setTopState] = useState(0);
  const [trigger, setTrigger] = useState(null);
  const [matchLength, setMatchLength] = useState(0);
  const [matchStart, setMatchStart] = useState(0);
  const [options, setOptions] = useState([]);
  const [selection, setSelection] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [caretState, setCaretState] = useState('');
  const [valueState, setValueState] = useState(null);
  const [functionSelected, setFunctionSelected] = useState(null);
  const [functionParamSelected, setFunctionParamSelected] = useState(0);
  const [recentValue, setRecentValue] = useState(props.defaultValue);
  const [elementTriggered, setElementTriggered] = useState(null);
  const [doubleQuoteString, setDoubleQuoteString] = useState(null);

  let enableSpaceRemovers = false;
  let refInput = createRef();

  // Display function hint
  const getFunctionHintToDisplay = (str, caret, providedOptionsObject) => {
    const functionHintToDisplay = getSelectedFunction(str, caret, providedOptionsObject);

    if (functionHintToDisplay) {
      const { functionName } = functionHintToDisplay;
      const functionLength = functionName.length;
      const optionsToTrigger = getOptionsToTrigger(props, functionName);

      if (optionsToTrigger) {
        // update elementTriggeredState with  previous function options. This is for the case of having nested functions
        setElementTriggered({ ...optionsToTrigger, matchStart: caret - 1 - functionLength });
      }

      setFunctionSelected({ str: functionName, matchStart: caret - 1 - functionLength, matchLength: functionLength });
      // some cases when deleting string, functionSelected state won't have values updated at the moment params are set so we will pass the new function Name
      return { ...functionHintToDisplay, funcSelectedStr: functionName };
    }

    return null;
  };

  const handleHighlightingForFunctionParams = (str, slug) => {
    let paramPosition = getActiveFunctionParamPosition(str, slug, functionSelected);
    paramPosition ? setFunctionParamSelected(paramPosition) : setFunctionParamSelected(0);
  };

  // Get a match by typing into the text area to display function hint or function options
  const getMatch = (str, caret, providedOptions, callback) => {
    const { trigger, matchAny } = props;
    const triggers = getTriggers(trigger);

    const providedOptionsObject = providedOptions;
    if (Array.isArray(providedOptions)) {
      triggers.forEach(triggerStr => {
        providedOptionsObject[triggerStr] = providedOptions;
      });
    }

    // Prevent triggering function options if string is within  double quotes
    if (!canTriggerFunction(str, doubleQuoteString, elementTriggered, setDoubleQuoteString)) {
      // If new function options can't be triggered with new entry but a previous one was set, we need to keep function hint active
      return functionSelected ? getFunctionHintToDisplay(str, caret, providedOptionsObject) : null;
    }

    // handle trigger autocomplete for fields
    const displayFieldsDialog = getMatchForFields(str, caret, providedOptionsObject, getFunctionHintToDisplay);

    if (displayFieldsDialog) {
      if (!elementTriggered || elementTriggered.triggerStr !== '[') {
        const { matchStart } = displayFieldsDialog;
        const optionsToTrigger = getOptionsToTrigger(props, str, matchStart);
        setElementTriggered({ ...optionsToTrigger, matchStart });
      }

      return displayFieldsDialog;
    }

    // handle trigger autocomplete by function
    const lastStringCharacter = str.substr(str.length - 1, 1);

    // if a formula is provided and the user highlights the entire formula,
    // we reset matchStart to initial position
    let matchStartForMatch = matchStart;
    if (str.length === 1) {
      matchStartForMatch = 0;
      setElementTriggered(null);
      setFunctionSelected(null);
    }

    const optionsToTrigger = getOptionsToTrigger(props, str, matchStartForMatch);
    const validTriggerForFunction = lastStringCharacter.match(REGEX_FOR_FUNCTION);

    if (optionsToTrigger) {
      setElementTriggered({ ...optionsToTrigger, matchStart: matchStartForMatch });
    }

    if ((optionsToTrigger && validTriggerForFunction) || (helperVisible && validTriggerForFunction)) {
      const { triggerStr } = optionsToTrigger ?? elementTriggered;

      let slugData = null;
      const triggerOptions = providedOptionsObject[triggerStr];
      let matchStartForSlug = matchStartForMatch;
      let matchedSlug = str.substring(matchStartForMatch, caret);

      if (!matchedSlug && elementTriggered) {
        // checks if a match is found when length of introduced string is > 1 and a function was selected in previous iteration
        matchedSlug = str.substring(elementTriggered.matchStart, caret);
        matchStartForSlug = elementTriggered.matchStart;
      }

      const options = triggerOptions.filter(slug => {
        const idx = slug.toLowerCase().indexOf(matchedSlug.toLowerCase());
        return idx !== -1 && (matchAny || idx === 0);
      });

      if (options.length > 0 && matchedSlug.toLowerCase() === options[0].toLowerCase()) {
        setFunctionSelected({ str: options[0], matchStart: matchStartForSlug, matchLength: options[0].length });

        // change value to upperCase if typed function has a match
        callback({ start: matchStartForSlug, end: options[0].length });
      } else if (!options.length) {
        if (functionSelected) {
          // get previous selected function data when deleting string
          const { str: funcString } = functionSelected;

          const triggerOptions = providedOptionsObject[funcString.substr(0, 1)];
          const options = triggerOptions.filter(slug => {
            const idx = slug.toLowerCase().indexOf(funcString.toLowerCase());
            return idx !== -1 && (matchAny || idx === 0);
          });

          // data default to display function hint
          const functionHintToDisplay = {
            trigger: '(',
            matchStart,
            matchLength: 0,
            options,
          };

          const triggerStr = str.substr(matchStart).toUpperCase();
          const triggerOptionsForCheck = providedOptionsObject[triggerStr];

          if (!triggerOptionsForCheck) {
            // if we are at the end of the string and previous functions has balanced parenthesis,then we can hide function hint
            if (hasMatchParenthesis(str)) {
              setFunctionSelected(null);
              return null;
            }

            // Otherwise keep the function hint active while user completes function parameters
            return functionHintToDisplay;
          }

          const funcOptions = triggerOptions.filter(slug => {
            const idx = slug.toLowerCase().indexOf(triggerStr.toLowerCase());
            return idx !== -1 && (matchAny || idx === 0);
          });

          if (funcOptions && funcOptions.length > 0) {
            return {
              trigger: triggerStr,
              matchStart: caret - 1,
              matchLength: triggerStr.length,
              options: funcOptions,
            };
          }

          // keep the function hint active while user completes function parameters
          return functionHintToDisplay;
        }
      }

      const currTrigger = triggerStr;
      const matchLength = matchedSlug.length;
      slugData = {
        trigger: currTrigger,
        matchStart: matchStartForSlug,
        matchLength,
        options,
      };

      return slugData;
    } else {
      // Handle cases to display function hint when there is not a function match
      const functionHintToShow = getFunctionHintToDisplay(str, caret, providedOptionsObject);
      if (!functionHintToShow) {
        // if function hint won´t be displayed we reset values so function hint or function options
        // can be triggered again when typing at last position
        setMatchStart(caret);
        setMatchLength(0);
      }
      return functionHintToShow;
    }
  };

  function getUpdatedCaretPositionWithKeyPress(e, caret) {
    if (e.keyCode === KEY_LEFT) {
      return caret - 1;
    }
    if (e.keyCode === KEY_RIGHT) {
      return caret + 1;
    }
    return caret;
  }

  const handleChange = e => {
    const { onChange, options, spaceRemovers, spacer, value } = props;

    const old = recentValue;
    const str = e.target.value;
    let caret = getInputSelection(e.target).end;

    caret = getUpdatedCaretPositionWithKeyPress(e, caret);

    if (!str.length) {
      setDoubleQuoteString(null);
      setHelperVisible(false);
      setFunctionParamSelected(0);
      setFunctionSelected(null);
      setMatchStart(0);
      setMatchLength(0);
    }

    setRecentValue(str);

    setCaretState(caret);
    setValueState(e.target.value);

    if (!str.length || !caret) {
      return onChange(e.target.value);
    }

    if (enableSpaceRemovers && spaceRemovers.length && str.length > 2 && spacer.length) {
      for (let i = 0; i < Math.max(old.length, str.length); ++i) {
        if (old[i] !== str[i]) {
          if (
            i >= 2 &&
            str[i - 1] === spacer &&
            spaceRemovers.indexOf(str[i - 2]) === -1 &&
            spaceRemovers.indexOf(str[i]) !== -1 &&
            getMatch(str.substring(0, i - 2), caret - 3, options)
          ) {
            const newValue = `${str.slice(0, i - 1)}${str.slice(i, i + 1)}${str.slice(i - 1, i)}${str.slice(i + 1)}`;

            updateCaretPosition(i + 1);
            refInput.current.value = newValue;

            if (!value) {
              setValueState(newValue);
            }

            return onChange(newValue);
          }

          break;
        }
      }

      enableSpaceRemovers = false;
    }

    let isValueToUpperCase = false;
    let newValue;

    const assignValueState = paramsForUpperCase => {
      if (paramsForUpperCase) {
        // change partial string to upperCase if a function match is found
        isValueToUpperCase = true;
        newValue = changeToUpperCase(paramsForUpperCase, e);
        setValueState(newValue);
      }
    };

    updateHelper(str, caret, options, assignValueState);

    if (!value && !isValueToUpperCase) {
      setValueState(e.target.value);
    }

    return onChange(newValue || e.target.value);
  };

  const handleClick = event => {
    handleChange(event);

    //TODO use this to display function hint when clicking in specific position
    // const pos = getInputSelection(e.target).end;
  };

  const handleKeyDown = event => {
    const { onKeyDown, passThroughEnter } = props;

    if (event.keyCode === KEY_LEFT || event.keyCode === KEY_RIGHT) {
      onKeyDown(event);
      handleChange(event);
    } else if (helperVisible) {
      switch (event.keyCode) {
        case KEY_ESCAPE:
          event.preventDefault();
          resetHelper();
          break;
        case KEY_UP:
          event.preventDefault();
          setSelection((options.length + selection - 1) % options.length);
          break;
        case KEY_DOWN:
          event.preventDefault();
          setSelection((selection + 1) % options.length);
          break;
        case KEY_ENTER:
        case KEY_RETURN:
        case KEY_TAB:
          if (!passThroughEnter) {
            event.preventDefault();
          }
          handleSelection(selection);
          break;
        default:
          onKeyDown(event);
          break;
      }
    } else {
      onKeyDown(event);
    }
  };

  //handle function options / fields options selection
  const handleSelection = idx => {
    const { spacer, onSelect, changeOnSelect } = props;

    const slug = options[idx];
    const value = recentValue;
    const part1 = value.substring(0, matchStart - trigger.length + 1);
    const part2 = value.substring(matchStart + matchLength);

    const event = { target: refInput.current };
    const changedStr = changeOnSelect(trigger, slug);

    // determine if function was selected
    const match = trigger.match(REGEX_FOR_FUNCTION);
    let keyToAutoComplete = trigger;
    const { byFuncStr, byHint, byFields } = triggerStrings;

    if (match) {
      setFunctionSelected({ str: slug, matchStart, matchLength });
      // using equal sign to handle logic when displaying function hint for a selected function
      keyToAutoComplete = byFuncStr;
    }

    // Update the value of the text area once user selects an option from the function hint dialog
    switch (keyToAutoComplete) {
      case '[':
        // For auto complete of Fields, we append ] at the end,
        const part2ForFields = value.substring(matchStart + 1 + matchLength);
        event.target.value = `${part1}${changedStr}${spacer}${part2ForFields}]`;
        break;
      case '=':
        event.target.value = `${part1}${changedStr.substr(1)}${spacer}${part2}(`;
        break;
      default:
        event.target.value = `${part1}${spacer}${part2}`;
        break;
    }

    handleChange(event);
    onSelect(event.target.value);

    enableSpaceRemovers = true;

    if (keyToAutoComplete === byFuncStr) {
      setTrigger(byHint);
      setMatchStart(event.target.value.length);
    } else if (keyToAutoComplete === byHint) {
      updateCaretPosition(part1.length + changedStr.length + 1);
    } else if (keyToAutoComplete === byFields) {
      setMatchStart(event.target.value.length);
    } else {
      resetHelper();
      updateCaretPosition(part1.length + changedStr.length + 1);
    }

    // force focus when selecting with click event
    refInput.current.focus();
  };

  const updateCaretPosition = caret => {
    setCaretState(caret);
    setCaretPosition(refInput.current, caret);
  };

  // update info to be display in floating windows. Function options, Fields options, Function hint
  // Only one window will be displayed at the time
  const updateHelper = (str, caret, options, callback) => {
    const input = refInput.current;

    const slug = getMatch(str, caret, options, callback);
    const { byHint } = triggerStrings;

    if (slug) {
      const caretPos = getCaretCoordinates(input, caret);
      const rect = input.getBoundingClientRect();

      const top = caretPos.top + input.offsetTop;
      const left = Math.min(
        caretPos.left + input.offsetLeft - OPTION_LIST_Y_OFFSET,
        input.offsetLeft + rect.width - OPTION_LIST_MIN_WIDTH
      );

      const { minChars, onRequestOptions, requestOnlyIfNoOptions } = props;

      const { matchLength, trigger, options: slugOptions } = slug;

      if (
        matchLength >= minChars &&
        (slugOptions.length > 1 || (slugOptions.length === 1 && slugOptions[0].length >= matchLength))
      ) {
        if (trigger === byHint) {
          handleHighlightingForFunctionParams(str, slug);
        }
        displayHelper(slug, top, left);
      } else {
        if (trigger === byHint) {
          handleHighlightingForFunctionParams(str, slug);
        } else {
          if (!requestOnlyIfNoOptions || !slug.options.length) {
            onRequestOptions(str.substr(slug.matchStart, slug.matchLength));
          }

          resetHelper();
        }
      }
    } else {
      resetHelper();
    }
  };

  const displayHelper = (slug, top, left) => {
    const { matchStart, matchLength, trigger, options } = slug;
    setHelperVisible(true);
    setTopState(top);
    setLeftState(left);
    setMatchStart(matchStart);
    setMatchLength(matchLength);
    setOptions(options);
    setTrigger(trigger);
  };

  const resetHelper = () => {
    setHelperVisible(false);
    setSelection(0);
    setFunctionParamSelected(0);
  };

  const renderAutocompleteList = () => {
    const { supportedFunctions } = props;

    const functionProps = supportedFunctions[functionSelected?.str];

    if (!helperVisible) {
      return null;
    }

    const { maxOptions, offsetX, offsetY } = props;

    if (options.length === 0) {
      return null;
    }

    if (selection >= options.length) {
      setSelection(0);

      return null;
    }

    const optionNumber = maxOptions === 0 ? options.length : maxOptions;

    const { byHint, byFields } = triggerStrings;

    // Don't display function hint if matching function has not been selected/typed
    if (trigger === byHint && !functionSelected) {
      return null;
    }

    if (trigger === byHint) {
      // display function Hint dialog
      return (
        <FunctionHint
          hintParams={{ functionProps, functionParamSelected, functionSelected: functionSelected.str }}
          style={{ left: leftState + offsetX, top: topState + offsetY + 7 }}
        />
      );
    }

    const start = trigger === byFields ? matchStart + 1 : matchStart;

    // Display function / Fields options
    return (
      <HelperList
        handleClick={handleSelection}
        handleOnMouseEnter={setSelection}
        highlightParams={{ start, length: matchLength }}
        maxOptionsToDisplay={optionNumber}
        optionSelected={selection}
        options={options}
        style={{ left: leftState + offsetX, top: topState + offsetY + 7 }}
        trigger={trigger}
        valueState={valueState}
      />
    );
  };

  const { defaultValue, value, error, maxLength, disabled } = props;

  let val = '';

  if (typeof value !== 'undefined' && value !== null) {
    val = value;
  } else if (valueState) {
    val = valueState;
  } else if (defaultValue) {
    val = defaultValue;
  }

  return (
    <>
      <BTTextarea
        ref={refInput}
        disabled={disabled}
        maxLength={maxLength}
        validation={!!error ? 'error' : null}
        value={val}
        onChange={handleChange}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      />
      {renderAutocompleteList()}
    </>
  );
};

AutocompleteTextField.propTypes = propTypes;
AutocompleteTextField.defaultProps = defaultProps;

export default AutocompleteTextField;
