import React, { useContext, useState } from 'react';
import { BTModal, BTButton } from '@btas/jasper';
import { deleteDataFlows } from './apis';
import { getWorkflowsUserCanDelete } from '../../auth/permissions/CanDeleteWorkflow';
import UserPermissionsContext from '../../_shared/UserPermissionsContext';
import CustomLogger from '../../_shared/Logger/CustomLogger';
const deleteDataFlowErrorCode = 'WKP2014';

export default function DeleteDataFlowsModal({
  selectedDataFlows,
  show,
  onClose,
  onDeleteProcessed,
  handleDeleteLockDataflowError,
  onError,
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { userPermissions } = useContext(UserPermissionsContext);

  async function deleteWorkflows(selectedDataFlow) {
    const lockedDataFlows = [];
    const dataFlowsToDelete = [];
    selectedDataFlow.forEach(sel => {
      sel.status === 'Final' ? lockedDataFlows.push(sel) : dataFlowsToDelete.push(sel.id);
    });
    if (dataFlowsToDelete.length > 0) {
      await deleteDataFlows(dataFlowsToDelete);
    }
    if (lockedDataFlows.length > 0) {
      handleDeleteLockDataflowError(lockedDataFlows);
    }
  }

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      if (userPermissions.isFeatureFlagEnabled()) {
        const dataFlowsUserCanDelete = getWorkflowsUserCanDelete(selectedDataFlows, userPermissions);

        if (dataFlowsUserCanDelete.size > 0) {
          const dataFlowsToDelete = Array.from(dataFlowsUserCanDelete.values());
          await deleteWorkflows(dataFlowsToDelete);
        }

        if (dataFlowsUserCanDelete.size !== selectedDataFlows.length) {
          const failedToDelete = selectedDataFlows.filter(dataFlow => !dataFlowsUserCanDelete.has(dataFlow.id));
          CustomLogger.pushLog(CustomLogger.operations.DELETE_WORKFLOW, {
            ids: failedToDelete.map(dataFlow => dataFlow.id),
          });
          userPermissions.setErrorData(failedToDelete.map(dataFlow => dataFlow.name));
        }
      } else {
        await deleteWorkflows(selectedDataFlows);
      }
      onDeleteProcessed();
    } catch (error) {
      CustomLogger.pushLog(CustomLogger.operations.DELETE_WORKFLOW, {
        message: error.message,
      });
      if (error.code === deleteDataFlowErrorCode) {
        onError({
          [error.target]: error.message,
        });
      }
    }
    setIsDeleting(false);
  };

  return (
    <BTModal btStyle="warning" show={show} title="Delete Data Flows" onCloseClick={onClose}>
      <BTModal.Body>
        <p>The selected data flows will be permanently deleted. Are you sure?</p>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton data-testid="close-button" onClick={onClose}>
          Cancel
        </BTButton>
        <BTButton btStyle="primary" disabled={isDeleting} hasSpinner={isDeleting} id="delete-df" onClick={handleDelete}>
          Delete
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
