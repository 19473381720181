/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import workpaperIcon from './wkp_grid_icon.svg';
import { BTIcon } from '@btas/jasper';

export default function SuccessJobItem({ fileName, jobOutput }) {
  const handleRedirect = () => {
    if (jobOutput.link) {
      window.open(jobOutput.link, '_blank');
    }
  };
  return (
    <div className="wkp-job-item" title={`${fileName}`}>
      <img alt="workpaper icon" src={workpaperIcon} />
      <a href onClick={handleRedirect}>
        <span className="wkp-job-item-name">
          <b>{fileName}</b>
        </span>
      </a>
      <span className="wkp-job-item-icon wkp-job-item-success-icon">
        <BTIcon icon="check-circle" />
      </span>
    </div>
  );
}
