import React from 'react';

import './SingleSelection/styles.scss';

export default function SingleSelection({ topic }) {
  return (
    <div className="wkp-topic-selected wkp-topic-selected-single">
      {topic.definition.length > 0 && <p>{topic.definition[0].Text}</p>}
      {topic.sections &&
        topic.sections.map((section, sectionIdx) => (
          <div key={sectionIdx}>
            {section.heading && section.document_links && section.document_links.length > 0 && (
              <>
                <h4>{section.heading}</h4>
                <p>
                  {section.document_links.map((dl, dlIdx) => (
                    <span key={dlIdx}>
                      <a
                        key={dl.title}
                        className="wkp-research-link"
                        href={dl.url && dl.url.startsWith('/product') ? `https://bloomberglaw.com${dl.url}` : dl.url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {dl.title}
                      </a>
                    </span>
                  ))}
                </p>
              </>
            )}
          </div>
        ))}
    </div>
  );
}
