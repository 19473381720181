export const buildDropdownOption = ({ value, withQuotes = false }) =>
  withQuotes ? { label: `"${value}"`, value: `"${value}"` } : { label: value, value };

export function buildCellReferenceError(references = ["'some sheet name'!ABC123"]) {
  const isSingleReference = references.length === 1;
  const joinedReferences = isSingleReference
    ? references[0]
    : `${references.slice(0, references.length - 1).join(', ')} and ${references[references.length - 1]}`;
  return `Cell reference${isSingleReference ? '' : 's'} ${joinedReferences} ${
    isSingleReference ? 'does' : 'do'
  } not exist`;
}

export const removeFirstAndLastCharacter = s => s.slice(1).slice(0, -1);

export const removeQuotationMarks = s => s.replaceAll('"', '');
