import {
  setTooltipArrowX,
  getTooltipX,
  getUpdatedTooltipX,
  isTooltipOutOfBounds,
} from './Spreadsheet/_spreadsheets/tooltipHelper';

export function initializeTooltip() {
  const getTooltiptMarkupElement = () => {
    const markup = document.createElement('div');
    markup.className = 'tooltip top fade';
    markup.setAttribute('role', 'tooltip');
    markup.innerHTML = `
      <div class="tooltip-arrow" style="left: 50%;"></div>
      <div class="tooltip-inner" ></div>`;

    return markup;
  };

  const initTooltip = tooltip => {
    tooltip.timeoutId = null;
    tooltip.regions = {};
    tooltip.activeRegion = null;
  };

  const tooltip = { container: null };

  initTooltip(tooltip);

  const tooltipExists = document.getElementsByClassName('tooltip').length !== 0;

  if (!tooltipExists) {
    const tooltipElement = getTooltiptMarkupElement();
    document.body.insertBefore(tooltipElement, window.document.body.firstChild);
  }

  const getContentFromFunction = async ({ tooltip, tooltipBody, callback }) => {
    const newContent = await callback();
    if (tooltip.activeRegion) {
      tooltipBody.innerText = newContent;
      tooltip.lock = false;
    }
  };

  const setHideStyle = target => {
    target.style.top = '-999px';
    target.style.left = '-999px';
    target.style.visibility = 'hidden';
  };

  const afterHideAction = ({ target }) => {
    target.removeEventListener('transitionend', afterHideAction);
    setHideStyle(target);
  };

  const processCell = ({ coordX, coordY }) => {
    const id = Object.keys(tooltip.regions)
      .filter(id => {
        const { originX, originY, destX, destY } = tooltip.regions[id];
        return coordX >= originX && coordY >= originY && coordX <= destX && coordY <= destY;
      })
      .filter(id => {
        const { activeCellValidator: validator } = tooltip.regions[id];
        return !validator || validator();
      })
      .sort((keyA, keyB) => {
        if (
          tooltip.regions[keyA].originX < tooltip.regions[keyB].originX &&
          tooltip.regions[keyA].originY < tooltip.regions[keyB].originY
        ) {
          return 1;
        }

        return -1;
      })[0];

    if (tooltip.regions[id]) {
      return {
        region: tooltip.regions[id],
        showTooltip: true,
      };
    }

    return {
      showTooltip: false,
    };
  };

  const showTooltip = ({ offsetX, offsetY }) => {
    const { x, y } = tooltip.container.getBoundingClientRect();
    const coordX = offsetX + x;
    const coordY = offsetY + y;

    const result = processCell({ coordX, coordY });

    if (!result.showTooltip) {
      hideTooltip();
      return;
    }

    if (!tooltip.activeRegion || result.region.id !== tooltip.activeRegion.id) {
      // if there is no other tooltip shown or tooltip is different than processed one
      // then set timer to show tooltip.
      tooltip.activeRegion = result.region;

      tooltip.timeoutId = setTimeout(async () => {
        if (tooltip.activeRegion) {
          const tooltipElement = document.getElementsByClassName('tooltip')[0];

          if (tooltipElement) {
            const tooltipBody = tooltipElement.getElementsByClassName('tooltip-inner')[0];

            if (typeof tooltip.activeRegion.content === 'function') {
              tooltip.lock = true;
              tooltipBody.innerHTML = `<div class="wkp-dot-loader"></div>`;
              await getContentFromFunction({ tooltip, tooltipBody, callback: tooltip.activeRegion.content });
            } else {
              if (tooltip.activeRegion.isHTML) {
                tooltipBody.innerHTML = tooltip.activeRegion.content;
              } else {
                tooltipBody.innerText = tooltip.activeRegion.content;
              }
            }

            const tooltipOriginalX = tooltip?.activeRegion?.position?.x;
            if (tooltipOriginalX) {
              if (isTooltipOutOfBounds(tooltipOriginalX, tooltipElement.offsetWidth)) {
                tooltipElement.style.left = getUpdatedTooltipX(tooltipOriginalX, tooltipElement.offsetWidth) + 'px';
              } else {
                tooltipElement.style.left = getTooltipX(tooltipOriginalX, tooltipElement.offsetWidth) + 'px';
              }

              tooltipElement.style.top = tooltip.activeRegion.position.y - tooltipElement.offsetHeight + 'px';
              tooltipElement.style.visibility = 'visible';
              const tooltipArrow = document.getElementsByClassName('tooltip-arrow')[0];
              setTooltipArrowX(tooltipOriginalX, tooltipElement.offsetWidth, tooltipArrow);
              tooltipElement.classList.add('in');
              tooltipBody.style.maxWidth = '600px';
            }
          }
        }
      }, 300);
    }

    return;
  };

  const hideTooltip = () => {
    const tooltipElement = document.getElementsByClassName('tooltip')[0];

    if (tooltipElement && !tooltip.lock) {
      if (tooltipElement.classList.contains('in')) {
        tooltipElement.addEventListener('transitionend', afterHideAction);
        tooltipElement.classList.remove('in');

        tooltip.activeRegion = null;

        if (tooltip.timeoutId) {
          clearTimeout(tooltip.timeoutId);
          tooltip.timeoutId = null;
        }
      } else {
        tooltipElement.removeEventListener('transitionend', afterHideAction);
        setHideStyle(tooltipElement);
      }
    }
  };

  const registerTooltip = ({ id, ...args }) => {
    tooltip.regions[id] = { ...args, id };
  };

  const unregisterTooltip = ({ id }) => {
    delete tooltip.regions[id];
  };

  const captureTooltipSectionByElement = element => {
    if (element) {
      tooltip.container = element;
      element.removeEventListener('mousemove', showTooltip);
      element.removeEventListener('mouseleave', hideTooltip);
      element.addEventListener('mousemove', showTooltip);
      element.addEventListener('mouseleave', hideTooltip);
    }
  };

  const clean = () => {
    hideTooltip();

    Object.keys(tooltip.regions)
      .map(id => ({ id }))
      .forEach(unregisterTooltip);

    initTooltip(tooltip);
  };

  return {
    registerTooltip,
    unregisterTooltip,
    captureTooltipSectionByElement,
    clean,
  };
}
