import { SJS_API } from '../../../../constants/featureFlags';
import GC from '../../../../SpreadSheets';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';

const confirmMessage =
  'The selected sheets will be permanently deleted and links to/from these sheets will be broken. Are you sure?';
let cancelResult = null;

export function overrideSheetDeleteEvent(spread) {
  if (!isFeatureFlagEnabled(SJS_API)) {
    overrideContextMenuDeleteSheetEvent(spread);
    spread.commandManager().addListener('wkp.deleteSheetListener', arg => {
      if (arg.command.cmd === 'gc.spread.contextMenu.deleteSheet') {
        // This listener gets executed after the delete actions finish
        // so we reset the cancelResult variable
        cancelResult = null;
      }
    });

    overrideDesignerDeleteSheetEvent(spread);
  }
}

function overrideContextMenuDeleteSheetEvent(spread) {
  spread.bind(GC.Spread.Sheets.Events.SheetChanging, (e, args) => {
    const action = args.propertyName;
    if (action === 'deleteSheet') {
      if (cancelResult === null) {
        cancelResult = !window.confirm(confirmMessage);
      }

      args.cancel = cancelResult;
    }
  });
}

function overrideDesignerDeleteSheetEvent(spread) {
  const sheetDeleteCommand = spread.commandManager().getCommand('Designer.deleteSheet');
  if (!sheetDeleteCommand) {
    return;
  }

  const oldSheetDeleteCommand = sheetDeleteCommand.execute;

  sheetDeleteCommand.execute = function (context, options, isUndo) {
    if (window.confirm(confirmMessage)) {
      const otherSelectedSheets = context.sheets.filter(
        sheet => sheet.isSelected() && sheet.name() !== options.sheetName
      );
      oldSheetDeleteCommand.apply(this, arguments);

      deleteOtherSelectedSheets(this, arguments, oldSheetDeleteCommand, otherSelectedSheets);
    }
  };
}

/**
 * The designer delete sheet action just deletes the current sheet,
 * so this function takes care of deleting the rest of the selected
 * sheets if they exist
 * @param {*} _this
 * @param {*} _arguments
 * @param {*} sheetDeleteCommand
 * @param {List<any>} selectedSheets
 */
function deleteOtherSelectedSheets(_this, _arguments, sheetDeleteCommand, selectedSheets) {
  if (selectedSheets.length > 0) {
    let newArguments = [..._arguments];
    const deletedSheetName = _arguments[1].sheetName;

    selectedSheets.forEach(sheet => {
      if (sheet.name() !== deletedSheetName) {
        newArguments[1] = { ...newArguments[1], sheetName: sheet.name() };
        sheetDeleteCommand.apply(_this, newArguments);
      }
    });
  }
}
