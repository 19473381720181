import { useLocation } from 'react-router-dom';

export default function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useParams = params => {
  const query = useQuery();
  return params.map(p => query.get(p));
};

export { useParams };
