export const errorCodes = {
  validationError: 'WKP2013',
};

export const DataFlowErrorCodes = [
  'IncorrectInputCount',
  'IncorrectOutputCount',
  'InputPortNotConnected',
  'OutputPortNotConnected',
  'NoSingleOutputSelected',
  'NoTabularOutputSelected',
  'ElementHasNoFields',
  'DuplicateField',
  'EmptyName',
  'InvalidFormula',
  'EmptyFieldName',
  'OutputNameNotUnique',
  'BranchWithoutOutput',
  'HasIndependentBranches',
  'IncorrectJoinKeyFields',
  'IncorrectJoinOutputColumns',
  'IncorrectMergeMappings',
  'AggregatedNonNumericField',
  'AggregateMissingFieldInInput',
];

export const AggregateBlockErrorCodes = {
  nonNumericField: 'AggregatedNonNumericField',
  missingFieldInInput: 'AggregateMissingFieldInInput',
};

export const isValidationError = err => err.code === errorCodes.validationError;
