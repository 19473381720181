import { useEffect, useRef } from 'react';
import { WORKPAPER_EXPORT_JOB_TYPE } from '../../_shared/jobs/jobTypes';
import { getJobsStatus, startJob, updateJob } from '../../_shared/jobs/apis';
import { generateStaticVersion, exportWorkpapers } from './apis';
import { WORKPAPER_JOB_STATUS_COMPLETED, WORKPAPER_JOB_STATUS_IN_PROGRESS } from '../../_shared/jobs/jobStatus';
import useJobsOverlay from '../common/JobsOverlayWindow/useJobsOverlay';
import CustomLogger from '../../_shared/Logger/CustomLogger';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { SJS_API } from '../../../constants/featureFlags';
import { getWorkbookState, workbookStates } from '../../editor/EditorPage/apis';

export default function useExportFile(loadJobs, processes, cleanGridSelection) {
  const prevProcessRef = useRef();
  const { bindOnJobCompleted } = useJobsOverlay();

  const exportFile = async ({ id: workpaperId, name: fileName }) => {
    const { jobId } = await startJob({
      workpaperId,
      jobType: WORKPAPER_EXPORT_JOB_TYPE,
      payload: {
        fileName,
      },
    });
    bindOnJobCompleted(
      jobId,
      function (startTime) {
        CustomLogger.pushLog(CustomLogger.operations.EXPORT, {
          duration: (Date.now() - startTime).toString(),
          workpaperId,
          fileName,
        });
      },
      Date.now()
    );

    await loadJobs();
    if (isFeatureFlagEnabled(SJS_API)) {
      const { state } = await getWorkbookState(workpaperId);
      if (state === workbookStates.inProgress) {
        await waitUntilExcelIsDone(workpaperId, jobId);
      }
      await updateJob(jobId, WORKPAPER_JOB_STATUS_COMPLETED, workpaperId);
    } else {
      await generateStaticVersion(workpaperId);
    }
  };

  const exportFiles = async selection => {
    selection.forEach(exportFile);
    cleanGridSelection();
  };

  useEffect(() => {
    if (processes && processes.length > 0 && prevProcessRef.current && prevProcessRef.current.length > 0) {
      if (processes.length === prevProcessRef.current.length) {
        const jobChanged = findStatusChangedValue(
          prevProcessRef.current,
          processes,
          WORKPAPER_JOB_STATUS_IN_PROGRESS,
          WORKPAPER_JOB_STATUS_COMPLETED
        );
        if (jobChanged && jobChanged.jobType === WORKPAPER_EXPORT_JOB_TYPE)
          downloadWorkpaper(jobChanged.workpaperId, jobChanged.jobId);
      }
    }
    prevProcessRef.current = processes;
  }, [processes]);

  function findStatusChangedValue(prevProcesses, newProcesses, inProgress, completed) {
    const index = prevProcesses.findIndex(
      (obj1, i) =>
        obj1.workpaperId === newProcesses[i].workpaperId &&
        obj1['status'] === inProgress &&
        newProcesses[i]['status'] === completed
    );
    if (index !== -1) {
      return newProcesses[index];
    }

    return null;
  }

  function fetchExcelRunningJobs(workpaperId) {
    return getJobsStatus({
      workpaperIds: [workpaperId],
      jobTypes: [WORKPAPER_EXPORT_JOB_TYPE],
      jobStatus: [WORKPAPER_JOB_STATUS_IN_PROGRESS],
    });
  }

  async function waitUntilExcelIsDone(workpaperId, ingoreJobId) {
    let { state } = await getWorkbookState(workpaperId);
    let retries = 600;

    while (state === workbookStates.inProgress) {
      if (--retries < 0) {
        throw new Error('Excel version generation failed. Reload the page');
      }
      await new Promise(r => setTimeout(r, 1000));
      state = (await getWorkbookState(workpaperId)).state;
    }

    let runningJobs = await fetchExcelRunningJobs(workpaperId);
    runningJobs = runningJobs.filter(({ jobId }) => ingoreJobId !== jobId);

    while (runningJobs.length > 0) {
      if (--retries < 0) {
        throw new Error('Excel version generation failed. Reload the page');
      }

      await new Promise(r => setTimeout(r, 1000));
      runningJobs = await fetchExcelRunningJobs(workpaperId);
      runningJobs = runningJobs.filter(({ jobId }) => ingoreJobId !== jobId);
    }
  }

  async function downloadWorkpaper(id, jobId) {
    const presignedUrl = await exportWorkpapers({ id, jobId });
    const link = document.createElement('a');
    link.href = presignedUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return { exportFiles };
}
