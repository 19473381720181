import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_USER_PERMISSIONS } from '../../../constants/featureFlags';
import { getReaderPermissions } from './apis';

export class UserPermissionData {
  constructor(permissionData, lockedWorkflowState) {
    this.permissionData = permissionData;
    this.deleteWorkflowErrorData = [];
    this.isActiveWorkflowLocked = false;
    this.activeDataFlowId = '';
    this.lockedWorkflowState = lockedWorkflowState;
  }

  setErrorData(errorData) {
    this.deleteWorkflowErrorData = errorData;
  }

  hasPermissionApiError() {
    return this.permissionData.apiError === true;
  }

  clearPermissionApiError() {
    if (this.permissionData.apiError) {
      this.permissionData.apiError = false;
    }
  }

  getDeleteWorkflowErrorData() {
    return this.hasDeleteWorkflowError() ? this.deleteWorkflowErrorData : [];
  }

  clearDeleteWorkflowError() {
    this.deleteWorkflowErrorData = [];
  }

  hasDeleteWorkflowError() {
    if (typeof this.deleteWorkflowErrorData === 'undefined') {
      return false;
    }
    if (Array.isArray(this.deleteWorkflowErrorData) === 'Array' && this.deleteWorkflowErrorData.length === 0) {
      return false;
    }
    if (typeof this.deleteWorkflowErrorData === 'object' && Object.keys(this.deleteWorkflowErrorData).length === 0) {
      return false;
    }
    if (typeof this.deleteWorkflowErrorData === 'string' && this.deleteWorkflowErrorData.length === 0) {
      return false;
    }

    return true;
  }

  isFeatureFlagEnabled() {
    return isFeatureFlagEnabled(WKP_USER_PERMISSIONS);
  }

  includes(permission) {
    if (!this.isFeatureFlagEnabled()) {
      return true;
    }

    /* React will sometimes attempt to render components with user permissions
       before permission data has been loaded. When this happens, another render 
       will happen after the permission data has loaded */
    if (!this.permissionData || !this.permissionData['permissions']) {
      return getReaderPermissions()['permissions'].includes(permission);
    }

    const permissionList = this.permissionData['permissions'];

    if (typeof permission === 'string') {
      return permissionList.includes(permission);
    }
    if (Array.isArray(permission)) {
      for (let i = 0; i < permission.length; i++) {
        if (permissionList.includes(permission[i])) {
          return true;
        }
      }
    }

    return false;
  }
  canEditWorkflow() {
    if (!this.isFeatureFlagEnabled()) {
      return true;
    }
    if (this.includes(['edit_workflow', 'edit_workflow_self'])) {
      return true;
    }
    return false;
  }
  canDownloadWorkflowOutputs() {
    if (!this.isFeatureFlagEnabled()) {
      return true;
    }
    return this.includes('download_workflow_outputs');
  }
  canCreateWorkflow() {
    if (!this.isFeatureFlagEnabled()) {
      return true;
    }
    return this.includes('create_workflow');
  }

  updateLockedState(status, dataFlowId, lastModified) {
    return this.lockedWorkflowState.updateLockedState(status, dataFlowId, lastModified);
  }

  updateLockedStateFromApi(status, dataFlowId, lastModified) {
    this.lockedWorkflowState.updateLockedStateFromApi(status, dataFlowId, lastModified);
  }

  activeWorkflowLocked(dataFlowId) {
    return this.lockedWorkflowState.activeWorkflowLocked(dataFlowId);
  }

  lockedWorkflowAlertIsVisible() {
    return this.lockedWorkflowState.lockedWorkflowAlertIsVisible();
  }

  dismissLockedWorkflowAlert() {
    this.lockedWorkflowState.dismissLockedWorkflowAlert();
  }

  resetLockedState() {
    this.lockedWorkflowState.resetState();
  }
}
