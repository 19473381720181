import { redirectToLogin } from './jwtAuth';

async function handleAuth(res) {
  const missingTokenErrorCode = 'GN0002';
  const unauthorizedAccessErrorCode = 'GN0003';
  if (res.status === 400 || res.status === 401) {
    const { error } = await res.clone().json();
    if (error && (error.code === missingTokenErrorCode || error.code === unauthorizedAccessErrorCode)) {
      redirectToLogin();
    }
  }
  return res;
}

function performFetch(url, fetchOptions) {
  return fetch(url, {
    credentials: 'include',
    ...fetchOptions,
    headers: { ...fetchOptions.headers, Authorization: `Bearer ${'token'}` },
  }).then(res => handleAuth(res));
}

export function get(url, fetchOptions = {}) {
  return performFetch(url, { ...fetchOptions, method: 'GET' });
}

export function getToggle(url) {
  // Temp solution for FF until ECDS is integrated
  return fetch(url, { headers: { Authorization: `Bearer ${'token'}` }, method: 'GET' }).then(handleAuth);
}

export function post(url, params = {}) {
  return performFetch(url, {
    ...params,
    headers: {
      'Content-Type': 'application/json',
      ...(params.headers || {}),
    },
    method: 'POST',
  });
}

export function put(url, params = {}) {
  return performFetch(url, {
    ...params,
    headers: {
      'Content-Type': 'application/json',
      ...(params.headers || {}),
    },
    method: 'PUT',
  });
}

export function patch(url, params = {}) {
  return performFetch(url, {
    ...params,
    headers: {
      'Content-Type': 'application/json',
      ...(params.headers || {}),
    },
    method: 'PATCH',
  });
}

export function remove(url, params = {}) {
  return performFetch(url, {
    ...params,
    headers: {
      'Content-Type': 'application/json',
      ...(params.headers || {}),
    },
    method: 'DELETE',
  });
}
