import { shapes } from '@clientio/rappid';
import { merge } from 'lodash';
import { INPUT_PORT_FILL_COLOR, LABEL_COLOR, PORT_COLOR } from '../shared/colors';

const elementModelProtoProps = {
  markup:
    '<g class="rotatable"><g class="scalable"><rect class="block"/></g><image class="image"/><text class="name"/></g>',
};

function createDefaultPortConfig({ labelX }) {
  return {
    label: {
      markup: null,
      position: {
        args: {
          x: labelX,
          y: 0,
        },
      },
    },
    attrs: {
      '.port-label': {
        fill: '#141414',
        fontWeight: 'bold',
      },
    },
  };
}

export const ElementModel = shapes.devs.Model.define(
  'shapes.IconBlock',
  {
    ports: {
      groups: {
        in: createPortConfig({
          cx: -8,
          magnet: 'passive',
          fill: INPUT_PORT_FILL_COLOR,
        }),
        out: createPortConfig({
          cx: 8,
        }),
      },
    },
  },
  elementModelProtoProps
);

export const ElementModelWithPortLabels = shapes.devs.Model.define(
  'shapes.IconBlockWithPortLabels',
  {
    ports: {
      groups: {
        in: merge(
          createPortConfig({
            cx: -18,
            magnet: 'passive',
            fill: LABEL_COLOR,
          }),
          createDefaultPortConfig({ labelX: -2 })
        ),
        out: merge(
          createPortConfig({
            cx: 18,
          }),
          createDefaultPortConfig({ labelX: 2 })
        ),
      },
    },
  },
  elementModelProtoProps
);

export const DefaultGraphElementAttrs = {
  // clear stencil tooltip
  root: {
    dataTooltip: null,
  },
  //to be changed if dragging block onto df looks inconsistent w new block sizes
  rect: { width: 54, height: 40 },
  '.block': {
    strokeWidth: '0',
    rx: 5,
    ry: 5,
  },

  image: {
    width: 18,
    height: 18,
    ref: '.block',
    refX: '18', // ( block width (54) - image width (18)) / 2 to center image
    refY: '11', // ( block height (40) - image height (18) ) / 2 to center image
  },

  '.name': {
    textVerticalAnchor: 'top',
    ref: '.block',
    refX: '50%',
    refY: '125%',
    fontSize: 12,
    fill: 'black',
    fontWeight: '500',
  },
};

export const DefaultStencilElementAttrs = {
  size: { width: 80, height: 70 },
  attrs: {
    rect: { width: 70, height: 60 },
    '.block': {
      strokeWidth: '0',
      rx: 5,
      ry: 5,
    },

    image: {
      width: 24,
      height: 24,
      ref: '.block',
      refX: '35%',
      refY: '15%',
    },

    '.name': {
      textVerticalAnchor: 'middle',
      textAnchor: 'middle',
      ref: '.block',
      refX: '50%',
      refY: '75%',
      fontSize: 13,
      fill: LABEL_COLOR,
      fontWeight: '500',
      letterSpacing: '0.1em',
    },
  },
};

function createPortConfig(bodyProps) {
  return {
    label: {
      markup: '<text font-size="0" />',
    },
    attrs: {
      '.port-body': {
        r: 5,
        fill: PORT_COLOR,
        stroke: PORT_COLOR,
        ...bodyProps,
      },
    },
  };
}

export function withTooltip(config, helpText) {
  return {
    ...config,
    attrs: {
      ...config.attrs,
      root: createTooltipAttrs(helpText),
    },
  };
}

export function createTooltipAttrs(helpText) {
  return {
    dataTooltip: helpText,
    dataTooltipPosition: 'left',
    dataTooltipPositionSelector: '.joint-stencil',
  };
}

export const ElementModelWithOutPortLabels = shapes.devs.Model.define(
  'shapes.IconBlockWithPortLabels',
  {
    ports: {
      groups: {
        in: createPortConfig({
          cx: -8,
          magnet: 'passive',
          fill: INPUT_PORT_FILL_COLOR,
        }),
        out: merge(
          createPortConfig({
            cx: 18,
          }),
          createDefaultPortConfig({ labelX: 2 })
        ),
      },
    },
  },
  elementModelProtoProps
);
export const ElementModelWithInPortLabels = shapes.devs.Model.define(
  'shapes.IconBlockWithPortLabels',
  {
    ports: {
      groups: {
        in: merge(
          createPortConfig({
            cx: -18,
            magnet: 'passive',
            fill: LABEL_COLOR,
          }),
          createDefaultPortConfig({ labelX: -2 })
        ),
        out: merge(
          createPortConfig({
            cx: 8,
          })
        ),
      },
    },
  },
  elementModelProtoProps
);
