import React, { useState, useEffect, useContext } from 'react';
import Spinner from '../../../../_shared/Spinner';
import EditorContext from '../../EditorContext';
import EmptyState from './TaxLawGuidancePanel/EmptyState';
import TopicsSelection from './TaxLawGuidancePanel/TopicsSelection';
import TopicsDetail from './TaxLawGuidancePanel/TopicsDetail';
import { getWorkpaperSelectedTopics, saveWorkpaperSelectedTopics } from './TaxLawGuidancePanel/apis';
import './TaxLawGuidancePanel/styles.scss';

export default function TaxLawGuidancePanel({ workpaperId, onClose }) {
  const { isLocked, isGlobalTemplate } = useContext(EditorContext);
  const [selectedItems, setSelectedItems] = useState(null);
  const [showTopicSelection, setShowTopicSelection] = useState(false);

  useEffect(() => {
    async function getTaxLawResearchTypes() {
      const data = await getWorkpaperSelectedTopics(workpaperId);
      setSelectedItems(data.topics);
    }

    getTaxLawResearchTypes();
  }, [workpaperId]);

  const handleSelectTopics = selectedTopics => {
    saveWorkpaperSelectedTopics({ workpaperId, topics: selectedTopics });
    setSelectedItems(selectedTopics);
    setShowTopicSelection(false);
  };

  const handleSelectTopicsFromEmptyState = () => setShowTopicSelection(true);
  const handleBackToTopicsSelection = () => setShowTopicSelection(true);
  const handleCancelTopicsSelection = () => setShowTopicSelection(false);

  return (
    <div className="wkp-right-pane">
      {!selectedItems && <Spinner />}
      {selectedItems && showTopicSelection && (
        <TopicsSelection
          defaultSelectedItems={selectedItems}
          workpaperId={workpaperId}
          onCancel={handleCancelTopicsSelection}
          onClose={onClose}
          onTopicsSelected={handleSelectTopics}
        />
      )}
      {selectedItems && !showTopicSelection && selectedItems.length === 0 && (
        <EmptyState
          isLocked={isLocked || isGlobalTemplate}
          onClose={onClose}
          onSelectTopics={handleSelectTopicsFromEmptyState}
        />
      )}
      {selectedItems && !showTopicSelection && selectedItems.length !== 0 && (
        <TopicsDetail
          isLocked={isLocked || isGlobalTemplate}
          selectedItems={selectedItems}
          onBackToTopicsSelection={handleBackToTopicsSelection}
          onClose={onClose}
        />
      )}
    </div>
  );
}
