import useEditorRedirectInterceptor from '../useEditorRedirectInterceptor';
import { useFeatureFlags } from './useFeatureFlags';

export default function useWorkpaperApp() {
  const { isFeatureFlagsLoaded } = useFeatureFlags();
  useEditorRedirectInterceptor(isFeatureFlagsLoaded);

  return {
    isFeatureFlagsLoaded,
  };
}
