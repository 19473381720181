export function resetDefaultBrowserBehavior() {
  preventF4OnEdge();
}

function preventF4OnEdge() {
  if (window.navigator.userAgent.indexOf('Edg') > -1) {
    window.addEventListener('keydown', e => {
      if (!e) {
        return;
      }

      const { key, shiftKey, altKey, ctrlKey } = e;

      if (key === 'F4' && (!shiftKey || !altKey || !ctrlKey)) {
        e.preventDefault();
      }
    });
  }
}
