import React, { useContext } from 'react';
import { BTAlert } from '@btas/jasper';
import { DataFlowEditorContext } from './DataFlowEditorContext';

const FailedToRestoreWarning = () => {
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const { isFailedToRestore } = dataFlowState;
  const onDismiss = () => dataFlowActions.setFailedToRestoreState(false);
  return (
    <BTAlert appear dismissible fixed btStyle="danger" visible={isFailedToRestore} onDismiss={onDismiss}>
      Something went wrong while restoring data flow state
    </BTAlert>
  );
};

export default FailedToRestoreWarning;
