import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export default function EditorRedirect() {
  const [id, setId] = useState(null);

  useEffect(() => {
    if (!id) {
      (async () => {
        setId(uuidv4());
      })();
    }
  }, [id]);

  if (id) {
    return (
      <Redirect
        to={{
          pathname: `/editor/${id}`,
          state: { newEmptyWorkpaper: true },
        }}
      />
    );
  }

  return null;
}
