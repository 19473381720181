import { AUTHENTICATOR_ENDPOINT, ecdsLogoutPageUrl, globalAccountId } from '../../configs/params';
import { remove } from './fetch';
import { getDataFromLocalStorage, removeDataFromLocalStorage, setDataToLocalStorage } from './storage';

const tokenInfo = 'token-info';
const userKey = 'btas-wkp-user';
const productInfo = 'btas-wkp-productInfo';
const userInfo = 'btas-wkp-user-info';
const aiSubscriptionInfo = 'wkp-ai-subscription';

const logoutSubscribers = [];

const localLogoutCleanup = () => {
  logoutSubscribers.forEach(subscriberCallback => subscriberCallback());
};

export const removeTokenInfo = () => {
  removeDataFromLocalStorage(tokenInfo);
};

export function logout() {
  // app cleanup
  localLogoutCleanup();
  try {
    remove(`${AUTHENTICATOR_ENDPOINT}`).then(response => {
      if (!response.ok) {
        throw new Error('Logout failed');
      }

      removeTokenInfo();
      removeDataFromLocalStorage('tokenExpirationTime');
      window.location.replace(ecdsLogoutPageUrl);
    });
  } catch (error) {
    removeTokenInfo();
    removeDataFromLocalStorage('tokenExpirationTime');
    removeAiSubscription();
    window.location.replace(ecdsLogoutPageUrl);
  }
}

export function login({ user, token }) {
  sessionStorage.setItem(userKey, JSON.stringify(user));
}

export function getUser() {
  const tokenInfo = getTokenInfo();

  if (!tokenInfo) return;

  const { userId, firstName, companyId, companyName, companyNameOverride = null, subscriptions = null } = tokenInfo;

  return {
    userId,
    firstName,
    companyId,
    companyName,
    companyNameOverride,
    subscriptions,
  };
}

export function getUserId() {
  const tokenInfo = getTokenInfo();

  if (!tokenInfo) return;

  const { userId } = tokenInfo;
  return userId;
}

export function getTokenInfo() {
  return JSON.parse(getDataFromLocalStorage(tokenInfo));
}

export function setTokenInfo(token) {
  setDataToLocalStorage(tokenInfo, JSON.stringify(token));
}

export function logoutSubscribe(callback) {
  const index = logoutSubscribers.push(callback) - 1;

  return {
    unsubscribe() {
      logoutSubscribers.splice(index, 1);
    },
  };
}

export function setProductInfo(info) {
  const { products } = info;
  let product;
  const productCodes = products['WKPP'];
  delete productCodes['packages'];

  for (const productCode in productCodes) {
    const editionCodes = productCodes[productCode];

    for (const editionCode in editionCodes) {
      product = {
        name: 'WKPP',
        productCode,
        editionCode,
      };
      break;
    }
    break;
  }

  sessionStorage.setItem(productInfo, JSON.stringify(product));
}

export function getProductInfo() {
  return JSON.parse(sessionStorage.getItem(productInfo));
}

export function removeProductInfo() {
  sessionStorage.removeItem(productInfo);
}

export function setAiSubscription(preferences) {
  if (!preferences) return false;
  let includeFound = false;
  let excludeFound = false;

  for (const preference of preferences) {
    if (preference.type === 'ai_labs_include' && preference.value === 'Y') {
      includeFound = true;
    } else if (preference.type === 'ai_labs_exclude' && preference.value === 'Y') {
      excludeFound = true;
    }
  }

  // Since XOR returns a binary value
  localStorage.setItem(aiSubscriptionInfo, Boolean(includeFound ^ excludeFound));

  // For the storage listener in nav
  window.dispatchEvent(new StorageEvent('storage'));
}

export function getAiSubscription() {
  return JSON.parse(localStorage.getItem(aiSubscriptionInfo) || false);
}

export function removeAiSubscription() {
  return localStorage.removeItem(aiSubscriptionInfo);
}

export function isGlobalTemplateAccount() {
  const { companyId } = getUser();
  return companyId === globalAccountId;
}

export function getUserInfo() {
  return JSON.parse(getDataFromLocalStorage(userInfo));
}

export function setUserInfo(data) {
  setDataToLocalStorage(userInfo, JSON.stringify(data));
}

export function removeUserInfo() {
  sessionStorage.removeItem(userInfo);
}
