import { BTAlert } from '@btas/jasper';

const GeneralErrorMessage = ({ dismissible, className = '', visible }) => (
  <BTAlert appear fixed btStyle="danger" className={className} dismissible={dismissible} visible={visible}>
    <span>
      The application has encountered a problem. If you continue to receive this error message, contact Customer Support
      by phone (<strong>1-800-424-2938</strong>) or email&nbsp;
      <b>
        <a href="mailto:appsupport@bloombergtax.com" rel="noreferrer" target="_blank">
          (appsupport@bloombergtax.com)
        </a>
      </b>
      &nbsp;.
    </span>
  </BTAlert>
);

export default GeneralErrorMessage;
