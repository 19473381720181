import React, { useState, useEffect, useContext } from 'react';
import { createPortal } from 'react-dom';
import { BTPopover, BTButton, BTAlert } from '@btas/jasper';
import GC from '../../../../SpreadSheets';
import EditorContext from '../EditorContext';
import { getApportionmentData, getInternationalTaxRateData, getStateTaxRateData } from './_spreadsheets/apis';
import { getMDYFormattedDate } from '../../../_shared/dateFormatting';
import { getFunctionArgumentValues, transformIntoString } from './_spreadsheets/stateTaxRateFormulaHelper';
import { intTaxRateFormulaName } from './_spreadsheets/formulas/intTaxRatesFormula';
import { stateTaxRateFormulaName } from './_spreadsheets/formulas/stateTaxRateFormula';
import { stateApportionFormulaName } from './_spreadsheets/formulas/stateApportionFormula';

class TaxRateTooltipHelper {
  /**
   * @public
   * @type {{ taxLawSummary: any, taxLawSummaryLink: any }}
   */
  taxData;

  /**
   * @public
   * @type {string}
   */
  message;

  /**
   * @public
   * @type {string}
   */
  errorMessage;

  /**
   * @abstract
   * @returns {Promise<void>}
   */
  fetchTaxRateData() {}

  static invalidValues = null;

  /**
   * @param {string} formula
   * @param {string|object} value
   * @returns {boolean}
   */
  static valid(formula, value) {
    if (!formula) {
      return false;
    }

    if (!this.invalidValues) {
      const { NotAvailable, Name } = GC.Spread.CalcEngine.Errors;
      this.invalidValues = [NotAvailable, Name].map(JSON.stringify);
    }

    return !this.invalidValues.includes(JSON.stringify(value));
  }

  /**
   * @param {any} sheet
   * @param {string} formula
   * @returns {TaxRateTooltipHelper|undefined}
   */
  static factory(sheet, formula) {
    let fnArguments;
    if (formula.includes(stateTaxRateFormulaName)) {
      fnArguments = getFunctionArgumentValues(sheet, formula, stateTaxRateFormulaName);
      return new StateTaxRateTooltipHelper(fnArguments);
    } else if (formula.includes(intTaxRateFormulaName)) {
      fnArguments = getFunctionArgumentValues(sheet, formula, intTaxRateFormulaName);
      return new IntTaxRateTooltipHelper(fnArguments);
    } else if (formula.includes(stateApportionFormulaName)) {
      fnArguments = getFunctionArgumentValues(sheet, formula, stateApportionFormulaName);
      return new ApportionRateTooltipHelper(fnArguments);
    }
    return undefined;
  }
}

class StateTaxRateTooltipHelper extends TaxRateTooltipHelper {
  constructor(fnArguments) {
    super();
    const [jurisdiction, , taxableIncome] = fnArguments;
    const periodStartDate = transformIntoString(fnArguments[1]);
    this.jurisdiction = jurisdiction;
    this.periodStartDate = periodStartDate;
    this.taxableIncome = taxableIncome;
  }

  async _fetchTaxRateData() {
    const taxRateData = await getStateTaxRateData({
      jurisdiction: this.jurisdiction,
      periodStartDate: this.periodStartDate,
      taxableIncome: this.taxableIncome,
    });
    const { tax_law_summary, tax_law_summary_link, upcomingRateChange, upcomingRateChangeDate, default_1, rate_type } =
      taxRateData.message;

    let upcomingMessage = '';
    if (rate_type && rate_type !== null && upcomingRateChangeDate) {
      const upcomingRateDateFormatted = getMDYFormattedDate(upcomingRateChangeDate);

      if (rate_type === 'Fixed') {
        upcomingMessage = `The corporate income tax rate for ${this.jurisdiction} will change from ${default_1} to ${upcomingRateChange}, effective ${upcomingRateDateFormatted}.`;
      } else if (rate_type === 'NotFixed') {
        upcomingMessage = `The corporate income tax rate table for ${this.jurisdiction} will change, effective ${upcomingRateDateFormatted}.`;
      }
    }

    this.message = upcomingMessage;
    this.taxData = { taxLawSummary: tax_law_summary, taxLawSummaryLink: tax_law_summary_link };
  }

  async fetchTaxRateData() {
    if (this.jurisdiction && this.periodStartDate) {
      try {
        this.errorMessage = undefined;
        await this._fetchTaxRateData();
        return this.taxData;
      } catch (_) {
        this.errorMessage = 'Error getting state tax rate.';
      }
    }
  }
}

class IntTaxRateTooltipHelper extends TaxRateTooltipHelper {
  constructor(fnArguments) {
    super();
    this.country = fnArguments[0];
    this.periodStartDate = fnArguments[1];
    this.taxableIncome = fnArguments[2];
    this.taxRateData = null;
  }

  _taxLawSummary() {
    const { country, taxRate, taxRates, taxRateType, severalIndustryTypes } = this.taxRateData;

    const formatTaxRate = taxRate => `${Math.round(taxRate * 100) / 100}%`;

    let { date } = this.taxRateData;
    if (/\d{4}-\d{1,2}-\d{1,2}/.test(date)) {
      const dateSplit = date.split('-');
      date = `${dateSplit[1]}-${dateSplit[2]}-${dateSplit[0]}`;
    }

    let message;
    if (taxRateType === 'Fixed') {
      const taxRatePercentage = formatTaxRate(taxRate);
      message = `The ${country} corporate income tax rate for tax years beginning ${date} is ${taxRatePercentage}.`;
    } else {
      const sortedTaxRates = taxRates.map(parseFloat).sort((a, b) => a - b);
      const firstRate = formatTaxRate(sortedTaxRates[0]);
      const lastRate = formatTaxRate(sortedTaxRates[taxRates.length - 1]);
      message = `The ${country} corporate income tax rate for tax years beginning ${date} ranges from ${firstRate} to ${lastRate}.`;
    }

    if (message && severalIndustryTypes) {
      message +=
        'BOLDThere may be different rates applicable depending on your circumstances, ' +
        'such as for small businesses or specialized industries.BOLD';
    }

    return message;
  }

  async _fetchTaxRateData() {
    this.taxRateData = await getInternationalTaxRateData({
      country: this.country,
      periodStartDate: this.periodStartDate,
      taxableIncome: this.taxableIncome,
    });

    this.taxData = {
      taxLawSummary: this._taxLawSummary(),
      taxLawSummaryLink: this.taxRateData.citations,
    };
  }

  async fetchTaxRateData() {
    if (this.country && this.periodStartDate) {
      try {
        this.errorMessage = undefined;
        await this._fetchTaxRateData();
        return this.taxData;
      } catch (_) {
        this.errorMessage = 'Error getting international tax rate.';
      }
    }
  }
}

class ApportionRateTooltipHelper extends TaxRateTooltipHelper {
  constructor(fnArguments) {
    super();
    this.state = fnArguments[0];
    this.date = fnArguments[1];
    this.type = fnArguments[2];
    this.taxRateData = null;
  }

  _taxLawSummary() {
    const { summary, citation } = this.taxRateData;

    let message;
    message = summary?.replace(citation, '').trim();

    return message;
  }

  async _fetchTaxRateData() {
    this.taxRateData = await getApportionmentData({
      state: this.state,
      date: this.date,
      type: this.type,
    });

    this.taxData = {
      taxLawSummary: this._taxLawSummary(),
      taxLawSummaryLink: this.taxRateData.source,
    };
  }

  async fetchTaxRateData() {
    if (this.state && this.date) {
      try {
        this.errorMessage = undefined;
        await this._fetchTaxRateData();
        return this.taxData;
      } catch (e) {
        this.errorMessage = 'Error getting apportion rate.';
      }
    }
  }
}

/**
 * create a react portal and attach to dom
 * @param { children, parent, className }
 * @return return the createPortal function
 */
function TaxLawPortal({ children, parent, className }) {
  const element = document.createElement('div');

  useEffect(() => {
    const target = parent?.appendChild ? parent : document.body;
    element.classList.add('portal-container');
    target.appendChild(element);
    return () => {
      // Remove element from dom on unmount
      target.removeChild(element);
    };
  }, [element, parent, className]);

  return createPortal(children, element);
}

let headerHeight;
let leftNavBarWidth;
const gavelPositioningGap = 5;

const TaxLawSummary = () => {
  const { spreadRef } = useContext(EditorContext);
  const [cellData, setCellData] = useState(null);
  const [error, setError] = useState();
  const [upcomingRateMsg, setUpcomingRateMsg] = useState('');

  useEffect(() => {
    const gridPosition = document.getElementsByClassName('spreadWrapper')[0].getBoundingClientRect();
    headerHeight = gridPosition.top;
    leftNavBarWidth = gridPosition.left;
  }, []);

  useEffect(() => {
    const spread = spreadRef?.current;
    spread?.bind(GC.Spread.Sheets.Events.EnterCell, tooltipHandler);
    spread?.bind(GC.Spread.Sheets.Events.TopRowChanged, tooltipHandler);
    spread?.bind(GC.Spread.Sheets.Events.LeftColumnChanged, tooltipHandler);

    return () => {
      spread?.unbind(GC.Spread.Sheets.Events.EnterCell, tooltipHandler);
      spread?.unbind(GC.Spread.Sheets.Events.TopRowChanged, tooltipHandler);
      spread?.unbind(GC.Spread.Sheets.Events.LeftColumnChanged, tooltipHandler);
    };
  }, []); // eslint-disable-line

  function cell(sheet) {
    const col = sheet.getActiveColumnIndex();
    const row = sheet.getActiveRowIndex();
    const formula = sheet.getFormula(row, col);
    const value = sheet?.getValue(row, col);

    return { col, row, formula, value };
  }

  function cellPosition(sheet) {
    const { row, col } = cell(sheet);
    const spread = spreadRef?.current;
    const cellRect = spread.getActiveSheet().getCellRect(row, col);
    const x = cellRect.x + cellRect.width + leftNavBarWidth + gavelPositioningGap;
    const y = cellRect.y + cellRect.height + headerHeight + gavelPositioningGap;

    return { x, y };
  }

  async function tooltipHandler(_, { sheet }) {
    const { formula, value } = cell(sheet);

    if (!TaxRateTooltipHelper.valid(formula, value)) {
      setCellData(null);
    } else {
      const taxRateTooltipHelper = TaxRateTooltipHelper.factory(sheet, formula);
      if (taxRateTooltipHelper) {
        await taxRateTooltipHelper.fetchTaxRateData();
        const { taxData, message, errorMessage } = taxRateTooltipHelper;

        if (errorMessage) {
          setCellData(null);
          setUpcomingRateMsg(null);
          setError(errorMessage);
        } else {
          setCellData({ ...taxData, ...cellPosition(sheet), show: true });
          setUpcomingRateMsg(message);
          setError(null);
        }
      }
    }
  }

  /**
   * replace "BOLD" into <strong /> tag
   * @param { String }
   * @returns HTML
   */
  const FormatSummary = ({ content }) => {
    const regex = /(?<=BOLD)(.*?)(?=BOLD)/;
    while (content?.includes('BOLD')) {
      let matched = regex.exec(content);
      let wrap = `<strong style="margin-top: 1em;">${matched[1]}</strong>`;
      content = content.replace(`BOLD${matched[1]}BOLD`, wrap);
    }
    return <p dangerouslySetInnerHTML={{ __html: content }} />;
  };

  return (
    <TaxLawPortal className="modal-portal">
      {cellData?.show && (
        <div className="wkp_taxrate_helper" style={{ top: cellData?.y, left: cellData?.x }}>
          <BTPopover
            boundary="scrollParent"
            content={
              <div className="wkp_taxrate_popover">
                <FormatSummary content={cellData?.taxLawSummary} />
                <a href={cellData?.taxLawSummaryLink} rel="noreferrer" target="_blank">
                  <strong>View related analysis in Bloomberg Tax Research</strong>
                  <i className="fas fa-external-link-alt fa-fw" />
                </a>
                {upcomingRateMsg && <BTAlert btStyle="info">{upcomingRateMsg}</BTAlert>}
              </div>
            }
            placement="bottom"
            triggerEvent="click keypress"
            type="trigger"
          >
            <BTButton btType="icon" icon={<i className="fas fa-gavel fa-fw" />} title="Related analysis" />
          </BTPopover>
        </div>
      )}
      {!!error && (
        <BTAlert
          dismissible
          fixed
          btStyle="danger"
          visible={!!error}
          onDismiss={() => {
            setError('');
          }}
        >
          {error}
        </BTAlert>
      )}
    </TaxLawPortal>
  );
};

export default TaxLawSummary;
