import * as wkpFetch from '../../../_shared/fetch';
import { spreadsheetServiceHostName, workpapersSpreadsheetModelHostName } from '../../../../configs/params';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { SJS_API } from '../../../../constants/featureFlags';

const getSpreadsheetServiceUrl = () =>
  isFeatureFlagEnabled(SJS_API) ? `${workpapersSpreadsheetModelHostName}` : `${spreadsheetServiceHostName}/spreadsheet`;

export async function getWorkpaperDataReferences(workpaperId, sheetName = '') {
  const response = await wkpFetch.get(
    `${getSpreadsheetServiceUrl()}/datareferences/${workpaperId}${sheetName && '/' + sheetName}`
  );
  if (!response.ok) {
    return null;
  }
  return await response.json();
}

export async function createWorkpaperDataReferences(workpaperId, metadata) {
  const body = { details: metadata };
  if (isFeatureFlagEnabled(SJS_API)) {
    body.workpaperId = workpaperId;
  }

  const response = await wkpFetch.post(`${getSpreadsheetServiceUrl()}/datareferences/create/${workpaperId}`, {
    body: JSON.stringify(body),
  });
  if (!response || !response.ok) {
    return null;
  }
  try {
    return await response.json();
  } catch {
    return null;
  }
}

export async function resolveFormulaValues(workpaperId, outputRequest) {
  const requestBody = JSON.stringify(outputRequest);
  const response = await wkpFetch.post(`${getSpreadsheetServiceUrl()}/datareferences/resolve/${workpaperId}`, {
    body: requestBody,
  });

  window.dataReferenceResolve && window.dataReferenceResolve.resolve();

  return response;
}

export async function updateReferences(workpaperId, references) {
  const body = JSON.stringify({
    workpaperId,
    details: references.map(({ row, column, id: referenceId, parameters, value, sheetName }) => ({
      row,
      column,
      referenceId,
      newValue: value,
      sheetName,
      parameters: typeof parameters === 'string' ? parameters : JSON.stringify(parameters),
    })),
  });
  const suffix = isFeatureFlagEnabled(SJS_API) ? `/${workpaperId}` : '';
  return await wkpFetch.put(`${getSpreadsheetServiceUrl()}/datareferences${suffix}`, {
    body,
  });
}

export async function deleteReferences(workpaperId, referenceIds) {
  const body = JSON.stringify({
    workpaperId: workpaperId,
    referenceIds,
  });
  const suffix = isFeatureFlagEnabled(SJS_API) ? `/${workpaperId}` : '';
  const response = await wkpFetch.remove(`${getSpreadsheetServiceUrl()}/datareferences${suffix}`, {
    body,
  });
  if (!response.ok) {
    throw await response.json();
  }
  return response;
}

export async function getAssociatedDataTransformations(workpaperId, taxPeriod, currTaxPeriod) {
  const response = await wkpFetch.get(
    `${getSpreadsheetServiceUrl()}/datareferences/${workpaperId}/tax-period/${taxPeriod}/current-tax-period/${currTaxPeriod}`
  );
  if (response.status !== 200) {
    return null;
  }
  const data = await response.json();
  return data;
}
