import { useCallback, useEffect, useMemo, useState } from 'react';
import { isFeatureFlagEnabled as isEnabled } from './featureFlags';
import { getUser } from '../components/_shared/auth';
import LDClient from '../components/_shared/launchDarklyClient';

export function useFeatureFlags() {
  const [featureFlags, setFeatureFlag] = useState([]);
  const [isFeatureFlagsLoaded, setFeatureFlagsLoaded] = useState(false);
  const ldClient = LDClient.getInstance();

  const userInfo = getUser();
  const { companyId = null } = userInfo != null && (typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo);

  // effect get all flags
  useEffect(() => {
    async function fetchFlags() {
      if (!ldClient.isInitDone && companyId !== null) {
        try {
          await ldClient.init(updateFlags);
          await ldClient.setFlags();
          saveFeatureFlags(ldClient.getFlags());
        } catch (error) {
          console.log('exception on using feature flags: ', error);
        }
      } else if (ldClient.isInitDone) {
        saveFeatureFlags(ldClient.getFlags());
      }
    }

    fetchFlags();
  }, [featureFlags, companyId, ldClient]);

  function updateFlags(updatedFlags) {
    setFeatureFlag(updatedFlags);
  }

  function saveFeatureFlags(data) {
    // Persist on window state and local state
    setFeatureFlag(data);
  }

  const isFeatureFlagEnabled = useCallback(
    flagName => {
      return isEnabled(flagName, featureFlags);
    },
    [featureFlags]
  );

  const excludeElementType = useCallback(
    (...params) => {
      return element => {
        for (const { type, flag } of params) {
          if (element.type === type) {
            return isFeatureFlagEnabled(flag);
          }
        }
        return element;
      };
    },
    [isFeatureFlagEnabled]
  );

  const featureFlag = useMemo(
    () => ({
      featureFlags,
      excludeElementType,
      isFeatureFlagEnabled,
    }),
    [excludeElementType, featureFlags, isFeatureFlagEnabled]
  );

  useEffect(() => {
    if (featureFlag && featureFlag.featureFlags && featureFlag.featureFlags.length > 0) {
      setFeatureFlagsLoaded(true);
    }
  }, [featureFlag]);

  return { featureFlag, isFeatureFlagsLoaded };
}
