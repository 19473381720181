import { exportIcons } from '../cellReview/exportIcons';
const { checkExclamationIcon } = exportIcons();

export const REVIEWED = 'REVIEWED';
export const STATE_TAX_RATE_ERROR = 'STATE_TAX_RATE_ERROR';

// 1.5 is a sensible multiplier of the width found by trial and error to ensure it looks good and proportionate
const MULTIPLIER = 1.5;

// Tried to abstract this method as much as possible
// in order to be able to cover other cases in the future and not only in the case of reviewed cells.
export function adjustCells(x, w, condition) {
  if (condition === REVIEWED || condition === STATE_TAX_RATE_ERROR) {
    const adjustmentWidth = getIconWidth(checkExclamationIcon.image);
    x += adjustmentWidth;
    w -= adjustmentWidth;
  }
  return { newX: x, newW: w };
}

function getIconWidth(icon) {
  return Math.floor(icon.width * MULTIPLIER);
}
