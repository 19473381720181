import * as api from '../../../../_shared/fetch';
import { spreadsheetServiceHostName, workpapersSpreadsheetModelHostName } from '../../../../../configs/params';
import { SJS_API } from '../../../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../../../utils/featureFlags';

export async function copySheet({ sourceWorkpaperId, worksheetNames, targetWorkpaperId, beforeSheet, jobId }) {
  let response;
  const sjsEnabled = isFeatureFlagEnabled(SJS_API);
  if (sjsEnabled) {
    response = await api.post(`${workpapersSpreadsheetModelHostName}/file/copy-worksheets/${targetWorkpaperId}`, {
      body: JSON.stringify({
        sourceWorkbookId: sourceWorkpaperId,
        worksheetNames,
        beforeSheet,
        jobId,
      }),
    });
  } else {
    response = await api.post(
      `${spreadsheetServiceHostName}/spreadsheet/workbook/${sourceWorkpaperId}/copy-sheet/${targetWorkpaperId}`,
      {
        body: JSON.stringify({
          worksheetNames,
          beforeSheet,
        }),
      }
    );
  }

  const result = { success: response.ok };

  if (!response.ok) {
    if (response.status === 400 || response.status === 409) {
      const data = await response.json();
      result.errorMessage = data.title;
    } else {
      result.systemErrorMessage = await response.text();
    }
  }

  return result;
}

export async function getWorkpapers() {
  const response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers`);
  return await response.json();
}

export async function getWorkbookSheets(workpaperId) {
  const sjsEnabled = isFeatureFlagEnabled(SJS_API);
  let response;

  if (sjsEnabled) {
    response = await api.get(`${workpapersSpreadsheetModelHostName}/file/worksheet-names/${workpaperId}`);
  } else {
    response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workbook/${workpaperId}/worksheets`);
  }
  return await response.json();
}
