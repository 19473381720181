import { useCallback, useState } from 'react';
import { getOriginalName } from '../../shared/utils/FieldHashUtils';

export default function useFieldNameValidation() {
  const [outputFieldNameError, setOutputFieldNameError] = useState('');

  const validateFieldName = useCallback((fieldName, inputFields, displayErrorMessage = false) => {
    if (fieldName) {
      if (inputFields.find(f => getOriginalName(f).toLowerCase() === fieldName.toLowerCase())) {
        setOutputFieldNameError('Column name must be unique.');
      } else {
        setOutputFieldNameError('');
      }
    } else {
      displayErrorMessage ? setOutputFieldNameError('Column name is required.') : setOutputFieldNameError('');
    }
  }, []);
  return { validateFieldName, outputFieldNameError };
}
