import * as fetch from '../../../../_shared/fetch';
import { coreAPIHostName, dataFlowServiceHostName, nodeAPIServiceHostName } from '../../../../../configs/params';
import CustomLogger from '../../../../_shared/Logger/CustomLogger';

export async function downloadXLSXFile(id, dataFlowRunId) {
  const response = await fetch.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/${id}/data-flow-run/${dataFlowRunId}/download-xlsx`
  );
  if (!response.ok) {
    throw response.data;
  }
  const { presignedDownloadUrl } = await response.json();

  const link = document.createElement('a');
  link.href = presignedDownloadUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function generatePresignedUrls(outputInfo) {
  const response = await fetch.get(
    `${nodeAPIServiceHostName}/node/presignedurl?outputInfo=${encodeURIComponent(JSON.stringify(outputInfo))}`
  );
  if (!response.ok) {
    throw response.data;
  }

  return response.json();
}

export async function createNodeObject(payload) {
  const response = await fetch.post(`${nodeAPIServiceHostName}/node/objects`, { body: JSON.stringify(payload) });
  if (!response.ok) {
    throw response.data;
  }
  return response.json();
}

export async function existedNode(resourceId) {
  const id = encodeURIComponent(resourceId);
  const response = await fetch.get(`${nodeAPIServiceHostName}/node/objects?resourceId=${id}`);
  if (!response.ok) {
    throw response.data;
  }
  return response.json();
}

export async function updateNodeObject(payload) {
  const response = await fetch.put(`${nodeAPIServiceHostName}/node/objects`, { body: JSON.stringify(payload) });
  if (!response.ok) {
    throw response.data;
  }
  return response.json();
}

export async function dataFlowOutputExport(dataFlowRunId, outputsToBeExported) {
  const response = await fetch.put(
    `${dataFlowServiceHostName}/api/v1/data-flows/data-flow-run/${dataFlowRunId}/output-information`,
    { body: JSON.stringify(outputsToBeExported) }
  );
  if (!response.ok) {
    throw new Error('Error while sending to Fixed Assets');
  }
  return response.json();
}

export async function exportToTPClassic(payload) {
  const response = await fetch.post(`${coreAPIHostName}/export-xlsx-to-tax-provision`, {
    body: JSON.stringify(payload),
  });
  if (response.status !== 200) {
    throw new Error('Error while exporting to TP Classic');
  }
}

export async function convertOutputsFileToXlsx(payload) {
  try {
    const response = await fetch.post(`${coreAPIHostName}/csv-to-xls`, { body: JSON.stringify(payload) });
    if (response.status !== 200) {
      throw new Error('Error while converting csv outputs files to xlsx');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    CustomLogger.pushLog(CustomLogger.operations.CONVERT_CSV_TO_XLSX, {
      message: 'Error converting output files to xlsx',
      payload: JSON.stringify(payload),
      error: JSON.stringify(error),
    });
  }
}
