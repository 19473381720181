import { isMatch, isValid } from 'date-fns';
import GC from '../../../../../SpreadSheets';

const dateFormats = [
  'MM-dd-yyyy',
  'M-d-yyyy',
  'MM-d-yyyy',
  'M-dd-yyyy',
  'MM/dd/yyyy',
  'M/d/yyyy',
  'MM/d/yyyy',
  'M/dd/yyyy',
  'yyyy-MM-dd',
  'yyyy-M-d',
  'yyyy-M-dd',
  'yyyy-MM-d',
  'yyyy/MM/dd',
  'yyyy/MM/d',
  'yyyy/M/dd',
  'yyyy/M/d',
];

export const momentDateFormats = [
  'MM-DD-YYYY',
  'M-D-YYYY',
  'MM-D-YYYY',
  'M-DD-YYYY',
  'MM/DD/YYYY',
  'M/D/YYYY',
  'MM/D/YYYY',
  'M/DD/YYYY',
  'YYYY-MM-DD',
  'YYYY-M-D',
  'YYYY-M-DD',
  'YYYY-MM-D',
  'YYYY/MM/DD',
  'YYYY/MM/D',
  'YYYY/M/DD',
  'YYYY/M/D',
  'ddd MMM DD YYYY HH:mm:ss',
];

export const operatorMap = {};
for (const [operatorString, operatorType] of Object.entries(GC.Spread.CalcEngine.preOperaterTypesMap)) {
  operatorMap[operatorType] = operatorString;
}

for (const [operatorString, operatorType] of Object.entries(GC.Spread.CalcEngine.operaterTypesMap)) {
  operatorMap[operatorType] = operatorString;
}

export function validateJurisdiction(jurisdictions, jurisdiction) {
  return (
    jurisdictions &&
    Array.isArray(jurisdictions) &&
    jurisdictions.some(
      item =>
        item.jurisdiction.toLowerCase() === jurisdiction.toLowerCase() ||
        item.abbreviation.toLowerCase() === jurisdiction.toLowerCase()
    )
  );
}

export function validatePeriodStartDate(periodStartDate) {
  const recordAsDate = new Date(periodStartDate);
  const isFormatValid = dateFormats.some(df => isMatch(periodStartDate, df));

  return isValid(recordAsDate) && isFormatValid;
}
