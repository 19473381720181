import React from 'react';
import { BTGrid, BTModal } from '@btas/jasper';
import Spinner from '../../_shared/Spinner';
import { getFormattedDate } from '../../_shared/dateFormatting';
import { wijmoKey } from '../../../configs/params';
import useGrid from './ViewSourceDataModal/useGrid';
import './ViewSourceDataModal/styles.scss';

export default function ViewSourceDataModal({ show, sourceData, onClose }) {
  const { dataFlow, fileName, fileVersionId, importedDate } = sourceData || {};

  const [state, actions] = useGrid({ dataFlow, fileVersionId });
  const { isLoading, gridData, columns, sort } = state;
  const { setSort } = actions;

  const handleSort = sortColumn => setSort(sortColumn);

  return (
    <BTModal
      className="wkp-view-source-data-modal-xl"
      show={show}
      title={fileName || dataFlow?.name}
      onCloseClick={onClose}
    >
      <BTModal.Body>
        {isLoading && <Spinner />}
        {!isLoading && (
          <>
            <p className="help-block wkp-header-text">
              <span>Imported: {importedDate ? getFormattedDate(importedDate) : ''}</span>
              <span>Data Transformation Workflow: {dataFlow?.name}</span>
            </p>
            <BTGrid
              allowSorting
              allowResizing={1}
              className="wkp-imported-file-grid"
              data={gridData}
              defaultSortedColumn={[sort]}
              wijmoKey={wijmoKey}
            >
              {columns.map(col => (
                <BTGrid.Header key={col.key} align="left" binding={col.key} width={col.width} onSort={handleSort}>
                  {col.key}
                </BTGrid.Header>
              ))}
            </BTGrid>
          </>
        )}
      </BTModal.Body>
    </BTModal>
  );
}
