import React, { useContext, useState } from 'react';
import { BTModal, BTButton } from '@btas/jasper';
import { deleteWorkpapers, workpapersLockStatus } from './DeleteModal/apis';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { RECYCLE_BIN_ENABLED } from '../../../../constants/featureFlags';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { getWorkflowsUserCanDelete } from '../../../auth/permissions/CanDeleteWorkflow';
import UserPermissionsContext from '../../../_shared/UserPermissionsContext';

export default function DeleteModal({
  selection,
  show,
  onClose,
  onDeleteProcessed,
  setHasDeleteWfError,
  setUndeletedWorkpapers,
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const { userPermissions } = useContext(UserPermissionsContext);
  const ids = selection.map(sel => sel.id);
  const message = isFeatureFlagEnabled(RECYCLE_BIN_ENABLED)
    ? 'The selected workpapers will be moved to the recycle bin. Are you sure?'
    : ' The selected workpapers will be permanently deleted and links to/from these workpapers will be broken. Are you sure?';
  const handleDelete = async () => {
    const operationStartTime = Date.now();
    setIsDeleting(true);
    if (userPermissions.isFeatureFlagEnabled()) {
      const workpapersUserCanDelete = getWorkflowsUserCanDelete(selection, userPermissions);

      if (workpapersUserCanDelete.size > 0) {
        const workpapersLock = await workpapersLockStatus(Array.from(workpapersUserCanDelete.keys()));
        if (workpapersLock && workpapersLock.length > 0) {
          await deleteWorkpapersLock(workpapersLock, operationStartTime);

          CustomLogger.pushLog(CustomLogger.operations.DELETE, {
            duration: (Date.now() - operationStartTime).toString(),
            ids: JSON.stringify(ids),
          });
        }
      }
      if (workpapersUserCanDelete.size !== selection.length) {
        const failedToDelete = selection.filter(workpapers => !workpapersUserCanDelete.has(workpapers.id));
        CustomLogger.pushLog(CustomLogger.operations.DELETE_WORKFLOW, {
          duration: (Date.now() - operationStartTime).toString(),
          ids: JSON.stringify(failedToDelete.map(workpaper => workpaper.id)),
        });
        userPermissions.setErrorData(failedToDelete.map(workpaper => workpaper.name));
        setHasDeleteWfError(failedToDelete.map(workpaper => workpaper.name));
      }
      setIsDeleting(false);
      onDeleteProcessed();
    } else {
      const workpapersLock = await workpapersLockStatus(ids);
      if (workpapersLock && workpapersLock.length > 0) {
        await deleteWorkpapersLock(workpapersLock, operationStartTime);

        setIsDeleting(false);
        onDeleteProcessed();

        CustomLogger.pushLog(CustomLogger.operations.DELETE, {
          duration: (Date.now() - operationStartTime).toString(),
          ids: JSON.stringify(ids),
        });
      }
    }
    setIsDeleting(false);
  };

  async function deleteWorkpapersLock(workpapersLock) {
    const workpaperIdsToDelete = workpapersLock.filter(x => !x.isLocked).map(x => x.id);
    const undeletedWorkpapers = workpapersLock.filter(x => x.isLocked);
    if (workpaperIdsToDelete && workpaperIdsToDelete.length > 0) {
      await deleteWorkpapers(workpaperIdsToDelete);
    }
    if (undeletedWorkpapers && undeletedWorkpapers.length > 0) {
      setUndeletedWorkpapers(undeletedWorkpapers);
    }
  }

  return (
    <BTModal btStyle="warning" id="delete-modal" show={show} title="Delete Workpapers" onCloseClick={onClose}>
      <BTModal.Body>
        <p>{message}</p>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton btStyle={isDeleting ? 'primary' : 'default'} disabled={isDeleting} onClick={onClose}>
          Cancel
        </BTButton>
        <BTButton btStyle="primary" disabled={isDeleting} hasSpinner={isDeleting} onClick={handleDelete}>
          Delete
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
