import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BTButton, BTIcon, BTModal, BTGrid } from '@btas/jasper';
import React from 'react';
import './styles.scss';
import useGrid from './useGrid';
import { wijmoKey } from '../../../configs/params';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import workpaperFileIcon from './wkp_file_icon.svg';
import PropTypes from 'prop-types';
import GeneralErrorMessage from '../GeneralErrorMessage';

const SftpModal = ({
  show,
  onOpen,
  onClose,
  generalError,
  setGeneralError,
  resourceId,
  resourceType,
  sourceWorkpaperName,
}) => {
  const [gridConfig, gridActions] = useGrid(setGeneralError, resourceId, resourceType);
  const { isLoading, gridData, /*defaultSort,*/ selectedObject } = gridConfig;
  const { /*setGridSorting,*/ setSelectedObject, setRefreshedSFTP } = gridActions;
  const { items } = gridData;

  const [gridDataItems, setGridDataItems] = useState(items);
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const FOLDER_TYPE = 'folder';
  const isSending = !!sourceWorkpaperName;

  useEffect(() => {
    const updateGridItems = async () => {
      setGridDataItems(items);
    };
    updateGridItems();
  }, [items, breadcrumbPath, selectedObject, setBreadcrumbPath, selectedFile]);

  const onRowSelected = rowList => {
    const hasMultipleRows = rowList.length > 1;
    const hasNoRows = rowList.length === 0;

    if (hasMultipleRows || hasNoRows) {
      if (isSending && hasMultipleRows) {
        setSelectedFile({ disableButton: true });
        return;
      }
      setSelectedFile(null);
      return;
    }

    const file = rowList[0];
    onSelectionChanged(file);
  };

  const onSelectionChanged = file => {
    let breadcrumbToUpdate = breadcrumbPath;
    if (!file) {
      // restart the path when file is null
      breadcrumbToUpdate.splice(0, breadcrumbPath.length);
      setBreadcrumbPath(breadcrumbToUpdate);
      setSelectedObject(file);
      setSelectedFile(null);
      return;
    }

    if (file.type === FOLDER_TYPE) {
      const pathIndex = breadcrumbPath.findIndex(object => {
        return object.path === file.path;
      });
      if (pathIndex > -1) {
        // only remove all the elements after the found folder
        breadcrumbToUpdate.splice(pathIndex + 1);
      } else {
        if (!file.prefix) {
          file.prefix = '';
        }
        file.prefix += `/${file.name}`;
        breadcrumbToUpdate.push(file);
      }

      setBreadcrumbPath(breadcrumbToUpdate);

      setSelectedObject(file);
      setSelectedFile(null);
      return;
    }
    // set the file to open
    setSelectedFile(file);
  };

  /* TODO: Implement sort */
  /*
  const onSortChange = order => setGridSorting(order);
  const handleSort = columnSort => {
    onSortChange(columnSort);
  };*/

  const isValidFile = fileExtension => {
    return ['xlsx', 'csv'].includes(fileExtension?.toLowerCase());
  };

  const isNameUnique = fileName => {
    return !gridDataItems.some(item => {
      const parsedItem = item.name.split('.').slice(0, -1).join('.');
      return parsedItem === fileName && item.type !== FOLDER_TYPE;
    });
  };

  const disableWkpSendButton = (selectedFile, sourceWorkpaperName) => {
    const multipleRow = !!selectedFile?.disableButton;
    const notAFolder = selectedFile && selectedFile?.type !== FOLDER_TYPE;
    return multipleRow || notAFolder || !isNameUnique(sourceWorkpaperName);
  };

  const isValidSelection = fileExtension => {
    return isSending
      ? FOLDER_TYPE === fileExtension
      : [FOLDER_TYPE, 'xlsx', 'csv'].includes(fileExtension?.toLowerCase());
  };

  const nameCell = ({ rowData }) => {
    if (!rowData) return null;
    let icon = 'file';
    switch (rowData.type) {
      case FOLDER_TYPE:
        icon = 'folder';
        break;
      case 'xlsx':
      case 'csv':
        icon = 'file-excel';
        break;
      default:
        break;
    }
    if (!isValidSelection(rowData.type)) {
      return (
        <span className="sftp-invalid-file-cell">
          <BTIcon icon={icon} />
          {rowData.name}
        </span>
      );
    }

    return (
      <>
        {['csv', 'xlsx'].includes(rowData.type) ? (
          <img alt="icon wkp file" className="wkp-file-excel-icon" src={workpaperFileIcon} />
        ) : (
          <BTIcon className="wkp-folder-icon-class" icon={icon} />
        )}
        <span className="sftp-file-cell">{rowData.name}</span>
      </>
    );
  };

  const typeCell = ({ rowData }) => {
    if (!rowData) return null;
    let type;
    switch (rowData.type.toLowerCase()) {
      case FOLDER_TYPE:
        type = 'Folder';
        break;
      case 'xlsx':
        type = 'Microsoft Excel Worksheet';
        break;
      case 'csv':
        type = 'Comma Separated Values File';
        break;
      default:
        type = 'Unsupported File';
        break;
    }
    if (!isValidSelection(rowData.type)) {
      return <span className="sftp-invalid-file-cell">{type}</span>;
    }

    return (
      <>
        <span>{type}</span>
      </>
    );
  };

  const lastModifiedCell = ({ rowData }) => {
    if (!rowData) return null;
    if (!isValidSelection(rowData.type)) {
      return <span className="sftp-invalid-file-cell">{rowData.lastModified}</span>;
    }

    return (
      <>
        <span>{rowData.lastModified}</span>
      </>
    );
  };

  const fileSizeCell = ({ rowData }) => {
    if (!rowData) return null;

    if (!isValidSelection(rowData.type)) {
      return <span className="sftp-invalid-file-cell">{rowData.fileSize}</span>;
    }

    return (
      <>
        <span>{rowData.fileSize}</span>
      </>
    );
  };

  const renderBreadcrumb = () => {
    return (
      <>
        <div className="wkp-dialog-breadcrumb">
          <BTButton btStyle="link" onClick={() => onSelectionChanged(null)}>
            SFTP
          </BTButton>
          {breadcrumbPath.map(folder => {
            return (
              <>
                <BTIcon icon="chevron-right" />
                <BTButton btStyle="link" onClick={() => onSelectionChanged(folder)}>
                  {folder.name}
                </BTButton>
              </>
            );
          })}
        </div>
      </>
    );
  };

  const getEmptyStateDescription = () => {
    return (
      <>
        Files uploaded to the SFTP site will appear here.&ensp;
        <Link rel="noopener noreferrer" target="_blank" to={`/help/index.html?article=SFTP`}>
          Help with SFTP site.
        </Link>
      </>
    );
  };
  return (
    <BTModal
      className="wkp-sftp-pick-file-dialog"
      show={show}
      size="modal-lg"
      title={isSending ? 'Select Folder' : 'Select Source File'}
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <div className="wkp-dialog-path">
          {renderBreadcrumb()}
          <div className="wkp-dialog-refresh">
            <BTButton btStyle="link" onClick={() => setRefreshedSFTP(true)}>
              <FontAwesomeIcon className="fa-fw" icon={faSync} />
              Refresh
            </BTButton>
          </div>
        </div>
        <BTGrid
          allowSelection
          //defaultSortedColumn={[defaultSort]}
          data={gridDataItems}
          isLoading={isLoading}
          loadingType={'spinner'}
          wijmoKey={wijmoKey}
          onClearFilterClick={onRowSelected}
          onSelectionChanged={onRowSelected}
        >
          <BTGrid.Header
            key="name"
            align="left"
            binding="name"
            cellTemplate={nameCell}
            maxWidth={350}
            width="2*"
            //onSort={handleSort}
          >
            Name
          </BTGrid.Header>
          <BTGrid.Header
            key="type"
            align="left"
            binding="type"
            cellTemplate={typeCell}
            maxWidth={220}
            minWidth={220}
            width="2*"
            //onSort={handleSort}
          >
            Type
          </BTGrid.Header>
          <BTGrid.Header
            key="lastModified"
            align="left"
            binding="lastModified"
            cellTemplate={lastModifiedCell}
            maxWidth={200}
            width="2*"
            //onSort={handleSort}
          >
            Last modified
          </BTGrid.Header>
          <BTGrid.Header
            key="fileSize"
            align="left"
            binding="fileSize"
            cellTemplate={fileSizeCell}
            maxWidth={90}
            width="*"
            //onSort={handleSort}
          >
            File size
          </BTGrid.Header>
          <BTGrid.EmptyState
            emptyStateDescription={getEmptyStateDescription()}
            icon={<BTIcon icon={'folder'} />}
            message={'This folder is empty.'}
          />
        </BTGrid>
        {generalError?.message && <GeneralErrorMessage dismissible={true} />}
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onClose}>Cancel</BTButton>
        <BTButton
          btStyle="primary"
          disabled={
            (isSending ? disableWkpSendButton(selectedFile, sourceWorkpaperName) : !isValidFile(selectedFile?.type)) ||
            !!generalError?.message ||
            isLoading
          }
          hasSpinner={false}
          onClick={() => onOpen(isSending ? selectedObject?.path : selectedFile)}
        >
          {isSending ? 'Send Here' : 'Select'}
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

SftpModal.propTypes = {
  sourceWorkpaperName: PropTypes.any,
};

export default SftpModal;
