export const copyCache = {
  get hasAny() {
    return this._value !== undefined;
  },
  get() {
    return this._value;
  },
  set(value) {
    this._value = value;
  },
  clear() {
    this._value = undefined;
  },
};
