import * as fetch from '../../../../_shared/fetch';
import { spreadsheetServiceHostName, workpapersSpreadsheetModelHostName } from '../../../../../configs/params';
import { isFeatureFlagEnabled } from '../../../../../utils/featureFlags';
import { SJS_API } from '../../../../../constants/featureFlags';

export async function getSourceDataCells(linkedOutputIds) {
  const spreadsheetServiceUrl = isFeatureFlagEnabled(SJS_API)
    ? `${workpapersSpreadsheetModelHostName}`
    : `${spreadsheetServiceHostName}/spreadsheet`;
  const response = await fetch.post(`${spreadsheetServiceUrl}/datareferences/data-flows/linked-workpapers`, {
    body: JSON.stringify(linkedOutputIds),
  });
  const data = await response.json();

  return data;
}
