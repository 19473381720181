import { addSheetHelpers } from './sheetHelpers';
import { overrideCellDefinition } from './cellOverrides';
import { attachSheetNameValidation } from './sheetNameValidation';
import { attachClipboardFunctionality } from './clipboard';
import { attachCommandsBehavior, overrideContextMenuFilterCommand } from './commandsBehavior';
import { createGoToSourceDataFlowCommand } from './commands/goToSourceDataFlow';
import { createCopySheetCommand } from './commands/copySheet';
import { createSendToTaxProvisionCommand } from './commands/sendToTaxProvision';
import { createSendToSFTPCommand } from './commands/sendToSFTP';
import { createCellReviewCommand } from './commands/cellReviewCommand';
import { createCellReviewCommand as oldCreateCellReviewCommand } from './commands/commands';
import { workpaperSourceDataDefinition } from './cellOverrides.SourceData';
import { resetDefaultBrowserBehavior } from './browserDefaultReset';
import { overrideShortcuts } from './commands/shortcuts/shortcutsOverrides';
import { isFeatureFlagEnabled } from '../../../../../utils/featureFlags';
import { CELL_REVIEW_ENABLED } from '../../../../../constants/featureFlags';

/**
 * Initialize some global spreadjs behavior such as helpers on GC.Spread.Sheets.Worksheet object and cell overrides.
 */
export function initSpreadjs(tooltipManager) {
  resetDefaultBrowserBehavior();
  addSheetHelpers();
  workpaperSourceDataDefinition(tooltipManager);
  overrideCellDefinition(tooltipManager);
  overrideContextMenuFilterCommand();
}

/**
 * Attach custom behavior to the spread, such as linking functionality and auto-save
 * @param {object} args { spread, workpaperId, enableAutoSave }
 */
export function attachBehavior({
  spread,
  workpaperId,
  tooltipManager,
  linkingFormulaReadOnly,
  navigator,
  setCommandsVisibleContext,
}) {
  attachCommandsBehavior({ spread, setCommandsVisibleContext });
  attachClipboardFunctionality({ spread, workpaperId });
  attachSheetNameValidation(spread);
  overrideShortcuts(spread);
  tooltipManager.captureTooltipSectionByElement(document.getElementById('designer'));
}

export { setCellDataSourceFormula, setStateTaxRateCell, registerGlobalCustomFunctions } from './formulas';
export {
  executeWithSuspendedPaint,
  isCellReference,
  getValueFromCellReference,
  isCalcError,
  formulaHelpers,
} from './spreadHelpers';

export function addCustomCommands(config, args) {
  const goToSourceDataFlowCommandConfig = createGoToSourceDataFlowCommand(args);
  const createCellReviewCommandConfig = isFeatureFlagEnabled(CELL_REVIEW_ENABLED)
    ? createCellReviewCommand(args)
    : oldCreateCellReviewCommand(args);
  const createCopySheetCommandConfig = createCopySheetCommand(args);
  const createSendToTaxProvisionCommandConfig = createSendToTaxProvisionCommand(args);
  const createSendToSFTPCommandConfig = createSendToSFTPCommand(args);
  return {
    ...config,
    commandMap: {
      ...config.commandMap,
      ...goToSourceDataFlowCommandConfig,
      ...createCellReviewCommandConfig,
      ...createCopySheetCommandConfig,
      ...createSendToTaxProvisionCommandConfig,
      ...createSendToSFTPCommandConfig,
    },
  };
}
