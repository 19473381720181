/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { BTComboBox } from '@btas/jasper';
import {
  buildDropdownOption,
  removeFirstAndLastCharacter,
  removeQuotationMarks,
} from '../../../_spreadsheets/sourceDataConnectionHelper';
import { getValueFromCellReference, isCellReference } from '../../../_spreadsheets';
import EditorContext from '../../../../EditorContext';
import { EventTrackingContext } from '../../../../../../_shared/EventTrackingContext';
import { getFakeEvent, itemTypes, setPropValues } from '../../../../../../_shared/EventTrackingContext/utils';
import { eventNames } from '../../../../../../_shared/EventTrackingContext/constants';

export default function ConnectionFilterCriteria({
  availableOptionFilters,
  element,
  fetchCriteriaValues,
  filters,
  index,
  readOnly,
  types,
  updateFilters,
}) {
  const [availableOptionValues, setAvailableOptionValues] = useState([]);
  const { trackEvent } = useContext(EventTrackingContext);
  const [criteriaValueSearch, setCriteriaValueSearch] = useState('');
  const [isFetchingCriteriaValues, setIsFetchingCriteriaValues] = useState(false);
  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(null);
  const searchLimit = 20;
  const filterName = filters[index].key.value;
  const { spreadRef } = useContext(EditorContext);

  const spread = spreadRef.current;

  useEffect(() => {
    updateCriteriaValues(filterName);
  }, [filterName]);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      updateCriteriaValues(filterName, null, true);
    }, 500);

    return () => {
      clearTimeout(delayDebounce);
    };
  }, [criteriaValueSearch]);

  useEffect(() => {
    updateCriteriaValues(filterName, availableOptionValues);
  }, [page]);

  const handleScroll = ({ target }) => {
    const { scrollTop, scrollHeight, clientHeight } = target;

    if (scrollTop + clientHeight >= scrollHeight) {
      const newPage = page + 1;
      if (newPage < pageLimit) {
        setPage(newPage);
      }
    }
  };

  const handleCriteriaNameChange = event => {
    if (!event) {
      let newFilters = filters;
      newFilters[index] = { key: { value: '', reference: undefined }, value: '', isReference: false };
      updateFilters(newFilters);
      return;
    }
    setAvailableOptionValues([]);
    setCriteriaValueSearch('');
    setPage(0);
    const valueWithoutQuotes = removeQuotationMarks(event.value);

    const isReference = event.isNew && isCellReference(spread, valueWithoutQuotes);

    const criteriaName = isReference ? getValueFromCellReference(spread, valueWithoutQuotes) : valueWithoutQuotes;
    const reference = isReference ? valueWithoutQuotes : undefined;

    updateCriteria({ value: criteriaName, element, reference });
    updateCriteriaValues(criteriaName);
  };

  const handleCriteriaValueChange = e => {
    let criteriaName;
    const newFilters = filters.map((filter, i) => {
      if (i === index) {
        const isReference = e?.isNew && isCellReference(spread, e?.value);
        criteriaName = filter.key.value;
        const value = !e
          ? undefined
          : isReference || types[criteriaName] === 'numeric' || e?.isNew
            ? e?.value
            : removeQuotationMarks(e?.value);

        return { ...filter, value, isReference };
      }
      return filter;
    });

    updateFilters(newFilters);
  };

  const handleCriteriaValueSearch = ({ target }) => {
    setPage(0);
    const value = types[filterName] === 'numeric' ? target.value : removeQuotationMarks(target.value);

    setCriteriaValueSearch(value);
  };

  const handleDeleteCriteria = () => {
    let newFilters;
    if (filters.length === 1) {
      newFilters = [{ key: { value: '', reference: undefined }, value: '', isReference: false }];
    } else {
      newFilters = filters.filter((_, i) => i !== index);
    }
    updateFilters(newFilters);
    setAvailableOptionValues([]);
  };

  const updateCriteria = ({ value, element, reference }) => {
    const newFilters = filters.map(filter => {
      if (filter.key.value === element.key.value) {
        return { ...filter, key: { value, reference }, value: '', isReference: false };
      }
      return filter;
    });

    updateFilters(newFilters);
  };

  const updateCriteriaValues = async (selectedFilter, prevOptions, isSearch = false) => {
    if (!selectedFilter) return;

    try {
      !isSearch && setIsFetchingCriteriaValues(true);
      const { records, totalRecordsCount } = await fetchCriteriaValues(
        selectedFilter,
        page,
        searchLimit,
        criteriaValueSearch
      );
      setPageLimit(Math.ceil(totalRecordsCount / searchLimit));

      const filterType = types[selectedFilter];
      const formattedAvailableOptions = records
        ?.filter(r => r !== null)
        .map(value => buildDropdownOption({ value, withQuotes: filterType !== 'numeric' }));

      if (formattedAvailableOptions) {
        if (prevOptions) {
          setAvailableOptionValues([...prevOptions, ...formattedAvailableOptions]);
        } else {
          setAvailableOptionValues(formattedAvailableOptions);
        }
      }
    } finally {
      setIsFetchingCriteriaValues(false);
    }
  };

  const getAvailableOptions = () => {
    const newFilters = filters.filter((_, i) => i !== index);
    return availableOptionFilters.filter(element => {
      const index = newFilters.findIndex(x => x.key.value === removeFirstAndLastCharacter(element.label));
      return index === -1;
    });
  };

  const getFilterName = () => {
    const { value, reference } = filters[index].key;
    if (!value) {
      return null;
    }

    return buildDropdownOption({ value: reference ?? value, withQuotes: reference == null && isNaN(value) });
  };

  const getFilterValue = () => {
    const { value, isReference } = filters[index];
    if (!value) {
      return null;
    }

    const isNewRawInput = types[filterName] === 'numeric' && isNaN(value) && !isReference;

    return buildDropdownOption({
      value: value,
      withQuotes: (types[filterName] !== 'numeric' && !isReference && isNaN(value)) || isNewRawInput,
    });
  };

  const selectedFilterName = getFilterName();
  const selectedFilterValue = getFilterValue();

  return (
    <div className="filter-criteria" id={`wkp-connection-filter-criteria-${index}`}>
      <div className="filter-criteria-item" data-testid="sdc-criteria-name">
        <BTComboBox
          key={`filter-${index}`}
          canCreateNew
          createText="Add"
          disabled={readOnly}
          isClearable={true}
          options={getAvailableOptions}
          placeholder="Field or cell reference"
          value={selectedFilterName}
          onChange={e => {
            // e.target does not exist, use the fake event with the specified tag
            const fakeEvent = getFakeEvent(itemTypes.DIV, 'wkp-connection-filter-criteria-field');

            // Track the event
            trackEvent(eventNames.interactiveClick, setPropValues(fakeEvent, eventNames.interactiveClick, null));

            // Handler
            handleCriteriaNameChange(e);
          }}
        />
      </div>
      <span className="equals">=</span>

      <div
        className="filter-criteria-item"
        data-testid="sdc-criteria-value"
        onChange={handleCriteriaValueSearch}
        onScroll={handleScroll}
      >
        <BTComboBox
          key={`value-${index}`}
          canCreateNew
          createText="Add"
          disabled={readOnly}
          isClearable={true}
          isLoading={isFetchingCriteriaValues}
          maxMenuHeight={100}
          noResultsMessage="No results found or no field has been selected"
          options={availableOptionValues}
          placeholder="Value or cell reference"
          value={selectedFilterValue}
          onChange={e => {
            // e.target does not exist, use the fake event with the specified tag
            const fakeEvent = getFakeEvent(itemTypes.DIV, 'wkp-connection-filter-criteria-value');

            // Track the event
            trackEvent(eventNames.interactiveClick, setPropValues(fakeEvent, eventNames.interactiveClick, null));

            // Handler
            handleCriteriaValueChange(e);
          }}
        />
      </div>

      <div className={`trash-can ${readOnly ? 'is-disabled' : ''}`} onClick={handleDeleteCriteria}>
        <FontAwesomeIcon className="fa-can" icon={faTrash} />
      </div>
    </div>
  );
}
