import React from 'react';
import times from './CloseButton/times.svg';

export default function CloseButton({ onClose }) {
  return (
    <button className="btn btn-link wkp-pane-close" onClick={onClose}>
      <img alt="Close" src={times} />
    </button>
  );
}
