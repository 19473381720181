import { useEffect } from 'react';

export function useClickAwayListener(element, callback) {
  useEffect(() => {
    if (callback && element) {
      function handleClickAway(event) {
        if (!element.contains(event.target)) {
          callback();
        }
      }
      document.addEventListener('mousedown', handleClickAway);
      return () => document.removeEventListener('mousedown', handleClickAway);
    }
  }, [element, callback]);
}
