import React, { useContext } from 'react';

import Dropzone from '../../shared/Dropzone';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import UploadFileDialog from '../FileDialog/UploadFileDialog';

const WKPInputFileImport = ({ updateIntegrationType }) => {
  const { dataFlowState, sourceFileUpload, dataFlowActions } = useContext(DataFlowEditorContext);
  const { dropzone, onSaveFile } = sourceFileUpload;

  return (
    <div className="wkp-sample-file-upload">
      <Dropzone className="wkp-sample-file-dropzone" dropzone={dropzone} />
      <UploadFileDialog
        dataFlowActions={dataFlowActions}
        dataFlowState={dataFlowState}
        updateIntegrationType={updateIntegrationType}
        onSaveFile={onSaveFile}
      />
    </div>
  );
};

export default WKPInputFileImport;
