import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/transpose_block_icon.svg';
import hintImage from '../icons/transpose_hint_img.svg';
import { FILTER_COLOR } from '../../shared/colors';
import { TransposeElementInspector } from '../../TransposeElementInspector';
import { TRANSPOSE } from '../types/shared/typesConstants';

export class TransposeElementType extends TransformationElementType {
  static TYPE = TRANSPOSE;

  static HELP_TEXT = `The transpose block converts rows to columns and vice versa. The values in the first column will become the new column headers.<img src=${hintImage} alt="Transpose hint" />`;

  constructor() {
    super(TransposeElementType.TYPE, 'Transpose', FILTER_COLOR, iconImage, TransposeElementType.HELP_TEXT);
  }

  get maxCount() {
    return -1;
  }

  applySourceElements(elementData, sourceElements) {
    const newElementData = super.applySourceElements(elementData, sourceElements);
    const fields = (elementData.fields ?? []).length ? elementData.fields : newElementData.fields;
    const sourceFields = newElementData.fields ?? [];
    const renamedFields = elementData.renamedFields || {};
    let exceedsColumnLimit =
      elementData.exceedsColumnLimit && sourceElements.in ? elementData.exceedsColumnLimit : false;

    return {
      ...newElementData,
      fields,
      sourceFields,
      renamedFields,
      exceedsColumnLimit,
    };
  }

  get inspectorComponent() {
    return TransposeElementInspector;
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      renamedFields: elementData.renamedFields,
      exceedsColumnLimit: elementData.exceedsColumnLimit,
      sourceFields: elementData.sourceFields,
    };
  }

  canPreview(elementData) {
    return !!elementData.fields;
  }

  getPreviewColumns(elementData) {
    const fields = elementData.fields || [];

    return fields;
  }
}
