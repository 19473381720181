import React, { useState } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Frame from '../_shared/Frame';
import Spinner from '../_shared/Spinner';
import DataFlowsList from './DataFlowsPage/DataFlowsList';
import DataFlowsListActions from './DataFlowsPage/DataFlowsListActions';
import EmptyState from './DataFlowsPage/EmptyState';
import RecentActivity from './DataFlowsPage/RecentActivity';
import useCreateDataFlow from './DataFlowsPage/useCreateDataFlow';
import useDataFlowRuns from './DataFlowsPage/useDataFlowRuns';
import useGrid from './DataFlowsPage/useGrid';
import ViewSourceDataModal from './DataFlowsPage/ViewSourceDataModal';
import DeleteDataFlowsModal from './DataFlowsPage/DeleteDataFlowsModal';
import DeleteDataFlowsErrorModal from './DataFlowsPage/DeleteDataFlowsErrorModal';
import { isFeatureFlagEnabled } from '../../utils/featureFlags';
import { DF_HIDE_RECENT_ACTIVITY_PANEL } from '../../constants/featureFlags';
import FailedToDeleteError from './FailedToDeleteError';

import './DataFlowsPage/styles.scss';
import ErrorRetrievingRole from './ErrorRetrievingRole';
import DeleteLockDataFlowErrorModal from './DataFlowsPage/DeleteLockDataflowErrorModal';

export default function DataFlowsPage({ showImportDialog }) {
  const [gridConfig, gridActions] = useGrid();
  const [createDataFlowView, createDataFlow] = useCreateDataFlow();
  const [runsState] = useDataFlowRuns();
  const [viewSourceData, setViewSourceData] = useState(null);
  const [showDeleteDataFlowModal, setShowDeleteDataFlowModal] = useState(false);
  const [showDeleteDataFlowErrorModal, setShowDeleteDataFlowErrorModal] = useState(false);
  const [showDeleteLockDataErrorModal, setShowDeleteLockDataFlowErrorModal] = useState(false);
  const [selectedLockDataflows, setSelectedLockDataFlow] = useState([]);
  const { isLoading, gridData, selectedDataFlows, gridSorting, gridFilters } = gridConfig;
  const isEmpty = gridData.length === 0;

  const handleViewSourceData = data => setViewSourceData(data);
  const handleViewSourceDataClose = () => setViewSourceData(null);
  const handleOpenDeleteDataFlowModal = () => setShowDeleteDataFlowModal(true);
  const handleCloseDeleteDataFlowModal = () => setShowDeleteDataFlowModal(false);
  const handleDeleteDataFlowsProcessed = () => {
    handleCloseDeleteDataFlowModal();
    gridActions.fetchDataFlows({ sorting: gridSorting, filters: gridFilters });
  };
  const handleDeleteDataFlowsError = () => {
    handleCloseDeleteDataFlowModal();
    setShowDeleteDataFlowErrorModal(true);
  };
  const handleDeleteLockDataflowError = lockedDataFlows => {
    setShowDeleteLockDataFlowErrorModal(true);
    setSelectedLockDataFlow(lockedDataFlows);
  };

  const handleCloseDeleteDataFlowErrorModal = () => setShowDeleteDataFlowErrorModal(false);

  return (
    <Frame activeItem="data-flows">
      <FailedToDeleteError appear dismissible fixed btStyle="danger">
        Test
      </FailedToDeleteError>
      <DeleteLockDataFlowErrorModal
        selectedLockDataflows={selectedLockDataflows}
        setShowDeleteLockDataFlowErrorModal={setShowDeleteLockDataFlowErrorModal}
        showDeleteLockDataErrorModal={showDeleteLockDataErrorModal}
      />

      <ErrorRetrievingRole appear dismissible fixed btStyle="warning"></ErrorRetrievingRole>

      <div className="wkp-data-flows-container">
        {createDataFlowView}
        {isLoading ? (
          <div className="wkp-data-flows-content">
            <Spinner />
          </div>
        ) : isEmpty ? (
          <div className="wkp-data-flows-content">
            <EmptyState onCreateDataFlowClick={createDataFlow} />
          </div>
        ) : (
          <>
            <div className="wkp-data-flows-content">
              <Grid fluid>
                <DataFlowsListActions />
                <DataFlowsList
                  actions={gridActions}
                  config={gridConfig}
                  onCreateDataFlowClick={createDataFlow}
                  onDeleteClick={handleOpenDeleteDataFlowModal}
                  onSelectionChanged={gridActions.setSelectedDataFlows}
                  onViewSourceData={handleViewSourceData}
                />
              </Grid>
            </div>
            {!isFeatureFlagEnabled(DF_HIDE_RECENT_ACTIVITY_PANEL) && (
              <RecentActivity
                error={runsState.error}
                isLoading={runsState.isLoading}
                runs={runsState.runs}
                onViewSourceData={handleViewSourceData}
              />
            )}
          </>
        )}
      </div>
      <ViewSourceDataModal
        show={viewSourceData !== null}
        sourceData={viewSourceData}
        onClose={handleViewSourceDataClose}
      />
      <DeleteDataFlowsModal
        handleDeleteLockDataflowError={handleDeleteLockDataflowError}
        selectedDataFlows={selectedDataFlows}
        show={showDeleteDataFlowModal}
        onClose={handleCloseDeleteDataFlowModal}
        onDeleteProcessed={handleDeleteDataFlowsProcessed}
        onError={handleDeleteDataFlowsError}
      />
      <DeleteDataFlowsErrorModal
        selectedDataFlows={selectedDataFlows}
        show={showDeleteDataFlowErrorModal}
        onClose={handleCloseDeleteDataFlowErrorModal}
      />
    </Frame>
  );
}
