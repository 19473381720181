import React, { useMemo } from 'react';
import ElementInspector from './shared/ElementInspector';
import { NUMERIC } from './shared/fieldTypes';
import { SINGLE, TABULAR } from '../../_shared/data-flows/outputFormatTypes';
import dataFlowConnectionHint from './elementType/icons/Data_flow_connections_hint.png';
import { BTCheckbox } from '@btas/jasper';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { EXPORT_TO_FIX_ASSETS } from '../../../constants/featureFlags';
import { identifiers } from '../../../constants/externalSystems';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';

// Labels are arbitrary but keys must match expected values on back end

const OutputElementInspector = ({ elementData, elementId, elementType, updateData, inputElements, isDirty }) => {
  const mappings = useMemo(() => elementData.mappings ?? {}, [elementData.mappings]);
  const fields = useMemo(() => elementData.fields ?? [], [elementData.fields]);
  const exportToFA = useMemo(() => elementData?.export?.FA ?? false[elementData.export], [elementData?.export]);
  const { outputFormat, tabularOutput } = elementData;

  const singleOutputOptions = useMemo(() => {
    return fields
      .filter(field => {
        if (field.type !== NUMERIC) {
          return false;
        }
        return !Object.values(mappings).find(m => m.name === field.name);
      })
      .map(field => ({ label: field.name, value: field }));
  }, [fields, mappings]);

  const alerts = useMemo(() => {
    if (Object.keys(inputElements).length === 0) {
      return [{ style: 'danger', content: 'Output block is not connected.' }];
    }

    if (outputFormat === SINGLE && (fields.length === 0 || singleOutputOptions.length === 0)) {
      return [{ style: 'danger', content: 'There are no available numeric fields as options for the output field.' }];
    }

    if (outputFormat === TABULAR && !Object.values(tabularOutput || []).some(v => v)) {
      return [{ style: 'danger', content: 'There are no selected columns to output.' }];
    }
  }, [inputElements, fields, singleOutputOptions, outputFormat, tabularOutput]);

  const handleFAOutputCheck = (name, { target }) => {
    updateData({
      export: {
        [name]: target.checked,
      },
    });
  };
  const canEditWorkflow = useCanEditWorkflow();
  return (
    <ElementInspector {...{ alerts, elementData, elementId, elementType, updateData, isDirty }}>
      <div className="wkp-output-element-inspector">
        {isFeatureFlagEnabled(EXPORT_TO_FIX_ASSETS) && (
          <div className="wkp-fa-export-output-element-inspector">
            <BTCheckbox
              checked={!!exportToFA}
              disabled={!canEditWorkflow}
              label="Include this output when sending to Fixed Assets"
              onChange={e => handleFAOutputCheck(identifiers.FA, e)}
            />
          </div>
        )}
        <div>
          <p>
            Tip: To link workpapers to this output, use the <span className="bold-text">Data Connections</span> panel on
            the right-hand side of a workpaper.
          </p>
          <img alt="connection hint" height="100" src={dataFlowConnectionHint} width="400" />
        </div>
      </div>
    </ElementInspector>
  );
};

export default OutputElementInspector;
