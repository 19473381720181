import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { BTAlert, BTButton, BTIcon } from '@btas/jasper';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import Dropzone from '../../shared/Dropzone';
import Spinner from '../../../_shared/Spinner';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import FileDialog from '../FileDialog/FileDialog';
import { useInputSourceFiles } from './useInputSourceFiles';
import FileSettings from '../FileSettings/FileSettings';

const DEFAULT_FIELDS = [];
const WKPInputSubInspector = ({ elementId, elementData, elementType, updateData, isDirty, updateAlerts }) => {
  const { dataFlowState, sourceFileUpload, dataFlowActions, previewState } = useContext(DataFlowEditorContext);
  const { valueErrorList, isWaiting } = previewState;
  const { setFilePropertiesDialog } = dataFlowActions;
  const { workingElement, filePropertiesDialog, taxPeriod } = dataFlowState;

  const { sourceFiles, setSourceFiles } = useInputSourceFiles(
    dataFlowState.id,
    elementId,
    elementData?.pendingSourceFileVersionId,
    filePropertiesDialog?.reloadFiles
  );

  const { dropzone, uploadError, isUploading, setUploadError } = sourceFileUpload;
  const [fieldErrors, setFieldErrors] = useState({});

  const [generalError, setGeneralError] = useState({
    message: undefined,
  });

  const fields = elementData.fields || DEFAULT_FIELDS;
  const { url } = useRouteMatch();
  const parentUrl = url.substring(0, url.lastIndexOf('/'));

  const onSubmit = async () => {
    setFilePropertiesDialog({
      popup: true,
      loading: false,
      uploadExistingFile: true,
      fileDialogTaxPeriod: taxPeriod,
      sourceInfo: {
        dataFlowId: dataFlowState.id,
        inputId: elementId,
        elementData: { ...workingElement.elementData, containsNewSourceFiles: true },
      },
      reloadFiles: true,
    });
    setUploadError(prevState => ({ ...prevState, [workingElement.id]: undefined }));
  };

  useEffect(() => {
    validateFields(fields);

    function validateFields(fieldList) {
      const newFields = fieldList;

      const newErrors = {};
      newFields.forEach((field, index) => {
        const error = validateName(index, field.name);
        if (error) {
          newErrors[index] = error;
        }
      });

      setFieldErrors(newErrors);
    }

    function validateName(index, name) {
      if (name === '') {
        return 'Column name is required.';
      } else {
        if (fields.find((f, fIndex) => f.name === name && fIndex !== index)) {
          return 'Column name must be unique.';
        } else {
          return null;
        }
      }
    }
  }, [fields]);

  useMemo(() => {
    const filteredErrors = [...new Set(Object.values(fieldErrors))];

    const alerts = [
      ...(Object.values(filteredErrors).length > 0
        ? [{ style: 'danger', content: Object.values(filteredErrors).map((err, key) => <p key={key}>{err}</p>) }]
        : []),
      ...(Object.keys(uploadError).length
        ? [{ style: 'danger', content: uploadError[workingElement.id] || null }]
        : []),
      valueErrorList?.length > 0 && !isWaiting ? { style: 'warning', content: valueErrorList || null } : [],
    ];
    updateAlerts(alerts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldErrors, uploadError, workingElement, valueErrorList, isWaiting]);

  const handleDismissAlert = () => {
    setGeneralError(null);
  };

  const importWKPFileDialog = () => {
    return (
      <div className="wkp-sample-file-upload">
        <Dropzone className="wkp-sample-file-dropzone" dropzone={dropzone} />
        <Row className="wkp-input-upload-file-row">
          <Col md={5}></Col>
          <Col md={4}>OR</Col>
        </Row>
        <BTButton className="wkp-input-upload-file-col" id="attach-existing-source-file" onClick={onSubmit}>
          ATTACH EXISTING SOURCE FILE
        </BTButton>
      </div>
    );
  };

  const fileSettings = useMemo(
    () => (
      <FileSettings
        elementData={elementData}
        elementId={elementId}
        importFile={importWKPFileDialog}
        setGeneralError={setGeneralError}
        setSourceFiles={setSourceFiles}
        sourceFiles={sourceFiles}
        updateData={updateData}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementId, elementData, sourceFiles, setGeneralError, updateData, setSourceFiles]
  );

  return (
    <div className="wkp-input-element-inspector">
      {(fields.length === 0 || sourceFiles?.length === 0 || sourceFiles[0]?.error) && (
        <Link to={parentUrl}>
          <BTIcon icon="arrow-left" />
          Select a different data source
        </Link>
      )}
      {fields.length > 0 ? (
        fileSettings
      ) : isUploading ? (
        <Spinner />
      ) : (
        <div className="wkp-sample-file-upload">
          <h4>Upload a source file.</h4>
          <Dropzone className="wkp-sample-file-dropzone" dropzone={dropzone} />
          <Row className="wkp-input-upload-file-row">
            <Col md={5}></Col>
            <Col md={4}>OR</Col>
          </Row>
          <BTButton className="wkp-input-upload-file-col" id="attach-existing-source-file" onClick={onSubmit}>
            ATTACH EXISTING SOURCE FILE
          </BTButton>
        </div>
      )}
      <FileDialog dataFlowActions={dataFlowActions} dataFlowState={dataFlowState} setGeneralError={setGeneralError} />
      <BTAlert
        appear
        dismissible
        fixed
        btStyle="danger"
        visible={generalError?.message !== undefined}
        onDismiss={handleDismissAlert}
      >
        {generalError ? generalError?.message : ''}
      </BTAlert>
    </div>
  );
};

export default WKPInputSubInspector;
