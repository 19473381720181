import React, { useCallback, useMemo } from 'react';
import { BTComboBox, BTForm } from '@btas/jasper';
import ElementInspector from './shared/ElementInspector';
import FieldsToAggregate from './AggregateElementInpector/FieldsToAggregate';
import FieldConstant from './shared/constants/fields.constant';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';

const AggregateElementInspector = ({ elementData, elementId, elementType, updateData, inputElements, isDirty }) => {
  const elementDataFields = elementData?.inputFields;
  const { groupBy: elementGroupBy = [] } = elementData;
  const canEditWorkflow = useCanEditWorkflow();

  const fields = useMemo(
    () => elementDataFields?.filter(f => f.name !== '' && f.name !== FieldConstant.COUNT) ?? [],
    [elementDataFields]
  );
  const groupByOptions = useMemo(
    () =>
      elementDataFields.map(field => ({
        label: field.original_name ? field.original_name : field.name,
        value: field.name,
      })),
    [elementDataFields]
  );
  const groupByValue = useMemo(
    () =>
      elementData?.groupBy.map(value => {
        const groupByField = elementDataFields.find(field => field.name === value || field?.original_name === value);
        const label = groupByField
          ? groupByField?.original_name
            ? groupByField.original_name
            : groupByField.name
          : value;
        return {
          label: label,
          value: groupByField ? groupByField.name : value,
        };
      }),
    [elementData.groupBy, elementDataFields]
  );

  const values = groupByValue.map(option => option.label);
  const options = groupByOptions.map(option => option.label);
  const unknownValues = useMemo(() => values.filter(value => !options.includes(value)) || [], [options, values]);

  const unknownValuesText = useMemo(() => {
    return unknownValues
      .map((item, index) => {
        const endCharacter = unknownValues.length === index + 1 ? '.' : ', ';
        return '‘' + item + '‘' + endCharacter;
      })
      .join('');
  }, [unknownValues]);

  const errorMessage = useMemo(() => {
    return unknownValues.length > 1 ? 'Unknown fields ' + unknownValuesText : 'Unknown field ' + unknownValuesText;
  }, [unknownValues.length, unknownValuesText]);

  const onGroupByChange = useCallback(
    selectedOptions => {
      if (canEditWorkflow) {
        updateData({ groupBy: selectedOptions ? selectedOptions.map(option => option.value) : [] });
      }
    },
    [canEditWorkflow, updateData]
  );

  const onFieldToAggregateChange = useCallback(
    fieldsToAggregate => {
      updateData({ fieldsToAggregate });
    },
    [updateData]
  );

  const alerts = useMemo(() => {
    if (Object.keys(inputElements).length === 0) {
      return [{ style: 'danger', content: 'Aggregate block is not connected.' }];
    }
  }, [inputElements]);

  return (
    <ElementInspector {...{ alerts, elementData, elementId, elementType, updateData, isDirty }}>
      <div>
        <BTForm.FormGroup errorText={errorMessage} hasError={unknownValues.length > 0} label="Group By">
          <BTComboBox
            isMulti
            popOutMenu
            disabled={!canEditWorkflow}
            hasError={unknownValues?.length > 0}
            maxMenuHeight={100}
            options={groupByOptions}
            value={groupByValue}
            onChange={onGroupByChange}
          />
        </BTForm.FormGroup>
        <FieldsToAggregate
          elementId={elementId}
          fields={fields}
          fieldsToAggregate={elementData.fieldsToAggregate}
          groupBy={elementGroupBy}
          onUpdate={onFieldToAggregateChange}
        />
      </div>
    </ElementInspector>
  );
};

export default AggregateElementInspector;
