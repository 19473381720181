export function convertInputToFilterValue(inputData) {
  if (inputData.length === 1) {
    return inputData[0].value || '';
  }
  const inputArray = inputData.map(item => (item.value ? '"' + item.value + '"' : '""'));
  const inputArrayWithoutDuplicates = Array.from(new Set(inputArray));
  if (inputArrayWithoutDuplicates.length === 1) {
    inputArrayWithoutDuplicates[0] = inputArrayWithoutDuplicates[0].slice(1, -1);
  }
  return inputArrayWithoutDuplicates.join(',');
}

export function convertFilterValueToInput(filterValue) {
  if (!filterValue) {
    return [];
  }

  // The regex is to split the filter value by the commas while ignoring commas that
  // are part of one of the filter criteria.
  // Example '"123,456","789"' should be split up into the array ["123,456","789"]
  // https://stackoverflow.com/questions/11456850/split-a-string-by-commas-but-ignore-commas-within-double-quotes-using-javascript
  const orCriteria = filterValue.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);

  if (!orCriteria) {
    return [{ value: '', label: '', isNew: false }];
  }

  if (orCriteria.length === 1 || !filterValue.includes('","')) {
    return [
      {
        value: filterValue,
        label: filterValue,
        isNew: false,
      },
    ];
  }

  return orCriteria.map(item => {
    //Remove extra quotes
    return {
      value: item.slice(1, -1),
      label: item.slice(1, -1),
      isNew: false,
    };
  });
}
