import { useContext, useEffect } from 'react';
import { ui } from '@clientio/rappid';
import { DataFlowEditorContext } from '../DataFlowEditorContext';

export const useNavigator = navigatorContainer => {
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);

  const { paper, paperScroller } = dataFlowState;

  useEffect(() => {
    const navigatorEl = navigatorContainer.current;

    if (paper && navigatorEl && paperScroller) {
      const navigator = new ui.Navigator({
        paperScroller: paperScroller,
        width: 200,
        height: 150,
        zoomOptions: { max: 4, min: 0.1 },
      });
      navigatorEl.appendChild(navigator.render().el);
      dataFlowActions.setNavigator(navigator);
    }
  }, [navigatorContainer, paper, paperScroller, dataFlowActions]);
};
