import { useContext, useEffect, useRef } from 'react';
import { ui } from '@clientio/rappid';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';

const UNUSED_HALO_TOOLS = ['clone', 'fork', 'resize', 'rotate', 'link', 'unlink', 'direction'];

export const useLinkHalo = () => {
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const { graph, paper, activeLink } = dataFlowState;
  const halo = useRef(null);
  const canEditWorkflow = useCanEditWorkflow();
  // Show halo when link is active
  useEffect(() => {
    if (!canEditWorkflow) {
      return;
    }
    if (activeLink) {
      const cellView = paper.findViewByModel(activeLink.cell);

      halo.current = createHalo(cellView, () => {
        cellView.model.remove();
        dataFlowActions.removeLink(activeLink.id);
      });
    } else if (halo.current) {
      //remove Halo whenever link is not active
      halo.current.remove();
      halo.current = null;
    }
  }, [dataFlowActions, graph, paper, activeLink, canEditWorkflow]);
};

const createHalo = (cellView, onRemove) => {
  const halo = new ui.Halo({ cellView, boxContent: false, clearAll: false });

  UNUSED_HALO_TOOLS.forEach(name => halo.removeHandle(name));

  // Disable built-in remove event handler to allow for manual implementation and confirm dialog
  halo._events['action:remove:pointerdown'] = [];
  halo.render();

  halo.on('action:remove:pointerdown', event => {
    event.stopPropagation();
    onRemove();
  });

  return halo;
};
