import * as api from '../../../../_shared/fetch';
import { v4 as uuidv4 } from 'uuid';
import {
  maxCommandDataSizeInMB,
  spreadsheetServiceHostName,
  workpapersSpreadsheetModelHostName,
} from '../../../../../configs/params';
import { generalErrorMessage } from '../../../../_shared/messages';
import { createCommandDataFile, getCommandSizeInMB } from './commandDataHelpers';
import { PreSignedUrlFileTypes } from '../../../../../constants/preSignedUrlFileTypes';

export async function saveCommand({ id, command }) {
  const { sheetName } = command;
  const { commandDataKey, isLargeCommand } = await evaluateCommandData(command);

  const body = JSON.stringify({
    workbookId: id,
    sheetName,
    ...(isLargeCommand ? { commandDataKey } : { commandText: JSON.stringify(command) }),
  });

  const response = await api.post(`${spreadsheetServiceHostName}/spreadsheet/commands`, {
    body,
  });

  window.commandsListener && window.commandsListener.resolve();

  if (!response.ok) {
    const error = await response.json();
    const mappedError =
      error && error.details ? error.details : [{ code: 'SERVER_ERROR', message: generalErrorMessage }];
    throw mappedError;
  }
}

export async function saveBatchCommand(workbookId, sheetName, batchCommands) {
  const { commandDataKey, isLargeCommand } = await evaluateCommandData(batchCommands);
  const body = JSON.stringify({
    workbookId,
    sheetName,
    details: isLargeCommand ? [{ commandDataKey }] : batchCommands,
  });
  const response = await api.post(`${spreadsheetServiceHostName}/spreadsheet/commands/batch`, {
    body,
  });

  window.commandsListener && window.commandsListener.resolve();

  return response;
}

export async function saveCommands(workbookId, sheetName, batchCommands) {
  const { commandDataKey, isLargeCommand } = await evaluateCommandData(batchCommands);
  const data = { workbookId, sheetName };
  if (isLargeCommand) data.commandDataKey = commandDataKey;
  else data.details = batchCommands;

  const body = JSON.stringify(data);
  const response = await api.post(`${spreadsheetServiceHostName}/spreadsheet/commands/save`, { body });
  if (response.ok) {
    window.commandsListener && window.commandsListener.resolve();
    return response;
  }
}

export async function processPendingCommandsWithSJS(workbookId) {
  await api.post(`${workpapersSpreadsheetModelHostName}/commands/${workbookId}/process-pending`, {
    body: JSON.stringify({ closeLatestVersion: true }),
  });
}

export const commandsStatusEnum = {
  Processing: 0,
  Success: 1,
  Fail: -1,
};

export async function getCommandDataPresignedUrl(fileName) {
  const filenameUrlEncoded = encodeURIComponent(fileName);
  const response = await api.get(
    `${spreadsheetServiceHostName}/spreadsheet/files/presigned-url/${filenameUrlEncoded}?fileType=${PreSignedUrlFileTypes.CommandData}`
  );

  if (!response.ok) {
    const error = await response.json();
    const mappedError =
      error && error.details ? error.details : [{ code: 'SERVER_ERROR', message: generalErrorMessage }];
    throw mappedError;
  }

  return await response.json();
}

export async function uploadCommandDataFileToBucket(presignedUrl, file) {
  const response = await fetch(presignedUrl, {
    method: 'PUT',
    body: file,
  });
  if (!response.ok) {
    throw new Error('Failed to upload source file. Please check your internet connection and try again.');
  }
}

async function evaluateCommandData(command) {
  const commandSizeInMB = getCommandSizeInMB(command);
  if (commandSizeInMB > maxCommandDataSizeInMB) {
    const uniqueFileName = `${uuidv4()}.json`;
    const commandDataFile = createCommandDataFile(command, uniqueFileName);
    const { url: commandDataUrl, key: commandDataKey } = await getCommandDataPresignedUrl(uniqueFileName);

    await uploadCommandDataFileToBucket(commandDataUrl, commandDataFile);

    return {
      isLargeCommand: true,
      commandDataKey,
    };
  }

  return {
    isLargeCommand: false,
  };
}
