import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { BTAlert, BTButton, BTForm, BTInput, P } from '@btas/jasper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { DataFlowEditorContext } from '../../DataFlowEditorPage/DataFlowEditorContext';
import { OUTPUT } from '../../../_shared/data-flows/dataFlowElementType';

import { INPUT_MAX_LENGTH, OUTPUT_BLOCK_MAX_LENGTH } from './constants/inputLengths';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';

const ElementInspector = ({ children, elementType, elementData, updateData, isDirty, alerts }) => {
  const {
    dataFlowActions,
    dataFlowState: { preview },
  } = useContext(DataFlowEditorContext);
  const { commitWorkingElement, setPreview } = dataFlowActions;
  const handleChange = ({ target: { value } }) => {
    updateData({ name: value });
  };

  const handlePreviewClick = () => {
    CustomLogger.start(CustomLogger.operations.BLOCK_TO_PREVIEW);
    commitWorkingElement();
    setPreview('restore');
  };

  const handleClose = () => {
    dataFlowActions.clearActiveElement();
  };

  const hasAlerts =
    elementType.type === 'input' || elementType.type === 'select'
      ? alerts && alerts.length > 0 && alerts.filter(m => m.content !== null && m.style === 'danger').length > 0
      : alerts && alerts.length > 0;

  const hasWarnings =
    elementType.type === 'input' || elementType.type === 'select'
      ? alerts && alerts.length > 0 && alerts.filter(m => m.content !== null && m.style === 'warning').length > 0
      : alerts && alerts.length > 0;

  const canPreview = elementType.canPreview(elementData);
  const canPreviewOutput = elementType.type === OUTPUT ? false : canPreview;
  const showPreview = !hasAlerts && canPreview && canPreviewOutput;
  const canEditWorkflow = useCanEditWorkflow();
  return (
    <>
      <h4 className={`wkp-inspector-title df-${elementType.type}-inspector`}>
        Configure {elementType.label} Block
        <Link rel="noopener noreferrer" target="_blank" to={`/help/index.html?article=${elementType.type}Block`}>
          <FontAwesomeIcon className="help-icon" icon={faQuestionCircle} />
        </Link>
        <button className="wkp-inspector-close" onClick={handleClose}>
          <i aria-hidden="true" class="fa fa-times"></i>
        </button>
      </h4>
      <div className={`wkp-inspector-body ${showPreview ? '' : 'wkp-full-height'}`}>
        <BTForm onSubmit={e => e.preventDefault()}>
          <BTForm.FormGroup className="wkp-step-name" htmlFor={`wkpStepName-${elementType.type}`} label="Step Name">
            <BTInput
              disabled={!canEditWorkflow}
              id={`wkpStepName-${elementType.type}`}
              maxLength={
                elementType.type === 'output' ? OUTPUT_BLOCK_MAX_LENGTH.toString() : INPUT_MAX_LENGTH.toString()
              }
              value={elementData.name}
              onChange={handleChange}
            />
          </BTForm.FormGroup>
        </BTForm>
        {children}
      </div>
      {canEditWorkflow && showPreview && (
        <div className="wkp-inspector-preview">
          <BTButton
            btStyle="primary"
            className="wkp-inspector-preview-btn"
            disabled={!isDirty && preview !== 'minimize'}
            onClick={handlePreviewClick}
          >
            Preview
          </BTButton>
          {hasWarnings && (
            <div className="wkp-inspector-warning">
              {alerts
                .filter(f => f.content && f.style === 'warning')
                .map(({ style, content }, key) => (
                  <BTAlert key={key} btStyle={style}>
                    <p>
                      One or more fields contain values that do not adhere to the field's data type. To avoid seeing
                      this warning, change the data type to 'Text'.
                    </p>
                    <ul>
                      {content?.map(field => (
                        <li>
                          Field '{JSON.parse(field).fieldName}' contains non-{JSON.parse(field).type} values
                        </li>
                      ))}
                    </ul>
                    <Link rel="noopener noreferrer" target="_blank" to={`/help/index.html?article=dataTypes`}>
                      Help with #VALUE! errors
                    </Link>
                  </BTAlert>
                ))}
            </div>
          )}
          {elementType.type === OUTPUT ? (
            <P className="wkp-inspector-help-text">Some changes will only be seen in the connected workpapers.</P>
          ) : null}
        </div>
      )}
      {hasAlerts && (
        <div className="wkp-inspector-footer">
          {alerts
            .filter(f => f.content && f.style === 'danger')
            .map(({ style, content }, key) => (
              <BTAlert key={key} btStyle={style}>
                {content}
              </BTAlert>
            ))}
        </div>
      )}
    </>
  );
};

export default ElementInspector;
