import React, { useState, useEffect } from 'react';
import { getUserPreferences, setUserPreferences as setUserPreferencesApi } from './UserPreferencesContext/apis';
import Spinner from './Spinner';
import './UserPreferencesContext/styles.scss';

const UserPreferencesContext = React.createContext();
const { Provider, Consumer } = UserPreferencesContext;

function UserPreferencesProvider({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userPreferences, setUserPreferencesState] = useState(null);

  useEffect(() => {
    const fetchUserPreferences = async () => {
      let userPreferences;

      const preferencesResult = await getUserPreferences();

      if (!preferencesResult) {
        userPreferences = { hasResetPassword: false, hasAiEnabled: false };
        setUserPreferencesApi(userPreferences);
      } else {
        userPreferences = preferencesResult;
      }

      setUserPreferencesState(userPreferences);
      setIsLoading(false);
    };

    fetchUserPreferences();
  }, []);

  const setUserPreferences = userPreferences => {
    setUserPreferencesState(userPreferences);
    setUserPreferencesApi(userPreferences);
  };

  return (
    <Provider
      value={{
        userPreferences,
        setUserPreferences,
      }}
    >
      {isLoading ? (
        <div className="wkp-provider-container">
          <Spinner />
        </div>
      ) : (
        children
      )}
    </Provider>
  );
}

export default UserPreferencesContext;
export { UserPreferencesProvider, Consumer as UserPreferencesConsumer };
