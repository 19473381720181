import { useCallback, useContext, useEffect, useRef } from 'react';
import { elementTools, dia, ui } from '@clientio/rappid';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { InputElementType } from '../elementType/types/InputElementType';
import {
  removeSourceFile,
  removeUplodFilePermanently,
  validateIsPermanentDelete,
} from '../InputElementInspector/removeSourceFile';
import { getDataFlowSourceFiles } from '../../shared/apis';
import { getDataFlowSourceFileVersion } from '../InputElementInspector/apis';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';

export const useElementTools = () => {
  const { dataFlowState, dataFlowActions, showConfirmationModal } = useContext(DataFlowEditorContext);
  const { graph, paper, activeElement, validationErrors } = dataFlowState;
  const elementToolsRef = useRef(null);
  const errorListRef = useRef([]);
  const activeElementHasError = useRef(false);
  const canEditWorkflow = useCanEditWorkflow();
  const removeElement = useCallback(
    (id, cellView) => {
      cellView.model.remove();
      dataFlowActions.removeElement(id);
    },
    [dataFlowActions]
  );

  useEffect(() => {
    if (!canEditWorkflow) {
      return;
    }
    if (elementToolsRef.current) {
      elementToolsRef.current.forEach(elementTool => {
        elementTool.remove();
      });
    }

    activeElementHasError.current = false;

    let elementWithErrorTools = [];

    if (validationErrors && validationErrors.length) {
      errorListRef.current = skipErrors(validationErrors);
      let allElements = graph.getElements();

      let foundElementWithErrors = allElements.reduce((acc, element) => {
        const hasError = errorListRef.current.some(error => error.elementId === element.attributes.id);
        if (hasError) {
          if (activeElement && activeElement.id === element.attributes.id) {
            activeElementHasError.current = true;
          } else {
            acc.push(element);
          }
        }
        return acc;
      }, []);

      elementWithErrorTools = foundElementWithErrors.map(cellElement => {
        return createErrorIconTool(cellElement);
      });
    }

    if (activeElement) {
      const { elementData, cell, type, id } = activeElement;
      const fields = elementData.fields || [];

      const cellView = paper.findViewByModel(cell);

      const activeElementTool = createCloseButtonTool(cellView, cell, async () => {
        if (type === InputElementType.TYPE && fields.length > 0) {
          const sourceFiles = await loadSourceFiles(dataFlowState.id, id, elementData?.pendingSourceFileVersionId);
          const sourceFile = sourceFiles.pop();

          // remove the UI cell only without file
          if (sourceFile === undefined) {
            removeElement(id, cellView);
            return;
          }
          const sourceFileId = sourceFile.id ?? sourceFile.sourceFileId;
          const deleteResp = await validateIsPermanentDelete(dataFlowState.id, id);
          if (deleteResp.permanentDeleteInfo && deleteResp.permanentDeleteInfo.length > 0) {
            showConfirmationModal(
              'Delete Source Data',
              'Deleting the input block will reset the data flow columns and remove all source data for the data flow. Are you sure?',
              'Delete',
              'Cancel',
              'warning',
              async () => {
                await removeSourceFile(sourceFileId, dataFlowState.id, id);
                await removeUplodFilePermanently(deleteResp.permanentDeleteInfo, 'wkp');
                removeElement(id, cellView);
              }
            );
          } else {
            await removeSourceFile(sourceFileId, dataFlowState.id, id);
            removeElement(id, cellView);
          }
        } else {
          removeElement(id, cellView);
        }
      });
      elementWithErrorTools = elementWithErrorTools.concat(activeElementTool);
    }

    elementToolsRef.current = elementWithErrorTools;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFlowActions, graph, paper, activeElement, showConfirmationModal, dataFlowState, canEditWorkflow]);

  async function loadSourceFiles(dataFlowId, inputId, pendingSourceFileVersionId) {
    try {
      if (pendingSourceFileVersionId) {
        const pendingVersion = await getDataFlowSourceFileVersion(dataFlowId, pendingSourceFileVersionId);
        return [{ ...pendingVersion, id: undefined }];
      } else {
        const inputSourceFiles = await getDataFlowSourceFiles(dataFlowId, inputId);
        return inputSourceFiles;
      }
    } catch (e) {
      return [];
    }
  }

  function calculateScale(cellView, cellElement) {
    const scaledCellRect = cellElement.getBoundingClientRect();
    const originalCellWidth = cellView.model.attributes.size.width;

    return scaledCellRect.width / originalCellWidth;
  }

  const getButtonAttributesForBlocks = (cellView, cellElement, innerBlockElement) => {
    let xOffset, yOffset, scale;
    const scaledBlockRect = innerBlockElement.getBoundingClientRect();

    scale = calculateScale(cellView, cellElement);

    const scaledCellRect = cellElement.getBoundingClientRect();
    const scaledXBlockRelativeCoord = scaledBlockRect.x - scaledCellRect.x;
    const normalizedXBlockRelativeCoord = scaledXBlockRelativeCoord / scale;
    xOffset = normalizedXBlockRelativeCoord - 3;
    yOffset = -8;
    return { scale, xOffset, yOffset };
  };

  const skipErrors = validationErrors => {
    const errorCodeToSkip = ['HasIndependentBranches', 'OutputPortNotConnected'];
    if (validationErrors && validationErrors.length) {
      validationErrors = validationErrors.filter(error => !errorCodeToSkip.includes(error.code));
    }
    return validationErrors;
  };

  function createCloseButtonTool(cellView, element, onRemove) {
    const getButtonAttributesForText = (cellView, cellElement) => {
      let xOffset, yOffset, scale;

      scale = calculateScale(cellView, cellElement);

      xOffset = -13;
      yOffset = -13;
      return { scale, xOffset, yOffset };
    };

    let scale, xOffset, yOffset;

    const cellElement = document.getElementById(cellView.id);
    const innerBlockElement = cellElement.querySelector('.block');

    if (innerBlockElement) {
      ({ scale, xOffset, yOffset } = getButtonAttributesForBlocks(cellView, cellElement, innerBlockElement));
    } else {
      ({ scale, xOffset, yOffset } = getButtonAttributesForText(cellView, cellElement));
    }

    const buttonRadius = 10 / scale;
    const crossSize = 4 / scale;
    const strokeWidth = 2 / scale;

    const removeButton = new elementTools.Button({
      markup: [
        {
          tagName: 'circle',
          selector: 'button',
          attributes: {
            r: buttonRadius,
            fill: 'black',
            cursor: 'pointer',
          },
        },
        {
          tagName: 'path',
          selector: 'icon',
          attributes: {
            d: `M -${crossSize} -${crossSize} ${crossSize} ${crossSize} M -${crossSize} ${crossSize} ${crossSize} -${crossSize}`,
            fill: 'none',
            stroke: '#FFFFFF',
            'stroke-width': strokeWidth,
            'pointer-events': 'none',
          },
        },
      ],
      x: '0%',
      y: '0%',
      offset: {
        x: xOffset,
        y: yOffset,
      },
      rotate: true,
      action: event => {
        event.stopPropagation();
        onRemove();
      },
    });

    if (activeElementHasError.current) {
      const tools = [removeButton];
      return createErrorIconTool(element, tools);
    } else {
      const toolsView = new dia.ToolsView({
        tools: [removeButton],
      });
      cellView.addTools(toolsView);
      return toolsView;
    }
  }

  function createErrorIconTool(element, tools) {
    const cellView = paper.findViewByModel(element);
    const cellElement = document.getElementById(cellView.id);
    const error = errorListRef.current.find(error => error.elementId === element.id);
    const scale = 1.2;
    let xOffset, yOffset;

    const innerBlockElement = cellElement.querySelector('.block');
    ({ xOffset, yOffset } = getButtonAttributesForBlocks(cellView, cellElement, innerBlockElement));

    const buttonRadius = 10 / scale;

    const validationErrorButton = new elementTools.Button({
      markup: [
        {
          tagName: 'circle',
          selector: 'button',
          attributes: {
            r: buttonRadius,
            fill: '#c31834',
            cursor: 'pointer',
          },
        },
        {
          tagName: 'path',
          selector: 'icon',
          attributes: {
            d: `M-1.002 5
          a1 1 0 1 1 2 0 1 1 0 0 1-2 0
          M-1.1 -2.995
          a.905.905 0 1 1 1.8 0
          l-.35 3.507
          a.552.552 0 0 1-1.1 0z
          `,
            fill: '#fff',
            stroke: '#fff',
            'stroke-width': 1,
            'pointer-events': 'none',
          },
        },
      ],
      x: '40%',
      y: '-8%',
      offset: {
        x: xOffset,
        y: yOffset,
      },
      rotate: true,
      cursor: 'pointer',
      event: 'element:mouseenter',
      stopPropagation: false,
    });

    const settings = {
      hideArrow: true,
      scale: 1,
      position: 'bottom',
      padding: 5,
    };

    new ui.Tooltip({
      content: () => {
        return error.message;
      },
      target: validationErrorButton.el,
      padding: settings.padding * scale,
      position: settings.position,
      scale,
      direction: false,
    });

    if (tools) {
      tools.push(validationErrorButton);
      const toolsView = new dia.ToolsView({
        tools: tools,
      });
      cellView.addTools(toolsView);
      return toolsView;
    } else {
      const toolsView = new dia.ToolsView({
        tools: [validationErrorButton],
      });
      cellView.addTools(toolsView);
      return toolsView;
    }
  }
};
