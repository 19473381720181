import React from 'react';
import ElementInspector from './shared/ElementInspector';

const alerts = [
  {
    style: 'info',
    content: 'This block is not yet implemented.',
  },
];

const DefaultElementInspector = ({ elementData, elementId, elementType, updateData, isDirty }) => (
  <ElementInspector
    alerts={alerts}
    elementData={elementData}
    elementId={elementId}
    elementType={elementType}
    isDirty={isDirty}
    updateData={updateData}
  />
);

export default DefaultElementInspector;
