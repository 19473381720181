import React, { useState, useContext } from 'react';
import Spinner from '../../../../../_shared/Spinner';
import UnderlinedLegend from '../../../../../_shared/UnderlinedLegend';
import SignOffList from './SignOffs/SignOffList';
import EmptyState from './SignOffs/EmptyState';
import { createWorkpaperSignOff } from './SignOffs/apis';
import DeleteConfirmationModal from './SignOffs/DeleteConfirmationModal';
import useSignOffList from './SignOffs/useSignOffList';
import EditorContext from '../../../EditorContext';
import './SignOffs/styles.scss';

export default function SignOffs({ workpaperId }) {
  const { isLocked, isSignOffPermission } = useContext(EditorContext);
  const [isLoading, setIsLoading] = useState(false);
  const [signOffs, fetchWorkpaperSignOffs] = useSignOffList({ workpaperId, setIsLoading });
  const [selectedId, setSelectedId] = useState();

  const handleAddSignOffClick = async () => {
    try {
      setIsLoading(true);
      await createWorkpaperSignOff(workpaperId);
      fetchWorkpaperSignOffs();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSignOffDeleteClick = id => setSelectedId(id);

  const closeDialog = () => setSelectedId('');

  const refreshList = async () => {
    try {
      setIsLoading(true);
      fetchWorkpaperSignOffs();
      closeDialog();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="wkp-sign-offs">
      <UnderlinedLegend>Sign-Offs</UnderlinedLegend>

      {isLoading ? (
        <Spinner />
      ) : signOffs.length ? (
        <SignOffList
          isLocked={isSignOffPermission ? false : isLocked}
          signOffs={signOffs}
          onAddSignOffClick={handleAddSignOffClick}
          onSignOffDeleteClick={handleSignOffDeleteClick}
        />
      ) : (
        <EmptyState isLocked={isSignOffPermission ? false : isLocked} onAddSignOffClick={handleAddSignOffClick} />
      )}

      <DeleteConfirmationModal refreshList={refreshList} selectedId={selectedId} onCloseDialog={closeDialog} />
    </div>
  );
}
