import GC from '../../../../../SpreadSheets';

export function addSheetHelpers() {
  GC.Spread.Sheets.Worksheet.prototype.setWkpTag = function (extra) {
    const existingTagInfo = this.tag();
    this.tag({ ...existingTagInfo, ...extra });
  };

  GC.Spread.Sheets.Worksheet.prototype.setWkpCellTag = function (row, col, extra) {
    const existingTagInfo = this.getTag(row, col);
    this.setTag(row, col, { ...existingTagInfo, ...extra });
  };
}

export function CopyExecute(callback) {
  let oldCopyExeFn = GC.Spread.Sheets.Commands.copy.execute;
  GC.Spread.Sheets.Commands.copy.execute = function (workbook, options, isUndo) {
    let sheet = workbook.getSheetFromName(options.sheetName);
    let sels = sheet.getSelections();
    let totalCells = 0;
    sels.forEach(sel => (totalCells = totalCells + sel.rowCount * sel.colCount));
    //use 50k of cells like excel online
    if (totalCells > 50000) {
      callback();
      return false;
    } else return oldCopyExeFn.apply(this, arguments);
  };
}
