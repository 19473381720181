import React, { useState } from 'react';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Panel from 'react-bootstrap/lib/Panel';
import PanelHeading from 'react-bootstrap/lib/PanelHeading';
import PanelTitle from 'react-bootstrap/lib/PanelTitle';
import PanelBody from 'react-bootstrap/lib/PanelBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import './MultipleSelection/styles.scss';

export default function MultipleSelection({ topics }) {
  const [expandedTopic, setExpandedTopic] = useState(null);

  const expandCollapseTopic = id => {
    const result = id === expandedTopic ? null : id;
    setExpandedTopic(result);
  };

  return (
    <div className="wkp-topic-selected">
      <PanelGroup accordion id="topics">
        {topics.map(topic => (
          <Panel key={topic.id} eventKey={topic.id}>
            <PanelHeading
              onClick={() => {
                expandCollapseTopic(topic.id);
              }}
            >
              <PanelTitle toggle>
                <FontAwesomeIcon icon={topic.id === expandedTopic ? faChevronDown : faChevronRight} />
                <span>{topic.title}</span>
              </PanelTitle>
            </PanelHeading>
            <PanelBody collapsible>
              {topic.definition.length > 0 && <p>{topic.definition[0].Text}</p>}
              {topic.sections &&
                topic.sections.map((section, sectionIdx) => (
                  <div key={sectionIdx}>
                    {section.heading && section.document_links && section.document_links.length > 0 && (
                      <>
                        <h4>{section.heading}</h4>
                        <p>
                          {section.document_links.map((dl, dlIdx) => (
                            <span key={dlIdx}>
                              <a
                                className="wkp-research-link"
                                dl={dl.title}
                                href={
                                  dl.url && dl.url.startsWith('/product') ? `https://bloomberglaw.com${dl.url}` : dl.url
                                }
                                rel="noreferrer"
                                target="_blank"
                              >
                                {dl.title}
                              </a>
                            </span>
                          ))}
                        </p>
                      </>
                    )}
                  </div>
                ))}
            </PanelBody>
          </Panel>
        ))}
      </PanelGroup>
    </div>
  );
}
