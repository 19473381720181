import { spreadsheetServiceHostName } from '../../../configs/params';
import * as api from '../../_shared/fetch';

export async function getList({ sorting, filters }) {
  let response;
  const filtersAndSortingToSend = removeEmptyFilters({
    sortByColumn: sorting.binding,
    sortDirection: sorting.direction,
    ...filters,
  });
  const bodyRequest = buildSortAndFiltersWorkpapersRequest(filtersAndSortingToSend);
  response = await api.post(`${spreadsheetServiceHostName}/recycle/list`, {
    body: JSON.stringify(bodyRequest),
  });

  if (!response.ok) {
    throw response.error;
  }

  const data = await response.json();

  const items = data.recycleItems.paginatedItems;

  return { ...data, items };
}

function buildSortAndFiltersWorkpapersRequest(obj) {
  return Object.entries(obj).reduce((prev, [k, v]) => {
    if (k === 'taxPeriod') {
      const taxPeriodValue = v === null ? 'unassigned' : v;
      return { ...prev, [k]: taxPeriodValue };
    } else {
      return {
        ...prev,
        filters: { ...prev['filters'], [k]: v },
      };
    }
  }, {});
}

function removeEmptyFilters(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(([k, v]) => {
      if (k === 'taxPeriod') {
        //This is needed since when the filter is in unassigned the taxPeriod value is null an need to query in the backend.
        //When is no taxPeriod filtering selection the value is undefined
        return v === null || v != null;
      }
      return v != null;
    })
  );
}

export async function getFilterOptions() {
  const response = await api.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers/list/filters`);
  const data = await response.json();

  return data;
}

export async function restoreWorkpapers(ids) {
  return await api.post(`${spreadsheetServiceHostName}/spreadsheet/workpapers/restore`, {
    body: JSON.stringify({ workpaperList: ids }),
  });
}
