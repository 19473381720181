import * as fetch from '../../../../../../_shared/fetch';
import { generalErrorMessage } from '../../../../../../_shared/messages';
import { spreadsheetServiceHostName } from '../../../../../../../configs/params';

export async function getComboboxData() {
  const response = await fetch.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers/workflow-status`);
  return response.json();
}

export async function getWorkflowStatus(id) {
  const response = await fetch.get(`${spreadsheetServiceHostName}/spreadsheet/workpapers/status/${id}`);

  return response.json();
}

export async function putWorkflowStatus({ id, ...rest }) {
  try {
    await fetch.put(`${spreadsheetServiceHostName}/spreadsheet/workpapers/status/${id}`, {
      body: JSON.stringify(rest),
    });

    return;
  } catch (error) {
    const mappedError =
      error && error.details ? error.details : [{ code: 'SERVER_ERROR', message: generalErrorMessage }];
    throw mappedError;
  }
}
