import { useState, useCallback, useEffect, useRef, useContext } from 'react';
import UserPreferencesContext from '../../_shared/UserPreferencesContext';
import { getFilterOptions, getList } from './apis';

const defaultSort = { binding: 'name', direction: 'ASC' };
const initialFilters = {
  name: '',
  taxPeriod: null,
  lastRunUser: null,
  lastRunDateFrom: null,
  lastRundDateTo: null,
  lastModifiedUser: null,
  lastModifiedDateFrom: null,
  lastModifiedDateTo: null,
  status: null,
};
const initialFilterOptions = { taxPeriods: [], lastRunUsers: [], lastModifiedUsers: [] };
const initialGridData = { items: [], totalCount: 0, filterOptions: initialFilterOptions };

export default function useGrid() {
  const { userPreferences, setUserPreferences } = useContext(UserPreferencesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [gridData, setGridData] = useState(initialGridData);
  const [selectedDataFlows, setSelectedDataFlows] = useState([]);
  const [gridSorting, setGridSorting] = useState(() => {
    if (userPreferences.dataflowColumnSort) {
      return userPreferences.dataflowColumnSort;
    }
    return defaultSort;
  });
  const timerHandleFilterChange = useRef();
  const [gridFilters, setGridFilters] = useState(initialFilters);
  const fetchDataFlows = useCallback(async function (configs) {
    const sorting = configs?.sorting ?? defaultSort;
    const filters = configs?.filters ?? initialFilters;

    const gridData = await getList({
      sorting,
      filters: {
        ...filters,
        name: filters.name ? filters.name : null,
        taxPeriod: filters.taxPeriod?.value,
        lastModifiedBy: filters.lastModifiedBy?.value,
        lastRunBy: filters.lastRunBy?.value,
      },
    });
    const filterOptionsData = await getFilterOptions();

    const filterOptions = {
      taxPeriods: filterOptionsData.taxPeriods.map(v => ({ value: v, label: v })),
      lastRunUsers: filterOptionsData.lastRunUsers.map(v => ({
        value: Object.keys(v)[0],
        label: Object.values(v)[0],
      })),
      lastModifiedUsers: filterOptionsData.lastModifiedUsers.map(v => ({
        value: Object.keys(v)[0],
        label: Object.values(v)[0],
      })),
      statuses: filterOptionsData.statuses?.map(v => ({ value: v, label: v })),
    };

    setGridData({
      ...gridData,
      filterOptions,
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (timerHandleFilterChange.current) {
      clearTimeout(timerHandleFilterChange.current);
    }

    timerHandleFilterChange.current = setTimeout(() => {
      fetchDataFlows({ sorting: gridSorting, filters: gridFilters });
      timerHandleFilterChange.current = null;
    }, 250);
    // intentionally I don't want to put gridSorting as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridFilters]);

  useEffect(() => {
    if (timerHandleFilterChange.current) {
      // clear timeout if trying to filter and sort at the same time
      clearTimeout(timerHandleFilterChange.current);
    }

    if (gridSorting) {
      fetchDataFlows({ sorting: gridSorting, filters: gridFilters });
    }
    // intentionally I don't want to put gridFilters as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataFlows, gridSorting]);
  const state = {
    isLoading,
    gridFilters,
    gridData,
    initialFilters,
    selectedDataFlows,
    defaultSort,
    gridSorting,
  };

  const actions = {
    setIsLoading,
    setGridSorting(order) {
      setGridSorting(order);
      setUserPreferences({
        ...userPreferences,
        dataFlowColumnSort: order,
      });
    },
    setGridFilters,
    fetchDataFlows,
    setSelectedDataFlows,
  };

  return [state, actions];
}
