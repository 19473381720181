import React from 'react';
import { BTAlert } from '@btas/jasper';
import ButtonsSection from '../../_shared/ButtonsSection';
import useLoadPublishedDataFlows from '../../_shared/data-flows/useLoadPublishedDataFlows';

export default function DataFlowsListActions() {
  const [{ error: dataFlowsError }, { resetError: resetDataFlowsError }] = useLoadPublishedDataFlows(true);

  return (
    <ButtonsSection>
      <BTAlert appear dismissible fixed btStyle="danger" visible={!!dataFlowsError} onDismiss={resetDataFlowsError}>
        Failed to load data flows, please try again
      </BTAlert>
    </ButtonsSection>
  );
}
