import { useState, useCallback } from 'react';

export const useConfirmationModal = () => {
  const [confirmationModalState, setConfirmationModalState] = useState({ show: false });

  const showConfirmationModal = useCallback(
    (title, message, confirmLabel, cancelLabel, modalStyle, onConfirm, onCancel) => {
      setConfirmationModalState({
        show: true,
        title,
        message,
        confirmLabel,
        cancelLabel,
        modalStyle,
        onConfirm: () => {
          setConfirmationModalState({ show: false });
          if (onConfirm) {
            onConfirm();
          }
        },
        onCancel: () => {
          setConfirmationModalState({ show: false });
          if (onCancel) {
            onCancel();
          }
        },
      });
    },
    [setConfirmationModalState]
  );

  return [confirmationModalState, showConfirmationModal];
};
