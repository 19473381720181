import GC from '../../../../../../SpreadSheets';
import { STATE_APPORTION } from '../../../../../_shared/DataReference/ReferenceType';
import { formatFormulaReturnValue, enqueueFormula } from '../formulas';

export const stateApportionFormulaName = 'STATEAPPORTION';

const name = stateApportionFormulaName;

const parameters = [
  {
    name: '"jurisdiction"',
    repeatable: false,
    optional: false,
  },
  {
    name: '"period_start_date"',
    repeatable: false,
    optional: false,
  },
  {
    name: '"factor"',
    repeatable: false,
    optional: false,
  },
];

const description = ' Retrieves the applicable state apportionment factor weighting for sales, property, or payroll.';

const evaluationFunction = (args, data) => {
  const [context, state, date, factor] = args;

  if (state && date && factor) {
    const { row, column, source } = context;
    const sheet = source.getSheet();
    const sheetName = sheet.name();
    const cellTag = data.getCellReferenceTag(sheet, row, column);
    const formulaType = STATE_APPORTION;

    enqueueFormula(data, context, formulaType, args);

    return formatFormulaReturnValue(
      data.renderCustomFormulaValues(cellTag, row, column, formulaType, sheetName),
      data,
      sheet,
      row,
      column
    );
  }
  return GC.Spread.CalcEngine.Errors.NotAvailable;
};

export const stateApportionFormula = (gcSyncFunction, data) =>
  new gcSyncFunction({
    name,
    minArgs: 3,
    maxArgs: 3,
    descriptionData: {
      name,
      description,
      parameters,
    },
    evaluationFunction,
    data,
  });
