import React, { useContext, useMemo } from 'react';
import { DataFlowEditorContext } from '../DataFlowEditorContext';

const ValidationErrors = ({ validationErrors }) => {
  const { dataFlowState } = useContext(DataFlowEditorContext);
  const { elements } = dataFlowState;

  const resolvedErrors = useMemo(() => {
    if (!validationErrors) {
      return [];
    }
    return validationErrors?.map(error => ({
      ...error,
      element: error.elementId ? elements[error.elementId] : null,
    }));
  }, [validationErrors, elements]);

  return (
    <ul>
      {resolvedErrors?.map((error, index) => (
        <li key={index}>
          {error.element ? `(${error.element.elementData.name || error.element.type}):` : ''} {error.message}
        </li>
      ))}
    </ul>
  );
};

export default ValidationErrors;
