import React from 'react';
import { Link } from 'react-router-dom';
import wkpEditorIcon from './AppBar/Spreadsheet-list-icon-white.svg';
import './AppBar/styles.scss';
import data_flows_nav_icon from './AppBar/data_flows_nav_icon.svg';

export default function AppBar({ activeItem }) {
  return (
    <nav id="wkp-app-bar">
      <ul className="nav">
        <li className={`nav-item ${activeItem === 'home' ? 'active' : ''}`}>
          <Link
            aria-label="Workpapers"
            className="nav-link"
            id="wkp-workpapers-btn"
            title="Workpapers"
            to="/workpapers"
          >
            <img alt="Workpapers" src={wkpEditorIcon} />
            <span className="sr-only">Workpapers</span>
          </Link>
        </li>
        <li className={`nav-item ${activeItem === 'data-flows' ? 'active' : ''}`}>
          <Link
            aria-label="Data transformation workflows"
            className="nav-link"
            id="wkp-data-flows-btn"
            title="Data transformation workflows"
            to="/data-flows"
          >
            <i>
              <img alt="Data transformation workflows icon" src={data_flows_nav_icon} />
            </i>
            <span className="sr-only">Data transformation workflows</span>
          </Link>
        </li>
        {/* OUT FOR NOW: <li className={`nav-item ${(activeItem === 'connection' ? 'active' : '')}`}>
            <a id="wkp-connections-btn" className="nav-link" href="/connections" title="Data connections" aria-label="Data connections">
              <i className="fas fa-sync-alt fa-lg"></i>
              <span className="visible-xs-inline">Connections</span>
            </a>
          </li> */}
      </ul>
    </nav>
  );
}
