import GC from '../../../../../../../SpreadSheets';
import { ShortcutActionBase, Commands, executeCommand } from './shell/action.base';
import { Util } from './common/util';
var __extends =
  (this && this.__extends) ||
  (function () {
    var extendStatics = function (d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function (d, b) {
            d.__proto__ = b;
          }) ||
        function (d, b) {
          for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
        };
      return extendStatics(d, b);
    };
    return function (d, b) {
      if (typeof b !== 'function' && b !== null)
        throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
      extendStatics(d, b);
      function __() {
        this.constructor = d;
      }
      d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
    };
  })();
var VerticalPosition = GC.Spread.Sheets.VerticalPosition;
var HorizontalPosition = GC.Spread.Sheets.HorizontalPosition;
var DELETE_ROW_OR_COLUMN = 'deleteEntireRowOrColumn',
  HIDE_SELECT_ROWS = 'hideSelectRows',
  GROUP_SELECT_RANGE = 'groupSelectRange',
  UNGROUP_SELECT_RANGE = 'ungroupSelectRange',
  INSERT_ROW_OR_COLUMN = 'InsertRowOrColumn';
var OperateEntireRowOrColumnUndoAction = /** @class */ (function (_super) {
  __extends(OperateEntireRowOrColumnUndoAction, _super);
  function OperateEntireRowOrColumnUndoAction() {
    return (_super !== null && _super.apply(this, arguments)) || this;
  }
  OperateEntireRowOrColumnUndoAction.prototype.executeImp = function () {
    var self = this;
    var sheet = self.getSheet();
    var options = self._options;
    var activeRow = sheet.getActiveRowIndex(),
      activeCol = sheet.getActiveColumnIndex();
    var isDelete = options.isDelete;
    var i = 0;
    if (options.isRow) {
      var rowInfos = options.rowInfos;
      if (rowInfos) {
        if (isDelete) {
          for (i = rowInfos.length - 1; i >= 0; i--) {
            const rowInfo = rowInfos[i];
            sheet.deleteRows(rowInfo.startRowIndex, rowInfo.rowCount);
          }
        } else {
          for (i = 0; i < rowInfos.length; i++) {
            const rowInfo = rowInfos[i];
            sheet.addRows(rowInfo.startRowIndex, rowInfo.rowCount);
          }
        }
      }
    } else {
      var colInfos = options.colInfos;
      if (colInfos) {
        if (isDelete) {
          for (i = colInfos.length - 1; i >= 0; i--) {
            const colInfo = colInfos[i];
            sheet.deleteColumns(colInfo.startColIndex, colInfo.colCount);
          }
        } else {
          for (i = 0; i < colInfos.length; i++) {
            const colInfo = colInfos[i];
            sheet.addColumns(colInfo.startColIndex, colInfo.colCount);
          }
        }
      }
    }
    sheet.showCell(activeRow, activeCol, VerticalPosition.nearest, HorizontalPosition.nearest);
    return true;
  };
  return OperateEntireRowOrColumnUndoAction;
})(ShortcutActionBase);
function selectionHandler(selections, options) {
  if (selections.length === 0) {
    return false;
  } else if (selections.length === 1) {
    const selection = selections[0];
    const rangeType = Util.getRangeType(selection);
    if (rangeType === 3 /* RangeType.entireSheet */) {
      //select all sheet.
      return true;
    } else if (rangeType === 2 /* RangeType.fullColumn */) {
      options.isRow = false;
      options.colInfos = [
        {
          startColIndex: selection.col,
          colCount: selection.colCount,
        },
      ];
    } else if (rangeType === 1 /* RangeType.fullRow */) {
      options.isRow = true;
      options.rowInfos = [
        {
          startRowIndex: selection.row,
          rowCount: selection.rowCount,
        },
      ];
    } else {
      return true;
    }
  } else {
    var startRangeType = Util.getRangeType(selections[0]);
    if (startRangeType === 0 /* RangeType.singleRange */ || startRangeType === 3 /* RangeType.entireSheet */) {
      return true;
    } else if (startRangeType === 2 /* RangeType.fullColumn */) {
      options.isRow = false;
    } else if (startRangeType === 1 /* RangeType.fullRow */) {
      options.isRow = true;
    }
    options.rowInfos = [];
    options.colInfos = [];
    for (var _i = 0, selections_1 = selections; _i < selections_1.length; _i++) {
      const selection = selections_1[_i];
      const rangeType = Util.getRangeType(selection);
      if (rangeType !== startRangeType) {
        return true;
      }
    }
    selections.forEach(function (selection) {
      const rangeType = Util.getRangeType(selection);
      if (rangeType === 1 /* RangeType.fullRow */) {
        options.rowInfos &&
          options.rowInfos.push({
            startRowIndex: selection.row,
            rowCount: selection.rowCount,
          });
      } else if (rangeType === 2 /* RangeType.fullColumn */) {
        options.colInfos &&
          options.colInfos.push({
            startColIndex: selection.col,
            colCount: selection.colCount,
          });
      }
    });
  }
}
Commands[DELETE_ROW_OR_COLUMN] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = DELETE_ROW_OR_COLUMN;
    if (!isUndo) {
      options.isDelete = true;
      var selections = sheet.getSelections();
      selectionHandler(selections, options);
    }
    return executeCommand(context, OperateEntireRowOrColumnUndoAction, options, isUndo);
  },
};
Commands[INSERT_ROW_OR_COLUMN] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = INSERT_ROW_OR_COLUMN;
    if (!isUndo) {
      options.isDelete = false;
      var selections = sheet.getSelections();
      selectionHandler(selections, options);
    }
    return executeCommand(context, OperateEntireRowOrColumnUndoAction, options, isUndo);
  },
};
var HideSelectRowsUndoAction = /** @class */ (function (_super) {
  __extends(HideSelectRowsUndoAction, _super);
  function HideSelectRowsUndoAction() {
    return (_super !== null && _super.apply(this, arguments)) || this;
  }
  HideSelectRowsUndoAction.prototype.executeImp = function () {
    var self = this;
    var sheet = self.getSheet(),
      options = self._options;
    var rowInfos = options.rowInfo;
    if (rowInfos.length > 0) {
      sheet.suspendPaint();
      sheet.suspendEvent();
      var activeRow = sheet.getActiveRowIndex(),
        activeCol = sheet.getActiveColumnIndex();
      rowInfos.forEach(function (rowInfo) {
        var startIndex = rowInfo.startRowIndex < 0 ? 0 : rowInfo.startRowIndex,
          rowCount = rowInfo.rowCount,
          endIndex = startIndex + rowCount;
        for (var i = startIndex; i < endIndex; i++) {
          sheet.setRowVisible(i, false);
        }
      });
      sheet.showCell(activeRow, activeCol, VerticalPosition.nearest, HorizontalPosition.nearest);
      sheet.resumeEvent();
      sheet.resumePaint();
      return true;
    }
    return false;
  };
  return HideSelectRowsUndoAction;
})(ShortcutActionBase);
Commands[HIDE_SELECT_ROWS] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    options.cmd = HIDE_SELECT_ROWS;
    var sheet = context.getSheetFromName(options.sheetName);
    var selections = sheet.getSelections();
    var rowInfos = [];
    if (!isUndo && selections.length > 0) {
      selections.forEach(function (selection) {
        var startIndex = selection.row;
        var rowCount = selection.rowCount;
        rowInfos.push({
          startRowIndex: startIndex,
          rowCount: rowCount,
        });
      });
      options.rowInfo = rowInfos;
    }
    return executeCommand(context, HideSelectRowsUndoAction, options, isUndo);
  },
};
var GroupSelectRangeUndoAction = /** @class */ (function (_super) {
  __extends(GroupSelectRangeUndoAction, _super);
  function GroupSelectRangeUndoAction() {
    return (_super !== null && _super.apply(this, arguments)) || this;
  }
  GroupSelectRangeUndoAction.prototype.executeImp = function () {
    var self = this;
    var command = self._options;
    var sheet = self.getSheet();
    var selection = command.selection,
      isGroup = command.isGroup;
    if (!selection) {
      return false;
    }
    var selectRangeType = Util.getRangeType(selection);
    try {
      sheet.suspendPaint();
      if (selectRangeType === 2 /* RangeType.fullColumn */) {
        var col = selection.col,
          colCount = selection.colCount;
        if (isGroup) {
          sheet.columnOutlines.group(col, colCount);
        } else {
          sheet.columnOutlines.ungroupRange(col, colCount);
        }
        sheet.showCell(0, col, VerticalPosition.nearest, HorizontalPosition.nearest);
      } else if (selectRangeType === 1 /* RangeType.fullRow */) {
        var row = selection.row,
          rowCount = selection.rowCount;
        if (isGroup) {
          sheet.rowOutlines.group(row, rowCount);
        } else {
          sheet.rowOutlines.ungroupRange(row, rowCount);
        }
        sheet.showCell(row, 0, VerticalPosition.nearest, HorizontalPosition.nearest);
      }
    } finally {
      sheet.resumePaint();
    }
    return true;
  };
  GroupSelectRangeUndoAction.prototype.canUndo = function () {
    return true;
  };
  return GroupSelectRangeUndoAction;
})(ShortcutActionBase);
Commands[GROUP_SELECT_RANGE] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = GROUP_SELECT_RANGE;
    if (!isUndo) {
      var selections = sheet.getSelections();
      options.isGroup = true;
      if (selections.length === 1) {
        var selection = selections[0];
        var selectionType = Util.getRangeType(selection);
        if (selectionType === 1 /* RangeType.fullRow */ || selectionType === 2 /* RangeType.fullColumn */) {
          options.selection = selection;
        }
      }
    }
    if (options.selection) {
      return executeCommand(context, GroupSelectRangeUndoAction, options, isUndo);
    } else {
      return false;
    }
  },
};
Commands[UNGROUP_SELECT_RANGE] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = UNGROUP_SELECT_RANGE;
    if (!isUndo) {
      var selections = sheet.getSelections();
      options.isGroup = false;
      if (selections.length === 1) {
        var selection = selections[0];
        var selectionType = Util.getRangeType(selection);
        if (selectionType === 1 /* RangeType.fullRow */ || selectionType === 2 /* RangeType.fullColumn */) {
          options.selection = selection;
        }
      }
    }
    if (options.selection) {
      return executeCommand(context, GroupSelectRangeUndoAction, options, isUndo);
    } else {
      return false;
    }
  },
};
export function initShortcutAboutRowsAndColumns(commands) {
  commands.register(
    HIDE_SELECT_ROWS,
    Commands[HIDE_SELECT_ROWS],
    57 /* 9 */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    GROUP_SELECT_RANGE,
    Commands[GROUP_SELECT_RANGE],
    39 /* Right Arrow*/,
    false /* ctrl */,
    true /* shift */,
    true /* alt */,
    false /* meta */
  );
  commands.register(
    UNGROUP_SELECT_RANGE,
    Commands[UNGROUP_SELECT_RANGE],
    37 /* Left Arrow*/,
    false /* ctrl */,
    true /* shift */,
    true /* alt */,
    false /* meta */
  );
  commands.register(
    DELETE_ROW_OR_COLUMN,
    Commands[DELETE_ROW_OR_COLUMN],
    109 /* - */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    INSERT_ROW_OR_COLUMN,
    Commands[INSERT_ROW_OR_COLUMN],
    107 /* + */,
    true /* ctrl */,
    true /* shift */,
    false /* alt */,
    false /* meta */
  );
  if (Util.isFirefox()) {
    commands.register(
      DELETE_ROW_OR_COLUMN,
      Commands[DELETE_ROW_OR_COLUMN],
      173 /* - */,
      true /* ctrl */,
      false /* shift */,
      false /* alt */,
      false /* meta */
    );
    commands.register(
      INSERT_ROW_OR_COLUMN,
      Commands[INSERT_ROW_OR_COLUMN],
      61 /* + */,
      true /* ctrl */,
      true /* shift */,
      false /* alt */,
      false /* meta */
    );
  } else {
    commands.register(
      DELETE_ROW_OR_COLUMN,
      Commands[DELETE_ROW_OR_COLUMN],
      189 /* - */,
      true /* ctrl */,
      false /* shift */,
      false /* alt */,
      false /* meta */
    );
    commands.register(
      INSERT_ROW_OR_COLUMN,
      Commands[INSERT_ROW_OR_COLUMN],
      187 /* + */,
      true /* ctrl */,
      true /* shift */,
      false /* alt */,
      false /* meta */
    );
  }
}
