import React from 'react';
import PropTypes from 'prop-types';

const FunctionHint = ({ style, hintParams }) => {
  const { functionSelected, functionParamSelected, functionProps } = hintParams;

  const renderParameters = parameters => {
    return (
      <>
        <table data-testid="hint-table-parameter">
          <tbody>
            {parameters.map((item, index) => (
              <tr key={index}>
                <td data-testid={`hint-table-parameter-name-${index}`}>{item.name}</td>
                <td data-testid={`hint-table-parameter-description-${index}`}>{item.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };

  const renderExample = example => {
    return (
      <>
        <h5 data-testid="hint-example-title">{example.title}</h5>
        {example.formulaList.map((formula, index) => (
          <pre key={index} data-testid={`hint-example-formula-${index}`}>
            {formula}
          </pre>
        ))}
      </>
    );
  };

  const renderFullDescription = fullDescription => {
    return (
      <div className="full-description" data-testid="hint-full-description">
        {fullDescription}
      </div>
    );
  };

  const params = functionProps
    ? functionProps.placeholderParams.map((p, idx) => {
        const param = idx === functionProps.placeholderParams.length - 1 ? p : `${p},`;
        return (
          <span key={idx} className={idx === functionParamSelected ? 'parameter-active' : ''}>
            {' '}
            {param}
          </span>
        );
      })
    : null;

  return (
    <div className="react-autocomplete-dialog-Helper" style={style}>
      <div className="header" data-testid="hint-header">
        <strong>{`${functionSelected}(`}</strong>
        <div className="params" data-testid="hint-parameter">
          {params}
        </div>
        {')'}
      </div>
      <div className="description" data-testid="hint-description">
        {functionProps?.description}
      </div>
      {functionProps?.parameters && renderParameters(functionProps?.parameters)}
      {functionProps?.fullDescription && renderFullDescription(functionProps?.fullDescription)}
      {functionProps?.example && renderExample(functionProps?.example)}
    </div>
  );
};

FunctionHint.propTypes = {
  style: PropTypes.object.isRequired,
  hintParams: PropTypes.object.isRequired,
};

export default FunctionHint;
