const isValidCellFormat = str => {
  let isValid = false;

  if (str.length > 0 && str.length < 2) {
    // minimun length is 2 if provided
    return isValid;
  }

  // string has numbers and letters
  if (!onlyNumbersAndLetters(str)) {
    return isValid;
  }

  // String has valid Cell format
  return cellHasValidFormat(str);
};

const onlyNumbersAndLetters = str => {
  const pattern = /^[A-Za-z0-9]+$/;
  return str.match(pattern);
};

const cellHasValidFormat = str => {
  let isValid = false;

  // string starts with letter
  if (!startWithLetter(str)) {
    return false;
  }

  // letters are between A and XFD
  // numbers are between 1 and 1048576
  let lettersCount = 0;
  let isNumberReached = false;
  let lettersStr = '';
  let numberMaxValue = 1048576;
  for (let i = 0; i < str.length; i++) {
    if (isLetter(str[i])) {
      lettersCount++;
      lettersStr += str[i];
    }

    // if we already got a number from the string, the following character should not include a letter
    if (isNumberReached) {
      const subStr = str.substring(i);

      // next characters should only be numbers
      if (!isNumber(subStr)) {
        return false;
      }

      // Check if number has leading zeros
      if (Number(str.substring(i - 1)).toString() !== str.substring(i - 1)) {
        return false;
      }

      // numbers part of the string should be > 1 && <= 1048576
      const cellNumber = parseInt(str.substring(i - 1));
      if (cellNumber > numberMaxValue || cellNumber === 0) {
        return false;
      }

      return true;
    }

    if (lettersCount === 3) {
      //max value for letters is XFD
      if (
        areLettersInInvalidRange(lettersStr, /^X[G-Z][A-D]$/gi) ||
        areLettersInInvalidRange(lettersStr, /^XF[E-Z]$/gi)
      ) {
        return false;
      }
    }

    // letters from string must have a maximum length of 3
    if (lettersCount > 3) {
      return isValid;
    }

    if (isNumber(str[i])) {
      isNumberReached = true;
    }

    // Verify if a number was detected in the string
    if (!isNumberReached && i + 1 === str.length) {
      return isValid;
    }
  }

  return true;
};

const startWithLetter = str => {
  const pattern = /^[A-Za-z]+$/;
  const firstLetter = str.substring(0, 1);
  return firstLetter.match(pattern);
};

const isNumber = char => {
  const pattern = /^[0-9]+$/;
  return char.match(pattern);
};

const isLetter = char => {
  const pattern = /^[A-Za-z]+$/;
  return char.match(pattern);
};

const areLettersInInvalidRange = (letters, pattern) => {
  if (letters.match(pattern)) {
    return true;
  }

  return false;
};

export default isValidCellFormat;
