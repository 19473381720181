import { useRef, useEffect, useCallback } from 'react';

export const useRunAfterUpdate = state => {
  const stateCallback = useRef();

  useEffect(() => {
    if (stateCallback.current) {
      stateCallback.current(state);
      stateCallback.current = null;
    }
  }, [state, stateCallback]);

  const runAfterUpdate = useCallback(
    callback => {
      stateCallback.current = callback;
    },
    [stateCallback]
  );

  return runAfterUpdate;
};
