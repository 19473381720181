// convert to Postgres default ISO 8601 format
function formatDate(date) {
  date = date.replace('T', ' ');
  date = date.replace('Z', '');

  //appending time to date if time is missing/omitted
  if (date.length <= 10) {
    date = date + ' 00:00:00';
  }

  return date;
}

export function getFormattedDateForGrid(isoString) {
  const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
  const localDateTime = new Date(isoString);
  const dateForGrid =
    localDateTime.toLocaleDateString('en', dateOptions) + ' ' + localDateTime.toLocaleTimeString('en', timeOptions);
  return dateForGrid;
}

export function getFormattedDate(isoString) {
  const date = isoString instanceof Date ? isoString : new Date(isoString);

  const month = appendZero(date.getMonth() + 1);
  const day = appendZero(date.getDate());
  const year = date.getFullYear();
  const minute = appendZero(date.getMinutes());
  let hour = date.getHours();

  const dayType = hour >= 12 ? 'PM' : 'AM';

  if (hour === 0) {
    hour = 12;
  } else if (hour > 12) {
    hour = hour - 12;
  }
  hour = appendZero(hour);

  return `${month}/${day}/${year}, ${hour}:${minute} ${dayType}`;
}

export function getDBFormattedDate(isoString) {
  const date = new Date(formatDate(isoString));
  const month = appendZero(date.getMonth() + 1);
  const day = appendZero(date.getDate());
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export function getMDYFormattedDate(isoString) {
  const date = isoString instanceof Date ? isoString : new Date(formatDate(isoString));
  const month = appendZero(date.getMonth() + 1);
  const day = appendZero(date.getDate());
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

function appendZero(value) {
  return value < 10 ? '0' + value : value;
}
