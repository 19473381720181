import React from 'react';
import { BTModal, BTButton } from '@btas/jasper';

export const ConfirmModal = ({ modalState }) => {
  const {
    show,
    title,
    message,
    confirmLabel,
    cancelLabel,
    modalStyle,
    onConfirm,
    onCancel,
    confirmBtnId,
    disableConfirm,
    isProcessing,
  } = modalState;
  return (
    <BTModal btStyle={modalStyle || ''} show={show} title={title || 'Confirm'} onCloseClick={onCancel}>
      <BTModal.Body>{message || 'Are you sure?'}</BTModal.Body>
      <BTModal.Footer>
        {cancelLabel && onCancel && <BTButton onClick={onCancel}>{cancelLabel}</BTButton>}
        <BTButton
          btStyle="primary"
          disabled={disableConfirm || false}
          hasSpinner={isProcessing || false}
          id={confirmBtnId || 'confirmBtn'}
          onClick={onConfirm}
        >
          {confirmLabel || 'Continue'}
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};
