import GC from '../../../../../../../SpreadSheets';
import { Commands, executeCommand, ShortcutActionBase } from './shell/action.base';
import { Util } from './common/util';
var __extends =
  (this && this.__extends) ||
  (function () {
    var extendStatics = function (d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function (d, b) {
            d.__proto__ = b;
          }) ||
        function (d, b) {
          for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
        };
      return extendStatics(d, b);
    };
    return function (d, b) {
      if (typeof b !== 'function' && b !== null)
        throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
      extendStatics(d, b);
      function __() {
        this.constructor = d;
      }
      d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
    };
  })();
var SET_DATE = 'setDate',
  COPY_CELL_DOWN = 'copyCellDown',
  COPY_CELL_RIGHT = 'copyCellRight',
  START_EDIT = 'startEdit',
  CHANGE_FORMULA_REFERENCE = 'changeFormulaReference';
var VerticalPosition = GC.Spread.Sheets.VerticalPosition;
var HorizontalPosition = GC.Spread.Sheets.HorizontalPosition;
var SetDateUndoAction = /** @class */ (function (_super) {
  __extends(SetDateUndoAction, _super);
  function SetDateUndoAction() {
    return (_super !== null && _super.apply(this, arguments)) || this;
  }
  SetDateUndoAction.prototype.executeImp = function () {
    var self = this;
    var sheet = self.getSheet();
    var options = self._options;
    sheet.suspendPaint();
    var activeRow = options.rowIndex,
      activeCol = options.columnIndex;
    var cell = sheet.getCell(activeRow, activeCol);
    if (cell.formula()) {
      cell.formula('');
    }
    var date = options.date;
    cell.value(date);
    sheet.showCell(activeRow, activeCol, VerticalPosition.nearest, HorizontalPosition.nearest);
    sheet.resumePaint();
    return true;
  };
  SetDateUndoAction.prototype.canUndo = function () {
    return true;
  };
  return SetDateUndoAction;
})(ShortcutActionBase);
Commands[SET_DATE] = {
  canUndo: true,
  execute: function (context, option, isUndo) {
    var sheet = context.getSheetFromName(option.sheetName);
    if (!isUndo && !option.cmd) {
      option.cmd = SET_DATE;
      var rowIndex = sheet.getActiveRowIndex();
      var colIndex = sheet.getActiveColumnIndex();
      var date = new Date();
      date.setHours(0, 0, 0, 0);
      var value = sheet.getValue(rowIndex, colIndex);
      if (value instanceof Date) {
        if (value.getTime() === date.getTime()) {
          return false;
        }
      }
      option.rowIndex = rowIndex;
      option.columnIndex = colIndex;
      option.date = date;
    }
    return executeCommand(context, SetDateUndoAction, option, isUndo);
  },
};
var AutoFillDirectionAction = /** @class */ (function (_super) {
  __extends(AutoFillDirectionAction, _super);
  function AutoFillDirectionAction() {
    return (_super !== null && _super.apply(this, arguments)) || this;
  }
  AutoFillDirectionAction.prototype.executeImp = function () {
    var self = this;
    var sheet = self.getSheet();
    var selections = sheet.getSelections();
    var options = self._options;
    if (selections.length === 1) {
      // In excel, only work for single selection range.
      var selection = selections[0],
        selectRowIndex = selection.row,
        selectColIndex = selection.col;
      // include single cell and single range, cannot use setValue, as need copy formula too.
      var startRange = null,
        fillRange = void 0;
      switch (options.direction) {
        case GC.Spread.Sheets.Fill.FillDirection.down:
          if (selectRowIndex > 0) {
            // first row, skip
            startRange = new GC.Spread.Sheets.Range(selectRowIndex - 1, selectColIndex, 1, selection.colCount);
            fillRange = new GC.Spread.Sheets.Range(
              selectRowIndex - 1,
              selectColIndex,
              selection.rowCount + 1,
              selection.colCount
            );
          }
          break;
        case GC.Spread.Sheets.Fill.FillDirection.right:
          if (selectColIndex > 0) {
            // first col, skip
            startRange = new GC.Spread.Sheets.Range(selectRowIndex, selectColIndex - 1, selection.rowCount, 1);
            fillRange = new GC.Spread.Sheets.Range(
              selectRowIndex,
              selectColIndex - 1,
              selection.rowCount,
              selection.colCount + 1
            );
          }
          break;
        default:
          break;
      }
      if (startRange && fillRange) {
        sheet.fillAuto(startRange, fillRange, {
          fillType: GC.Spread.Sheets.Fill.FillType.direction,
          direction: options.direction,
        });
        sheet.showCell(startRange.row, startRange.col, VerticalPosition.nearest, HorizontalPosition.nearest);
      }
    }
    return true;
  };
  return AutoFillDirectionAction;
})(ShortcutActionBase);
Commands[COPY_CELL_DOWN] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    options.cmd = COPY_CELL_DOWN;
    var sheet = context.getSheetFromName(options.sheetName);
    var selections = sheet.getSelections();
    if (selections.length > 0) {
      options.direction = GC.Spread.Sheets.Fill.FillDirection.down;
      return executeCommand(context, AutoFillDirectionAction, options, isUndo);
    }
    return false;
  },
};
Commands[COPY_CELL_RIGHT] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    options.cmd = COPY_CELL_RIGHT;
    var sheet = context.getSheetFromName(options.sheetName);
    var selections = sheet.getSelections();
    if (selections.length > 0) {
      options.direction = GC.Spread.Sheets.Fill.FillDirection.right;
      return executeCommand(context, AutoFillDirectionAction, options, isUndo);
    }
    return false;
  },
};
Commands[START_EDIT] = {
  canUndo: false,
  execute: function (context, options) {
    var sheet = context.getSheetFromName(options.sheetName);
    if (sheet && !sheet.isEditing()) {
      sheet.startEdit();
    }
  },
};
export function initShortcutAboutCell(commands) {
  if (Util.isFirefox()) {
    commands.register(
      SET_DATE,
      Commands[SET_DATE],
      59 /* semicolon */,
      true /* ctrl */,
      false /* shift */,
      false /* alt */,
      false /* meta */
    );
  } else {
    commands.register(
      SET_DATE,
      Commands[SET_DATE],
      186 /* semicolon */,
      true /* ctrl */,
      false /* shift */,
      false /* alt */,
      false /* meta */
    );
  }
  commands.register(
    COPY_CELL_DOWN,
    Commands[COPY_CELL_DOWN],
    68 /* D */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    COPY_CELL_RIGHT,
    Commands[COPY_CELL_RIGHT],
    82 /* R */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    START_EDIT,
    Commands[START_EDIT],
    113 /* Function key 2 (F2) */,
    false /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.setShortcutKey(
    CHANGE_FORMULA_REFERENCE,
    115 /* Function key 4 (F4) */,
    false /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
}
