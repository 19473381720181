import { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function useHistoryItem({
  active,
  displayName,
  item,
  getItemDisplayName,
  onVersionNameChange,
  setReadOnlyModeDisplayName,
}) {
  const versionNameInputRef = useRef(null);
  const history = useHistory();
  const [versionName, setVersionName] = useState('');
  const [showVersionNameInput, setShowVersionNameInput] = useState(false);
  const [showVersionNameOptions, setShowVersionNameOptions] = useState(false);
  const [isValidName, setIsValidName] = useState(true);
  const { id, workpaperId, latest } = item;

  const onVersionNameInputChange = e => {
    setVersionName(e.target.value);

    const isValid = Boolean(e.target.value && e.target.value.trim().length);
    setIsValidName(isValid);
  };

  const onVersionNameKeyUp = event => {
    const { key } = event;
    if (key === 'Enter') {
      const isSameName = versionName.trim() === displayName.trim();
      if (isValidName && !isSameName) {
        onVersionNameChange({ versionName, versionId: id });
      }

      setShowVersionNameInput(false);
    } else if (key === 'Escape') {
      setShowVersionNameInput(false);
    }
  };

  const openVersionItem = (workpaperId, versionId, displayName) => {
    if (active) return;

    setReadOnlyModeDisplayName(displayName);

    const pathname = `/editor/${workpaperId}` + (!latest ? `/history/${versionId}` : '');
    history.push({
      pathname,
      state: { displayName },
    });
  };

  const renameVersionItem = () => {
    setIsValidName(true);
    const initialName = getItemDisplayName(item);
    setVersionName(initialName);
    setShowVersionNameInput(true);
  };

  useEffect(() => {
    if (showVersionNameInput) {
      versionNameInputRef.current.focus();
    }
  }, [showVersionNameInput]);

  return {
    versionNameInputRef,
    showVersionNameInput,
    isValidName,
    versionName,
    workpaperId,
    versionId: id,
    latest,
    showVersionNameOptions,
    setShowVersionNameOptions,
    onVersionNameInputChange,
    onVersionNameKeyUp,
    openVersionItem,
    renameVersionItem,
  };
}
