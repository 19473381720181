import React from 'react';
import TextField from './AutoCompleteField/AutoCompleteField';
import {
  filterFunctions,
  triggerStrings,
  filterFunctionOptions,
  hintOptions,
  fieldOptions,
} from './AutoCompleteField/constants';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';

const FilterTextArea = ({ fields, maxLength, error, value, onChange }) => {
  const { byFunc, byHint, byFields } = triggerStrings;
  const canEditWorkflow = useCanEditWorkflow();
  return (
    <TextField
      disabled={!canEditWorkflow}
      error={error}
      maxLength={maxLength}
      options={{
        ...filterFunctionOptions,
        ...fieldOptions(fields),
        ...hintOptions,
      }}
      spacer=""
      supportedFunctions={filterFunctions}
      trigger={[...byFunc, ...byFields, byHint]}
      value={value}
      onChange={onChange}
    />
  );
};

export default FilterTextArea;
