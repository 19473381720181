import React, { useEffect, useState } from 'react';
import { getUser } from './auth';
import Tracker from './EventTrackingContext/Tracker';
import eventConfigurations from './EventTrackingContext/eventConfiguration';
import { setPropValues } from './EventTrackingContext/utils';

const EventTrackingContext = React.createContext();

const EventTrackingProvider = ({ children }) => {
  const userInfo = getUser();
  const [tracker, setTracker] = useState(null);

  useEffect(() => {
    if (userInfo && !tracker) {
      setTracker(new Tracker(userInfo));
    }
  }, [userInfo, tracker]);

  useEffect(() => {
    const handleEvent = (event, eventName, tagPropertiesMapping) => {
      const tag = event.target.getAttribute('data-track-tag');
      if (tag && tagPropertiesMapping[tag] && tracker) {
        const isValidEvent = tracker.isValid(eventName);
        if (isValidEvent) {
          tracker.track(eventName, setPropValues(event, eventName, tagPropertiesMapping[tag]));
        } else {
          console.error(`[TrackingSupport] Error: '${eventName}' not valid`);
        }
      }
    };

    const addEventListeners = () => {
      eventConfigurations.forEach(({ eventType, eventName, tagPropertiesMapping }) => {
        document.addEventListener(eventType, event => handleEvent(event, eventName, tagPropertiesMapping));
      });
    };

    const removeEventListeners = () => {
      eventConfigurations.forEach(({ eventType, eventName, tagPropertiesMapping }) => {
        document.removeEventListener(eventType, event => handleEvent(event, eventName, tagPropertiesMapping));
      });
    };

    addEventListeners();
    return removeEventListeners;
  }, [tracker]);

  // function to manually trigger tracking events
  const trackEvent = (eventName, properties) => {
    if (tracker && tracker.isValid(eventName)) {
      tracker.track(eventName, properties);
    } else {
      console.error(`[TrackingSupport] Error: '${eventName}' not valid or tracker not initialized`);
    }
  };

  return <EventTrackingContext.Provider value={{ trackEvent }}>{children}</EventTrackingContext.Provider>;
};

export { EventTrackingProvider, EventTrackingContext };
