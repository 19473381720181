/**
 * This file overrides the paint method, keeping the older paint methods hierarchy untouched.
 *
 * The changes here are only related to the icons on the cell.
 *
 * Currently it also has the code for state tax rate logic
 */

import GC from '../../../../../SpreadSheets';
import { StateTaxRateDataFormulaRegEx } from './formulas';
import { adjustCells, STATE_TAX_RATE_ERROR } from './commands/_shared/adjustCells';
import { exportIcons } from './commands/cellReview/exportIcons';
import { registerTooltip, unregisterTooltip } from './tooltipHelper';

const { checkExclamationIcon } = exportIcons();

export function overrideCellDefinition(tooltipManager) {
  const oldPaint = GC.Spread.Sheets.CellTypes.Base.prototype.paint;

  GC.Spread.Sheets.CellTypes.Base.prototype.paint = function (ctx, value, x, y, w, h, style, context) {
    const modifiedArguments = [...arguments];
    const { sheet, row, col } = context;
    const taxRateTooltipId = `wkp_cell_tax_rate_error_'${row}'_'${col}'`;
    const formula = sheet.getFormula(row, col);

    if (formula) {
      const stateTaxRateDataFormulaMatch = formula.match(StateTaxRateDataFormulaRegEx);

      if (stateTaxRateDataFormulaMatch) {
        const stateTaxRateHasError = createStateTaxRateIndicator(
          x,
          y,
          ctx,
          context,
          formula,
          tooltipManager,
          stateTaxRateDataFormulaMatch,
          w,
          h
        );
        if (stateTaxRateHasError) {
          const { newX, newW } = adjustCells(x, w, STATE_TAX_RATE_ERROR);
          modifiedArguments[2] = newX;
          modifiedArguments[4] = newW;
        } else {
          unregisterTooltip(tooltipManager, taxRateTooltipId);
        }
      } else {
        unregisterTooltip(tooltipManager, taxRateTooltipId);
      }

      oldPaint.apply(this, modifiedArguments);
      return;
    }

    unregisterTooltip(tooltipManager, taxRateTooltipId);
    oldPaint.apply(this, modifiedArguments);
  };
}

/* Description: returns true if user inputs (jurisdiction, periodStartDate, taxableIncome) are invalid and generates error tooltip with appropriate messages
 * Inputs:
    x - origin x coordinate location inside cell
    y - origin y coordinate location inside cell
    ctx - paint context for the cell
    context - react context of the sheet and cell
    sheet - current worksheet
    row - cell ro
    col - cell column
    tooltipManager - keeps track of tooltip state
    stateTaxRateDataFormulaMatch - matches to STATETAXRATE formula to execute
 */
export function createStateTaxRateIndicator(
  x,
  y,
  ctx,
  context,
  formula,
  tooltipManager,
  stateTaxRateDataFormulaMatch,
  w,
  h
) {
  const { sheet, row, col } = context;
  const value = sheet.getCell(row, col).value();
  const stateTaxRateValue = parseFloat(value);

  if (!stateTaxRateValue) {
    const cellTag = sheet.getTag(row, col);
    const cellStateTaxRateTooltip = cellTag && cellTag.stateTaxRateTooltip;

    if (cellStateTaxRateTooltip) {
      stateTaxRateErrorIndicator(ctx, x, y, sheet, row, col, tooltipManager, cellStateTaxRateTooltip);
      return true;
    }
  }
  return false;
}

/* Description: Draws and registers state tax rate error icon and hover tooltip
 * Inputs:
    ctx - paint context for the cell
    x - origin x coordinate location inside cell
    y - origin y coordinate location inside cell
    sheet - current worksheet
    row - cell row
    col - cell column
    tooltipManager - keeps track of tooltip state
    tooltipContent - error content to display in tooltip
 */
function stateTaxRateErrorIndicator(ctx, x, y, sheet, row, col, tooltipManager, tooltipContent) {
  const tooltipId = `wkp_cell_tax_rate_error_'${row}'_'${col}'`;

  try {
    ctx.drawImage(checkExclamationIcon.image, x + 2, y + 2, 16, 16);
  } catch (error) {}
  const { height, width } = sheet.getCellRect(row, col);
  registerTooltip(tooltipManager, x, y, { height, width }, { x: 5, y: 0 }, tooltipId, tooltipContent, { isHTML: true });
}
