import { getUser } from '../../../../../_shared/auth';
import { MARK_CELL_REVIEWED_COMMAND, UNMARK_CELL_REVIEWED_COMMAND } from './cellReview/constants';
import { CELL_REVIEW } from '../../../../../_shared/DataReference/ReferenceType';
import { v4 as uuidv4 } from 'uuid';
import { cleanDirtyCellValue } from '../../../DataReference/dataReferenceHelper';

export function createCellReviewCommand({ cellReviewFunctionManager, workpaperId, addToHistory }) {
  const {
    dataReferences,
    enqueueManyDataReferences,
    processCellReviewQueue,
    dataReferenceDeleteQueue,
    processDataReferencesDeleteQueue,
  } = cellReviewFunctionManager?.current;

  const cellReviewCommand = {
    text: 'Mark reviewed',
    commandName: MARK_CELL_REVIEWED_COMMAND,
    execute(context, _, contextMenuArgs) {
      const { Spread: spread } = context;
      const cellReviewLimit = 4;

      const user = getUser();
      const sheet = getActiveSheet(spread, contextMenuArgs);
      const sheetName = sheet.name();

      const selections = sheet.getSelections();
      const nonReviewedCells = [];
      const dataReferencesToAdd = [];
      const dataReferencesToUpdate = [];

      sheet.suspendPaint();

      selections.forEach(selected => {
        for (let rowX = 0; rowX < selected.rowCount; rowX++) {
          for (let colY = 0; colY < selected.colCount; colY++) {
            const row = selected.row + rowX;
            const column = selected.col + colY;

            if (user) {
              const cell = sheet.getCell(row, column);
              const value = cell.text();

              const existingReviews = dataReferences.current.filter(
                dRef =>
                  dRef.type === CELL_REVIEW &&
                  dRef.row === row &&
                  dRef.column === column &&
                  dRef.sheetName === sheetName
              );

              const prevUserReview = existingReviews.find(
                dRef => jsonPropertyCheck(dRef.parameters, 'ReviewerUserId') === user.userId
              );
              const isReviewLimit = existingReviews.length >= cellReviewLimit && !prevUserReview;

              if (isReviewLimit) {
                nonReviewedCells.push(`${columnToLetter(column + 1)}${row + 1}`);
                continue;
              }

              if (prevUserReview) {
                prevUserReview.value = cleanDirtyCellValue(value);
                prevUserReview.parameters = {
                  ReviewerUserId: user.userId,
                  ReviewerUserFirstName: user.firstName,
                  ReviewDateTime: new Date().toISOString().slice(0, 19),
                  ApprovedValue: value,
                };
                dataReferencesToUpdate.push(prevUserReview);
                dataReferencesToAdd.push(prevUserReview);
              } else {
                const dataReference = {
                  id: uuidv4(),
                  sheetName,
                  row,
                  column,
                  type: CELL_REVIEW,
                  value: cleanDirtyCellValue(value),
                  parameters: {
                    ReviewerUserId: user.userId,
                    ReviewerUserFirstName: user.firstName,
                    ReviewDateTime: new Date().toISOString().slice(0, 19),
                    ApprovedValue: value,
                  },
                };

                dataReferencesToAdd.push(dataReference);
              }
            }
          }
        }
      });

      if (dataReferencesToUpdate.length) {
        dataReferences.current = dataReferences.current.filter(x => !dataReferencesToUpdate.some(y => y.id === x.id));
      }

      if (dataReferencesToAdd.length) {
        enqueueManyDataReferences(dataReferencesToAdd);

        // Add context menu item to remove approval (otherwise user has to make another selection)
        spread.contextMenu.menuData.push({
          text: 'Unmark reviewed',
          name: UNMARK_CELL_REVIEWED_COMMAND,
          command: UNMARK_CELL_REVIEWED_COMMAND,
        });

        processCellReviewQueue(spread, sheet, addToHistory, false);
      }

      if (nonReviewedCells.length > 0) {
        alert(
          `Cell${nonReviewedCells.length > 1 ? 's' : ''} ${nonReviewedCells.join(
            ', '
          )} could not be reviewed.\nOnly four unique users can mark a cell as reviewed at one time.`
        );
      }

      sheet.resumePaint();
      return true;
    },
    workArea: 'viewport',
  };

  const unmarkCellReviewCommand = {
    text: 'Unmark reviewed',
    commandName: UNMARK_CELL_REVIEWED_COMMAND,
    execute(context, _, contextMenuArgs) {
      const { Spread: spread } = context;
      const user = getUser();
      const sheet = getActiveSheet(spread, contextMenuArgs);
      const selections = sheet.getSelections();
      const referenceIdsToDelete = [];

      sheet.suspendPaint();

      selections.forEach(selected => {
        for (let rowX = 0; rowX < selected.rowCount; rowX++) {
          for (let colY = 0; colY < selected.colCount; colY++) {
            const row = selected.row + rowX;
            const column = selected.col + colY;

            const cellReviewRefs = dataReferences.current.filter(
              dRef =>
                dRef.type === CELL_REVIEW &&
                dRef.row === row &&
                dRef.column === column &&
                dRef.sheetName === sheet.name()
            );

            // eslint-disable-next-line no-loop-func
            cellReviewRefs?.forEach(ref => {
              const reviewerId = jsonPropertyCheck(ref.parameters, 'ReviewerUserId');
              if (reviewerId === user.userId) {
                referenceIdsToDelete.push(ref.id);
              }
            });
          }
        }
      });

      // Remove context menu item to remove approval (otherwise user has to make another selection)
      if (referenceIdsToDelete.length) {
        dataReferenceDeleteQueue.current = [...dataReferenceDeleteQueue.current, ...referenceIdsToDelete];
        processDataReferencesDeleteQueue(workpaperId, () => {}, true, spread, addToHistory);
        spread.contextMenu.menuData = spread.contextMenu.menuData.filter(
          ({ command }) => command !== UNMARK_CELL_REVIEWED_COMMAND
        );
      }

      sheet.resumePaint();

      return true;
    },
    workArea: 'viewport',
  };

  return {
    [MARK_CELL_REVIEWED_COMMAND]: {
      ...cellReviewCommand,
      visibleContext: 'ClickViewport || ClickCorner || ClickColHeader || ClickRowHeader',
      enableContext: '!CellLocked || !IsProtected',
    },
    [UNMARK_CELL_REVIEWED_COMMAND]: {
      ...unmarkCellReviewCommand,
      visibleContext: 'ClickViewport || ClickCorner || ClickColHeader || ClickRowHeader',
      enableContext: '!CellLocked || !IsProtected',
    },
  };
}

function getActiveSheet(spread, contextMenuArgs) {
  return contextMenuArgs ? spread.getSheetFromName(contextMenuArgs.sheetName) : spread.getActiveSheet();
}

function columnToLetter(column) {
  let temp,
    letter = '';
  while (column > 0) {
    temp = (column - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    column = (column - temp - 1) / 26;
  }
  return letter;
}

function jsonPropertyCheck(jsonString, property) {
  const regex = new RegExp(`"${property}"\\s*:\\s*"(.*?)"`);
  const match = jsonString.match(regex);
  if (match) {
    return match[1];
  }

  return null;
}
