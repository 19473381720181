import { useRef } from 'react';
import { CELL_REVIEW } from '../../../_shared/DataReference/ReferenceType';

function updateUndoStack(undoManager, historyTracker) {
  const undoStack = undoManager.getUndoStack();

  if (undoStack.length) {
    undoStack.forEach(stackItem => {
      const { cmd, sheetName, row, col, sheetArea } = stackItem;
      if (
        !historyTracker.current.some(
          record =>
            record.cmd === cmd &&
            record.sheetName === sheetName &&
            record.row === row &&
            record.col === col &&
            record.sheetArea === sheetArea
        )
      ) {
        historyTracker.current.push(stackItem);
      }
    });
  }
}

export const CELL_REVIEW_ADDED = 'CELL_REVIEW_ADDED';
export const CELL_REVIEW_REMOVED = 'CELL_REVIEW_REMOVED';
export const CELL_REVIEW_COMMAND_DELETE = 'CELL_REVIEW_COMMAND_DELETE';

function useHistoryTracker(cellReviewFunctionManager, workpaperId) {
  const {
    dataReferenceDeleteQueue,
    processDataReferencesDeleteQueue,
    enqueueManyDataReferences,
    processCellReviewQueue,
  } = cellReviewFunctionManager.current;
  const historyTracker = useRef([]);
  const addToHistory = (action, spread) => {
    updateUndoStack(spread.undoManager(), historyTracker);
    historyTracker.current.push(action);
  };

  const undoSpreadJsAction = spread => {
    const undoManager = spread.undoManager();
    const undoStack = undoManager.getUndoStack();
    if (undoStack.length) {
      undoManager.undo();
    }
  };

  const removeCellReviewTag = tag => tag?.references?.filter(({ type }) => type !== CELL_REVIEW);

  const undoRemovedCellReviews = (spread, latestAction, isUndoDeleteCommandAction = false) => {
    const cellReviewsToAdd = latestAction.data.map(cellReview => ({
      ...cellReview,
      parameters: cellReview?.parameters ? JSON.parse(cellReview.parameters) : '{}',
    }));
    enqueueManyDataReferences(cellReviewsToAdd, isUndoDeleteCommandAction);
    processCellReviewQueue(spread, spread.getActiveSheet(), null);
  };

  const undoAction = spread => {
    updateUndoStack(spread.undoManager(), historyTracker);
    const latestAction = historyTracker.current.pop();
    if (!latestAction) {
      undoSpreadJsAction(spread);
      return;
    }
    switch (latestAction.cmd) {
      case CELL_REVIEW_ADDED:
        const idsToDelete = latestAction.data.map(({ id }) => id);
        dataReferenceDeleteQueue.current = idsToDelete;
        latestAction.data.forEach(({ row, column }) => {
          // Remove cell-tag
          try {
            const activeSheet = spread.getActiveSheet();
            const tag = activeSheet.getTag(row, column);
            const newTag = removeCellReviewTag(JSON.parse(tag));
            activeSheet.setTag(JSON.stringify(newTag));
          } catch (error) {
            console.log(error);
          }
        });
        processDataReferencesDeleteQueue(workpaperId, () => spread.getActiveSheet().repaint());
        break;
      case CELL_REVIEW_REMOVED:
        undoRemovedCellReviews(spread, latestAction);
        break;
      case CELL_REVIEW_COMMAND_DELETE:
        undoRemovedCellReviews(spread, latestAction, true);
        undoSpreadJsAction(spread);
        break;
      default:
        undoSpreadJsAction(spread);
        break;
    }
  };

  return { addToHistory, undoAction, historyTracker };
}

export default useHistoryTracker;
