export function getOriginalNames(fields) {
  return fields ? fields.map(f => getOriginalName(f)) : [];
}

export function getHashToOriginalNameMapping(fields, newFieldHashValues) {
  const mapping = fields?.reduce((map, field) => {
    map[field.name] = getOriginalName(field);
    return map;
  }, {});

  if (newFieldHashValues && Object.keys(newFieldHashValues).length > 0) {
    const originalNames = new Set(Object.values(mapping));

    Object.keys(newFieldHashValues).forEach(function (key) {
      if (originalNames.has(newFieldHashValues[key])) {
        delete mapping[newFieldHashValues[key]];
        mapping[key] = newFieldHashValues[key];
      }
    });
  }
  return mapping;
}

export function getOriginalNameToHashMapping(fields) {
  if (!fields) {
    return {};
  }

  return fields.reduce((map, field) => {
    map[getOriginalName(field)] = field.name;

    return map;
  }, {});
}

export function getOriginalName(field) {
  return field?.original_name || field?.name;
}

export function getOriginalNameFromInternal(name, fields) {
  for (const field of fields) {
    if (field.original_name && field.name === name) {
      return field.original_name;
    }
  }

  return name;
}

export function getInternalNameFromOriginal(original_name, fields) {
  for (const field of fields) {
    if (field.original_name === original_name) {
      return field.name;
    }
  }

  return original_name;
}

export function generateFormulaWithOriginalName(formula, fields) {
  const fieldNameMap = getHashToOriginalNameMapping(fields);
  return convertFormula(formula, fieldNameMap);
}

export function generateFormulaWithInternalName(formula, fields) {
  const fieldNameMap = getOriginalNameToHashMapping(fields);
  return convertFormula(formula, fieldNameMap);
}

export function convertFormula(formula, fieldNameMap) {
  if (!formula) {
    return '';
  }

  //TODO: Optimize code to avoid performance overhead from creating strings.
  Object.keys(fieldNameMap).forEach(function (fieldName) {
    formula = formula.replaceAll('[' + fieldName + ']', '[' + fieldNameMap[fieldName] + ']');
  });

  return formula;
}
