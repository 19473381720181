import React from 'react';
import PropTypes from 'prop-types';

const HelperList = ({
  handleClick,
  handleOnMouseEnter,
  highlightParams,
  maxOptionsToDisplay,
  options,
  optionSelected,
  trigger,
  style,
  valueState,
}) => {
  const listHeader = (
    <li>
      {' '}
      <strong> {trigger === '[' ? 'Fields' : 'Functions'} </strong>
    </li>
  );

  const { start, length } = highlightParams;

  const helperOptions = options.slice(0, maxOptionsToDisplay).map((val, idx) => {
    const highlightStart = val.toLowerCase().indexOf(valueState.substr(start, length).toLowerCase());

    return (
      <li
        key={val}
        className={idx === optionSelected ? 'active' : null}
        onClick={() => {
          handleClick(idx);
        }}
        onMouseEnter={() => {
          handleOnMouseEnter(idx);
        }}
      >
        {val.slice(0, highlightStart)}
        <strong>{val.substr(highlightStart, length)}</strong>
        {val.slice(highlightStart + length)}
      </li>
    );
  });

  return (
    <ul className="react-autocomplete-input" style={style}>
      {listHeader}
      {helperOptions}
    </ul>
  );
};

HelperList.propTypes = {
  trigger: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  maxOptionsToDisplay: PropTypes.number.isRequired,
  valueState: PropTypes.string.isRequired,
  highlightParams: PropTypes.object.isRequired,
  optionSelected: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleOnMouseEnter: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
};

export default HelperList;
