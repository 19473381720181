import * as fetch from '../../../../../../_shared/fetch';
import { spreadsheetServiceHostName } from '../../../../../../../configs/params';

export async function getTaxLawTopicsArticles(topics) {
  const queryString = topics.map(topic => encodeURIComponent(topic)).join('&topics=');
  const response = await fetch.get(
    `${spreadsheetServiceHostName}/spreadsheet/tax-law-guidance/topics-details/topics=${queryString}`
  );
  const result = await response.json();

  return result.items.map(json => ({
    id: json.id,
    title: json.title,
    definition: JSON.parse(json.definition),
    sections: JSON.parse(json.sections),
  }));
}
