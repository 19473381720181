import { useContext, useEffect } from 'react';
import { ui } from '@clientio/rappid';
import { getElementType } from '../shared/graphUtils';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { ElementTypes } from '../elementType/types';
import {
  ADVANCED_TRANSPOSE_BLOCK,
  TRANSPOSE_BLOCK,
  JOIN_BLOCK,
  REORDER_BLOCK,
  MULTIMERGE_BLOCK,
} from '../../../../constants/featureFlags';
import { ADVANCED_TRANSPOSE, TRANSPOSE, JOIN, REORDER, MULTIMERGE } from './../elementType/types/shared/typesConstants';

const VERTICAL_MARGIN = 18;
const HORIZONTAL_MARGIN = 9;
const STENCIL_STEP = 80;

export const useStencil = container => {
  const { dataFlowActions, dataFlowState, featureFlags } = useContext(DataFlowEditorContext);
  const { clientDimensions, paperScroller } = dataFlowState;

  useEffect(() => {
    if (clientDimensions && paperScroller) {
      const stencilEl = container.current;
      const stencil = createStencil(stencilEl, paperScroller, clientDimensions, dataFlowActions, featureFlags);

      dataFlowActions.setStencil(stencil);
    }
  }, [dataFlowActions, container, paperScroller, clientDimensions, featureFlags]);
};

function createStencil(stencilEl, paperScroller, clientDimensions, dataFlowActions, featureFlags) {
  const { clientHeight } = clientDimensions;

  const stencil = new ui.Stencil({
    paper: paperScroller,
    width: 100,
    height: clientHeight,
    usePaperGrid: true,
    scaleClones: true,
    dropAnimation: {
      duration: 150,
      easing: 'linear',
    },
    canDrag: elementView => {
      return elementView.model.prop('enabled');
    },
    dragStartClone: element => {
      const clonedElement = element.clone();
      const elementType = getElementType(clonedElement);

      dataFlowActions.setDraggingElementFromStencil(true);

      return elementType.createGraphInstance(clonedElement);
    },
    dragEndClone: element => {
      const elementType = getElementType(element);

      dataFlowActions.setDraggingElementFromStencil(false);

      return elementType.createGraphInstance(element);
    },
  });

  stencilEl.appendChild(stencil.render().el);

  // Exclude elements regarding to feature flags.
  const excludeElements = featureFlags.excludeElementType(
    { type: TRANSPOSE, flag: TRANSPOSE_BLOCK },
    { type: ADVANCED_TRANSPOSE, flag: ADVANCED_TRANSPOSE_BLOCK },
    { type: JOIN, flag: JOIN_BLOCK },
    { type: REORDER, flag: REORDER_BLOCK },
    { type: MULTIMERGE, flag: MULTIMERGE_BLOCK }
  );

  const elements = ElementTypes.filter(excludeElements).map((elementType, index) => {
    return elementType.createStencilInstance([HORIZONTAL_MARGIN, VERTICAL_MARGIN + STENCIL_STEP * index]);
  });

  stencil.load(elements);

  return stencil;
}
