import { useCallback, useContext, useReducer } from 'react';
import * as apis from './apis';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import { transformToConfiguration } from './useSaveDataFlow/transformToConfiguration';

export const ConnectedOutputsChangedCode = 'WKP2012';

export const initialState = {
  isLoading: false,
  error: null,
  isSuccess: false,
  isSavePublish: false,
  saveResponse: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'loading':
      return { isLoading: true, error: null, isSuccess: false, saveResponse: null };
    case 'error':
      return { isLoading: false, error: action.error, isSuccess: false, saveResponse: null };
    case 'success':
      return {
        isLoading: false,
        error: null,
        isSuccess: true,
        isSavePublish: false,
        saveResponse: action.saveResponse,
      };
    case 'success-save-publish':
      return { isLoading: false, error: null, isSuccess: true, isSavePublish: true, saveResponse: action.saveResponse };
    case 'reset':
      return initialState;
    default:
      throw new Error();
  }
}

export default function useSaveDataFlow() {
  const { dataFlowActions } = useContext(DataFlowEditorContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const saveDataFlow = useCallback(
    async (canPublish, dataFlow) => {
      dispatch({ type: 'loading' });
      try {
        let response = null;
        if (canPublish) {
          // user click save and publish
          dataFlowActions.setSaveMenuSaveAndPublish();
          response = await apis.saveAndPublishDataFlow({
            id: dataFlow.id,
            name: dataFlow.name,
            configuration: transformToConfiguration(dataFlow.elements, dataFlow.links, dataFlow.workflowConfiguration),
          });
        } else {
          // user click the save
          dataFlowActions.setSaveMenuSave();
          response = await apis.saveDataFlow({
            id: dataFlow.id,
            name: dataFlow.name,
            configuration: transformToConfiguration(dataFlow.elements, dataFlow.links, dataFlow.workflowConfiguration),
          });
        }
        if (response.error) {
          dispatch({ type: 'error', error: response.error });
          dataFlowActions.setIsHeaderInfoShown(false);
          if (canPublish) {
            dataFlowActions.setIsValid(false);
          }
        } else {
          if (canPublish) {
            dispatch({ type: 'success-save-publish', saveResponse: response });
            dataFlowActions.setLastPublishedDate(response.lastModifiedDate);
          } else {
            dispatch({ type: 'success', saveResponse: response });
          }
          dataFlowActions.setSaveStateDirty(false);
          dataFlowActions.setIsValid(response.isValid && response.validationErrors.length === 0);
          dataFlowActions.setIsHeaderInfoShown(true);
          dataFlowActions.setLastModifiedDate(response.lastModifiedDate);
          dataFlowActions.setValidationErrors(response.validationErrors);
        }
      } catch (e) {
        dispatch({ type: 'error', error: e.message });
        dataFlowActions.setIsValid(false);
        dataFlowActions.setIsHeaderInfoShown(false);
      }
    },
    [dispatch, dataFlowActions]
  );

  const reset = useCallback(() => dispatch({ type: 'reset' }), [dispatch]);

  return [state, { saveDataFlow, reset }];
}
