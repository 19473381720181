import { CELL_REVIEW_ENABLED } from '../../../../../constants/featureFlags';
import GC from '../../../../../SpreadSheets';
import { isFeatureFlagEnabled } from '../../../../../utils/featureFlags';

import { isSourceDataFormulaMatch } from './formulas';

const visibleValue = 'ClickViewport';
const notVisibleValue = 'none';

export const setGoToSourceCommandsBehavior = (sheet, setCommandsVisibleContext) => {
  const activeCell = sheet.getCell(sheet.getActiveRowIndex(), sheet.getActiveColumnIndex());
  const formula = activeCell.formula();

  if (formula) {
    const sourceDataFormulaMatch = isSourceDataFormulaMatch(formula);
    if (sourceDataFormulaMatch) {
      setCommandsVisibleContext({
        'wkp.goToSourceDataFlow': visibleValue,
      });
    }
  } else {
    setCommandsVisibleContext({
      'wkp.goToSourceDataFlow': notVisibleValue,
    });
  }
};

export const setReviewCommandsBehavior = (sheet, setCommandsVisibleContext) => {
  const visibleContext = 'ClickViewport || ClickCorner || ClickColHeader || ClickRowHeader';
  const selections = sheet.getSelections();
  let hasReviewedAndNotReviewedCells = false;
  let reviewedCells = 0;
  let notReviewedCells = 0;

  for (let selectionIndex = 0; selectionIndex < selections.length; selectionIndex++) {
    if (hasReviewedAndNotReviewedCells) {
      break;
    }

    for (let rowX = 0; rowX < selections[selectionIndex].rowCount; rowX++) {
      if (hasReviewedAndNotReviewedCells) {
        break;
      }

      for (let colY = 0; colY < selections[selectionIndex].colCount; colY++) {
        const rowIdx = selections[selectionIndex].row + rowX;
        const colIdx = selections[selectionIndex].col + colY;
        const reviewedCellsTag = sheet.tag() && sheet.tag().reviewedCells;
        const isReviewed =
          reviewedCellsTag && reviewedCellsTag.find(cell => cell.row === rowIdx && cell.col === colIdx);

        if (isReviewed) {
          reviewedCells++;
        } else {
          notReviewedCells++;
        }

        if (reviewedCells > 0 && notReviewedCells > 0) {
          hasReviewedAndNotReviewedCells = true;

          break;
        }
      }
    }
  }

  if (hasReviewedAndNotReviewedCells) {
    setCommandsVisibleContext({
      'wkp.contextMenuUnmarkCellReviewed': visibleContext,
      'wkp.contextMenuMarkCellReviewed': visibleContext,
    });
  } else {
    if (reviewedCells === 0 && notReviewedCells > 0) {
      setCommandsVisibleContext({
        'wkp.contextMenuMarkCellReviewed': visibleContext,
        'wkp.contextMenuUnmarkCellReviewed': notVisibleValue,
      });
    } else {
      setCommandsVisibleContext({
        'wkp.contextMenuMarkCellReviewed': notVisibleValue,
        'wkp.contextMenuUnmarkCellReviewed': visibleContext,
      });
    }
  }
};

export function attachCommandsBehavior({ spread, setCommandsVisibleContext }) {
  spread.bind('SelectionChanged', (_, { sheet }) => {
    setGoToSourceCommandsBehavior(sheet, setCommandsVisibleContext);
    if (!isFeatureFlagEnabled(CELL_REVIEW_ENABLED)) {
      setReviewCommandsBehavior(sheet, setCommandsVisibleContext);
    }
  });
}

/**
 * Overrides the context menu filter action in order to
 * set the filter dropdown buttons at the top of the
 * selected region
 */
export function overrideContextMenuFilterCommand() {
  const oldFilterCommand = GC.Spread.Sheets.Commands.contextmenuFilterForSheet.execute;

  GC.Spread.Sheets.Commands.contextmenuFilterForSheet.execute = function (context, options, _isUndo) {
    const sheet = context.getSheetFromName(options.sheetName);
    const selection = options.cmdOption.selection;
    const { rowCount: expandedRowCount } = sheet.expandSelection(sheet, selection);
    const { row, col, rowCount, colCount } = selection;
    const newRowCount = rowCount === 1 ? expandedRowCount : rowCount;
    options.cmdOption.selection = new GC.Spread.Sheets.Range(row + 1, col, newRowCount, colCount);

    oldFilterCommand.apply(this, arguments);
  };
}

/**
 * Overrides the ribbon filter action in order to
 * set the filter dropdown buttons at the top of the
 * selected region
 * @param {*} spread
 */
export function overrideRibbonFilterCommand(spread) {
  const filterCommand = spread.commandManager()['Designer.setRowFilter'];
  if (!filterCommand) return;

  const oldFilterCommand = filterCommand.execute;

  filterCommand.execute = function (context, options, _isUndo) {
    const sheet = context.getSheetFromName(options.sheetName);
    sheet.clearSelection();

    const selections = options.selections;
    for (const selection of selections) {
      const { rowCount: expandedRowCount } = sheet.expandSelection(sheet, selection);
      const { row, col, rowCount, colCount } = selection;
      const newRowCount = rowCount === 1 ? expandedRowCount : rowCount;
      sheet.addSelection(row + 1, col, newRowCount, colCount);
    }

    oldFilterCommand.apply(this, arguments);

    // restore selection
    sheet.clearSelection();

    for (const selection of selections) {
      const { row, col, rowCount, colCount } = selection;
      sheet.addSelection(row, col, rowCount, colCount);
    }
  };
}
