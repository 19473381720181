import {
  WORKPAPER_IMPORT_JOB_TYPE,
  WORKPAPER_COPY_JOB_TYPE,
  WORKPAPER_EXPORT_JOB_TYPE,
  WORKPAPER_ROLLFORWARD_JOB_TYPE,
  WORKPAPER_GLOBAL_TEMPLATE_JOB_TYPE,
  WORKPAPER_RECOVERY_JOB_TYPE,
} from '../../../_shared/jobs/jobTypes';
export default function generateJobTypeText(type) {
  switch (type) {
    case WORKPAPER_IMPORT_JOB_TYPE:
      return { action: 'import', process: 'Importing' };
    case WORKPAPER_COPY_JOB_TYPE:
      return { action: 'copy', process: 'Copying' };
    case WORKPAPER_EXPORT_JOB_TYPE:
      return { action: 'export', process: 'Exporting' };
    case WORKPAPER_ROLLFORWARD_JOB_TYPE:
      return { action: 'rollforward', process: 'Rollforward in progress' };
    case WORKPAPER_GLOBAL_TEMPLATE_JOB_TYPE:
      return { action: 'template', process: 'Creating template' };
    case WORKPAPER_RECOVERY_JOB_TYPE:
      return { action: 'recovery', process: 'Recovering' };
    default:
  }
}
