import { AnnotationElementType } from '../AnnotationElementType';
import { AggregateElementType } from './AggregateElementType';
import { FilterElementType } from './FilterElementType';
import { FormulaElementType } from './FormulaElementType';
import { InputElementType } from './InputElementType';
import { JoinElementType } from './JoinElementType';
import { LookupElementType } from './LookupElementType';
import { MergeElementType } from './MergeElementType';
import { OutputElementType } from './OutputElementType';
import { SelectElementType } from './SelectElementType';
import { TransposeElementType } from './TransposeElementType';
import { AdvancedTransposeElementType } from './AdvancedTransposeElementType';
import { SortElementType } from './SortElementType';
import { ReorderElementType } from './ReorderElementType';
import { MultiMergeElementType } from './MultiMergeElementType';

export const ElementTypes = [
  new AnnotationElementType(),
  new InputElementType(),
  new OutputElementType(),
  new SelectElementType(),
  new FilterElementType(),
  new ReorderElementType(),
  new SortElementType(),
  new TransposeElementType(),
  new AdvancedTransposeElementType(),
  new AggregateElementType(),
  new FormulaElementType(),
  new LookupElementType(),
  new JoinElementType(),
  new MergeElementType(),
  new MultiMergeElementType(),
];

const createMap = field =>
  ElementTypes.reduce((byType, elementType) => {
    byType[elementType[field]] = elementType;
    return byType;
  }, {});

export const ElementTypeMap = createMap('type');
