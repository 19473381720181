export const TOGGLE_ON_SAVE = 'TOGGLE_ON_SAVE';
export const TRIGGER_SAVE = 'TRIGGER_SAVE';
export const IS_COMMANDS_SAVING = 'IS_COMMANDS_SAVING';

export const initialEditorState = {
  toggleOnSave: true,
  triggerSave: false,
  isCommandsSaving: false,
};

/**
 *
 * @param {*} state
 * @param {*} action
 * Initial reducer of the editor, but the intention is to extend it
 * for the other properties managed by the editor context.
 * @returns state (the new editor state)
 */

export default function reducer(state = initialEditorState, action) {
  switch (action.type) {
    case TOGGLE_ON_SAVE:
      return { ...state, toggleOnSave: action.payload };
    case TRIGGER_SAVE:
      if (!action.payload) {
        return { ...state, triggerSave: action.payload, isCommandsSaving: false };
      }
      return { ...state, triggerSave: action.payload };
    case IS_COMMANDS_SAVING:
      return { ...state, isCommandsSaving: action.payload };
    default:
      return state;
  }
}
