import { BTCheckbox, BTForm, BTInput, BTAlert, BTIcon, BTButton } from '@btas/jasper';
import React, { useContext, useMemo, useEffect, useState, useCallback } from 'react';
import ElementInspector from './shared/ElementInspector';
import { DataFlowEditorContext } from './DataFlowEditorContext';
import Data_type_text from './shared/icons/Data_type_text.svg';
import Data_type_number from './shared/icons/Data_type_number.svg';
import Data_type_text_overriden from './shared/icons/Data_type_text_overriden.svg';
import Data_type_number_overriden from './shared/icons/Data_type_number_overriden.svg';
import { useCanEditWorkflow } from '../../_shared/UserPermissionsContext';
import { isFeatureFlagEnabled } from '../../../utils/featureFlags';
import { WKP_CONFIG_PANEL_PAGINATION } from '../../../constants/featureFlags';
import { getPaginationProps } from './shared/utils/PaginationUtils';
import { PaginationContainer } from './shared/PaginationContainer';
import { INSPECTOR_PAGE_SIZE } from './shared/constants/pagination';

export const SelectElementInspector = ({ elementData, elementType, updateData, elementId, inputElements, isDirty }) => {
  const { dataFlowState, previewState } = useContext(DataFlowEditorContext);
  const { workingElement } = dataFlowState;
  const { valueErrorList, isWaiting } = previewState;
  const [fieldErrors, setFieldErrors] = useState({});
  const [generalError] = useState({
    message: undefined,
  });
  const [types, setTypes] = useState(false);
  const [page, setPage] = useState(1);
  const renamedFields = useMemo(() => elementData.renamedFields ?? {}, [elementData.renamedFields]);
  const selectedFields = useMemo(() => elementData.selectedFields ?? {}, [elementData.selectedFields]);
  const paginationEnabled = isFeatureFlagEnabled(WKP_CONFIG_PANEL_PAGINATION);
  const totalFields = inputElements.in?.elementData?.fields?.length ?? 0;
  const { pageSize, startIndex, endIndex } = useMemo(() => {
    return getPaginationProps(totalFields, paginationEnabled, page);
  }, [totalFields, paginationEnabled, page]);
  const fields = useMemo(() => {
    return paginationEnabled && totalFields > 0
      ? inputElements.in?.elementData?.fields.slice(startIndex, endIndex) || []
      : inputElements.in?.elementData?.fields || [];
  }, [paginationEnabled, inputElements, startIndex, endIndex, totalFields]);
  const updatedFields = elementData.updatedFields;

  const sourceFieldTypes = useMemo(() => {
    if (!inputElements.in) {
      return {};
    }

    if (inputElements.in.elementData.fields) {
      return inputElements.in.elementData.fields?.reduce((acum, field) => {
        acum[field.name] = field.type;

        return acum;
      }, {});
    }

    return {};
  }, [inputElements]);
  const canEditWorkflow = useCanEditWorkflow();
  const menuItemClass = canEditWorkflow ? 'wkp-menu-item' : 'wkp-menu-item-disabled';
  /* re-validate the inspector whenever the workingElement is changed */
  useEffect(() => {
    validateSelectedFields(fields);

    function validateSelectedFields(fieldList) {
      const newErrors = {};

      const finalNames = fieldList.map((f, i) => {
        if (!selectedFields[f.name]) {
          return null;
        }

        if (renamedFields[i] && renamedFields[i][f.name]) {
          return renamedFields[i][f.name];
        }

        return f.name;
      });

      finalNames.forEach((name, i) => {
        if (name === null) {
          return;
        }

        const times = finalNames.filter(n => n !== null && n.trim() === name.trim()).length;

        if (times > 1) {
          newErrors[i] = 'Column name must be unique.';
        }
      });

      setFieldErrors(newErrors);
    }
  }, [fields, renamedFields, selectedFields, workingElement]);

  /* update the state when a checkbox is selected or deselected */
  const columnMappingCheckboxes = useMemo(() => {
    const handleTabularOutputChange = ({ target }) => {
      const { id, checked } = target;
      updateData({ selectedFields: { ...selectedFields, [id]: checked } });
    };

    return fields.map(({ name }) => (
      <BTCheckbox
        key={name}
        checked={selectedFields && selectedFields[name]}
        disabled={!canEditWorkflow}
        id={name}
        label={name}
        onChange={handleTabularOutputChange}
      />
    ));
  }, [fields, selectedFields, updateData, canEditWorkflow]);

  const dataTypes = useMemo(() => {
    const handleTypeChange = e => {
      e.preventDefault();
      const value = e.currentTarget.getAttribute('value');
      const id = e.currentTarget.getAttribute('id');

      setTypes(!types);

      updateData({
        updatedFields: updatedFields.map(field => {
          if (field.name === id) {
            if (value === 'clear') {
              return { ...field, type: sourceFieldTypes[field.name], overriden: false };
            }

            return { ...field, type: value, overriden: true };
          }

          return field;
        }),
      });
    };

    const result = updatedFields.map(({ name, type, overriden }, i) => {
      const isOverriden = overriden;

      return (
        <div>
          <button
            aria-expanded="false"
            aria-haspopup="true"
            aria-label="data-types"
            className={`wkp-dropdown${canEditWorkflow ? '' : '-disabled'} btn dropdown-toggle`}
            data-toggle="dropdown"
            disabled={!canEditWorkflow}
            id={`field-type-${i}`}
            title={isOverriden ? 'Data type (overriden)' : ''}
            type={type}
          >
            {type === 'date' ? (
              <div style={{ fontSize: '14px', color: isOverriden ? '#B6721D' : 'black' }}>
                <BTIcon alt="calendar data type" icon="calendar" />
              </div>
            ) : type === 'numeric' ? (
              <img alt="numeric data type" src={isOverriden ? Data_type_number_overriden : Data_type_number}></img>
            ) : (
              <img alt="text data type" src={isOverriden ? Data_type_text_overriden : Data_type_text}></img>
            )}
          </button>
          <ul aria-labelledby={`field-type-${i}`} className="dropdown-menu" role="menu">
            <h6 className="dropdown-header">Override Data type</h6>
            <li className={menuItemClass} id={name} role="presentation" value="date" onClick={e => handleTypeChange(e)}>
              <button className="wkp-data-type dropdown-item" role="menuitem">
                <BTIcon alt="" icon="calendar" /> Date
              </button>
            </li>
            <li
              className={menuItemClass}
              id={name}
              role="presentation"
              value="numeric"
              onClick={e => handleTypeChange(e)}
            >
              <button className="wkp-data-type dropdown-item" role="menuitem">
                <img alt="" src={Data_type_number}></img> Number
              </button>
            </li>
            <li className={menuItemClass} id={name} role="presentation" value="text" onClick={e => handleTypeChange(e)}>
              <button className="wkp-data-type dropdown-item" role="menuitem">
                <img alt="" src={Data_type_text}></img> Text
              </button>
            </li>
            {isOverriden && (
              <>
                <hr className="wkp-line-break" />
                <li
                  className={menuItemClass}
                  id={name}
                  role="presentation"
                  value="clear"
                  onClick={e => handleTypeChange(e)}
                >
                  <button className="wkp-data-type-clean dropdown-item" role="menuitem">
                    <span style={{ fontSize: '18px' }}>
                      <BTIcon icon="close" />
                    </span>{' '}
                    Clear override
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      );
    });
    return paginationEnabled ? result.slice(startIndex, endIndex) : result;
  }, [
    updatedFields,
    types,
    updateData,
    sourceFieldTypes,
    menuItemClass,
    canEditWorkflow,
    paginationEnabled,
    startIndex,
    endIndex,
  ]);

  /* check for any invalid statuses - no columns selected, duplicated column names */
  const alerts = useMemo(() => {
    if (!Object.values(selectedFields || []).some(v => v)) {
      return [{ style: 'danger', content: 'Select at least one column.' }];
    } else {
      const filteredErrors = [...new Set(Object.values(fieldErrors))];
      return [
        ...(Object.values(filteredErrors).length > 0
          ? [{ style: 'danger', content: Object.values(filteredErrors).map((err, key) => <p key={key}>{err}</p>) }]
          : []),
        valueErrorList?.length > 0 && !isWaiting ? { style: 'warning', content: valueErrorList || null } : [],
      ];
    }
  }, [selectedFields, fieldErrors, valueErrorList, isWaiting]);

  const handleSetAll = useCallback(
    value => {
      updateData({
        selectedFields: Object.keys(selectedFields).reduce((acum, key) => {
          acum[key] = value;

          return acum;
        }, {}),
      });
    },
    [updateData, selectedFields]
  );

  const onFieldRename = (index, newName) => {
    const renameFieldIndex = paginationEnabled ? (page - 1) * pageSize + index : index;
    if (newName && newName.length > 0) {
      const fieldName = fields[index].name;
      renamedFields[renameFieldIndex] = { [fieldName]: !isNaN(newName) ? newName.concat(' ') : newName };
    } else if (renamedFields[renameFieldIndex]) {
      delete renamedFields[renameFieldIndex];
    }
    updateData({ renamedFields });
  };
  function renamedFieldsPreview(field, index) {
    const updatedIndex = paginationEnabled ? (page - 1) * pageSize + index : index;
    return (renamedFields[updatedIndex] && renamedFields[updatedIndex][field.name]) || '';
  }

  return (
    <ElementInspector {...{ alerts, elementData, elementId, elementType, updateData, isDirty }}>
      <div className="wkp-select-element-inspector">
        {canEditWorkflow && (
          <>
            <BTButton btStyle="link" onClick={() => handleSetAll(true)}>
              Select All
            </BTButton>
            <BTButton btStyle="link" onClick={() => handleSetAll(false)}>
              Deselect All
            </BTButton>
          </>
        )}
        <table className="wkp-fields-list">
          <thead>
            <tr>
              <th>Column name</th>
              <th>Rename to</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fields.map((f, i) => (
              <tr key={i}>
                <td className="wkp-no-paddings">
                  <BTForm.FormGroup>
                    <div className="wkp-column-items">{columnMappingCheckboxes[i]}</div>
                  </BTForm.FormGroup>
                </td>
                <td className="wkp-no-paddings">
                  <BTInput
                    disabled={!canEditWorkflow}
                    hasError={!!fieldErrors[i]}
                    value={renamedFieldsPreview(f, i)}
                    onChange={e => onFieldRename(i, e.target.value)}
                  />
                </td>
                <td className="wkp-no-paddings">{dataTypes[i]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {paginationEnabled && totalFields > INSPECTOR_PAGE_SIZE && (
          <PaginationContainer
            endIndex={endIndex}
            page={page}
            setPage={setPage}
            startIndex={startIndex}
            totalFields={totalFields}
          />
        )}
        <BTAlert
          appear
          dismissible
          fixed
          btStyle="danger"
          visible={generalError.message !== undefined}
          onDismiss={() => {}}
        >
          {generalError ? generalError.message : ''}
        </BTAlert>
      </div>
    </ElementInspector>
  );
};
