import { FORMULA_COLOR } from '../../shared/colors';
import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/formula_block_icon.svg';
import hintImage from '../icons/formula_hint_img.svg';
import FormulaElementInspector from '../../FormulaElementInspector';
import { FORMULA } from '../types/shared/typesConstants';
import {
  getOriginalName,
  getOriginalNameFromInternal,
  getInternalNameFromOriginal,
  generateFormulaWithInternalName,
  generateFormulaWithOriginalName,
} from '../../../shared/utils/FieldHashUtils';
export class FormulaElementType extends TransformationElementType {
  static TYPE = FORMULA;

  static HELP_TEXT = `The formula block allows you to append a column to your data and define a formula for that column. The formula supplied will be applied to each row in the data set.<img src=${hintImage} alt="Formula hint" />`;

  constructor() {
    super(FormulaElementType.TYPE, 'Formula', FORMULA_COLOR, iconImage, FormulaElementType.HELP_TEXT);
  }

  get initialData() {
    return {
      name: this.label,
      type: this.type,
      outputFieldName: '',
      outputFieldType: null,
      formula: '',
      formulaIsValid: false,
      AIGenerated: false,
    };
  }

  get maxCount() {
    return -1;
  }

  get inspectorComponent() {
    return FormulaElementInspector;
  }

  applySourceElements(elementData, sourceElements) {
    let fields = elementData.fields || [];

    if (sourceElements.in) {
      const newElementData = super.applySourceElements(elementData, sourceElements);
      fields = newElementData.fields;
    }

    const formula = generateFormulaWithOriginalName(elementData.formula || '', elementData.fields || []);
    const outputFieldName = getOriginalNameFromInternal(elementData.outputFieldName || '', elementData.fields || []);

    if (
      elementData.outputFieldName &&
      elementData.outputFieldType &&
      !fields.find(f => getOriginalName(f) === outputFieldName)
    ) {
      const formulaField = (elementData.fields || []).find(f => getOriginalName(f) === outputFieldName);

      fields.push(
        formulaField
          ? formulaField
          : {
              name: outputFieldName,
              type: elementData.outputFieldType,
            }
      );
    }

    return {
      ...elementData,
      fields: fields,
      formula,
      outputFieldName,
      AIGenerated: elementData?.AIGenerated,
    };
  }

  generateReadableFormula(normal, fields) {
    return generateFormulaWithOriginalName(normal, fields);
  }

  getPreviewColumns(elementData) {
    const previewColumns = [...elementData.fields];

    let outputField = previewColumns.find(f => f.name === elementData.outputFieldName);

    if (elementData.outputFieldHash) {
      outputField.original_name = outputField.name;
      outputField.name = elementData.outputFieldHash;
    }

    return previewColumns;
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      outputFieldName: getInternalNameFromOriginal(elementData.outputFieldName, elementData.fields),
      outputFieldType: elementData.outputFieldType,
      formula: generateFormulaWithInternalName(elementData.formula, elementData.fields),
      AIGenerated: elementData?.AIGenerated,
    };
  }
}
