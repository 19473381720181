import React, { useState } from 'react';
import dataFlowSourceFile from '../shared/icons/data_flow_source_file.svg';
import './Dropzone/styles.scss';
import uploadIcon from './Dropzone/upload_icon.svg';

const Dropzone = ({ dropzone, selectedFile, className = '', typeTitle = 'file' }) => {
  const { getRootProps, getInputProps, isDragActive } = dropzone;
  const [isDragging, setIsDragging] = useState(false);
  const isFileSelected = !!selectedFile;

  const handleDragEnter = e => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleDragLeave = e => {
    e.preventDefault();
    //Calculate the cursor position relative to the div (parent div of the drag zone)
    const rect = e.currentTarget.getBoundingClientRect();
    const isOutside =
      e.clientX < rect.left || e.clientX >= rect.right || e.clientY < rect.top || e.clientY >= rect.bottom;
    //check if the cursor if outside of the div
    if (isOutside) {
      setIsDragging(false);
    }
  };

  const handleDrop = e => {
    const { onDrop } = getRootProps();
    e.preventDefault();
    setIsDragging(false);
    //call the onDrop from dropzone prop received
    onDrop && onDrop(e);
  };

  return (
    <div {...getRootProps()} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDrop={handleDrop}>
      <div
        className={`wkp-dropzone ${isDragActive || isDragging ? 'wkp-drag' : ''} ${
          isFileSelected ? 'wkp-selected' : ''
        } ${className}`}
      >
        {isFileSelected ? (
          <div className="wkp-selected-files wkp-dropzone-area">
            <img alt="Data flow source file icon" aria-hidden="true" src={dataFlowSourceFile} /> {selectedFile.name}
          </div>
        ) : (
          <div className="wkp-dropzone-area">
            <div className="wkp-dropzone-title">
              <img alt="Upload icon" aria-hidden="true" className="wkp-upload-icon" src={uploadIcon} />
              <span className="wkp-browse-link">Browse</span> or drop {typeTitle} here.
            </div>
            <div className="wkp-dropzone-formats">Supported file formats: Excel (*.xlsx), comma separated (*.csv)</div>
          </div>
        )}
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

export default Dropzone;
