import GC from '../../../../../../../SpreadSheets';
import { Commands, ShortcutActionBase, executeCommand } from './shell/action.base';
import { Util } from './common/util';
var __extends =
  (this && this.__extends) ||
  (function () {
    var extendStatics = function (d, b) {
      extendStatics =
        Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array &&
          function (d, b) {
            d.__proto__ = b;
          }) ||
        function (d, b) {
          for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
        };
      return extendStatics(d, b);
    };
    return function (d, b) {
      if (typeof b !== 'function' && b !== null)
        throw new TypeError('Class extends value ' + String(b) + ' is not a constructor or null');
      extendStatics(d, b);
      function __() {
        this.constructor = d;
      }
      d.prototype = b === null ? Object.create(b) : ((__.prototype = b.prototype), new __());
    };
  })();
var VerticalPosition = GC.Spread.Sheets.VerticalPosition;
var HorizontalPosition = GC.Spread.Sheets.HorizontalPosition;
var rangeToFormula = GC.Spread.Sheets.CalcEngine.rangeToFormula;
var allRelative = GC.Spread.Sheets.CalcEngine.RangeReferenceRelative.allRelative;
var INSERT_SUM_FUNCTION = 'insertSumFunction';
var InsertSumFunctionAction = /** @class */ (function (_super) {
  __extends(InsertSumFunctionAction, _super);
  function InsertSumFunctionAction() {
    return (_super !== null && _super.apply(this, arguments)) || this;
  }
  InsertSumFunctionAction.prototype.executeImp = function () {
    var self = this;
    var sheet = self.getSheet();
    var selections = sheet.getSelections();
    var formulaName = 'SUM';
    sheet.suspendCalcService();
    var result = true,
      edittingIndexInfo;
    if (self.getSelectionTypeWithSelection(selections) === 3 /* SelectionRangeType.OnlyCells */) {
      for (var _i = 0, selections_1 = selections; _i < selections_1.length; _i++) {
        var selection = selections_1[_i];
        if (selection.row + selection.rowCount === sheet.getRowCount()) {
          continue;
        } else {
          var table = sheet.tables.find(sheet.getSelections()[0].row, sheet.getSelections()[0].col);
          if (table) {
            var dataRange = table.dataRange();
            if (
              selection.row === dataRange.row &&
              selection.rowCount === dataRange.rowCount &&
              selection.col + selection.colCount <= dataRange.col + dataRange.colCount
            ) {
              if (!table.showFooter()) {
                table.showFooter(true);
              }
            }
          }
        }
        try {
          edittingIndexInfo = self.setFormulaForSingleRange(selection, formulaName);
        } catch (_a) {
          result = false;
          break;
        }
      }
    }
    sheet.resumeCalcService();
    if (result) {
      var edittingRowIndex = edittingIndexInfo.edittingRowIndex,
        edittingColIndex = edittingIndexInfo.edittingColIndex;
      if (sheet.getActiveRowIndex() !== edittingRowIndex || sheet.getActiveColumnIndex() !== edittingColIndex) {
        sheet.setActiveCell(edittingRowIndex, edittingColIndex);
      }
      sheet.startEdit();
    }
    return result;
  };
  InsertSumFunctionAction.prototype.getSelectionTypeWithSelection = function (selections) {
    var selectionType;
    for (var _i = 0, selections_2 = selections; _i < selections_2.length; _i++) {
      var selection = selections_2[_i];
      if (selection.col === -1 && selection.row === -1) {
        return 0 /* SelectionRangeType.Sheet */;
      } else if (selection.row === -1) {
        if (selectionType === undefined) {
          selectionType = 2 /* SelectionRangeType.OnlyColumn */;
        } else if (selectionType !== 2 /* SelectionRangeType.OnlyColumn */) {
          return 4 /* SelectionRangeType.Mixture */;
        }
      } else if (selection.col === -1) {
        if (selectionType === undefined) {
          selectionType = 1 /* SelectionRangeType.OnlyRow */;
        } else if (selectionType !== 1 /* SelectionRangeType.OnlyRow */) {
          return 4 /* SelectionRangeType.Mixture */;
        }
      } else {
        if (selectionType === undefined) {
          selectionType = 3 /* SelectionRangeType.OnlyCells */;
        } else if (selectionType !== 3 /* SelectionRangeType.OnlyCells */) {
          return 4 /* SelectionRangeType.Mixture */;
        }
      }
    }
    return selectionType;
  };
  InsertSumFunctionAction.prototype._noNeedSetFormula = function (row, col) {
    var sheet = this.getSheet(),
      style = sheet.getStyle(row, col);
    if (style && (style.cellType || style.cellButtons)) {
      return true;
    }
    return false;
  };
  InsertSumFunctionAction.prototype.calculateValidRange = function (row, column, rowCount, columnCount) {
    var r = row;
    var c = column;
    var rc = rowCount;
    var cc = columnCount;
    if (r !== -1 && c !== -1) {
      var spans = this.getSheet().getSpans();
      if (spans && spans.length > 0) {
        var newSelection = this.cellRangeInflate(spans, new GC.Spread.Sheets.Range(row, column, rowCount, columnCount));
        r = newSelection.row;
        c = newSelection.col;
        rc = newSelection.rowCount;
        cc = newSelection.colCount;
      }
    }
    return {
      row: r,
      col: c,
      rowCount: rc,
      colCount: cc,
    };
  };
  InsertSumFunctionAction.prototype.cellRangeInflate = function (spans, cellRange) {
    var spansLength = spans && spans.length;
    for (var i = 0; i < spansLength; i++) {
      var cr = spans[i];
      if (cellRange.intersect(cr.row, cr.col, cr.rowCount, cr.colCount)) {
        spans.splice(i, 1);
        return this.cellRangeInflate(spans, cellRange.union(cr));
      }
    }
    return cellRange;
  };
  InsertSumFunctionAction.prototype.getFormulaRange = function (range) {
    var self = this;
    var sheet = self.getSheet(),
      leftCell = sheet.getValue(range.row, range.col - 1),
      topCell = sheet.getValue(range.row - 1, range.col),
      startWithLeft = false;
    startWithLeft = self.getStartRangeDirection(range, sheet, leftCell, topCell);
    var calculateRange = self.calculateRange(startWithLeft, range);
    var row = calculateRange.row,
      col = calculateRange.col,
      rowCount = calculateRange.rowCount,
      colCount = calculateRange.colCount;
    if (col !== undefined && row !== undefined) {
      return;
    }
    if (col === undefined && row === undefined) {
      return range;
    } else if (col === undefined) {
      var r = row;
      return self.calculateValidRange(r, range.col, range.row - r, colCount);
    }
    return self.calculateValidRange(range.row, col, rowCount, range.col - col);
  };
  InsertSumFunctionAction.prototype.calculateRange = function (startWithLeft, range) {
    var row,
      col,
      rowCount = 1,
      colCount = 1,
      sheet = this.getSheet(),
      pureNull = false;
    //top
    if (startWithLeft === false) {
      for (let i = range.row - 1; i >= 0; i--) {
        let value = sheet.getValue(i, range.col);
        if (this.valueIsNumber(value)) {
          pureNull = true;
          if (i === 0) {
            row = 0;
          }
        } else if (pureNull === true && !this.valueIsNumber(value)) {
          row = i + 1;
          break;
        }
      }
    }
    //left
    if (!pureNull) {
      for (let i = range.col - 1; i >= 0; i--) {
        let value = sheet.getValue(range.row, i);
        if (this.valueIsNumber(value)) {
          pureNull = true;
          if (i === 0) {
            col = 0;
          }
        } else if (pureNull === true && !this.valueIsNumber(value)) {
          col = i + 1;
          break;
        }
      }
    }
    return {
      row: row,
      col: col,
      rowCount: rowCount,
      colCount: colCount,
    };
  };
  InsertSumFunctionAction.prototype.getStartRangeDirection = function (range, sheet, leftCellValue, aboveCellValue) {
    var leftRangeHaveNumberValue = false,
      topRangeFirstValueFormula = false,
      startWithLeft = false;
    //Make sure the range on the left, sort from right to left,
    //(the first non-empty cell is number), and the formula is empty
    for (var i = range.col - 1; i >= 0; i--) {
      var value = sheet.getValue(range.row, i),
        formula = sheet.getFormula(range.row, i);
      if (formula) {
        break;
      }
      if (this.valueIsNumber(value)) {
        leftRangeHaveNumberValue = true;
        break;
      }
    }
    //Make sure the top range, sort from bottom up,
    //the first empty cell exists (empty cell refers to all cells that are not of type number
    //and the formula is empty) is number,
    for (let i = range.row - 1; i >= 0; i--) {
      let value = sheet.getValue(i, range.col);
      let formula = sheet.getFormula(i, range.col);
      if (formula) {
        topRangeFirstValueFormula = true;
        break;
      }
      if (this.valueIsNumber(value)) {
        break;
      }
    }
    if (
      (this.valueIsNumber(leftCellValue) && !this.valueIsNumber(aboveCellValue)) ||
      (leftRangeHaveNumberValue && topRangeFirstValueFormula)
    ) {
      startWithLeft = true;
    }
    return startWithLeft;
  };
  InsertSumFunctionAction.prototype.valueIsNumber = function (value) {
    if (typeof value === 'number') {
      return true;
    }
    return false;
  };
  InsertSumFunctionAction.prototype.getSpan = function (row, col) {
    return new GC.Spread.Sheets.Range(row, col, 1, 1);
  };
  InsertSumFunctionAction.prototype.setFormulaForSingleRange = function (range, formulaName) {
    var self = this,
      sheet = self.getSheet(),
      spread = sheet.getParent();
    var startRowIndex = range.row,
      startColIndex = range.col;
    var span = self.getSpan(startRowIndex, startColIndex);
    var rangeIsSpan = false;
    if (span && span.equals(range)) {
      rangeIsSpan = true;
    }
    var useR1C1 = spread.options.referenceStyle === 1; /* R1C1 */
    var edittingRowIndex = range.row,
      edittingColIndex = range.col;
    if ((range.colCount === 1 && range.rowCount === 1) || rangeIsSpan) {
      // single cell
      self.setFormulaToSingleCell(range, formulaName, useR1C1);
    } else if (range.colCount === 1) {
      var lastRowIndex = startRowIndex + range.rowCount - 1,
        col = startColIndex;
      if (!sheet.getText(startRowIndex, col)) {
        // If the first cell of selected range has value, set formula to last cell of selected range. If not, set formula to the first cell.
        self.setFormulaToSingleCell(range, formulaName, useR1C1);
      } else {
        if (!sheet.getText(lastRowIndex, col)) {
          // If the last cell of selected range has value, set formula to next null row.
          var formulaRange = {
            row: startRowIndex,
            col: col,
            colCount: range.colCount,
            rowCount: range.rowCount - 1,
          };
          edittingRowIndex = lastRowIndex;
          let formulaString = rangeToFormula(
            formulaRange,
            lastRowIndex,
            col,
            allRelative,
            spread.options.referenceStyle === 1 /* useR1C1 */
          );
          self.setFormulaToSheet(sheet, lastRowIndex, col, formulaName, formulaString);
        } else if (lastRowIndex + 1 < sheet.getRowCount()) {
          edittingRowIndex = lastRowIndex + 1;
          let formulaString = rangeToFormula(range, lastRowIndex + 1, col, allRelative, useR1C1);
          self.setFormulaToSheet(sheet, lastRowIndex + 1, col, formulaName, formulaString);
        }
      }
    } else if (range.rowCount === 1) {
      let row = startRowIndex;
      let lastColIndex = startColIndex + range.colCount - 1;
      if (!sheet.getText(row, startColIndex)) {
        self.setFormulaToSingleCell(range, formulaName, useR1C1);
      } else {
        if (!sheet.getText(row, lastColIndex)) {
          let formulaRange = {
            row: row,
            col: startColIndex,
            colCount: range.colCount - 1,
            rowCount: range.rowCount,
          };
          edittingColIndex = lastColIndex;
          let formulaString = rangeToFormula(formulaRange, row, lastColIndex, allRelative, useR1C1);
          self.setFormulaToSheet(sheet, row, lastColIndex, formulaName, formulaString);
        } else if (lastColIndex + 1 < sheet.getColumnCount()) {
          edittingColIndex = lastColIndex + 1;
          let formulaString = rangeToFormula(range, row, lastColIndex + 1, allRelative, useR1C1);
          self.setFormulaToSheet(sheet, row, lastColIndex + 1, formulaName, formulaString);
        }
      }
    } else {
      self.setMultiRowColRangeFormula(range, formulaName);
    }
    return { edittingRowIndex: edittingRowIndex, edittingColIndex: edittingColIndex };
  };
  InsertSumFunctionAction.prototype.setFormulaToSingleCell = function (range, formulaName, useR1C1) {
    var self = this,
      sheet = self.getSheet(),
      startRowIndex = range.row,
      startColIndex = range.col;
    if (self._noNeedSetFormula(startRowIndex, startColIndex)) {
      return;
    }
    var formulaRange = self.getFormulaRange(range);
    var formulaString = '';
    if (formulaRange) {
      if (
        formulaRange.rowCount !== 1 ||
        formulaRange.colCount !== 1 ||
        formulaRange.row !== startRowIndex ||
        formulaRange.col !== startColIndex
      ) {
        formulaString = rangeToFormula(formulaRange, startRowIndex, startColIndex, allRelative, useR1C1);
      }
    }
    self.setFormulaToSheet(sheet, startRowIndex, startColIndex, formulaName, formulaString);
  };
  InsertSumFunctionAction.prototype.setFormulaToSheet = function (sheet, row, col, formulaName, formulaString) {
    var formula = '=' + formulaName + '(' + formulaString + ')';
    sheet.setFormula(row, col, formula);
    sheet.showCell(row, col, VerticalPosition.nearest, HorizontalPosition.nearest);
  };
  InsertSumFunctionAction.prototype.setMultiRowColRangeFormula = function (range, formulaName) {
    var self = this,
      lastRow = range.row + range.rowCount - 1,
      lastCol = range.col + range.colCount - 1,
      sheet = self.getSheet(),
      setNextCell = true,
      setRightCell = true;
    for (let i = range.col; i < range.col + range.colCount; i++) {
      if (sheet.getValue(lastRow, i)) {
        setNextCell = false;
        break;
      }
    }
    for (let i = range.row; i < range.row + range.rowCount; i++) {
      if (sheet.getValue(i, lastCol)) {
        setRightCell = false;
        break;
      }
    }
    if (!setRightCell) {
      self.setWholeRowFormula(setNextCell, lastRow, range, formulaName);
    } else if (!setNextCell && setRightCell) {
      self.setWholeColFormula(lastCol, range, formulaName);
    } else {
      self.setWholeRowFormula(setNextCell, lastRow, range, formulaName);
      self.setWholeColFormula(lastCol, range, formulaName);
    }
  };
  InsertSumFunctionAction.prototype.setWholeRowFormula = function (setNextCell, lastRow, range, formulaName) {
    var setFormulaCellRow = setNextCell ? lastRow : lastRow + 1,
      self = this;
    for (var i = range.col; i < range.col + range.colCount; i++) {
      if (self._noNeedSetFormula(setFormulaCellRow, i)) {
        break;
      }
      var formulaRange = {
        col: i,
        colCount: 1,
        row: range.row,
        rowCount: range.rowCount + (setNextCell ? -1 : 0),
      };
      var formula = rangeToFormula(
        formulaRange,
        setFormulaCellRow,
        i,
        allRelative,
        self.getSpread().options.referenceStyle === 1 /* useR1C1 */
      );
      formula = '=' + formulaName + '(' + formula + ')';
      self.getSheet().setFormula(setFormulaCellRow, i, formula);
      self.getSheet().showCell(setFormulaCellRow, i, VerticalPosition.nearest, HorizontalPosition.nearest);
    }
  };
  InsertSumFunctionAction.prototype.setWholeColFormula = function (lastCol, range, formulaName) {
    var self = this;
    for (var i = range.row; i < range.row + range.rowCount; i++) {
      if (self._noNeedSetFormula(i, lastCol)) {
        break;
      }
      var formulaRange = {
        col: range.col,
        colCount: range.colCount - 1,
        row: i,
        rowCount: 1,
      };
      var formula = rangeToFormula(
        formulaRange,
        i,
        lastCol - 1,
        allRelative,
        self.getSpread().options.referenceStyle === 1 /* useR1C1 */
      );
      formula = '=' + formulaName + '(' + formula + ')';
      self.getSheet().setFormula(i, lastCol, formula);
      self.getSheet().showCell(i, lastCol, VerticalPosition.nearest, HorizontalPosition.nearest);
    }
  };
  return InsertSumFunctionAction;
})(ShortcutActionBase);
Commands[INSERT_SUM_FUNCTION] = {
  canUndo: true,
  execute: function (context, options, isUndo) {
    options.cmd = INSERT_SUM_FUNCTION;
    return executeCommand(context, InsertSumFunctionAction, options, isUndo);
  },
};
export function initShortcutAboutCalc(commands) {
  if (Util.isFirefox()) {
    commands.register(
      INSERT_SUM_FUNCTION,
      Commands[INSERT_SUM_FUNCTION],
      61 /* = */,
      false /* ctrl */,
      false /* shift */,
      true /* alt */,
      false /* meta */
    );
  } else {
    commands.register(
      INSERT_SUM_FUNCTION,
      Commands[INSERT_SUM_FUNCTION],
      187 /* = */,
      false /* ctrl */,
      false /* shift */,
      true /* alt */,
      false /* meta */
    );
  }
}
