import {
  SemanticSearchServiceClient,
  SimpleQARequest,
  TaxAppId,
  TaxNodeGatewayEnv,
} from '@taxresearch/tax-research-gateway-client';

export const getAiSummary = async query => {
  try {
    const sssClient = new SemanticSearchServiceClient(TaxNodeGatewayEnv.AUTODETECT, TaxAppId.WORKPAPERS);

    // Examples of the various presets in SimpleQARequest, corresponding to the GoBar scopes in Tax Research
    // The default search scope is All (no specific state or federal content filtering).
    const allReq = new SimpleQARequest(query);
    // const fedReq = new SimpleQARequest(query, TaxSearchView.FEDERAL);
    // const stateReq = new SimpleQARequest(query, TaxSearchView.STATE);

    const res = await sssClient.getQuestionAnswer(allReq);
    // res will contain the upstream response from semantic search service
    console.log(res);
    return res;
  } catch (error) {
    console.log(`Error while fetching AI summary: ${error}`);
  }
};
