import { V } from '@clientio/rappid';

/**
 * @param {import('jointjs').dia.ElementView} elementView
 * @param {import('jointjs').dia.Link[]} links
 * @param {import('jointjs').dia.Element.Port} port
 */
export function createPortModel(elementView, links, port) {
  const magnet = elementView.el.querySelector(`circle.port-body[port="${port.id}"][port-group="${port.group}"]`);
  const associatedLinks = links.filter(link => link.target().port === port.id || link.source().port === port.id);

  return {
    elementId: elementView.model.id,
    id: port.id,
    group: port.group,
    links: associatedLinks,
    magnet,
  };
}

/**
 * @param {import('jointjs').dia.Element} element
 * @param {import('jointjs').dia.Graph} graph
 * @param {import('jointjs').dia.Paper} paper
 */
export function createElementModel(element, graph, paper) {
  const elementView = paper.findViewByModel(element);
  const outboundLinks = graph.getConnectedLinks(element, { outbound: true });
  const inboundLinks = graph.getConnectedLinks(element, { inbound: true });
  const outPorts = element.getGroupPorts('out').map(port => createPortModel(elementView, outboundLinks, port));
  const inPorts = element.getGroupPorts('in').map(port => createPortModel(elementView, inboundLinks, port));

  return {
    id: element.id,
    element,
    elementView,
    outPorts,
    inPorts,
    get freeInPorts() {
      return this.inPorts.filter(ip => ip.links.length === 0);
    },
    get freeOutPorts() {
      return this.outPorts.filter(ip => ip.links.length === 0);
    },
  };
}

/**
 * Calculates the length of the line segment between two points (x0, y0) and (x, y).
 * @param {{ x: number, y: number}} a
 * @param {{ x: number, y: number}} b
 * @returns {number}
 */
export function calculateDistance(a, b) {
  const distance = Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));

  return distance;
}

/**
 * Toggles highlighting the magnets.
 * @param {HTMLOrSVGElement[]} magnets
 * @param {bool} hightligh
 */
export function toggleMagnetHighlight(magnets, hightligh = true) {
  magnets.forEach(magnet => magnet.classList[hightligh ? 'add' : 'remove']('autoconnecting'));
}

/**
 * Given an element in the DOM, it returns the BBox of that element relative to the given
 *   Paper boundaries.
 * @param {import('jointjs').dia.Paper} paper
 * @param {HTMLOrSVGElement} element
 *
 * @returns {import('jointjs').g.Rect}
 */
export function findElementBBoxRelativeToPaper(paper, element) {
  const bbox = V(element).getBBox({ target: paper.el.querySelector('svg') });

  return bbox;
}

/**
 * @param {import('jointjs').dia.Paper} paper
 * @param {import('jointjs').dia.ElementView} targetElementView
 * @param {SVGElement} targetMagnet
 */
export function findCompatiblePortsForGivenMagnet(paper, targetElementView, targetMagnet) {
  const elementsWithOutPortsInPaper = paper.model
    .getElements()
    .map(element => createElementModel(element, paper.model, paper))
    .filter(el => el.outPorts.length > 0);

  const compatibleOutPortsInPaper = elementsWithOutPortsInPaper
    .reduce((acc, element) => {
      const elementPorts = element.outPorts.map(freePort => {
        const portsBBox = findElementBBoxRelativeToPaper(paper, freePort.magnet);
        return {
          elementId: element.id,
          element,
          elementView: element.elementView,
          portId: freePort.id,
          magnet: freePort.magnet,
          center: portsBBox.center(),
          size: { width: portsBBox.width, height: portsBBox.height },
        };
      });

      return [...acc, ...elementPorts];
    }, [])
    .filter(fp => paper.options.validateConnection(fp.elementView, fp.magnet, targetElementView, targetMagnet));

  return compatibleOutPortsInPaper;
}
