import newDataFlow from './CreateDataFlowTemplateDialog/DTW-Template-Tile-Blank.svg';
import templatePic from './CreateDataFlowTemplateDialog/DTW-Template-Tile-Fallback.svg';

export default async function createTemplateGrid(templates, onTemplateClick, onCreateClick) {
  const columns = 3;

  const gridItems = [];
  gridItems.push({
    image: newDataFlow,
    text: 'Blank',
    handler: onCreateClick,
  });

  templates.items.forEach(function (template) {
    const item = template.name;
    const data = {
      image: templatePic,
      text: item,
      handler: () => onTemplateClick(template),
    };
    gridItems.push(data);
  });

  let itemsToDisplay = [];

  let row = 0;
  let col = 0;
  let i = 0;
  while (i < gridItems.length) {
    if (col === 0) {
      itemsToDisplay.push({});
      itemsToDisplay[row].key = row;
      itemsToDisplay[row].data = [];
    }
    itemsToDisplay[row].data.push(gridItems[i]);
    i++;
    col++;
    if (col === columns) {
      col = 0;
      row++;
    }
  }
  return itemsToDisplay;
}
