import { useContext, useEffect } from 'react';
import { dia } from '@clientio/rappid';
import { DataFlowEditorContext } from '../DataFlowEditorContext';

export const useGraph = () => {
  const { dataFlowActions } = useContext(DataFlowEditorContext);

  // Initialize graph
  useEffect(() => {
    const graph = new dia.Graph();

    dataFlowActions.setGraph(graph);
  }, [dataFlowActions]);
};
