import React, { useMemo, useState } from 'react';
import { BTGrid, BTButton, BTInput, BTComboBox, BTPopover, BTIcon } from '@btas/jasper';
import SignOffTooltip from './SignOffPopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import workpaperIcon from './Workpaper_icon.svg';
import workpaperTemplateIcon from './Workpaper_template_icon.svg';
import exclamataionIcon from './Exclamation_icon.svg';
import './Grid/styles.scss';
import { wijmoKey } from '../../../../configs/params';
import EmptyGrid from './EmptyGrid';
import WorkpapersActionButtons from '../WorkpapersActionButtons';
import { workpaper_status } from '../../../editor/EditorPage/Spreadsheet/shared/constants';
import { isGlobalTemplateAccount } from '../../../_shared/auth';
import InvalidCellReviewsTooltip from './InvalidCellReviewsPopover';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { CELL_REVIEW_HOME_PAGE } from '../../../../constants/featureFlags';

const WorkpapersBTGrid = ({
  config,
  handleWorkpapersSelectionChange,
  onSortChange,
  onFilterChange,
  onClearFilters,
  isTaxPeriodSelected,
  setIsSortingFromGrid,
  isSortingFromGrid,
}) => {
  const { gridData, gridSorting, gridFilters } = config;
  const { items, filterOptions } = gridData;
  const isReviewsEnabled = isFeatureFlagEnabled(CELL_REVIEW_HOME_PAGE);
  const handleSelectionChanged = items => handleWorkpapersSelectionChange(items);
  const handleSort = columnSort => onSortChange(columnSort);

  const handleFilterChange = (field, value) => {
    if (field === 'taxPeriod') {
      setIsSortingFromGrid(true);
    }
    onFilterChange(field, value);
  };

  const isFiltered = useMemo(() => Object.values(gridFilters).some(filter => filter), [gridFilters]);
  const nameCell = ({ rowData }) => (
    <BTButton btStyle="link" className="wkp-saved-wkp">
      <a href={`/editor/${rowData.id}`} rel="noreferrer" target="_blank">
        <img alt="Saved workpaper" src={rowData.isGlobalTemplate ? workpaperTemplateIcon : workpaperIcon} />
        {rowData.name}
      </a>
    </BTButton>
  );

  const templateCell = ({ rowData }) => (
    <p className="wkp-global-template">{rowData?.isGlobalTemplate ? 'Yes' : 'No'}</p>
  );
  return (
    <BTGrid
      allowSelection
      allowSorting
      allowResizing={1}
      data={items}
      defaultSortedColumn={[gridSorting]}
      pageYOffset={450}
      wijmoKey={wijmoKey}
      onClearFilterClick={onClearFilters}
      onSelectionChanged={handleSelectionChanged}
    >
      <BTGrid.Header binding="sel" minWidth={50} width="*">
        sel
      </BTGrid.Header>
      <BTGrid.Header
        key="name"
        align="left"
        binding="name"
        cellTemplate={nameCell}
        minWidth={200}
        width="2*"
        onSort={handleSort}
      >
        Name
      </BTGrid.Header>
      <BTGrid.Header
        key="isGlobalTemplate"
        align="left"
        binding="isGlobalTemplate"
        cellTemplate={templateCell}
        hidden={!isGlobalTemplateAccount()}
        minWidth={140}
        width="*"
        onSort={handleSort}
      >
        Global Template
      </BTGrid.Header>
      <BTGrid.Header key="entity" align="left" binding="entity" minWidth={140} width="*" onSort={handleSort}>
        Entity
      </BTGrid.Header>
      <BTGrid.Header
        key="jurisdiction"
        align="left"
        binding="jurisdiction"
        minWidth={140}
        width="*"
        onSort={handleSort}
      >
        Jurisdiction
      </BTGrid.Header>
      <BTGrid.Header
        key="taxPeriod"
        align="left"
        binding="taxPeriod"
        hidden={isTaxPeriodSelected}
        width={140}
        onSort={handleSort}
      >
        Period
      </BTGrid.Header>
      <BTGrid.Header
        key="workpaperType"
        align="left"
        binding="workpaperType"
        minWidth={140}
        width="*"
        onSort={handleSort}
      >
        Workpaper type
      </BTGrid.Header>
      <BTGrid.Header
        key="status"
        align="left"
        binding="status"
        cellTemplate={context => (
          <>
            <span className="status-color-dot" style={{ backgroundColor: context.rowData.statusColor }} />
            {context.text}
            {context.text === workpaper_status.Final ? (
              <BTIcon
                icon="lock"
                style={{ marginLeft: '3px', marginBottom: '1px', color: '#646464', fontSize: '12px' }}
              />
            ) : null}
          </>
        )}
        minWidth={140}
        width="*"
        onSort={handleSort}
      >
        Status
      </BTGrid.Header>
      <BTGrid.Header
        key="lastModifiedDate"
        align="left"
        binding="lastModifiedDate"
        cellTemplate={context => (
          <div className="last-modified-container">
            <p>{context.rowData.lastModifiedUser},</p>
            <p>{context.text}</p>
          </div>
        )}
        width={260}
        onSort={handleSort}
      >
        Last modified
      </BTGrid.Header>
      <BTGrid.Header
        key="workpaperInvalidCellReviews"
        align="center"
        binding="workpaperInvalidCellReviews"
        cellTemplate={context => (
          <>
            {context.text !== '0' && (
              <BTPopover
                content={
                  <div className="cellReviews-popover">
                    <InvalidCellReviewsTooltip
                      workpaperInvalidCellReviews={
                        isReviewsEnabled ? context.rowData.workpaperInvalidCellReviewsUsers : ''
                      }
                    />
                  </div>
                }
                placement="top"
              >
                <span className="wkp-cellReviews">
                  <img alt="Saved workpaper" src={exclamataionIcon} />
                  <span>{context.text}</span>
                </span>
              </BTPopover>
            )}
          </>
        )}
        hidden={!isReviewsEnabled}
        maxWidth={130}
      >
        Cell review
      </BTGrid.Header>

      <BTGrid.Header
        key="signOffs"
        align="center"
        binding="totalSignOffs"
        cellTemplate={context => (
          <>
            {context.text !== '0' && (
              <BTPopover
                content={
                  <div className="signOff-popover">
                    <SignOffTooltip workpaperSignOffs={context.rowData.workpaperSignOffs} />
                  </div>
                }
              >
                <span className="wkp-signOff">
                  <i>
                    <FontAwesomeIcon className="fa-fw" icon={faCheck} />
                  </i>
                  {context.text}
                </span>
              </BTPopover>
            )}
          </>
        )}
        maxWidth={130}
      >
        Sign-Offs
      </BTGrid.Header>

      <BTGrid.Filter binding="name">
        <BTInput name="name" value={gridFilters.name} onChange={e => handleFilterChange('name', e.target.value)} />
      </BTGrid.Filter>

      {isGlobalTemplateAccount() ? (
        <BTGrid.Filter binding="isGlobalTemplate">
          <BTComboBox
            isClearable
            id={'wkp-globalTemplate-select-container'}
            maxMenuHeight={100}
            name="isGlobalTemplate"
            options={filterOptions.isGlobalTemplate}
            value={gridFilters.isGlobalTemplate}
            onChange={opt => handleFilterChange('isGlobalTemplate', opt)}
          />
        </BTGrid.Filter>
      ) : (
        <></>
      )}
      <BTGrid.Filter binding="entity">
        <BTComboBox
          isClearable
          id={'wkp-entity-select-container'}
          maxMenuHeight={100}
          name="entity"
          options={filterOptions.entities}
          value={gridFilters.entity}
          onChange={opt => handleFilterChange('entity', opt)}
        />
      </BTGrid.Filter>
      <BTGrid.Filter binding="jurisdiction">
        <BTComboBox
          isClearable
          id={'wkp-jurisdiction-select-container'}
          maxMenuHeight={100}
          name="jurisdiction"
          options={filterOptions.jurisdictions}
          value={gridFilters.jurisdiction}
          onChange={opt => handleFilterChange('jurisdiction', opt)}
        />
      </BTGrid.Filter>
      <BTGrid.Filter binding={!isTaxPeriodSelected ? 'taxPeriod' : ''}>
        <BTComboBox
          isClearable
          id={'wkp-tax-period-select-container'}
          maxMenuHeight={100}
          name="taxPeriod"
          options={filterOptions.taxPeriods}
          value={isSortingFromGrid ? gridFilters.taxPeriod : null}
          onChange={opt => handleFilterChange('taxPeriod', opt)}
        />
      </BTGrid.Filter>
      <BTGrid.Filter binding="workpaperType">
        <BTComboBox
          isClearable
          id={'wkp-type-select-container'}
          maxMenuHeight={100}
          name="workpaperType"
          options={filterOptions.workpaperTypes}
          value={gridFilters.workpaperType}
          onChange={opt => handleFilterChange('workpaperType', opt)}
        />
      </BTGrid.Filter>
      <BTGrid.Filter binding="status">
        <BTComboBox
          isClearable
          id={'wkp-status-select-container'}
          maxMenuHeight={100}
          name="status"
          options={filterOptions.statuses}
          value={gridFilters.status}
          onChange={opt => handleFilterChange('status', opt)}
        />
      </BTGrid.Filter>

      {/* We need to add this empty fragment in order to keep the filters align with theirs headers when
        the screen is small and the horizontal scroll could be possible */}
      <BTGrid.Filter binding="workpaperInvalidCellReviews" hidden={!isReviewsEnabled}>
        <></>
      </BTGrid.Filter>
      <BTGrid.Filter binding="totalSignOffs">
        <></>
      </BTGrid.Filter>

      <BTGrid.EmptyState isFiltered={isFiltered} />
    </BTGrid>
  );
};

export default function Grid({
  actions,
  open,
  config,
  onSortChange,
  onFilterChange,
  onClearFilters,
  isDragActive,
  isTaxPeriodSelected,
  setIsSortingFromGrid,
  isSortingFromGrid,
  workpapersDropzone,
  processes,
  loadJobs,
  cleanGridSelection,
  setHasDeleteWfError,
  setUndeletedWorkpapers,
}) {
  const {
    gridData: { items, totalCount },
    gridFilters,
    gridSorting,
  } = config;
  const [selectedWorkpapers, setSelectedWorkpapers] = useState([]);

  return (
    <>
      <WorkpapersActionButtons
        actions={actions}
        cleanGridSelection={cleanGridSelection}
        gridFilters={gridFilters}
        gridSorting={gridSorting}
        loadJobs={loadJobs}
        processes={processes}
        selection={selectedWorkpapers}
        setHasDeleteWfError={setHasDeleteWfError}
        setUndeletedWorkpapers={setUndeletedWorkpapers}
        workpapersDropzone={workpapersDropzone}
      />
      {isDragActive && <div className="wkp-drop-zone"></div>}
      {items.length === 0 && totalCount === 0 ? (
        <div className="wkp-saved-wkp-section wkp-empty wkp-drag">
          <div className="wkp-grid-container">
            <EmptyGrid onBrowseClick={open} />
          </div>
        </div>
      ) : (
        <WorkpapersBTGrid
          config={config}
          handleWorkpapersSelectionChange={sel => setSelectedWorkpapers(sel)}
          isSortingFromGrid={isSortingFromGrid}
          isTaxPeriodSelected={isTaxPeriodSelected}
          setIsSortingFromGrid={setIsSortingFromGrid}
          onClearFilters={onClearFilters}
          onFilterChange={onFilterChange}
          onSortChange={onSortChange}
        />
      )}
    </>
  );
}
