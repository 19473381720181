import { nodeAPIServiceHostName } from '../../../configs/params';
import * as api from '../fetch';

const formatByteToSize = bytes => {
  if (bytes < 1024) {
    return bytes + ' B';
  } else if (bytes < 1024 * 1024) {
    return (bytes / 1024).toFixed(2) + ' KB';
  } else {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
  }
};

const lastNameOrType = (path, delimiter) => {
  return path.substring(path.lastIndexOf(delimiter) + 1);
};

const retrieveObjInfo = objKey => {
  const MIN_FOLDER_PATH_LENGTH = 2;
  const FIRST_PATH = 1;

  const isFolder = objKey.split('/').length > MIN_FOLDER_PATH_LENGTH;
  const fileName = isFolder ? objKey.split('/')[FIRST_PATH] : lastNameOrType(objKey, '/');
  const fileType = isFolder ? 'folder' : lastNameOrType(objKey, '.');
  return {
    fileName,
    fileType,
    isFolder,
  };
};

const parseSFTPList = (listObjects, objPrefix, sftpBucket) => {
  const items = [];
  const emptyPath = '/';
  for (let obj of listObjects) {
    const { fileName, fileType, isFolder } = retrieveObjInfo(obj.Key);
    if (obj.Key.startsWith('/File Backup/')) {
      continue;
    }
    if (obj.Key !== emptyPath && !items.some(obj => obj.name === fileName)) {
      items.push({
        name: fileName,
        type: fileType,
        lastModified: isFolder ? '' : new Date(obj.LastModified).toLocaleString(),
        fileSize: isFolder ? '' : formatByteToSize(obj.Size),
        path: `${objPrefix}/${fileName}`,
        prefix: objPrefix,
        bucket: sftpBucket,
      });
    }
  }
  return { items };
};

export function getParsedList({ sorting, selected, sftpRootList }) {
  const { sftpBucket, listBucketObj } = sftpRootList;
  if (selected === null) {
    return parseSFTPList(listBucketObj, '', sftpBucket);
  }
  if (selected.type === 'folder') {
    const filterList = listBucketObj.filter(obj => obj.Key.startsWith(`${selected.prefix}/`));
    return parseSFTPList(
      filterList.map(obj => ({ ...obj, Key: obj.Key.replace(selected.prefix, '') })),
      selected.prefix,
      sftpBucket
    );
  }
}

export async function getSFTPlistObject(resourceId, resourceType) {
  const res = await api.get(
    `${nodeAPIServiceHostName}/node/SFTP/files?resourceId=${resourceId}&resourceType=${resourceType}`
  );
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}
