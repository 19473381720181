import { useState, useEffect } from 'react';
import { BTForm, BTComboBox } from '@btas/jasper';
import PropTypes from 'prop-types';
import { dataFlowServiceHostName } from '../../configs/params';
import * as fetch from '../../components/_shared/fetch';

export default function TaxPeriodInput({
  canCreateNew = true,
  disabled,
  errorText,
  value,
  onChange,
  onError,
  showSmallText = true,
  isClearable = true,
  isRequired = true,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetch
      .get(`${dataFlowServiceHostName}/api/v1/data-flows/tax-periods`)
      .then(res => res.json())
      .then(response => {
        if (response instanceof Array) {
          const mappedOptions = response.map(value => ({ label: value, value }));
          setOptions(mappedOptions);

          setSelectedOption(mappedOptions.find(opt => opt.value === value));
        } else {
          onError(response);
        }
      });
  }, [value, onError]);

  return (
    <BTForm.FormGroup
      disabled={disabled}
      errorText={errorText}
      hasError={
        typeof errorText === 'string' ||
        (!!errorText && typeof errorText !== 'string' && Object.keys(errorText)?.length > 0)
      }
      htmlFor="taxPeriod"
      label="Tax period"
      required={isRequired}
    >
      <BTComboBox
        canCreateNew={canCreateNew}
        createText="Add tax period"
        id="taxPeriod"
        isClearable={isClearable}
        isSearchable={true}
        maxMenuHeight={100}
        noResultsMessage="No tax periods created. Start typing to add a tax period."
        options={options}
        placeholder='This can be any label you like,  e.g. "TY2020", "Provision 2021 Q2", etc.'
        value={selectedOption}
        onChange={option => {
          const selectedValue = option && option.value;

          setSelectedOption(option);
          onChange(selectedValue);
        }}
      />
    </BTForm.FormGroup>
  );
}

TaxPeriodInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onError: PropTypes.func,
};

TaxPeriodInput.defaultProps = {
  disabled: false,
  errorText: undefined,
  onError: () => {},
};
