import { FORMULA_COLOR } from '../../shared/colors';
import { TransformationElementType } from '../TransformationElementType';
import iconImage from '../icons/aggregate_block_icon.svg';
import hintImage from '../icons/aggregate_hint_img.svg';
import AggregateElementInspector from '../../AggregateElementInspector';
import { NUMERIC } from '../../shared/fieldTypes';
import { SUM } from '../../shared/aggregateTypes';
import FieldConstant from '../../shared/constants/fields.constant';
import { AGGREGATE } from '../types/shared/typesConstants';
import { uniqBy } from 'lodash';

export class AggregateElementType extends TransformationElementType {
  static TYPE = AGGREGATE;
  static HELP_TEXT = `The aggregate block allows you to summarize your data based on fields of your choosing.<img src=${hintImage} alt="Aggregate hint" />`;

  constructor() {
    super(AggregateElementType.TYPE, 'Aggregate', FORMULA_COLOR, iconImage, AggregateElementType.HELP_TEXT);
  }

  get initialData() {
    return {
      name: this.label,
      type: this.type,
      groupBy: [],
      inputFields: [],
      fieldsToAggregate: [{ field: '', aggregateFunction: SUM }],
    };
  }

  applyMergeElements(elementData, sourceElements) {
    let sourceFields = [];

    if (sourceElements['in']) {
      sourceFields = sourceElements['in'].elementData.fields ?? [];
    }
    return {
      ...elementData,
      fields: sourceFields,
      currentFields: uniqBy(
        [...(elementData?.fields || []), ...(elementData?.currentFields || []), ...sourceFields],
        'name'
      ),
    };
  }

  extractTypeData(elementData) {
    this.aggregateFieldsExpand(elementData);
    return {
      ...super.extractTypeData(elementData),
      fields: elementData.fields,
      inputFields: elementData.inputFields,
      groupBy: elementData.groupBy,
      fieldsToAggregate: elementData.fieldsToAggregate.filter(fieldToAggregate => fieldToAggregate.field),
    };
  }

  aggregateFieldsExpand(elementData) {
    if (elementData?.inputFields && elementData?.inputFields[0]?.original_name) {
      elementData.fieldsToAggregate.forEach((field, index) => {
        const validateFieldName =
          elementData?.inputFields.find(element => element.original_name === field.field)?.name || field.field;
        elementData.fieldsToAggregate[index].field = validateFieldName;
      });
      const groupByFields = [];
      elementData.groupBy.forEach((field, index) => {
        const validateGroupByName =
          elementData?.inputFields.find(element => element.original_name === field)?.name || field;
        groupByFields.push(validateGroupByName);
        elementData.groupBy[index] = validateGroupByName;
      });
      elementData.fields.forEach((field, index) => {
        if (field.name.includes('Count')) {
          const updateFormat = {
            name: field.name,
            type: field.type,
            date_format: 'MM/dd/yyyy',
            original_name: field.name,
          };
          elementData.fields[index] = updateFormat;
        } else {
          const validateGroupByName =
            elementData?.inputFields.find(element => element.original_name === field.name) || field;
          elementData.fields[index] = validateGroupByName;
        }
      });
      groupByFields.forEach(field => {
        if (!elementData?.fields.find(element => element.name === field)) {
          const validateGroupByName = elementData?.inputFields.find(element => element.name === field) || field;
          elementData?.fields.unshift(validateGroupByName);
        }
      });
    }
  }

  applySourceElements(elementData, sourceElements) {
    const newElementData = this.applyMergeElements(elementData, sourceElements);
    const { fields, inputFields, currentFields } = newElementData;
    const groupBy = elementData.groupBy;
    const fieldsToAggregate = elementData.fieldsToAggregate.filter(fieldToAggregate => {
      // keep fields with empty names
      if (!fieldToAggregate.field) {
        return true;
      }
      let field = fields.find(f => f.name === fieldToAggregate.field);
      if (!field) field = inputFields.find(f => f.name === fieldToAggregate.field);
      if (!field) field = currentFields.find(f => f.name === fieldToAggregate.field);
      return field;
    });
    const fieldNames = fields.map(field => field.name);
    const notEmptyFieldsToAggregate = fieldsToAggregate.filter(({ field }) => field);
    const groupByFields = groupBy.length || notEmptyFieldsToAggregate.length ? groupBy : fieldNames;
    const newFields = groupByFields
      .map(fieldName => fields.find(field => fieldName === field.name))
      .filter(field => field !== undefined);
    newFields.push(
      ...this.getAggregateFieldsWithCorrectNames(
        [...fieldsToAggregate, { field: FieldConstant.COUNT, aggregateFunction: '' }],
        fields,
        groupByFields
      )
    );
    // prevent empty fieldToAggregate from propagating
    const outputFields = newFields.filter(f => !!f.name);
    return {
      ...newElementData,
      inputFields: fields,
      fields: outputFields,
      fieldsToAggregate,
      groupBy,
    };
  }

  getAggregateFieldsWithCorrectNames(fieldsToAggregate, inputFields, groupByFields) {
    const existedNames = [...groupByFields];
    const fieldToAggregateNames = fieldsToAggregate.map(({ field }) => field);
    return fieldsToAggregate.map(fieldToAggregate => {
      const { field } = fieldToAggregate;
      const inputField = inputFields.find(inputField => inputField.name === field) || { name: field, type: NUMERIC };
      const hasMultipleAggregatesForField = fieldToAggregateNames.filter(name => field === name).length > 1;
      const name = this.getAggregateFieldName(fieldToAggregate, hasMultipleAggregatesForField, existedNames);
      existedNames.push(name);
      return {
        ...inputField,
        name,
      };
    });
  }

  getAggregateFieldName({ field, aggregateFunction }, hasMultipleAggregatesForField, existedNames) {
    let name = field;
    if (hasMultipleAggregatesForField && aggregateFunction) {
      name = `${aggregateFunction}_${name}`;
    }
    let possiblePostfix = 0;
    const addPostfix = postfix => `${name}${postfix || ''}`;
    while (existedNames.includes(addPostfix(possiblePostfix))) {
      possiblePostfix = possiblePostfix ? possiblePostfix + 1 : 1;
    }
    name = addPostfix(possiblePostfix);
    return name;
  }

  get maxCount() {
    return -1;
  }

  get inspectorComponent() {
    return AggregateElementInspector;
  }
}
