import React from 'react';
import { BTButton } from '@btas/jasper';
import { eventTrackTagNames } from '../../../../../../_shared/EventTrackingContext/constants';

export default function EmptyState({ onAddSignOffClick, isLocked }) {
  return (
    <div className="wkp-sign-off-empty-state-panel">
      <p>No sign-offs have been added to this workpaper</p>

      <BTButton
        btStyle="link"
        btType="add"
        data-track-tag={eventTrackTagNames.interactive}
        disabled={isLocked}
        id="add-sign-off"
        onClick={onAddSignOffClick}
      >
        Add sign-off
      </BTButton>
    </div>
  );
}
