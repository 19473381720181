import { getUserId } from '../../_shared/auth';

export function getWorkflowsUserCanDelete(workflows, userPermissions) {
  const userId = getUserId();
  const workflowIdsToDelete = workflows.map(sel => sel.id);

  const deleteSet = new Set(workflowIdsToDelete);

  const workflowsToDelete = workflows.filter(workflow => {
    if (!userPermissions.includes('delete_workflow_self')) {
      return false;
    }
    if (!userPermissions.includes('delete_workflow_others')) {
      return workflow.userId === userId && deleteSet.has(workflow.id);
    }
    return true;
  });

  return new Map(workflowsToDelete.map(i => [i.id, i]));
}
