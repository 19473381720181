import React from 'react';
import { Route } from 'react-router-dom';
import { UserPreferencesProvider } from '../components/_shared/UserPreferencesContext';

export default function PrivateRoute({ component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return <UserPreferencesProvider>{Component ? <Component {...props} /> : render()}</UserPreferencesProvider>;
      }}
    />
  );
}
