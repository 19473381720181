import React from 'react';
import { BTAlert } from '@btas/jasper';

import { useLockedWorkflowAlertIsVisible } from '../../_shared/UserPermissionsContext/LockedWorkflows/LockedWorkflowHooks';
import { useDismissLockedWorkflowAlert } from '../../_shared/UserPermissionsContext/LockedWorkflows/LockedWorkflowHooks';
const LockedWorkflowAlert = () => {
  const isVisible = useLockedWorkflowAlertIsVisible();
  const dismissAlert = useDismissLockedWorkflowAlert();
  return (
    <BTAlert appear dismissible fixed btStyle="warning" visible={isVisible} onDismiss={() => dismissAlert()}>
      <p>
        This data transformation workflow has been marked 'Final' and is locked as read-only. To unlock this data
        transformation workflow, open the <b>Workflow</b> panel and change the <b>Status</b>.
      </p>
    </BTAlert>
  );
};

export default LockedWorkflowAlert;
