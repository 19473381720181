import * as fetch from '../../../_shared/fetch';
import {
  dataFlowServiceHostName,
  nodeAPIServiceHostName,
  spreadsheetServiceHostName,
} from '../../../../configs/params';
import * as apis from './apis';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { WKP_INGEST_REWRITE } from '../../../../constants/featureFlags';
import { INTEGRATIONS_TYPE, SFTP_REGEX } from '../InputElementInspector/constants';

export async function getWorkbookInfo(uploadResult) {
  const integrationType = SFTP_REGEX.test(uploadResult.bucket) ? INTEGRATIONS_TYPE.SFTP : null;
  uploadResult = { ...uploadResult, integrationType };
  return getSheetNames(uploadResult);
}

export async function getSheetNames(sourceFileInfo) {
  const uri = isFeatureFlagEnabled(WKP_INGEST_REWRITE)
    ? `${spreadsheetServiceHostName}/spreadsheet/files/sheet-names`
    : `${dataFlowServiceHostName}/api/v1/data-flows/source-files/xlsx-sheets`;

  const data = await fetch
    .post(uri, {
      body: JSON.stringify(sourceFileInfo),
    })
    .then(data => data.json())
    .catch(err => {
      throw err;
    });

  // if response is an error then we show error pop up message
  if (data?.error) {
    return { error: data.error };
  }

  return isFeatureFlagEnabled(WKP_INGEST_REWRITE)
    ? { sheetNames: data.result, sourceData: sourceFileInfo }
    : { sheetNames: data, sourceData: sourceFileInfo };
}

export async function getUploadedFilesByTaxPeriod(taxPeriod) {
  const taxPeriodEncoded = encodeURIComponent(taxPeriod);
  const data = await fetch.get(
    `${dataFlowServiceHostName}/api/v1/data-flows/source-files/tax-periods/${taxPeriodEncoded}`
  );
  return data.json();
}

export async function importSourceFileToCsv({ dataFlowId, inputId, sheetData, sourceData, systemCode }) {
  const { sheetName, dataStartAt, numHeaders } = sheetData;

  // common body data
  const processingParams = { sheet: sheetName || '', headerNum: numHeaders || '1', cell: dataStartAt || 'A1' };
  let bodyData = {
    fileInfo: sourceData,
    processingParams,
    dataFlowId,
    inputId,
    systemCode,
  };

  // construct source xlsx info if is xlsx
  if (sheetData !== undefined && Object.keys(sheetData).length > 0) {
    bodyData = {
      ...bodyData,
    };
  }

  const uploadConvertedCsvResult = await fetch.post(
    `${dataFlowServiceHostName}/api/v1/data-flows/source-files/import-from-source-file-to-csv`,
    {
      body: JSON.stringify(bodyData),
    }
  );

  const data = await uploadConvertedCsvResult.json();
  if (!uploadConvertedCsvResult.ok) {
    throw data.error;
  }
  const sourceFileInfo = { ...data.csvFileInfo, name: sourceData.name };

  return { sourceFileInfo, processId: data.processId };
}

export async function getSourceFileMetadata(
  csvFileInfo,
  {
    dataFlowId,
    inputId,
    sheetData = {},
    taxPeriod,
    updateSourceFile,
    fileLocationId,
    sourceData,
    sourceFileId,
    systemCode,
    additionalFields,
  }
) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/${dataFlowId}/source-files/${inputId}/pending`;

  const payloadRequest = updateSourceFile
    ? { ...csvFileInfo, sheetData, taxPeriod, updateSourceFile, sourceFileId, systemCode, additionalFields }
    : {
        ...csvFileInfo,
        originalFileInfo: sourceData,
        sheetData,
        taxPeriod,
        fileLocationId,
        systemCode,
        additionalFields,
      };

  const res = await fetch.post(reqUri, {
    body: JSON.stringify(payloadRequest),
  });
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function validateSourceFile(name, taxPeriod) {
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/validate-existence`;

  const res = await fetch.post(reqUri, {
    body: JSON.stringify({ name, taxPeriod }),
  });
  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}

export async function processSourceFileToCsvProcessStatus(processId) {
  let processIsRunning = true;
  // rename the endpoint
  const reqUri = `${dataFlowServiceHostName}/api/v1/data-flows/source-files/process-source-file-to-csv/status/${processId}`;

  while (processIsRunning) {
    const response = await fetch.get(reqUri);

    const data = await response.json();

    if (!response.ok) {
      throw data.error;
    }

    if (data.status === 'finished' || data.status === 'failed') {
      processIsRunning = false;
    } else {
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
}

export async function processSourceFileToCsv(inputFilePayload) {
  // trigger the lambda to convert input file to csv
  const { sourceFileInfo, processId } = await apis.importSourceFileToCsv(inputFilePayload);

  // Polling from endpoint by process id to make sure source file has been entirely converted to csv
  await apis.processSourceFileToCsvProcessStatus(processId);

  // This line of code won't execute until the processSourceFileToCsv completed

  const data = await apis.getSourceFileMetadata(sourceFileInfo, inputFilePayload);
  return data;
}

export async function addDeletionMarkOnSFTPObject(path, resourceId) {
  const reqUri = `${nodeAPIServiceHostName}/node/SFTP`;
  const sftpObj = {
    path,
    resourceId,
  };
  await fetch.remove(reqUri, {
    body: JSON.stringify(sftpObj),
  });
}

export async function ImportFile(
  dataflowId,
  sourceFileKey,
  fileName,
  sheetName,
  dataStartAt,
  numHeaders,
  jobId,
  integrationType
) {
  const reqUri = `${spreadsheetServiceHostName}/spreadsheet/files/import`;
  const response = await fetch.post(reqUri, {
    body: JSON.stringify({
      jobId: jobId,
      resourceId: dataflowId,
      details: {
        fileName: fileName,
        sourceFileKey: sourceFileKey,
      },
      properties: {
        sheetName: sheetName,
        columnHeadersStartAt: dataStartAt,
        columnHeaderRowsNumber: numHeaders,
      },
      integrationType,
    }),
  });

  const data = await response.json();
  if (!response.ok) {
    throw data.error;
  }

  return data;
}
