import { functionNamesWithVariableParameterCount, functions, triggerStrings } from './constants';

const functionNameRegex = new RegExp('^[A-Za-z]+$');

// Evaluate if string has balanced square brackets or balanced double quotes so functions are not triggered
export const canTriggerFunction = (str, doubleQuoteString, elementTriggered, updateDoubleQuoteStringObject) => {
  if (elementTriggered && elementTriggered.triggerStr === '[') {
    // if trigger is for fields, we skip following validation as field headers can have double quotes
    return true;
  }

  const chartToEvaluate = str.charAt(str.length - 1);

  if (chartToEvaluate === '"' && !doubleQuoteString) {
    const checkMatch = str.split('"').length - 1;
    if (checkMatch === 2) {
      updateDoubleQuoteStringObject({
        [chartToEvaluate]: { matchStart: str.indexOf('"'), matchEnd: str.lastIndexOf('"') },
      });
    } else {
      updateDoubleQuoteStringObject({ [chartToEvaluate]: { matchStart: str.length - 1 } });
    }
    return false;
  } else if (chartToEvaluate === '"' && !doubleQuoteString?.matchEnd) {
    const checkMatch = str.split('"').length - 1;
    if (checkMatch === 0) {
      updateDoubleQuoteStringObject(null);
      return true;
    }
    updateDoubleQuoteStringObject({
      [chartToEvaluate]: { ...doubleQuoteString[chartToEvaluate], matchEnd: str.length - 1 },
    });
    return false;
  } else if (doubleQuoteString) {
    const countMatch = str.split('"').length - 1;
    if (countMatch % 2 !== 0) {
      updateDoubleQuoteStringObject({ '"': { matchStart: str.lastIndexOf('"') } });
      return false;
    }
  }

  if (doubleQuoteString && doubleQuoteString['"'] && !doubleQuoteString['"'].matchEnd) {
    return false;
  } else {
    updateDoubleQuoteStringObject(null);
    return true;
  }
};

export const arrayTriggerMatch = (triggers, re) => {
  const triggersMatch = triggers.map(trigger => ({
    triggerStr: trigger,
    triggerMatch: trigger.match(re),
    triggerLength: trigger.length,
  }));

  return triggersMatch;
};

// Check if the provided string at a specific start position can trigger function options
export const isTrigger = (trigger, str, i) => {
  if (!trigger || !trigger.length) {
    return true;
  }

  if (str.toLowerCase().substr(i, trigger.length) === trigger.toLowerCase()) {
    return true;
  }

  return false;
};

// change string to upperCase at a specified start/end position. Used when the user is typing a matching function in lowercase
export const changeToUpperCase = (paramsForUpperCase, e) => {
  const { start, end } = paramsForUpperCase;

  let updatedValue;

  const oldValue = e.target.value;

  if (start === 0) {
    updatedValue = `${oldValue.substr(0, end).toUpperCase()}${oldValue.substr(end + 1)}`;
  } else {
    const part1Length = oldValue.substr(0, start).length;
    const part2Length = oldValue.substr(start, end).length;
    updatedValue = `${oldValue.substr(0, start)}${oldValue.substr(start, end).toUpperCase()}${oldValue.substr(
      part1Length + part2Length + 1
    )}`;
  }
  return updatedValue;
};

export const getSelectedFunction = (str, caret, providedOptionsObject) => {
  let functionSearchEnd = getParenthesesIndexBeforeCursor(str, caret);

  let functionOption;
  if (functionSearchEnd === -1) {
    return null;
  } else {
    functionOption = [getFunctionMatch(str, functionSearchEnd, providedOptionsObject)];
  }

  if (!functionOption || !functionOption[0]) {
    return null;
  } else {
    return {
      trigger: '(',
      matchStart: caret,
      matchLength: 0,
      options: functionOption,
      functionName: functionOption[0],
      paramPosition: getParamPosition(str, functionSearchEnd, caret, functionOption),
    };
  }
};

function getParamPosition(str, parenthesesIndex, caret, functionOption) {
  let paramStr = str.substring(parenthesesIndex + 1, caret);

  let nestedCount = 0;
  let commas = 0;
  for (let i = 0; i < paramStr.length; i++) {
    if (paramStr.charAt(i) === '(') {
      nestedCount++;
    }
    if (paramStr.charAt(i) === ')') {
      nestedCount--;
    }
    if (nestedCount === 0 && paramStr.charAt(i) === ',') {
      commas++;
    }

    if (nestedCount < 0) {
      console.error('Error parsing formula string:' + str);
    }
  }

  if (
    functionOption in functionNamesWithVariableParameterCount &&
    commas > functionNamesWithVariableParameterCount[functionOption]
  ) {
    return functionNamesWithVariableParameterCount[functionOption];
  }
  return commas;
}

function hasBalancedParenthesis(str) {
  let open = 0;
  let closed = 0;
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) === '(') {
      open++;
    } else if (str.charAt(i) === ')') {
      closed++;
    }
  }
  return open === closed;
}

function getParenthesesIndexBeforeCursor(str, caret) {
  let index = -1;

  for (let i = caret - 1; i >= 0; i--) {
    if (str.charAt(i) === '(') {
      if (hasBalancedParenthesis(str.substring(i + 1, caret))) {
        index = i;
        break;
      }
    }
  }
  return index;
}

function getFunctionMatch(str, parenthesesIndex, providedOptionsObject) {
  const optionsByChar = Object.values(providedOptionsObject);
  for (let i = 0; i < optionsByChar.length; i++) {
    for (let j = 0; j < optionsByChar[i].length; j++) {
      const option = optionsByChar[i][j];

      if (parenthesesIndex - option.length >= 0) {
        const input = str.substring(parenthesesIndex - option.length, parenthesesIndex);

        if (input.match(functionNameRegex) && input === option) {
          let validCharBeforeInput = false;
          if (parenthesesIndex - option.length >= 1) {
            const inputWithPrevChar = str.substring(parenthesesIndex - option.length - 1, parenthesesIndex);
            if (inputWithPrevChar.match(functionNameRegex)) {
              validCharBeforeInput = true;
            }
          }

          if (!validCharBeforeInput) {
            return option;
          }
        }
      }
    }
  }
  return null;
}

// get function and fields options that matches with introduced string at a specified position
export const getOptionsToTrigger = ({ trigger, regex }, funcStr, matchStart = null) => {
  const triggersMatch = getTriggersMatch({ trigger, regex });

  if (matchStart) {
    const { byHint } = triggerStrings;
    return triggersMatch.find(
      t =>
        t.triggerStr.toLowerCase() === funcStr.substr(matchStart, 1).toLowerCase() &&
        funcStr.substr(matchStart, 1) !== byHint
    );
  }

  return triggersMatch.find(t => t.triggerStr.toLowerCase() === funcStr.substr(0, 1).toLowerCase());
};

export const getTriggersMatch = ({ trigger, regex }) => {
  const re = new RegExp(regex);

  return arrayTriggerMatch(getTriggers(trigger), re);
};

export const getTriggers = trigger => {
  let triggers = trigger;
  if (!Array.isArray(triggers)) {
    triggers = new Array(trigger);
  }
  return triggers.sort();
};

// Determine if provided string has balanced parentheses
export const hasMatchParenthesis = strToEvaluate => {
  const block = strToEvaluate;
  const startIndex = strToEvaluate.indexOf('(');
  let currPos = startIndex;
  let openParenthesis = 0;

  while (currPos <= block.length) {
    let currChar = block.charAt(currPos);
    switch (currChar) {
      case '(':
        openParenthesis++;
        break;
      case ')':
        openParenthesis--;
        break;
      default:
        break;
    }
    currPos++;
  }

  return openParenthesis === 0;
};

export const getActiveFunctionParamPosition = (str, slug, functionSelected) => {
  if (str.includes(',')) {
    // Logic to set the function parameter that will be highlighted in function hint dialog
    const lastTriggerPosition = str.lastIndexOf('(');
    const functionStringToCheck = str.substring(lastTriggerPosition);

    const key = functionSelected?.str || slug.funcSelectedStr;
    const functionProps = functions[key];

    if (functionProps) {
      if (slug?.paramPosition >= 0) {
        // return param position after evaluating nested functions
        return slug.paramPosition;
      }
      const paramSelectedCount = functionStringToCheck.split(',').length - 1;
      const paramSelectedPosition = paramSelectedCount - 1;
      return paramSelectedPosition + 1;
    }
  } else {
    return null;
  }
};

// get a match to trigger autocomplete for fields
export const getMatchForFields = (str, caret, providedOptionsObject, callback) => {
  const lastStringCharacter = str.substr(str.length - 1, 1);

  if (lastStringCharacter === '[') {
    //trigger by fields
    return {
      trigger: '[',
      matchStart: caret - 1,
      matchLength: 0,
      options: providedOptionsObject['['],
    };
  }

  const triggerFieldDialogPos = str.lastIndexOf('[', caret - 1);
  const closeFieldDialogPos = str.lastIndexOf(']', caret - 1);

  if (
    (triggerFieldDialogPos !== -1 && closeFieldDialogPos === -1) ||
    (triggerFieldDialogPos !== -1 && closeFieldDialogPos !== -1 && triggerFieldDialogPos > closeFieldDialogPos)
  ) {
    let fieldsToDisplay = null;

    const optionsTrigger = providedOptionsObject['['];
    for (let i = caret - 1; i >= triggerFieldDialogPos + 1; i--) {
      const matchedSlug = str.substring(i);
      const options = optionsTrigger.filter(slug => {
        const idx = slug.toLowerCase().indexOf(matchedSlug.toLowerCase());
        return idx !== -1 && idx === 0;
      });

      fieldsToDisplay = {
        trigger: '[',
        matchStart: triggerFieldDialogPos,
        matchLength: matchedSlug.length,
        options,
      };
    }

    if (fieldsToDisplay) {
      if (fieldsToDisplay.options.length === 0) {
        // if there is not match then return function hint. If previous function was not selected then it will return null
        return callback(str, caret, providedOptionsObject);
      }

      return fieldsToDisplay;
    }
  }
};
