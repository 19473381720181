import { useCallback, useEffect, useMemo, useState } from 'react';
import { getPublishedDataFlows } from './apis';

export default function useLoadPublishedDataFlows(loadInitialData = false) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataFlows, setDataFlows] = useState([]);

  const resetError = useCallback(() => {
    if (error) {
      setError(null);
    }
  }, [error]);

  const fetchDataFlows = useCallback(async () => {
    resetError();
    setIsLoading(true);
    try {
      const publishedDataFlows = await getPublishedDataFlows();
      setDataFlows(publishedDataFlows);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [resetError]);

  useEffect(() => {
    if (loadInitialData) {
      fetchDataFlows();
    }
    // Should not depend on fetchDataFlows, otherwise can cause endless requests loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadInitialData]);

  const isEmpty = useMemo(() => !isLoading && dataFlows.length === 0, [dataFlows.length, isLoading]);

  const state = {
    isLoading,
    isEmpty,
    error,
    dataFlows,
  };

  const actions = {
    fetchDataFlows,
    resetError,
  };

  return [state, actions];
}
