import { useContext, useEffect } from 'react';
import { getType } from '../shared/graphUtils';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { ElementTypeMap } from '../elementType/types';

function updateStencilElements(stencil, updateElement) {
  const stencilElements = stencil.getGraph().getElements();
  stencilElements.map(updateElement);
}

export const useRefreshStencilTooltips = () => {
  const { dataFlowState } = useContext(DataFlowEditorContext);
  const { stencil, isDraggingElementFromStencil } = dataFlowState;

  useEffect(() => {
    if (stencil && isDraggingElementFromStencil) {
      updateStencilElements(stencil, element => element.attr('root/dataTooltip', null));
    }
    return () => {
      if (stencil && isDraggingElementFromStencil) {
        updateStencilElements(stencil, element => {
          const type = getType(element);
          const { helpText } = ElementTypeMap[type];
          element.attr('root/dataTooltip', helpText);
        });
      }
    };
  }, [stencil, isDraggingElementFromStencil]);
};
