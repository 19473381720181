import { BTButton, BTModal } from '@btas/jasper';
import React, { useContext } from 'react';
import Spinner from '../Spinner';
import { SAVE_AS, RENAME } from './actions';
import SaveAs from './SaveAs';
import Rename from './Rename';
import useWorkpaperActionModal from './useWorkpaperActionModal';
import './styles.scss';
import { getFakeEvent, itemTypes, setPropValues } from '../EventTrackingContext/utils';
import { eventNames } from '../EventTrackingContext/constants';
import { EventTrackingContext } from '../EventTrackingContext';

export default function WorkpaperActionModal({ workpaperId, fileMenuAction, handleFileMenuAction }) {
  const {
    name,
    taxPeriod,
    isSaving,
    isLoadingNextName,
    title,
    taxPeriodComboData,
    errorMessage,
    closeActionModal,
    handleSave,
    handleName,
    handleTaxPeriod,
  } = useWorkpaperActionModal({
    workpaperId,
    handleFileMenuAction,
    fileMenuAction,
  });
  const { trackEvent } = useContext(EventTrackingContext);

  const saveDisable = isSaving || isLoadingNextName || !!errorMessage;
  const renderBody = () => {
    switch (fileMenuAction) {
      case SAVE_AS:
        return (
          <SaveAs
            errorMessage={errorMessage}
            handleName={handleName}
            handleTaxPeriod={handleTaxPeriod}
            name={name}
            taxPeriod={taxPeriod}
            taxPeriodComboData={taxPeriodComboData}
          />
        );
      case RENAME:
        return (
          <Rename
            errorMessage={errorMessage}
            handleName={handleName}
            handleTaxPeriod={handleTaxPeriod}
            name={name}
            taxPeriod={taxPeriod}
            taxPeriodComboData={taxPeriodComboData}
          />
        );
      default:
        break;
    }
  };

  return (
    <BTModal id="workbook-action" show={!!fileMenuAction} size="modal-lg" title={title} onCloseClick={closeActionModal}>
      <BTModal.Body id="workbook-action-body">
        {isLoadingNextName || isSaving ? <Spinner /> : renderBody()}
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton btStyle={isSaving ? 'primary' : 'default'} disabled={isSaving} onClick={closeActionModal}>
          Cancel
        </BTButton>
        <BTButton
          btStyle={saveDisable ? 'default' : 'primary'}
          disabled={saveDisable}
          hasSpinner={isSaving}
          onClick={e => {
            // e.target does not exist, use the fake event with the specified tag
            const fakeEvent = getFakeEvent(itemTypes.BUTTON, 'workpaper-save-as');

            // Track the event
            trackEvent(eventNames.interactiveClick, setPropValues(fakeEvent, eventNames.interactiveClick, null));

            // Handler
            handleSave(e);
          }}
        >
          Save
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
}
