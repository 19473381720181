import React, { useEffect, useState, useMemo } from 'react';
import { BTButton, BTInput, BTCheckbox } from '@btas/jasper';
import Spinner from '../../../../../_shared/Spinner';
import CloseButton from '../shared/CloseButton';
import { getTaxLawTopics } from './TopicsSelection/apis';

export default function TopicsSelection({ defaultSelectedItems, workpaperId, onTopicsSelected, onClose, onCancel }) {
  const [topics, setTopics] = useState(null);
  const [filter, setFilter] = useState('');
  const [checkboxItems, setCheckboxItems] = useState(() =>
    defaultSelectedItems.reduce((obj, topic) => {
      obj[topic] = true;
      return obj;
    }, {})
  );

  const visibleTopics = useMemo(() => {
    if (!topics) {
      return [];
    }

    if (!filter) {
      return topics;
    }

    return topics.filter(topic => topic.title.toUpperCase().indexOf(filter.toUpperCase()) > -1);
  }, [topics, filter]);

  const handleSelectTopics = () => {
    const selectedItems = topics.filter(topic => checkboxItems[topic.id]).map(topic => topic.id);
    onTopicsSelected(selectedItems);
  };

  const handleFilterChange = e => {
    setFilter(e.target.value);
  };

  const handleCheckboxChange = id => () => {
    setCheckboxItems(prev => ({ ...prev, [id]: !prev[id] }));
  };

  useEffect(() => {
    (async () => {
      const response = await getTaxLawTopics();
      setTopics(response);
    })();
  }, [workpaperId]);

  return (
    <>
      <div className="wkp-right-pane-header">
        <h2>Select Research Topics</h2>
        <CloseButton onClose={onClose} />
      </div>

      <div className="wkp-select-research-topics">
        {!topics && <Spinner />}
        {topics && (
          <>
            <div className="wkp-filter-input">
              <i className="fa fa-filter"></i>
              <BTInput placeholder="Filter topics" value={filter} onChange={handleFilterChange} />
            </div>
            <div className="wkp-research-topic-list">
              {visibleTopics.map(topic => (
                <div key={topic.id} className="checkbox">
                  <BTCheckbox
                    checked={checkboxItems[topic.id] === true}
                    label={topic.title}
                    onChange={handleCheckboxChange(topic.id)}
                  />
                </div>
              ))}
            </div>
            <div className="wkp-select-research-topics-footer">
              <BTButton onClick={onCancel}>Cancel</BTButton>
              <BTButton btStyle="primary" onClick={handleSelectTopics}>
                Select topics
              </BTButton>
            </div>
          </>
        )}
      </div>
    </>
  );
}
