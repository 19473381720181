import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import GC from './SpreadSheets';
import {
  EnviromentName,
  EnviromentUrl,
  spreadjsDesignerKey,
  spreadjsKey,
  spreadsheetServiceHostName,
} from './configs/params';
import * as serviceWorker from './serviceWorker';
import { SessionInstrumentation, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';
import './index.scss';
import App from './App';
import { getUser } from './components/_shared/auth';

GC.Spread.Sheets.LicenseKey = spreadjsKey;
GC.Spread.Sheets.Designer.LicenseKey = spreadjsDesignerKey;

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const user = getUser();

EnviromentName !== 'LOCAL' &&
  initializeFaro({
    url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/b5e9b3f716ec8eaed6d5b9185f75996f',
    app: {
      name: 'BTAX-WORKPAPERS',
      version: '1.0.0',
      environment: EnviromentUrl,
    },

    user: {
      id: user?.userId,
    },

    instrumentations: [
      // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
      ...getWebInstrumentations({
        // Optional, if you want to disable the console instrumentation
        captureConsole: false,
      }),

      new SessionInstrumentation(),

      // Initialization of the tracing package.
      // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
      new TracingInstrumentation({
        instrumentationOptions: {
          // Requests to these URLs have tracing headers attached.
          propagateTraceHeaderCorsUrls: [
            // eslint-disable-next-line no-template-curly-in-string
            new RegExp(`https://${spreadsheetServiceHostName}/*`),
            // eslint-disable-next-line no-template-curly-in-string
            `https://${spreadsheetServiceHostName}`,
          ],
        },
      }),
    ],
  });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
