import GC from '../../../../../SpreadSheets';

export const gcExpressionTypes = {
  number: GC.Spread.CalcEngine.ExpressionType.number,
  string: GC.Spread.CalcEngine.ExpressionType.string,
  reference: GC.Spread.CalcEngine.ExpressionType.reference,
  missingArgument: GC.Spread.CalcEngine.ExpressionType.missingArgument,
  function: GC.Spread.CalcEngine.ExpressionType.function,
  operator: GC.Spread.CalcEngine.ExpressionType.operator,
  parentheses: GC.Spread.CalcEngine.ExpressionType.parentheses,
};

/**
 * Executes a function suspending the paint and events from spreadjs. Performance improver.
 * @param {GC.Spread.Sheet.Workbook} spread The spread to suspend paint and events
 * @param {function} action The action to be executed
 */
export function executeWithSuspendedPaint(spread, action, suspendEvent = false) {
  try {
    spread.suspendPaint();

    if (suspendEvent) {
      spread.suspendEvent();
    }

    action();
  } finally {
    if (suspendEvent) {
      spread.resumeEvent();
    }

    spread.resumePaint();
  }
}

export function isCellReference(spread, value = '') {
  try {
    // if the value is a registered custom name AND the name refers to a cell, ISREF evaluates to true, so we need to check if the value is a custom name to discard it as a cell reference
    const isCustomName = !!spread.getCustomName(value);
    return (
      !isCustomName && GC.Spread.Sheets.CalcEngine.evaluateFormula(spread.getActiveSheet(), `ISREF(${value})`, 0, 0)
    );
  } catch (error) {
    return false;
  }
}

export function getValueFromCellReference(spread, reference = "'some sheet name'!AB12") {
  return GC.Spread.Sheets.CalcEngine.evaluateFormula(spread.getActiveSheet(), reference, 0, 0);
}

export const isCalcError = cellValue => cellValue instanceof GC.Spread.CalcEngine.CalcError;

const getArgumentsFromExpression = (expression, sheet) =>
  expression.arguments.map(arg => getValueFromExpression(arg, sheet));

function getValueFromExpression(expression, sheet) {
  const type = expression.type;
  let value;

  switch (type) {
    case gcExpressionTypes.number:
    case gcExpressionTypes.string:
      value = expression.value;
      break;
    // spread js returns "invalid formula" error if value is undefined
    case gcExpressionTypes.missingArgument:
      value = null;
      break;
    default:
      value = GC.Spread.Sheets.CalcEngine.expressionToFormula(sheet, expression, 0, 0);
  }

  return { value, type };
}

const formulaHelpers = {
  getArgumentValuesFromExpression(expression, sheet) {
    return expression.arguments.map(arg => {
      switch (arg.type) {
        case gcExpressionTypes.number:
        case gcExpressionTypes.string:
          return arg.value;
        case gcExpressionTypes.reference: {
          const formula = GC.Spread.Sheets.CalcEngine.expressionToFormula(sheet, arg, 0, 0);
          const value = GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, formula, 0, 0);
          return value;
        }
        // spread js returns "invalid formula" error if value is undefined
        case gcExpressionTypes.missingArgument:
          return null;
        default:
          const argFormula = GC.Spread.Sheets.CalcEngine.expressionToFormula(sheet, arg, 0, 0);

          return GC.Spread.Sheets.CalcEngine.evaluateFormula(sheet, argFormula, 0, 0);
      }
    });
  },
  getArgumentsFromExpression,
  getValueFromExpression,
  getActiveCellFormula(spread) {
    const sheet = spread.getActiveSheet();
    const activeCellFormula = GC.Spread.Sheets.CalcEngine.rangeToFormula(
      sheet.getRange(sheet.getActiveRowIndex(), sheet.getActiveColumnIndex())
    );
    return `'${sheet.name()}'!${activeCellFormula}`;
  },
  getWorkpaperTaxPeriod(spread) {
    const activeSheet = spread.getActiveSheet();
    const taxPeriod = GC.Spread.Sheets.CalcEngine.evaluateFormula(activeSheet, 'TAX_PERIOD()', 0, 0);

    return taxPeriod;
  },
};

export { formulaHelpers };
