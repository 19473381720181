import { BTAlert } from '@btas/jasper';
import React from 'react';

export default function DeleteLockDataFlowErrorModal({
  selectedLockDataflows,
  showDeleteLockDataErrorModal,
  setShowDeleteLockDataFlowErrorModal,
}) {
  const errorMessage = `Failed to delete ${selectedLockDataflows.length} data transformation workflow(s) because they are currently locked as final:`;

  const listOfDataFlows = selectedLockDataflows
    .map(workflow => `'${workflow.name}' - ${workflow.taxPeriod} - Locked as Final`)
    .sort();

  return (
    <BTAlert
      appear
      dismissible
      fixed
      btStyle="danger"
      visible={showDeleteLockDataErrorModal}
      onDismiss={() => setShowDeleteLockDataFlowErrorModal(false)}
    >
      {errorMessage}
      <ul>
        {listOfDataFlows.map(item => (
          <li>{item}</li>
        ))}
      </ul>
    </BTAlert>
  );
}
