import { ElementTypeMap } from '../elementType/types';

export function restoreElements(elements = {}, graph, dataFlowActions) {
  return Object.keys(elements).reduce((res, elementId) => {
    const element = elements[elementId];
    const elementType = ElementTypeMap[element.type];
    const cell = elementType.restoreInstance(element);
    cell.addTo(graph);
    const typeData = elementType.extractTypeData(element);
    dataFlowActions.addElement(cell, { name: element.name, ...typeData }, true);
    return { ...res, [elementId]: { cell, element } };
  }, {});
}
