import { LookupElementInspector, LookupTableInput, LookupTableOutput } from '../../LookupElementInspector';
import { LOOKUP_COLOR } from '../../shared/colors';
import iconImage from '../icons/lookup_block_icon.svg';
import hintImage from '../icons/lookup_hint_img.svg';
import { TransformationElementType } from '../TransformationElementType';
import { computeKeyFieldAutoMappings } from './shared/utils';
import { getOriginalName } from '../../../shared/utils/FieldHashUtils';
import { LOOKUP } from '../types/shared/typesConstants';

export class LookupElementType extends TransformationElementType {
  static TYPE = LOOKUP;

  static HELP_TEXT = `The lookup block searches for corresponding data between data sets and appends values based on matching results. The lookup block can be used for tasks like assigning tax account numbers to your trial balance or adding exchange rates for currency translations.<img src=${hintImage} alt="Lookup hint" />`;

  constructor() {
    super(LookupElementType.TYPE, 'Lookup', LOOKUP_COLOR, iconImage, LookupElementType.HELP_TEXT, true);
  }

  get initialData() {
    return {
      name: this.label,
      type: this.type,
      lookupTableInput: LookupTableInput.B,
      lookupTableOutput: LookupTableOutput.T,
      keyFields: [{ left: null, right: null }],
      renamed: { [LookupTableInput.A]: {}, [LookupTableInput.B]: {} },
      fieldsByPort: { T: [], F: [] },
    };
  }

  get maxCount() {
    return -1;
  }

  get inPorts() {
    return [LookupTableInput.A, LookupTableInput.B];
  }

  get outPorts() {
    return [LookupTableOutput.T, LookupTableOutput.F];
  }

  get inspectorComponent() {
    return LookupElementInspector;
  }

  getOrderedSelectItemsFields(sourceDataInput, sourceDataFields, lookupTableFields) {
    return {
      leftSelectItems: sourceDataInput === 'A' ? sourceDataFields : lookupTableFields,
      rightSelectItems: sourceDataInput === 'A' ? lookupTableFields : sourceDataFields,
    };
  }

  applySourceElements(elementData, sourceElements) {
    const lookupTableInput = elementData.lookupTableInput ?? this.initialData.lookupTableInput;
    const lookupTableOutput = elementData.lookupTableOutput ?? this.initialData.lookupTableOutput;
    const sourceDataInput = lookupTableInput === 'A' ? 'B' : 'A';
    const lookupTableFields = sourceElements[lookupTableInput]?.elementData?.fields ?? [];
    const sourceDataFields = sourceElements[sourceDataInput]?.elementData?.fields ?? [];
    const { leftSelectItems, rightSelectItems } = this.getOrderedSelectItemsFields(
      sourceDataInput,
      sourceDataFields,
      lookupTableFields
    );

    let keyFields = elementData.keyFields;
    //compute auto mapping if user has not defined any keyFields
    if (!keyFields[0].left?.name && !keyFields[0].right?.name) {
      keyFields = computeKeyFieldAutoMappings(elementData.keyFields, leftSelectItems, rightSelectItems);
    } else {
      //use most recent keyfield data
      const newKeyFields = keyFields.reduce((acc, { left, right }) => {
        const newLeft = (left && leftSelectItems.find(item => item.name === left.name)) || null;
        const newRight = (right && rightSelectItems.find(item => item.name === right.name)) || null;
        if (newLeft && newRight) {
          acc.push({ left: newLeft, right: newRight });
        }
        return acc;
      }, []);

      keyFields = newKeyFields;
    }

    const lookupSide = lookupTableInput === 'A' ? 'left' : 'right';
    const { renamed, outFields } = this.reconcileRenamed(
      lookupTableFields,
      sourceDataFields,
      lookupSide,
      sourceDataInput,
      keyFields
    );

    const fieldsToPortT =
      lookupTableFields.length === 0 || sourceDataFields.length === 0 ? elementData?.fieldsByPort.T : outFields;
    const fieldsByPort = { T: fieldsToPortT, F: sourceDataFields };

    return {
      ...elementData,
      fields: fieldsToPortT,
      keyFields: keyFields.length > 0 ? keyFields : this.initialData.keyFields,
      lookupTableInput,
      lookupTableOutput,
      renamed,
      sourceDataFields,
      lookupTableFields,
      fieldsByPort,
    };
  }

  extractTypeData(elementData) {
    return {
      ...super.extractTypeData(elementData),
      lookupTableInput: elementData.lookupTableInput,
      keyFields: elementData.keyFields,
      renamed: elementData.renamed,
      lookupTableOutput: elementData.lookupTableOutput,
      outputPorts: this.outPorts.length,
      sourceDataFields: elementData.sourceDataFields,
      lookupTableFields: elementData.lookupTableFields,
      fieldsByPort: elementData.fieldsByPort,
    };
  }

  reconcileRenamed(lookupTableFields, sourceDataFields, lookupTableInput, sourceDataInput, keyFields) {
    const sourceDataColumnNames = sourceDataFields.map(getOriginalName);
    const lookupDataColumnNames = lookupTableFields.map(getOriginalName);

    const keyFieldColumnNames = keyFields.map(keyField =>
      keyField[lookupTableInput] ? getOriginalName(keyField[lookupTableInput]) : ''
    );

    const lookupTableRenamed = {};
    const outFields = [...sourceDataFields];

    for (const lookupField of lookupTableFields) {
      // Rename duplicate fields unless these are key fields
      if (keyFieldColumnNames.includes(getOriginalName(lookupField))) {
        continue;
      }

      // Add a suffix to duplicate field names
      let newName = getOriginalName(lookupField);

      if (sourceDataColumnNames.includes(newName)) {
        newName = this.findFieldName(newName, lookupDataColumnNames, sourceDataColumnNames);
        lookupTableRenamed[lookupField.name] = newName;

        outFields.push({ ...lookupField, original_name: undefined, name: newName });
      } else {
        outFields.push({ ...lookupField, [lookupField.original_name ? 'original_name' : 'name']: newName });
      }
    }

    return { renamed: { [lookupTableInput]: lookupTableRenamed, [sourceDataInput]: {} }, outFields };
  }

  findFieldName(name, lookupFields, sourceDataFields, depth = 0) {
    if (depth === 0) {
      if (sourceDataFields.includes(name)) {
        return this.findFieldName(name, lookupFields, sourceDataFields, 1);
      } else {
        return name;
      }
    }

    const newName = `${name}_${depth}`;

    if (lookupFields.includes(newName) || sourceDataFields.includes(newName)) {
      return this.findFieldName(name, lookupFields, sourceDataFields, depth + 1);
    } else {
      return newName;
    }
  }
}
