import React from 'react';

export default function SideBarPanelItem({
  isActive,
  name,
  buttonId,
  buttonLabel,
  buttonTitle,
  onSideBarIconClick,
  children,
}) {
  return (
    <li className={isActive ? 'nav-item active' : 'nav-item'}>
      <button
        aria-label={buttonTitle}
        className="nav-link btn btn-link"
        id={buttonId}
        title={buttonTitle}
        type="button"
        onClick={onSideBarIconClick(name)}
      >
        {children}
        <span className="sr-only">{buttonLabel}</span>
      </button>
    </li>
  );
}
