import React from 'react';
import './ValidationError/styles.scss';

const ValidationErrors = ({ error }) => {
  const { message, details } = error;

  return (
    <div className="wkp-error-container">
      <p>{message}</p>

      {details?.length && (
        <ul>
          {details.map((error, index) => (
            <li key={index}>{error.message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ValidationErrors;
