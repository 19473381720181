import * as fetch from '../fetch';
import { dataFlowServiceHostName } from '../../../configs/params';

export async function getPublishedDataFlows() {
  const response = await fetch.get(`${dataFlowServiceHostName}/api/v1/data-flows/published`);

  const data = await response.json();
  if (!response.ok) {
    throw data.error;
  }

  return data;
}
