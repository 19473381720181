import { BTButton } from '@btas/jasper';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Spinner from '../../../../../_shared/Spinner';
import CloseButton from '../shared/CloseButton';
import ConnectionForm from './ConnectionForm';
import './EditDataConnection/styles.scss';
import { getDataFlowByOutputId } from './apis';
import DataConnectionError from './DataConnectionError';
import { eventNames } from '../../../../../_shared/EventTrackingContext/constants';
import { EventTrackingContext } from '../../../../../_shared/EventTrackingContext';
import { setPropValues } from '../../../../../_shared/EventTrackingContext/utils';

export default function EditDataConnection({
  cellDataSourceConnection,
  dataFlowGroups,
  filters,
  formData,
  readOnly,
  saveButtonDisabled,
  setCellFormData,
  workpaperId,
  onClose,
  onDeleteConnection,
  onFieldChange,
  onSaveConnection,
}) {
  const [editMode, setEditMode] = useState(false);
  const [dataConnectionStatus, setDataConnectionStatus] = useState(null);

  const history = useHistory();
  const { trackEvent } = useContext(EventTrackingContext);

  useEffect(() => {
    setEditMode(false);
  }, [cellDataSourceConnection]);

  useEffect(() => {
    if (!formData?.output) {
      return;
    }
    (async () => {
      try {
        const data = await getDataFlowByOutputId(formData.output);
        setDataConnectionStatus(data);
      } catch (error) {
        console.error('Data Flow connection error:', error);
      }
    })();
  }, [formData.output]);

  const handleSave = async () => {
    try {
      await onSaveConnection();
      setEditMode(false);
    } catch (error) {
      const fieldErrors = error
        .filter(event => event.target)
        .reduce((prev, curr) => ({ ...prev, [curr.target]: curr.message }), {});
      const formError = error.filter(event => !event.target);

      onFieldChange({ ...formData, fieldErrors, formError });
    }
  };

  const handleCancel = () => {
    setEditMode(false);
    setCellFormData();
  };

  const isLoading = isEmpty(formData.fieldErrors) && (!dataFlowGroups || (formData.output && !filters));

  return (
    <div className="wkp-right-pane wkp-edit-connection-panel">
      <div className="wkp-right-pane-header">
        <h2>{editMode ? 'Edit Data Connection' : 'Data Connection Details'}</h2>
        <CloseButton onClose={onClose} />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {dataConnectionStatus?.connectionError ? (
            <DataConnectionError dataConnectionStatus={dataConnectionStatus} />
          ) : (
            <ConnectionForm
              dataFlowGroups={dataFlowGroups}
              filters={filters}
              formData={formData}
              isReadOnly={!editMode}
              updateParent={onFieldChange}
            />
          )}

          {editMode ? (
            <Row className="wkp-edit-footer-buttons">
              <Col xs={6}>
                <BTButton onClick={handleCancel}>Cancel</BTButton>
              </Col>
              <Col xs={6}>
                <BTButton
                  btStyle="primary"
                  disabled={saveButtonDisabled}
                  id="wkp-connection-save-btn"
                  onClick={e => {
                    handleSave();
                    // Tracking event manually due to conditional rendering
                    trackEvent(eventNames.interactiveClick, setPropValues(e, eventNames.interactiveClick, null));
                  }}
                >
                  Save connection
                </BTButton>
              </Col>
            </Row>
          ) : (
            <div className="wkp-connection-action-buttons">
              {!dataConnectionStatus?.connectionError && (
                <BTButton
                  btStyle="link"
                  disabled={readOnly}
                  id="wkp-connection-edit-btn"
                  onClick={e => {
                    setEditMode(true);
                    // Tracking event manually due to conditional rendering
                    trackEvent(eventNames.interactiveClick, setPropValues(e, eventNames.interactiveClick, null));
                  }}
                >
                  <span>Edit connection</span>
                </BTButton>
              )}

              <BTButton
                btStyle="link"
                disabled={readOnly}
                id="wkp-connection-delete-btn"
                onClick={e => {
                  onDeleteConnection();
                  // Tracking event manually due to conditional rendering
                  trackEvent(eventNames.interactiveClick, setPropValues(e, eventNames.interactiveClick, null));
                }}
              >
                <span>Delete connection</span>
              </BTButton>

              {!dataConnectionStatus?.nullOutputId && (
                <BTButton
                  btStyle="link"
                  id="wkp-connection-go-to-data-transformation-btn"
                  onClick={e => {
                    // Tracking event manually due to conditional rendering
                    trackEvent(eventNames.interactiveClick, setPropValues(e, eventNames.interactiveClick, null));
                    const dataFlowId = formData.dataFlow?.id ? formData.dataFlow.id : dataConnectionStatus.dataFlowId;
                    history.push(
                      `/data-flows/${dataFlowId}/editor?activeElement=${formData.output}&sourceWorkpaper=${workpaperId}`
                    );
                  }}
                >
                  <span>Go to data transformation workflow</span>
                </BTButton>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
