import React from 'react';
import './AiGenerated/styles.scss';
import AiIcon from './AiGenerated/AI_Icon_White.svg';
import AiIconDisabled from './AiGenerated/AI_Icon_Dark_Mode_Disabled.svg';

function AiGenerated({ disabled, uniqueId }) {
  return (
    <a
      className={`ai-generated-button ${disabled && 'disabled'}`}
      href="http://www.bloombergindustry.com/ai-notice"
      id={uniqueId}
      rel="noreferrer"
      tabIndex={0}
      target="_blank"
    >
      <img alt="Ai icon" className="ai-generated-icon" src={disabled ? AiIconDisabled : AiIcon} />
      Generated
    </a>
  );
}

export default AiGenerated;
