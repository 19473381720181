import React from 'react';
import { BTEmptyState } from '@btas/jasper';
import CloseButton from '../shared/CloseButton';
import taxLawResearchEmptyState from './EmptyState/tax_law_research_empty_state.svg';

export default function EmptyState({ isLocked, onSelectTopics, onClose }) {
  return (
    <>
      <div className="wkp-right-pane-header">
        <h2>Tax Law Research</h2>
        <CloseButton onClose={onClose} />
      </div>

      <BTEmptyState
        buttonText={isLocked ? null : 'Select topics'}
        className="wkp-right-pane-empty-state"
        icon={<img alt="No topics have been selected for this workpaper." src={taxLawResearchEmptyState} />}
        message="No topics have been selected for this workpaper."
        onButtonClick={onSelectTopics}
      />
    </>
  );
}
