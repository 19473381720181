import { dataFlowServiceHostName } from '../../../../configs/params';
import { GENERAL_TAX_ERROR } from '../../../../constants/messages';
import CustomLogger from '../../../_shared/Logger/CustomLogger';
import * as fetch from '../../../_shared/fetch';

export async function generateAIExpression(fields, blockType, prompt) {
  const url = `${dataFlowServiceHostName}/api/v1/data-flows/formulas/ai-generate`;

  const response = await fetch.post(url, {
    body: JSON.stringify({
      fields,
      blockType,
      prompt,
    }),
  });

  if (response.ok) {
    const data = await response.json();

    return data.message;
  } else {
    CustomLogger.pushLog(CustomLogger.operations.GENERATE_AI_EXPRESSION, {
      error: `Error while generating AI expression for ${blockType} block`,
      message: 'Error while generating AI expression',
      body: JSON.stringify({
        fields,
        blockType,
        prompt,
      }),
    });

    if (response.status === 400) {
      const data = await response.json();

      throw new Error(data.message);
    }

    throw new Error(GENERAL_TAX_ERROR);
  }
}
