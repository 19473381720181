import { BTButton, BTForm, BTInput, BTModal } from '@btas/jasper';
import React, { useContext, useEffect, useState } from 'react';

import SheetList from '../../../_shared/SheetList';
import { getWorkbookInfo } from './apis';
import isValidCellFormat from './utils';
import './styles.scss';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { STEP_START } from '../InputElementInspector/useImportFile';

const FileDataDialog = ({ elementData, dataFlowActions, dataFlowState, setGeneralError }) => {
  const { setWKPFileImportProperties } = dataFlowActions;
  const { workingElement, wkpFileImportProperties, taxPeriod: dataFlowTaxPeriod } = dataFlowState;
  const [sheetData, setSheetData] = useState({ sheetName: '', dataStartAt: '', numHeaders: '' });
  const [validationError, setValidationError] = useState({});
  const { fileImport } = useContext(DataFlowEditorContext);
  const { importFileState, setImportFileState, updateImportFileState } = fileImport;

  useEffect(() => {
    async function fetchSheets() {
      let sheetOptions = [];
      let sourceData;

      if (!wkpFileImportProperties?.isCsvFile) {
        setWKPFileImportProperties({ ...wkpFileImportProperties, isLoadingDataDialog: true });

        if (elementData?.uploadedFile) {
          const { name, location, bucket } = elementData?.uploadedFile;
          sourceData = {
            name,
            path: location,
            bucket,
          };
        }
        try {
          const sheetsResult = await getWorkbookInfo(sourceData);
          if (sheetsResult?.error) {
            setGeneralError(sheetsResult.error);
            setWKPFileImportProperties({
              popupDataDialog: false,
              isLoadingDataDialog: false,
              isSavingDataDialog: false,
              sheets: [],
            });
            return;
          }
          sheetOptions = sheetsResult.sheetNames.map(sheet => {
            return { value: sheet.name, label: sheet.name };
          });
          const defaultSheet = sheetOptions.length > 0 ? sheetOptions[0].value : '';
          setSheetData({ sheetName: defaultSheet, dataStartAt: '', numHeaders: '' });
          setWKPFileImportProperties({ ...wkpFileImportProperties, isLoadingDataDialog: false, sheets: sheetOptions });
        } catch (error) {
          setGeneralError(error.message);
          setWKPFileImportProperties({
            popupDataDialog: false,
            isLoadingDataDialog: false,
            isSavingDataDialog: false,
            sheets: [],
          });
        }
      }
    }
    if (wkpFileImportProperties?.popupDataDialog) {
      setGeneralError(null);
      fetchSheets();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wkpFileImportProperties?.popupDataDialog]);

  useEffect(() => {
    if (importFileState[workingElement.id]?.error) {
      setGeneralError(importFileState[workingElement.id]?.error);

      const newImportFileState = importFileState;
      delete newImportFileState[workingElement.id];
      setImportFileState(newImportFileState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importFileState[workingElement.id]?.error]);

  const onClose = () => {
    setValidationError({});
    setWKPFileImportProperties({
      popupDataDialog: false,
      isLoadingDataDialog: false,
      isSavingDataDialog: false,
    });
  };

  const onSheetComboChange = e => {
    const { value } = e;
    setSheetData(prev => ({ ...prev, sheetName: value }));
  };

  const onSheetValueChange = e => {
    const { name, value } = e.target;
    setSheetData(prev => ({ ...prev, [name]: value.toString().toUpperCase() }));
  };

  const isValidHeaderInput = str => {
    return onlyNumberVals(str);
  };

  const onlyNumberVals = str => {
    const pattern = /^[0-9]+$/;
    return str.match(pattern);
  };

  const onFileSubmit = async () => {
    setValidationError({});
    setGeneralError(null);

    if (!sheetData?.sheetName && wkpFileImportProperties?.sheets?.length > 1 && !wkpFileImportProperties.isCsvFile) {
      setValidationError({
        sheetName: 'Sheet is required',
      });
      return;
    }

    if (sheetData.dataStartAt && !isValidCellFormat(sheetData.dataStartAt)) {
      setValidationError({
        dataStartAt:
          'Invalid cell reference. Valid cell references start with one or more letters and are followed by one or more numbers.',
      });
      return;
    }

    if (sheetData.numHeaders && !isValidHeaderInput(sheetData.numHeaders)) {
      setValidationError({
        numHeaders: 'Number of column header rows is invalid.',
      });
      return;
    }
    updateImportFileState(workingElement.id, {
      sheetData: sheetData,
      currentStep: STEP_START,
    });

    onClose();
  };

  return (
    <BTModal
      show={wkpFileImportProperties?.popupDataDialog}
      size="modal-lg"
      title="Select Data Within File"
      onCloseClick={onClose}
    >
      <BTModal.Body>
        <BTForm>
          <BTForm.FormGroup label="File name">
            <BTInput disabled name="fileName" value={wkpFileImportProperties?.fileName} />
          </BTForm.FormGroup>
          <BTForm.FormGroup label="Tax period">
            <BTInput
              disabled
              name="taxPeriod"
              value={wkpFileImportProperties?.fileDialogTaxPeriod || dataFlowTaxPeriod}
            />
          </BTForm.FormGroup>
          {!wkpFileImportProperties?.isCsvFile && (
            <SheetList
              hasError={!!validationError.sheetName}
              isRequired={true}
              loading={wkpFileImportProperties?.isLoadingDataDialog}
              sheetData={sheetData}
              sheets={wkpFileImportProperties?.sheets}
              onChange={onSheetComboChange}
            />
          )}
          <BTForm.FormGroup
            errorText={validationError.dataStartAt}
            hasError={!!validationError.dataStartAt}
            htmlFor="dataStartAt"
            label="Column headers start at"
          >
            <BTInput
              disabled={wkpFileImportProperties?.isLoadingDataDialog}
              id="dataStartAt"
              name="dataStartAt"
              placeholder="A1"
              value={sheetData.dataStartAt}
              onChange={onSheetValueChange}
            />
          </BTForm.FormGroup>
          <BTForm.FormGroup
            errorText={validationError.numHeaders}
            hasError={!!validationError.numHeaders}
            htmlFor="numHeaders"
            label="Number of column header rows"
          >
            <BTInput
              disabled={wkpFileImportProperties?.isLoadingDataDialog}
              id="numHeaders"
              name="numHeaders"
              placeholder="1"
              value={sheetData.numHeaders}
              onChange={onSheetValueChange}
            />
          </BTForm.FormGroup>
        </BTForm>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onClose}>Cancel</BTButton>
        <BTButton
          btStyle="primary"
          disabled={wkpFileImportProperties?.isLoadingDataDialog || wkpFileImportProperties?.isSavingDataDialog}
          hasSpinner={wkpFileImportProperties?.isSavingDataDialog}
          id="df-save-file-settings"
          onClick={onFileSubmit}
        >
          Save
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

export default FileDataDialog;
