import React from 'react';
import { Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { UserPreferencesProvider } from '../components/_shared/UserPreferencesContext';
import { EditorContextProvider } from '../components/editor/EditorPage/EditorContext';

export default function EditorPageRoute({ component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <UserPreferencesProvider>
            <QueryClientProvider client={new QueryClient()}>
              <EditorContextProvider>{Component ? <Component {...props} /> : render()}</EditorContextProvider>
            </QueryClientProvider>
          </UserPreferencesProvider>
        );
      }}
    />
  );
}
