import React from 'react';
import './TileButton/styles.scss';

const TileButton = ({ icon, name, children, className = '', ...props }) => (
  <button className={`wkp-tile-button ${className}`} {...props}>
    {icon && <img alt={`${name} icon`} src={icon} />}

    {children}
  </button>
);

export default TileButton;
