import React from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import './ButtonsSection/styles.scss';

const ButtonsSection = ({ children, className = '' }) => (
  <Row>
    <Col>
      <div className={`wkp-buttons-section ${className}`}>{children}</div>
    </Col>
  </Row>
);

export default ButtonsSection;
