import GC from '../../../../../../../SpreadSheets';

class CustomCommand {
  constructor(canUndo, executor, undoExecutor) {
    this.canUndo = canUndo;
    this.execute = function (spread, options, isUndo) {
      GC.Spread.Sheets.Commands.startTransaction(spread, options);
      spread.suspendPaint();
      spread.suspendCalcService();
      if (isUndo) {
        undoExecutor(spread, options);
      } else {
        executor(spread, options);
      }
      spread.resumeCalcService(false);
      spread.resumePaint();
      GC.Spread.Sheets.Commands.endTransaction(spread, options);
      return true;
    };
  }
}

export const customUndo = undoAction =>
  new CustomCommand(false, spread => {
    try {
      undoAction(spread);
    } catch (error) {
      console.log(error);
    }
  });

export const initShortcutAboutUndo = (commandManager, { undoAction }) => {
  const { z } = GC.Spread.Commands.Key;

  commandManager.register('customUndo', customUndo(undoAction), z, true, false, false, false);
};
