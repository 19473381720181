import { removeSourceFileData, removeSourceFilePermanently, validatePermanentDelete } from './apis';

export async function removeSourceFile(sourceFileId, dataFlowId, inputId) {
  try {
    return await removeSourceFileData(sourceFileId, dataFlowId, inputId);
  } catch (error) {
    const mappedError =
      error && error.details ? error.details : [{ code: 'SERVER_ERROR', message: 'Error Removing Source File Data' }];
    throw mappedError;
  }
}

export async function validateIsPermanentDelete(dataFlowId, inputId) {
  try {
    return await validatePermanentDelete(dataFlowId, inputId);
  } catch (error) {
    const mappedError =
      error && error.details ? error.details : [{ code: 'SERVER_ERROR', message: 'Error Removing Source File Data' }];
    throw mappedError;
  }
}

export async function removeUplodFilePermanently(deleteInfos, systemCode) {
  try {
    return await removeSourceFilePermanently(deleteInfos, systemCode);
  } catch (error) {
    const mappedError =
      error && error.details ? error.details : [{ code: 'SERVER_ERROR', message: 'Error Removing Source File Data' }];
    throw mappedError;
  }
}
