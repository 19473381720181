import React from 'react';
import { workpapersReleaseVersion } from '../../../configs/params';
import './Footer/styles.scss';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div id="rootFooter">
      <footer className="bbna-footer container-fluid">
        <div className="pull-left">
          Copyright © {currentYear}&nbsp;
          <a href="https://pro.bloombergtax.com/" rel="noopener noreferrer" target="_blank">
            Bloomberg Tax
          </a>
          . All Rights Reserved.
        </div>
        <ul className="list-inline pull-left">
          <li>
            <a href="https://www.bloombergindustry.com/about-us/" rel="noopener noreferrer" target="_blank">
              About Us
            </a>
          </li>
          <li>
            <a href="mailto:appsupport@bloombergtax.com" rel="noopener noreferrer" target="_blank">
              Customer Support
            </a>
          </li>
          <li>
            <a href="https://www.bloombergindustry.com/privacy-policy/" rel="noopener noreferrer" target="_blank">
              Privacy Policy
            </a>
          </li>
          <li>{workpapersReleaseVersion}</li>
        </ul>
        <p title="Bloomberg Tax products are not intended for the upload or storage of individual taxpayer PII. Please continue to check back for product updates. For more information, see our product descriptions and privacy policy.">
          Bloomberg Tax products are not intended for the upload or storage of individual taxpayer{' '}
          <abbr title="Personally Identifiable Information">PII</abbr>. Please continue to check back for product
          updates. For more information, see our product descriptions and privacy policy.
        </p>
      </footer>
    </div>
  );
}
