import React, { useState } from 'react';
import { BTButton, BTForm, BTTextarea, BTModal } from '@btas/jasper';
import { generalErrorMessage } from './../messages.js';
import './FeedbackForm/styles.scss';
import './FeedbackForm/api';
import { sendFeedback } from './FeedbackForm/api';
import GeneralErrorMessage from '../GeneralErrorMessage.jsx';

const FeedbackForm = ({ onClose, onSuccess }) => {
  const DEFAULT_SUCCESS_MESSAGE = 'Thank you for your feedback';
  const FEEDBACK_LABEL = "Have a suggestion? Found a bug? Let us know and we'll take a look.";
  const MAXIMUM_ALLOWED_CHARACTERS = '5000';
  const [error, setError] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState('');

  const onSubmit = async () => {
    setIsSaving(true);
    try {
      await sendFeedback(message);
      onSuccess(DEFAULT_SUCCESS_MESSAGE);
    } catch {
      setError(generalErrorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  const onMessageChange = e => setMessage(e.target.value);

  return (
    <BTModal show={true} size="modal-lg" title="Share Your Feedback" onCloseClick={onClose}>
      <GeneralErrorMessage dismissible={true} visible={!!error} />
      <BTModal.Body className="feedbackForm">
        <BTForm>
          <BTForm.FormGroup label={FEEDBACK_LABEL}>
            <BTTextarea
              className="feedback-bbui-text-area"
              maxLength={MAXIMUM_ALLOWED_CHARACTERS}
              placeholder="Describe your idea or issue..."
              rows="6"
              onChange={onMessageChange}
            />
          </BTForm.FormGroup>
        </BTForm>
      </BTModal.Body>
      <BTModal.Footer>
        <BTButton disabled={isSaving} onClick={onClose}>
          Cancel
        </BTButton>
        <BTButton btStyle="primary" disabled={isSaving || !message.length} hasSpinner={isSaving} onClick={onSubmit}>
          Send
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};

export default FeedbackForm;
