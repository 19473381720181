import React, { useState } from 'react';
import { BTModal, BTButton } from '@btas/jasper';
import { deleteWorkpaperSignOff } from '../SignOffs/apis';

const DeleteConfirmationModal = ({ selectedId, onCloseDialog, refreshList }) => {
  const [isSaving, setIsSaving] = useState(false);

  const onDelete = async () => {
    setIsSaving(true);
    await deleteWorkpaperSignOff(selectedId);
    setIsSaving(false);
    refreshList();
  };

  return (
    <BTModal btStyle="warning" show={Boolean(selectedId)} title="Delete Sign-Off" onCloseClick={onCloseDialog}>
      <BTModal.Body>The selected sign-off will be permanently deleted. Are you sure?</BTModal.Body>
      <BTModal.Footer>
        <BTButton onClick={onCloseDialog}>Cancel</BTButton>
        <BTButton autoFocus btStyle="primary" disabled={isSaving} hasSpinner={isSaving} onClick={onDelete}>
          Delete
        </BTButton>
      </BTModal.Footer>
    </BTModal>
  );
};
export default DeleteConfirmationModal;
