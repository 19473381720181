import React, { useState, useMemo, useCallback, useContext, useRef, useEffect } from 'react';
import './SpreadView/gc.spread.sheets.bna.10.1.0.scss';
import './SpreadView/btas-spreadjs-designer-styles.css';
import './SpreadView/btas-spreadjs-icons-styles.css';
import GC from './SpreadView/SpreadSheets';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import usePreviewRender from './usePreviewRender';
import { objToHeadersWithoutRecords } from './utils';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';
import { isFeatureFlagEnabled } from '../../../../utils/featureFlags';
import { WKP_DF_PREVIEW_PAGINATION } from '../../../../constants/featureFlags';

export default function SpreadView({
  records,
  expand,
  previewRecordsCount,
  setPreviewRecordsCount,
  startIndex,
  endIndex,
}) {
  const { dataFlowState, previewState } = useContext(DataFlowEditorContext);
  const previewRunId = useMemo(() => previewState?.previewRunId, [previewState?.previewRunId]);
  const { activeElement } = dataFlowState;
  const activeElementId = useMemo(() => dataFlowState?.activeElement?.id, [dataFlowState?.activeElement?.id]);
  const previewPaginationEnabled = isFeatureFlagEnabled(WKP_DF_PREVIEW_PAGINATION);

  const previewFields = useMemo(() => {
    if (previewPaginationEnabled) {
      const previewFieldsAll = activeElement?.elementData?.fields;
      return previewFieldsAll.slice(startIndex, endIndex);
    }
    return activeElement?.elementData?.fields;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeElement?.elementData?.fields, records]);
  //TECH DEBT: For now only returns results from first output port
  const port = useMemo(
    () => (activeElement?.elementType?.outPorts ? activeElement.elementType.outPorts[0] : 'out'),
    [activeElement?.elementType?.outPorts]
  );

  const [showData, getOutputRecords] = usePreviewRender();
  const [filterOptions, setFilterOptions] = useState({
    active: false,
    filterItems: {},
    sortItem: { column: null, asc: null, colIndex: null },
  });

  const [dataParams, setDataParams] = useState({});

  const previewRecords = useMemo(() => records, [records]);
  const designer = useRef(null);
  const filterOptionsRef = useRef(null);
  const [workbook, setWorkbook] = useState(null);
  const canEditWorkflow = useCanEditWorkflow();

  const previewHeight = useMemo(() => {
    if (expand === 'maximize') {
      return '75vh';
    } else if (expand === 'restore') {
      return '200px';
    } else {
      return '0vh';
    }
  }, [expand]);

  const hostStyle = {
    width: `calc(100vw - ${canEditWorkflow ? '635' : '541'}px)`,
    height: previewHeight,
  };

  useEffect(() => {
    async function getData() {
      const { previewFields, previewRunId, activeElementId, port, sortedColumn, sortDirection, filterItems } =
        dataParams;

      if (activeElementId && port) {
        if (!filterOptions.active) {
          showData(designer, workbook, previewRecords, filterOptions, previewFields);
          return;
        }

        const resultOutputData = await getOutputRecords(
          previewFields,
          previewRunId,
          activeElementId,
          port,
          sortedColumn,
          sortDirection,
          filterItems
        );

        if (resultOutputData?.records) {
          setPreviewRecordsCount({
            count: resultOutputData?.records.length,
            isFiltered: !(Object.keys(filterItems).length === 0),
            isSorted: !!sortedColumn,
          });
        }

        if (!resultOutputData?.records.length) {
          resultOutputData.records = objToHeadersWithoutRecords(previewFields);
        }

        filterOptions.active = false;
        showData(designer, workbook, resultOutputData, filterOptions, previewFields);
      }
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataParams]);

  useEffect(() => {
    if (designer.current) {
      const workbook = designer.current;
      workbook.refresh();
    }
  }, [expand]);

  useEffect(() => {
    if (!workbook) {
      const workbook = new GC.Spread.Sheets.Workbook('df-designer', { sheetCount: 0 });
      // moved these binding to only bind once
      workbook.bind(GC.Spread.Sheets.Events.RangeSorting, function (event, args) {
        // get sorted data from the server and show the data.
        const previewTableColumns = Object.keys(records?.records[0]);
        const sortedColumn = previewTableColumns[args.col];

        const updatedFilters = { ...filterOptionsRef.current };

        updatedFilters.sortItem.column = sortedColumn;
        updatedFilters.sortItem.asc = args.ascending;
        updatedFilters.sortItem.colIndex = args.col;
        updatedFilters.active = true;

        filterOptionsRef.current = updatedFilters;
        setFilterOptions(updatedFilters);

        setDataParams({
          previewFields,
          previewRunId,
          activeElementId,
          port,
          sortedColumn,
          sortDirection: args.ascending,
          filterItems: updatedFilters.filterItems,
        });
      });

      workbook.bind(GC.Spread.Sheets.Events.RangeFiltering, function (event, args) {
        // get filtered data from the server and show the data.
        const previewTableColumns = Object.keys(records?.records[0]);
        const filteredColumn = previewTableColumns[args.col];

        let filterText = document.getElementById('bb-filter-txt');

        const updatedFilters = { ...filterOptionsRef.current };

        updatedFilters.filterItems[filteredColumn] = {
          value: filterText.value,
          column: args.col,
        };

        updatedFilters.active = true;

        filterOptionsRef.current = updatedFilters;
        setFilterOptions(updatedFilters);

        setDataParams({
          previewFields,
          previewRunId,
          activeElementId,
          port,
          sortedColumn: updatedFilters?.sortItem?.column,
          sortDirection: updatedFilters?.sortItem?.asc,
          filterItems: updatedFilters.filterItems,
        });
      });

      setWorkbook(workbook);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workbook]);

  const resetFilters = () => {
    setFilterOptions({
      active: false,
      filterItems: {},
      sortItem: { column: null, asc: null },
    });

    filterOptionsRef.current = {
      active: false,
      filterItems: {},
      sortItem: { column: null, asc: null },
    };
  };

  useEffect(() => {
    if (previewRecords?.records?.length === 1) {
      //if array is only one and values are null then the ui is only displaying headers when a file is removed
      const hasDataValues = Object.values(previewRecords.records[0]).find(item => item !== null);

      if (!hasDataValues && previewRecordsCount?.count > 0) {
        setPreviewRecordsCount({
          count: 0,
          isFiltered: false,
          isSorted: false,
        });

        resetFilters();
      }
    }

    if (
      previewRecords?.records?.length > 1 &&
      previewRecordsCount?.count === 0 &&
      !previewRecordsCount.isFiltered &&
      !previewRecordsCount.isSorted
    ) {
      setPreviewRecordsCount({
        count: previewRecords?.records?.length,
      });

      resetFilters();
    } else if (!previewRecordsCount.isFiltered && !previewRecordsCount.isSorted) {
      resetFilters();
    }
  }, [
    previewRecords,
    previewRecords.records,
    previewRecordsCount?.count,
    previewRecordsCount.isFiltered,
    previewRecordsCount.isSorted,
    setPreviewRecordsCount,
  ]);

  const designerRef = useCallback(
    node => {
      if (node !== null) {
        if (!workbook) {
          return;
        }

        if (!filterOptions.active) {
          showData(designer, workbook, previewRecords, filterOptions, previewFields);
        }
      }
    },
    [workbook, filterOptions, showData, previewRecords, previewFields]
  );

  const handleSheetTooltip = useCallback(() => {
    const toolTipDiv = document.querySelector('.gc-spread-toolTip');

    if (toolTipDiv) {
      toolTipDiv.remove();
    }
  }, []);

  useEffect(() => {
    if (expand === 'maximize') {
      const selector = canEditWorkflow ? '.wkp-preview-expand-no-palette' : '.wkp-preview-expand';
      document.querySelector(selector)?.addEventListener('mouseleave', handleSheetTooltip);
    } else if (expand === 'restore') {
      const selector = canEditWorkflow ? '.wkp-preview' : '.wkp-preview-no-palette';
      document.querySelector(selector)?.addEventListener('mouseleave', handleSheetTooltip);
    } else {
      const selector = canEditWorkflow ? '.wkp-preview-minimize' : '.wkp-preview-minimize-no-palette';
      document.querySelector(selector)?.addEventListener('mouseleave', handleSheetTooltip);
    }

    return () => {
      window.removeEventListener('mouseleave', handleSheetTooltip);
    };
  }, [handleSheetTooltip, expand, canEditWorkflow]);

  return (
    <div id="preview-designer">
      <div ref={designerRef} className="df-designer" id="df-designer" style={hostStyle}></div>
    </div>
  );
}
