import * as fetch from '../../../../../../_shared/fetch';
import { spreadsheetServiceHostName } from '../../../../../../../configs/params';

export async function getWorkpaperSignOffs(id) {
  const response = await fetch.get(`${spreadsheetServiceHostName}/spreadsheet/sign-offs/${id}`);
  const data = await response.json();

  if (!response.ok) {
    throw data.error;
  }

  return data;
}

export async function createWorkpaperSignOff(id) {
  await fetch.post(`${spreadsheetServiceHostName}/spreadsheet/sign-offs/`, {
    body: JSON.stringify({ id }),
  });

  return;
}

export async function deleteWorkpaperSignOff(id) {
  await fetch.remove(`${spreadsheetServiceHostName}/spreadsheet/sign-offs/${id}`);
}
