import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BTButton, BTModal } from '@btas/jasper';
import useQuery from '../../../_shared/useQuery';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { removePendingData } from './apis';
import { getWorkpaperMetadata } from './CloseDataFlow/apis';

const CloseDataFlow = () => {
  const history = useHistory();
  const query = useQuery();
  const { dataFlowState, dataFlowActions } = useContext(DataFlowEditorContext);
  const goTo = useRef();
  const [showConfirm, setShowConfirm] = useState(false);
  const [workpaperName, setWorkpaperName] = useState();

  const { isDirty } = dataFlowState;
  const sourceWorkpaper = query.get('sourceWorkpaper');
  const closeConfirm = useCallback(() => setShowConfirm(false), [setShowConfirm]);
  const openConfirm = useCallback(() => setShowConfirm(true), [setShowConfirm]);

  const goToDataFlow = useCallback(() => history.push('/data-flows/'), [history]);
  const goToWorkpaper = () => history.push(`/editor/${sourceWorkpaper}`);
  const handleGoToDataFlow = () => {
    goTo.current = goToDataFlow;

    closeDataFlow();
  };

  const handleGoToWorkpaper = () => {
    goTo.current = goToWorkpaper;

    closeDataFlow();
  };

  const closeDataFlow = useCallback(() => {
    if (!isDirty) {
      goTo.current();
    } else {
      openConfirm();
    }
  }, [isDirty, openConfirm]);

  const onConfirm = useCallback(async () => {
    await removePendingData(dataFlowState.id);
    dataFlowActions.setSaveStateDirty(false);

    // allow to reset browser navigation handler
    setTimeout(goTo.current, 0);
  }, [dataFlowState.id, dataFlowActions]);

  useEffect(() => {
    const fetchWorkpaperMetadata = async () => {
      try {
        const { name } = await getWorkpaperMetadata(sourceWorkpaper);

        setWorkpaperName(name);
      } catch (err) {
        // Ignore
      }
    };

    if (sourceWorkpaper) {
      fetchWorkpaperMetadata();
    }
  }, [sourceWorkpaper]);

  return (
    <>
      <BTButton btStyle="default" onClick={handleGoToDataFlow}>
        Close
      </BTButton>

      <BTButton
        btStyle="link"
        btType="previous"
        hidden={!(sourceWorkpaper && workpaperName)}
        onClick={handleGoToWorkpaper}
      >
        Return to '{workpaperName}'
      </BTButton>

      <BTModal show={showConfirm} title="Close Data Transformation Workflow" onCloseClick={closeConfirm}>
        <BTModal.Body>The data transformation workflow has unsaved changes. What would you like to do?</BTModal.Body>
        <BTModal.Footer>
          <BTButton onClick={onConfirm}>Discard changes and close</BTButton>
          <BTButton btStyle="primary" onClick={closeConfirm}>
            Cancel
          </BTButton>
        </BTModal.Footer>
      </BTModal>
    </>
  );
};

export default CloseDataFlow;
