export function computeKeyFieldAutoMappings(keyFields = [], leftFields = [], rightFields = []) {
  let newKeyFields = [];

  const leftHashMap = buildFieldsHashMap(leftFields);
  const rightHashMap = buildFieldsHashMap(rightFields);

  // Clean from obsolete links
  newKeyFields = keyFields.reduce((acc, { left, right }) => {
    const newLeft = (left && leftHashMap[left.name.toLowerCase()]) || null;
    const newRight = (right && rightHashMap[right.name.toLowerCase()]) || null;

    if (newLeft && newRight) {
      acc.push({ left: newLeft, right: newRight });
    }

    return acc;
  }, []);

  if (newKeyFields.length > 0) {
    // Autopopulate right fields by left field names (case-insensitive)
    newKeyFields = newKeyFields.map(mapping => {
      if (mapping.right || !mapping.left) {
        return mapping;
      }
      return {
        ...mapping,
        right: rightHashMap[mapping.left.name.toLowerCase()] || null,
      };
    });
  } else {
    // Find intersecting fields on the left and right if mappings are empty
    newKeyFields = leftFields.reduce((newMappings, leftField) => {
      const matchingRightField = rightHashMap[leftField.name.toLowerCase()];
      if (matchingRightField) {
        newMappings.push({ left: leftField, right: matchingRightField });
      }
      return newMappings;
    }, []);
  }

  return newKeyFields;
}

export function buildFieldsHashMap(fields) {
  return fields.reduce((hashMap, field) => {
    const key = field.name.toLowerCase();
    hashMap[key] = field;
    return hashMap;
  }, {});
}

export function objectArrayRemoveDuplicates(arr) {
  const uniqueArray = arr.filter((value, index) => {
    const _value = JSON.stringify(value);
    return (
      index ===
      arr.findIndex(obj => {
        return JSON.stringify(obj) === _value;
      })
    );
  });
  return uniqueArray;
}

export function determineFieldOptions(inputFields, inputKeyFields) {
  let fieldOptions = [];
  if ((!inputFields || inputFields.length === 0) && inputKeyFields) {
    //input fields for the side do not exist but keyfields for the side have been defined, meaning input is disconnected from previous block
    fieldOptions = objectArrayRemoveDuplicates(inputKeyFields);
  } else if (
    inputFields &&
    inputKeyFields &&
    !inputKeyFields.every(r =>
      inputFields.some(e => (e?.original_name === undefined ? e.name === r.name : e.original_name === r.name))
    )
  ) {
    //input fields and keyfields for the side have been defined but do not match, meaning a new input source has been connected
    fieldOptions = objectArrayRemoveDuplicates(inputFields.concat(inputKeyFields));
  } else {
    fieldOptions = inputFields;
  }
  return fieldOptions;
}
