import { isFeatureFlagEnabled } from '../../../../../../utils/featureFlags';
import { SEND_TO_SFTP_SPREADSHEET } from '../../../../../../constants/featureFlags';

export function createSendToSFTPCommand({ onWorkpaperSendToSFTPModalOpen }) {
  const enableTPButton = () => {
    return !isFeatureFlagEnabled(SEND_TO_SFTP_SPREADSHEET);
  };

  const sendToSFTPCommand = {
    text: 'Send to SFTP',
    commandName: 'wkp.sendToSFTP',
    bigButton: '=ribbonHeight>toolbarHeight',
    canUndo: false,
    execute: async (_context, _, _contextMenuArgs) => {
      await onWorkpaperSendToSFTPModalOpen();
      return true;
    },
    iconClass: 'wkp-send-to-sftp',
    workArea: 'viewport',
    enableContext: enableTPButton(),
  };

  return {
    'wkp.sendToSFTP': sendToSFTPCommand,
  };
}
