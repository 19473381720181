import React from 'react';
import ValidationErrorsAlert from './shared/ValidationErrorsAlert';
import { useValidateDataFlow } from './ValidateDataFlow/useValidateDataFlow';

const ValidateDataFlow = ({ dataFlowId }) => {
  const [validationErrors, { reset }] = useValidateDataFlow(dataFlowId);
  return (
    <ValidationErrorsAlert
      show={validationErrors && validationErrors.length > 0}
      validationErrors={validationErrors}
      onDismiss={reset}
    />
  );
};

export default ValidateDataFlow;
