import { routers } from '@clientio/rappid';

export const getRouter = (vertices, opt, linkView) => {
  const { sourceAnchor, targetAnchor, sourceBBox, targetBBox } = linkView;
  //avoids non orthogonal links when source and target ports are too close each other
  if (sourceAnchor.distance(targetAnchor) < 100) {
    const mid = { y: sourceAnchor.y, x: targetAnchor.x };
    if (sourceBBox.containsPoint(mid)) {
      return [
        {
          x: sourceAnchor.x,
          y: (sourceAnchor.y + targetAnchor.y) / 2,
        },
        {
          x: targetAnchor.x,
          y: (sourceAnchor.y + targetAnchor.y) / 2,
        },
      ];
    } else if (targetBBox.clone().inflate(0, 10).containsPoint(mid)) {
      return [
        {
          x: (sourceAnchor.x + targetAnchor.x) / 2,
          y: sourceAnchor.y,
        },
        {
          x: (sourceAnchor.x + targetAnchor.x) / 2,
          y: targetAnchor.y,
        },
      ];
    }
    return [mid];
  }
  //return manhattan router with its options when source and target ports are not close
  return routers.manhattan(vertices, opt, linkView);
};
