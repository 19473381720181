import { BTButton, BTForm } from '@btas/jasper';
import iconImage from '../elementType/icons/AI_Lab_icon.svg';
import './styles.scss';

const AILabModal = ({ blockType, expressionGenerationHandler, isAIGenerated }) => {
  const textDescription = blockType === 'formula' ? 'formula' : 'filter criteria';
  return (
    <BTForm.FormGroup>
      <div className="wkp-expesion-generator-ai-lab">
        {isAIGenerated && (
          <p className="expression-generator-summary-disclaimer">
            The Bloomberg Tax AI Expression Generator is experimental in nature, and users are strongly advised to
            review the outputs to ensure the desired result has been achieved.
          </p>
        )}
        <img alt="AI Lab Icon" src={iconImage} />
        <p>
          <b>{`Unsure how to write your ${textDescription}?`}</b> Describe it and we will automatically generate it for
          you.
        </p>
      </div>
      <BTButton
        className="wkp-inspector-preview-btn"
        style={{ marginBottom: '10px' }}
        onClick={expressionGenerationHandler}
      >
        Describe
      </BTButton>
    </BTForm.FormGroup>
  );
};
export default AILabModal;
