import { ElementTypeMap } from '../elementType/types';

export function getType(cell) {
  return cell.attributes.type;
}

export function getElementType(cell) {
  return ElementTypeMap[getType(cell)];
}

export function findUniqueElementName(graph, cell) {
  const elementType = getElementType(cell);
  const elements = graph.getElements();
  const baseName = elementType.initialData.name;
  // * remove new line from label text to display in single line
  const blockName = baseName.split('\n').join(' ');

  let name = baseName;
  let num = 1;

  // eslint-disable-next-line no-loop-func
  while (!!elements.find(e => e.attr('.name/text') === name)) {
    name = `${blockName} ${num++}`;
  }

  return name;
}
