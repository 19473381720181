import * as fetch from '../../../_shared/fetch';
import { workpapersNodeServiceHostName } from '../../../../configs/params';

export async function getDataFlowsLinkedWorkpapers(ids) {
  const qs = ids.map(df => `dataFlowIds=${df}`).join('&');
  const res = await fetch.get(
    `${workpapersNodeServiceHostName}/api/v1/data-source-connections/data-flows/summary?${qs}`
  );

  const data = await res.json();
  if (!res.ok) {
    throw data.error;
  }

  return data;
}
