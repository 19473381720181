import GC from './SpreadView/SpreadSheets';
import { useCallback } from 'react';
import { formatOutputRecords } from './utils';
import { RowFilter } from './SpreadView/RowFilter';
import * as apis from './apis';
import CustomLogger from '../../../_shared/Logger/CustomLogger';

export default function usePreviewRender() {
  const getOutputRecords = useCallback(
    async (previewFields, previewRunId, activeElementId, port, _sortCol, _ascending, _filterItems) => {
      // if parameters are default values or not provided then we return records from parent. Otherwise, we make the respective api call
      let filterValues = {};
      for (let column in _filterItems) {
        filterValues[column] = _filterItems[column].value;
      }

      let sortField = null;
      let sortDirection = null;
      if (_sortCol && _sortCol.length) {
        sortField = {
          sortField: _sortCol,
        };
        sortDirection = _ascending ? 'ASC' : 'DESC';
      }

      let searchExpr = null;
      if (Object.keys(filterValues).length) {
        searchExpr = { expr: filterValues };
      }

      const outputPreviewRecords = await apis.getPreviewOutputRecords(
        previewRunId,
        activeElementId,
        port,
        100,
        1,
        sortField,
        sortDirection,
        searchExpr
      );

      const previewRecords = formatOutputRecords(previewFields, outputPreviewRecords.records);
      return previewRecords;
    },
    []
  );

  const showData = useCallback((designer, workbook, data, filterStatus, previewFields = []) => {
    workbook.suspendPaint();

    workbook.clearSheets();

    const worksheet = new GC.Spread.Sheets.Worksheet();
    worksheet.options.isProtected = true;

    worksheet.setRowCount(0, GC.Spread.Sheets.SheetArea.viewport);
    worksheet.setColumnCount(0, GC.Spread.Sheets.SheetArea.viewport);

    workbook.addSheet(1, worksheet);
    worksheet.autoGenerateColumns = false;
    worksheet.setDataSource(data.records, true);

    // disable right click context menu
    workbook.options.allowContextMenu = false;

    // hide the Tab strip (area where the tab of the sheets is displayed)
    workbook.options.tabStripVisible = false;

    // max align for the scrollbar to avoid blank page
    workbook.options.scrollbarMaxAlign = true;

    workbook.options.hideSelection = true;

    worksheet.options.isProtected = true;
    worksheet.options.protectionOptions = {
      allowSelectLockedCells: true,
      allowSelectUnlockedCells: true,
      allowSort: true,
      allowFilter: true,
      allowInsertColumns: false,
      allowResizeColumns: true,
      allowResizeRows: false,
      allowEditObjects: false,
      allowDragInsertRows: false,
      allowDragInsertColumns: false,
      allowInsertRows: false,
      allowDeleteRows: false,
      allowDeleteColumns: false,
      allowOutlineColumns: false,
      allowOutlineRows: false,
    };

    const rowCount = data.records?.length ? data.records.length : 0;
    const columns = data.records?.length ? Object.keys(data?.records[0]) : null;

    previewFields.forEach((field, to) => {
      const fieldName = field.original_name ? field.original_name : field.name;
      worksheet.bindColumn(to, fieldName);
    });

    const filter = new GC.Spread.Sheets.Filter.HideRowFilter(
      new GC.Spread.Sheets.Range(-1, -1, rowCount, columns?.length)
    );

    filter.filterDialogVisibleInfo({
      sortByValue: true,
      sortByColor: false,
      filterByColor: false,
      filterByValue: false,
      listFilterArea: true,
    });

    worksheet.rowFilter(filter);

    if (filterStatus.active && filterStatus.sortItem.column) {
      filter.sortColumn(filterStatus.sortItem.colIndex, filterStatus.sortItem.asc);
    }

    // If filter is active we assume that all the returned rows should be show
    // The worker applies the filter and we only show the result records
    if (filterStatus.active && data.records?.length > 0) {
      for (let filterColumn in filterStatus.filterItems) {
        filter.addFilterItem(filterStatus.filterItems[filterColumn].column, new RowFilter());
        filter.filter(filterStatus.filterItems[filterColumn].column);
      }
    }

    const openFilterDialogOld = filter.openFilterDialog;
    filter.openFilterDialog = function (filterButtonHitInfo) {
      // customize the filter dialog
      let includeItemsChecked = null;

      if (document.getElementById('includeItems')) {
        includeItemsChecked = document.getElementById('includeItems').checked;
      }

      openFilterDialogOld.call(this, filterButtonHitInfo);
      var itemsContainer = document.getElementsByClassName('gc-filter-item-container');
      if (includeItemsChecked) {
        var filterSS = GC.Spread.Sheets.findControl(document.getElementsByClassName('gc-filter-item-container')[0]);

        // don't let filter dialog to update checked values.
        // the item in the list might not in the displayed data.
        filterSS.unbind(GC.Spread.Sheets.Events.CellClick);
        filterSS.unbind(GC.Spread.Sheets.Events.CellChanged);

        // get server unique values
      } else {
        itemsContainer[0].style.display = 'none';
      }

      const filterSearchTxt = document.getElementById('gc-filterSearch');
      filterSearchTxt.style.display = 'none';

      const newFilterSearchTxt = document.createElement('input');
      newFilterSearchTxt.id = 'bb-filter-txt';
      newFilterSearchTxt.style.marginLeft = '5px';
      newFilterSearchTxt.style.width = '184px';
      newFilterSearchTxt.placeholder = 'filter';
      filterSearchTxt.parentElement.appendChild(newFilterSearchTxt);

      const checked = document.getElementsByClassName('gc-filter-check-outerDiv');
      checked[0].style.display = 'none';

      const dlg = document.getElementsByClassName('gc-popup-gc-dialog1');
      if (includeItemsChecked) {
        dlg[0].style.height = '290px';
      } else {
        dlg[0].style.height = '150px';
      }
    };

    // Headers style definition
    const style = worksheet.getActualStyle(1, -1);
    style.font = 'bold ' + style.font;
    style.hAlign = GC.Spread.Sheets.HorizontalAlign.center;

    columns?.forEach((column, index) => {
      // set column width
      worksheet.setColumnWidth(index, 140);

      // styles for header
      worksheet.setStyle(0, index, style, GC.Spread.Sheets.SheetArea.colHeader);

      // cell format
      if (data?.recordTypes[column]?.type === 'numeric') {
        worksheet.getRange(0, index, worksheet.getRowCount()).formatter(data?.recordTypes[column]?.format);
      }
    });

    // lock the cells and update default styles
    const defaultStyle = worksheet.getActualStyle(1, -1);
    defaultStyle.locked = true;
    defaultStyle.foreColor = '#141414';
    defaultStyle.cellPadding = '0 2';
    defaultStyle.hAlign = GC.Spread.Sheets.HorizontalAlign.left;
    worksheet.setDefaultStyle(defaultStyle);

    workbook.resumePaint();

    designer.current = workbook;

    CustomLogger.end(CustomLogger.operations.BLOCK_TO_PREVIEW);
  }, []);

  return [showData, getOutputRecords];
}
