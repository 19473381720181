import { useCallback, useEffect, useState } from 'react';
import { validateDataFlow } from '../apis';
import useQuery from '../../../_shared/useQuery';

export function useValidateDataFlow(dataFlowId) {
  const [validationErrors, setValidationErrors] = useState(null);
  const query = useQuery();
  const validateConfiguration = query.get('validateConfiguration');
  useEffect(() => {
    async function validate() {
      try {
        const data = await validateDataFlow(dataFlowId);
        if (data.validationErrors) {
          setValidationErrors(data.validationErrors);
        }
      } catch (e) {}
    }
    if (validateConfiguration) {
      validate();
    }
  }, [dataFlowId, validateConfiguration, setValidationErrors]);
  const reset = useCallback(() => setValidationErrors(null), [setValidationErrors]);

  return [validationErrors, { reset }];
}
