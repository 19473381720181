import { useContext, useEffect } from 'react';
import { getType } from '../shared/graphUtils';
import { DataFlowEditorContext } from '../DataFlowEditorContext';
import { ElementTypeMap } from '../elementType/types';

export const useRefreshStencil = () => {
  const { dataFlowState } = useContext(DataFlowEditorContext);
  const { stencil, elements } = dataFlowState;

  useEffect(() => {
    if (stencil && elements) {
      const countsByElementType = Object.values(elements).reduce((countsByType, element) => {
        const type = element.elementType.type;
        const currentCount = countsByType[type] || 0;
        countsByType[type] = currentCount + 1;
        return countsByType;
      }, {});

      const stencilElements = stencil.getGraph().getElements();
      stencilElements.forEach(stencilElement => {
        const type = getType(stencilElement);
        const elementType = ElementTypeMap[type];
        const count = countsByElementType[type] || 0;
        const enabled = elementType.maxCount === -1 || count < elementType.maxCount;

        stencilElement.prop('enabled', enabled);
        stencilElement.attr('.block/opacity', enabled ? 1 : 0.6);
      });
    }
  }, [stencil, elements]);
};
