export class NewElementDataConfig {
  constructor(elementData, elementType, targetPort, fieldsMapping) {
    this.elementData = elementData;
    this.elementType = elementType;
    this.targetPort = targetPort;
    this.fieldsMapping = fieldsMapping;
  }
  updateConfigurations() {
    switch (this.elementType) {
      case 'lookup':
      case 'join':
      case 'merge':
        return this.updateKeyFields();
      case 'formula':
      case 'filter':
        return this.updateFormula();
      case 'transpose':
      case 'sort':
        return this.updateTransposeOrSort();
      case 'aggregate':
        return this.updateAggregate();
      case 'advancedTranspose':
        return this.updateAdvancedTranspose();
      case 'select':
        return this.updateSelect();
      case 'reorder':
        return this.updateReorder();
      default:
        break;
    }
  }
  updateTransposeOrSort() {
    const fieldList = this.elementType === 'transpose' ? this.elementData.fields : this.elementData.sortedFields;
    const updatedFields = this.updateListOfFields(fieldList, 'name');

    return this.elementType === 'transpose'
      ? { fields: updatedFields, sourceFields: updatedFields }
      : { sortedFields: updatedFields };
  }

  updateAggregate() {
    const groupByList = this.elementData.groupBy;
    const fieldsToAggregateList = this.elementData.fieldsToAggregate;
    const updateGroupBy = this.updateGroupBy(groupByList);
    const updatedFieldsToAggregate = this.updateListOfFields(fieldsToAggregateList, 'field');
    return { groupBy: updateGroupBy, fieldsToAggregate: updatedFieldsToAggregate };
  }

  updateAdvancedTranspose() {
    const checkedFieldKeys = Object.keys(this.elementData?.checkedFields);
    let updatedCheckedFields = {};
    checkedFieldKeys.forEach(field => {
      const newFieldName = this.updateFieldName(field);
      updatedCheckedFields[newFieldName] = this.elementData.checkedFields[field];
    });

    return { checkedFields: updatedCheckedFields };
  }

  updateListOfFields(fieldList, fieldKey) {
    const updatedFields = fieldList.map(field => {
      const currentFieldName = field[fieldKey];
      const newFieldName = this.updateFieldName(currentFieldName);
      return { ...field, [fieldKey]: newFieldName };
    });
    return updatedFields;
  }

  updateGroupBy(groupByList) {
    const updatedFields = groupByList?.map(field => {
      const currentFieldName = field;
      const newFieldName = this.updateFieldName(currentFieldName);
      return newFieldName;
    });
    return updatedFields;
  }

  updateKeyFields() {
    const fieldsToMap = this.elementType === 'merge' ? this.elementData.mappings : this.elementData.keyFields;
    const key = this.elementType === 'merge' ? 'mappings' : 'keyFields';
    let blockSide = this.targetPort;

    if (this.elementType !== 'merge') {
      blockSide = this.targetPort === 'A' ? 'left' : 'right';
    }

    const updatedKeyFields = fieldsToMap?.map(keyField => {
      let newKeyFieldMapping = { ...keyField };
      const currentFieldName = keyField[blockSide]?.name;
      const newFieldName = this.updateFieldName(currentFieldName);
      if (newFieldName !== currentFieldName) {
        newKeyFieldMapping[blockSide] = { ...keyField[blockSide], name: newFieldName };
      }
      return newKeyFieldMapping;
    });
    return { [key]: updatedKeyFields };
  }

  updateFieldName(currentFieldName) {
    const findFieldMapping = this.fieldsMapping.find(field => field?.oldFields?.value === currentFieldName);
    return findFieldMapping?.newFields?.value ? findFieldMapping?.newFields?.value : currentFieldName;
  }

  updateFormula() {
    const currentFields = this.elementType === 'filter' ? this.elementData.filterCriteria : this.elementData.fields;
    let newFormula = this.elementData.formula;
    let updatedFilterCriteria = [];
    const objKey = this.elementType === 'filter' ? 'field' : 'name';

    currentFields?.forEach(field => {
      const currentFieldName = field[objKey];
      const newFieldName = this.updateFieldName(field[objKey]);
      const replaceCondition =
        this.elementType === 'filter' ? newFieldName !== currentFieldName : newFormula.includes(currentFieldName);
      if (replaceCondition) {
        newFormula = newFormula.replace(currentFieldName, newFieldName);
      }
      if (this.elementType === 'filter') {
        let updateFilterCriteria = { ...field, field: newFieldName };
        updatedFilterCriteria.push(updateFilterCriteria);
      }
    });
    return this.elementType === 'filter'
      ? { formula: newFormula, filterCriteria: updatedFilterCriteria }
      : { formula: newFormula };
  }

  updateSelect() {
    let updatedRenameConfig = {};
    let updatedSelectedFieldConfig = {};
    const updatedFields = this.updateListOfFields(this.elementData.fields, 'name');
    const updatedUpdatedFields = this.updateListOfFields(this.elementData.updatedFields, 'name');

    const renamedFieldsKey = Object.keys(this.elementData.renamedFields);

    this.elementData.updatedFields.forEach(field => {
      const currentField = field.name;
      const newFieldName = this.updateFieldName(currentField);

      updatedSelectedFieldConfig[newFieldName] = this.elementData.selectedFields[currentField];

      if (renamedFieldsKey.length > 0) {
        renamedFieldsKey.forEach(key => {
          if (this.elementData.renamedFields[key][currentField]) {
            updatedRenameConfig[key] = { [newFieldName]: this.elementData.renamedFields[key][currentField] };
          }
        });
      }
    });

    return {
      fields: updatedFields,
      renamedFields: updatedRenameConfig,
      selectedFields: updatedSelectedFieldConfig,
      updatedFields: updatedUpdatedFields,
    };
  }

  updateReorder() {
    const nameReference = this.elementData.fields?.original_name ? 'original_name' : 'name';
    const updatedFields = this.updateListOfFields(this.elementData.fields, nameReference);
    const updatedNewFieldOrder = this.updateListOfFields(this.elementData.newFieldOrder, nameReference);
    return { fields: updatedFields, newFieldOrder: updatedNewFieldOrder };
  }
}
