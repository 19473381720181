import { useState, useCallback, useEffect } from 'react';
import { getSourceFilesDetails } from './apis';

const initialGridData = [];

export default function useGrid({ dataFlowId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [gridData, setGridData] = useState(initialGridData);

  const fetchSourceFiles = useCallback(
    async function () {
      if (!dataFlowId) {
        return;
      }

      const gridData = await getSourceFilesDetails({ dataFlowId });
      setGridData(gridData);
      setIsLoading(false);
    },
    [dataFlowId]
  );

  useEffect(() => {
    fetchSourceFiles();
  }, [fetchSourceFiles]);

  const state = {
    isLoading,
    gridData,
  };

  return [state];
}
