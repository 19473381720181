import React from 'react';
import { BTButton, BTForm } from '@btas/jasper';
import FilterCriteriaItem from './FilterFieldsRow/FilterCriteriaItem';
import { useCanEditWorkflow } from '../../../_shared/UserPermissionsContext';

const defaultFieldToFilter = { field: '', operator: '', filterValue: '' };

const FilterCriteria = ({ filterCriteria, fieldOptions, onUpdate, onAdd, onRemove }) => {
  const onCriteriaRemove = fieldToFilterIndex => () =>
    onRemove(
      filterCriteria.length > 1
        ? filterCriteria.filter((_, index) => index !== fieldToFilterIndex)
        : [defaultFieldToFilter]
    );

  const onCriteriaAdd = () => {
    onAdd(filterCriteria);
  };

  const onFieldUpdate = fieldToFilterIndex => newFieldToFilter => {
    onUpdate(newFieldToFilter, filterCriteria, fieldToFilterIndex);
  };
  const canEditWorkflow = useCanEditWorkflow();
  return (
    <div className="wkp-simple-criteria-block">
      <BTForm.FormGroup className="wkp-fields-to-filter">
        {filterCriteria.map((filterCriteriaItem, filterCriteraIndex) => (
          <FilterCriteriaItem
            key={`${filterCriteraIndex}-${filterCriteriaItem}`}
            fieldOptions={fieldOptions}
            filterCriteria={filterCriteria}
            filterField={filterCriteriaItem}
            index={filterCriteraIndex}
            onRemove={onCriteriaRemove(filterCriteraIndex)}
            onUpdate={onFieldUpdate(filterCriteraIndex)}
          />
        ))}
        {canEditWorkflow ? (
          <BTButton
            btStyle="link"
            btType="add"
            className="wkp-add-filter-btn"
            disabled={filterCriteria.length === 10}
            onClick={onCriteriaAdd}
          >
            Add another
          </BTButton>
        ) : (
          <></>
        )}
      </BTForm.FormGroup>
    </div>
  );
};

export default FilterCriteria;
