import GC from '../../../../../../SpreadSheets';
import { INT_TAX_RATE } from '../../../../../_shared/DataReference/ReferenceType';
import { formatFormulaReturnValue, enqueueFormula } from '../formulas';
export const intTaxRateFormulaName = 'INTTAXRATE';

const name = intTaxRateFormulaName;

const parameters = [
  {
    name: '"jurisdiction"',
    repeatable: false,
    optional: false,
  },
  {
    name: '"period_start_date"',
    repeatable: false,
    optional: false,
  },
  {
    name: 'taxable_income',
    repeatable: false,
    optional: true,
  },
];

const description =
  'Retrieves international tax rates from the tax law database. Taxable income parameter is optional. For jurisdictions with graduated income tax, if no taxable income is entered then the tax rate for the highest income bracket is returned; if taxable income is entered, then the effective rate is computed.';

const evaluationFunction = (args, data) => {
  const [context, country, date] = args;

  if (country && date) {
    const { row, column, source } = context;
    const sheet = source.getSheet();
    const sheetName = sheet.name();
    const cellTag = data.getCellReferenceTag(sheet, row, column);
    const formulaType = INT_TAX_RATE;

    enqueueFormula(data, context, formulaType, args);

    return formatFormulaReturnValue(
      data.renderCustomFormulaValues(cellTag, row, column, formulaType, sheetName),
      data,
      sheet,
      row,
      column
    );
  }
  return GC.Spread.CalcEngine.Errors.NotAvailable;
};

export const intTaxRatesFormula = (gcSyncFunction, data) =>
  new gcSyncFunction({
    name,
    minArgs: 2,
    maxArgs: 3,
    descriptionData: {
      name,
      description,
      parameters,
    },
    evaluationFunction,
    data,
  });
