import React from 'react';
import workpaperIcon from './wkp_grid_icon.svg';
import inProgressIcon from '../../../../_shared/icons/Cresent_Spinner.svg';
import generateJobTypeText from '../jobTypeTextResolver';
export default function InProcessJobItem({ fileName, status, type }) {
  const jobType = generateJobTypeText(type);
  return (
    <div className="wkp-job-item" title={`${status} '${fileName}'`}>
      <img alt="workpaper icon" src={workpaperIcon} />
      <span className="wkp-job-item-name">
        <b>
          {jobType?.process} '{fileName}'
        </b>
      </span>
      <span className="wkp-job-item-icon">
        <img alt="in progress job icon" className="progress-spinner" src={inProgressIcon} />
      </span>
    </div>
  );
}
