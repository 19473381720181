import React, { useContext } from 'react';
import { BTAlert } from '@btas/jasper';
import { DataFlowEditorContext } from './DataFlowEditorContext';

const FileValidationMessage = ({ dismissible, visible, message }) => {
  const { sourceFileUpload } = useContext(DataFlowEditorContext);
  const { setUploadValidationError } = sourceFileUpload;
  const onDismiss = () => setUploadValidationError(null);

  return (
    <BTAlert appear fixed btStyle="danger" dismissible={dismissible} visible={visible} onDismiss={onDismiss}>
      {message}
    </BTAlert>
  );
};

export default FileValidationMessage;
