export const AGGREGATE = 'aggregate';
export const FILTER = 'filter';
export const FORMULA = 'formula';
export const INPUT = 'input';
export const JOIN = 'join';
export const LOOKUP = 'lookup';
export const MERGE = 'merge';
export const OUTPUT = 'output';
export const SELECT = 'select';
export const TRANSPOSE = 'transpose';
export const ADVANCED_TRANSPOSE = 'advancedTranspose';
export const ANNOTATION = 'annotation';
export const REORDER = 'reorder';
export const MULTIMERGE = 'multiMerge';
