import React, { useContext } from 'react';
import { BTAlert } from '@btas/jasper';
import UserPermissionsContext from '../_shared/UserPermissionsContext';

const FailedToDeleteError = () => {
  const { userPermissions } = useContext(UserPermissionsContext);

  const errorData = userPermissions.getDeleteWorkflowErrorData();
  const isVisible = userPermissions.hasDeleteWorkflowError();

  const text = errorData.length > 1 ? 's' : '';
  const errorMessage = `Failed to delete ${errorData.length} data transformation workflow${text} because you do not have permission to delete others' items:`;

  return (
    <BTAlert
      appear
      dismissible
      fixed
      btStyle="danger"
      visible={isVisible}
      onDismiss={() => userPermissions.clearDeleteWorkflowError()}
    >
      {errorMessage}
      <ul>
        {errorData.map(item => (
          <li>{item}</li>
        ))}
      </ul>
    </BTAlert>
  );
};

export default FailedToDeleteError;
