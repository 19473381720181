import React, { useMemo, useState } from 'react';
import { BTGrid, BTInput, BTComboBox, BTDatePicker } from '@btas/jasper';
import { wijmoKey } from '../../../../configs/params';
import workpaperIcon from './Workpaper_icon.svg';
import EmptyGrid from './EmptyGrid';
import WorkpapersActionButtons from '../WorkpapersActionButtons';
import './Grid/styles.scss';
import { getFormattedDateForGrid } from '../../../_shared/dateFormatting';

const WorkpapersBTGrid = ({
  config,
  handleWorkpapersSelectionChange,
  onSortChange,
  onFilterChange,
  onClearFilters,
  setIsSortingFromGrid,
}) => {
  const { gridData, gridSorting, gridFilters } = config;
  const { items, filterOptions } = gridData;

  const handleFilterChange = (field, value) => {
    if (field === 'taxPeriod') {
      setIsSortingFromGrid(true);
    }
    onFilterChange(field, value);
  };

  const isFiltered = useMemo(() => Object.values(gridFilters).some(filter => filter), [gridFilters]);
  const nameTemplate = ({ rowData }) => (
    <div className="wkp-deleted-wkp">
      <img alt="Deleted workpaper" src={workpaperIcon} />
      {rowData.name}
    </div>
  );

  const taxPeriodTemplate = ({ rowData }) => <div data-testid="wkp-deleted-tax-period">{rowData.taxPeriod}</div>;

  const deletedByTemplate = ({ rowData }) => <div data-testid="wkp-deleted-deleted-by">{rowData.deletedBy}</div>;

  const deletedOnTemplate = context => {
    const date = getFormattedDateForGrid(context.rowData.deletedDate);

    return (
      <div className="deleted-on" data-testid="wkp-deleted-deleted-on">
        <p>{date}</p>
      </div>
    );
  };

  return (
    <BTGrid
      allowSelection
      allowSorting
      allowResizing={1}
      data={items}
      defaultSortedColumn={[gridSorting]}
      pageYOffset={450}
      wijmoKey={wijmoKey}
      onClearFilterClick={onClearFilters}
      onSelectionChanged={handleWorkpapersSelectionChange}
    >
      <BTGrid.Header binding="sel" minWidth={50} width="*">
        sel
      </BTGrid.Header>
      <BTGrid.Header
        key="name"
        align="left"
        binding="name"
        cellTemplate={nameTemplate}
        minWidth={200}
        width="2*"
        onSort={onSortChange}
      >
        Name
      </BTGrid.Header>
      <BTGrid.Header
        key="taxPeriod"
        align="left"
        binding="taxPeriod"
        cellTemplate={taxPeriodTemplate}
        width={140}
        onSort={onSortChange}
      >
        Tax Period
      </BTGrid.Header>
      <BTGrid.Header
        key="deletedBy"
        align="left"
        binding="deletedBy"
        cellTemplate={deletedByTemplate}
        minWidth={140}
        width="*"
        onSort={onSortChange}
      >
        Deleted by
      </BTGrid.Header>
      <BTGrid.Header
        key="deletedOn"
        align="left"
        binding="deletedOn"
        cellTemplate={deletedOnTemplate}
        width={260}
        onSort={onSortChange}
      >
        Deleted on
      </BTGrid.Header>

      <BTGrid.Filter binding="name">
        <BTInput
          data-testid="wkp-name-filter"
          name="name"
          value={gridFilters.name}
          onChange={e => handleFilterChange('name', e.target.value)}
        />
      </BTGrid.Filter>
      <BTGrid.Filter binding="taxPeriod">
        <BTComboBox
          isClearable
          id="wkp-tax-period-select-container"
          maxMenuHeight={100}
          name="taxPeriod"
          options={filterOptions.taxPeriods}
          value={gridFilters.taxPeriod}
          onChange={opt => handleFilterChange('taxPeriod', opt?.value)}
        />
      </BTGrid.Filter>
      <BTGrid.Filter binding="deletedBy">
        <BTInput
          isClearable
          data-testid="wkp-deleted-by-filter"
          name="deletedBy"
          value={gridFilters.deletedBy}
          onChange={e => handleFilterChange('deletedBy', e.target.value)}
        />
      </BTGrid.Filter>
      <BTGrid.Filter binding="deletedOn">
        <BTDatePicker
          data-testid="wkp-deleted-on-filter"
          dateFormat="MM/dd/yyyy"
          dateSelected={gridFilters.deletedOn}
          placeholder="MM/DD/YYYY"
          popperProps={{
            positionFixed: true,
          }}
          value={gridFilters.deletedOn}
          onChange={date => handleFilterChange('deletedOn', date)}
        />
      </BTGrid.Filter>

      <BTGrid.EmptyState isFiltered={isFiltered} />
    </BTGrid>
  );
};

export default function Grid({
  actions,
  config,
  onSortChange,
  onFilterChange,
  loadJobs,
  onClearFilters,
  setIsSortingFromGrid,
  isSortingFromGrid,
  cleanGridSelection,
}) {
  const {
    gridData: { items },
    totalCount,
  } = config;
  const [selectedWorkpapers, setSelectedWorkpapers] = useState([]);

  return (
    <>
      <h2>Recycle Bin</h2>
      <WorkpapersActionButtons
        actions={actions}
        cleanGridSelection={cleanGridSelection}
        config={config}
        loadJobs={loadJobs}
        selection={selectedWorkpapers}
      />
      {items.length === 0 && totalCount === 0 ? (
        <div className="wkp-deleted-wkp-section wkp-empty wkp-drag">
          <div className="wkp-grid-container">
            <EmptyGrid />
          </div>
        </div>
      ) : (
        <WorkpapersBTGrid
          config={config}
          handleWorkpapersSelectionChange={setSelectedWorkpapers}
          isSortingFromGrid={isSortingFromGrid}
          setIsSortingFromGrid={setIsSortingFromGrid}
          onClearFilters={onClearFilters}
          onFilterChange={onFilterChange}
          onSortChange={onSortChange}
        />
      )}
    </>
  );
}
