import React from 'react';
import InProcessJobItem from './InProcessJobItem';
import SuccessJobItem from './SuccessJobItem';
import FailedJobItem from './FailedJobItem';
import {
  WORKPAPER_JOB_STATUS_IN_PROGRESS,
  WORKPAPER_JOB_STATUS_COMPLETED,
  WORKPAPER_JOB_STATUS_FAILED,
} from '../../../../_shared/jobs/jobStatus';

export default function JobItem({ fileName, status, type, jobOutput }) {
  const displayJobStatusComponent = () => {
    switch (status) {
      case WORKPAPER_JOB_STATUS_IN_PROGRESS:
        return <InProcessJobItem fileName={fileName} status={status} type={type} />;
      case WORKPAPER_JOB_STATUS_COMPLETED:
        return <SuccessJobItem fileName={fileName} jobOutput={jobOutput} />;
      case WORKPAPER_JOB_STATUS_FAILED:
        return <FailedJobItem jobOutput={jobOutput} />;
      default:
    }
  };
  return displayJobStatusComponent();
}
