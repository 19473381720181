import React, { useContext } from 'react';
import { BTButton, BTIcon } from '@btas/jasper';
import { getFormattedDate } from '../../../../../../_shared/dateFormatting';
import { getUser } from '../../../../../../_shared/auth';
import { UserPermissionsContext } from '../../../../../../_shared/UserPermissionsContext';
import { eventTrackTagNames } from '../../../../../../_shared/EventTrackingContext/constants';

export default function SignOffList({ signOffs, onAddSignOffClick, isLocked, onSignOffDeleteClick }) {
  const userInfo = getUser();
  const { userPermissions } = useContext(UserPermissionsContext);

  function handleSignOffDelete(id) {
    if (!isLocked) {
      onSignOffDeleteClick(id);
    }
  }
  return (
    <div className="wkp-sign-off-list">
      {signOffs.map(({ firstName, lastName, signOffDate, userId, id }) => (
        <p key={id}>
          {firstName} {lastName} | {getFormattedDate(signOffDate)}
          {userInfo.userId === userId && (
            <span className={`wkp-sign-off-delete-icon ${isLocked ? 'wkp-sign-off-locked' : ''}`}>
              <BTIcon data-testid="icon" icon="trash" onClick={() => handleSignOffDelete(id)} />
            </span>
          )}
        </p>
      ))}
      {userPermissions.includes('edit_workpaper_sign-offs') && (
        <BTButton
          btStyle="link"
          btType="add"
          data-track-tag={eventTrackTagNames.interactive}
          disabled={isLocked}
          id="add-sign-off"
          onClick={onAddSignOffClick}
        >
          Add another sign-off
        </BTButton>
      )}
    </div>
  );
}
