import React from 'react';
import { BTIcon } from '@btas/jasper';
import inProgressIcon from '../../../_shared/icons/Cresent_Spinner_White.svg';
export default function PanelHeader({
  children,
  setOnClose,
  hasProcessingJob,
  hasFailedJob,
  hasJobs,
  minimize,
  setMinimize,
}) {
  return (
    <>
      <div
        className={hasFailedJob ? 'panel-heading wkp-pane-title wkp-failed-jobs-title' : 'panel-heading wkp-pane-title'}
      >
        <h4>
          {' '}
          {hasProcessingJob ? (
            <img alt="in progress job icon" className="progress-spinner" src={inProgressIcon} />
          ) : (
            ''
          )}{' '}
          {children}
        </h4>
        <button
          className="wkp-window-action-btn wkp-window-btn-minimize"
          type="button"
          onClick={() => {
            setMinimize(!minimize);
          }}
        >
          <BTIcon icon={!minimize ? 'chevron-down' : 'chevron-up'} />
        </button>
        {!hasProcessingJob & hasJobs ? (
          <button
            className="wkp-window-action-btn wkp-window-btn-close"
            type="button"
            onClick={() => {
              setOnClose(true);
            }}
          >
            <BTIcon icon="close" />
          </button>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
