import { userDataAPIPath } from '../../../../configs/params';
import * as wkpFetch from '../../fetch';

export async function sendFeedback(message) {
  const reqUri = `${userDataAPIPath}/feedback`;
  const result = await wkpFetch.post(reqUri, {
    body: JSON.stringify({ message }),
  });

  if (!result.ok) {
    throw result.json().error;
  }
}
