import React from 'react';
import './styles.scss';
import PanelHeader from './PanelHeader';
import PanelBody from './PanelBody';

export default function JobsOverlayWindow({
  hasJobs,
  hasFailedJob,
  hasProcessingJob,
  jobs,
  minimize,
  processingJobs,
  setMinimize,
  setOnClose,
}) {
  return (
    <div className={hasJobs ? 'wkp-jobs-pane panel panel-default' : 'wkp-no-jobs-pane'}>
      <PanelHeader
        hasFailedJob={hasFailedJob}
        hasJobs={hasJobs}
        hasProcessingJob={hasProcessingJob}
        minimize={minimize}
        setMinimize={setMinimize}
        setOnClose={setOnClose}
      >
        {hasProcessingJob ? `Processing ${processingJobs} workpapers` : 'Processed workpapers'}
      </PanelHeader>

      <PanelBody hasProcessingJob={hasProcessingJob} jobs={jobs} minimize={minimize} />
    </div>
  );
}
