import GC from './SpreadSheets';

// This filter return all the rows.
// The worker applies the filter and spread js only needs to show all the rows.
// This is added to allow spreadjs show the icon for the filtered columns.
export function RowFilter() {
  GC.Spread.Sheets.ConditionalFormatting.Condition.apply(this, arguments);
}
RowFilter.prototype = new GC.Spread.Sheets.ConditionalFormatting.Condition();
RowFilter.prototype.evaluate = function (evaluator, row, col) {
  return true;
};
