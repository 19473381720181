import { useEffect, useState } from 'react';
import { getDataFlowSourceFiles } from '../../shared/apis';
import { getDataFlowSourceFileVersion } from './apis';

export const useInputSourceFiles = (dataFlowId, inputId, pendingSourceFileVersionId, reloadFiles) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sourceFiles, setSourceFiles] = useState([]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    loadSourceFiles();

    async function loadSourceFiles() {
      setIsLoading(true);
      try {
        if (pendingSourceFileVersionId) {
          const pendingVersion = await getDataFlowSourceFileVersion(dataFlowId, pendingSourceFileVersionId);
          setSourceFiles([{ ...pendingVersion, id: undefined }]);
        } else {
          const inputSourceFiles = await getDataFlowSourceFiles(dataFlowId, inputId);
          setSourceFiles(inputSourceFiles);
        }
      } catch (e) {
        setSourceFiles([]);
      } finally {
        setIsLoading(false);
      }
    }
  }, [dataFlowId, pendingSourceFileVersionId, inputId, reloadFiles]);

  return { isLoading, sourceFiles, setSourceFiles };
};
