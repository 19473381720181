import GC from '../../../../../../../SpreadSheets';
import { Commands } from './shell/action.base';
import { Util } from './common/util';
var SELECT_ENTIRE_ROW = 'selectEntireRow',
  GO_SHEET_BEGINNING = 'goToSheetBeginning',
  SELECT_ENTIRE_COLUMN = 'selectEntireColumn',
  GO_SHEET_BOTTOM_RIGHT = 'goToSheetBottomRight',
  GO_TO_PRECEDENTS = 'goToPrecedent',
  SELECT_VISIBLE_CELLS = 'selectVisibleCells',
  GO_TO_DEPENDENTS = 'goToDependents',
  SELECT_ALL = 'SelectAll',
  SELECTION_HOME = 'selectionHome',
  SELECTION_END = 'selectionEnd',
  SELECTION_TOP = 'selectionTop',
  SELECTION_BOTTOM = 'selectionBottom',
  GOTO_NEXT_TOP_NON_NULL_CELL = 'gotoNextTopNonNullCell',
  GOTO_NEXT_BOTTOM_NON_NULL_CELL = 'gotoNextBottomNonNullCell',
  GOTO_NEXT_LEFT_NON_NULL_CELL = 'gotoNextLeftNonNullCell',
  GOTO_NEXT_RIGHT_NON_NULL_CELL = 'gotoNextRightNonNullCell';
var VerticalPosition = GC.Spread.Sheets.VerticalPosition;
var HorizontalPosition = GC.Spread.Sheets.HorizontalPosition;
Commands[SELECT_ENTIRE_ROW] = {
  canUndo: false,
  execute: function (context, options) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = SELECT_ENTIRE_ROW;
    if (sheet.editorStatus() === GC.Spread.Sheets.EditorStatus.ready) {
      var selections = sheet.getSelections(),
        lastSelctionIndex = selections.length - 1;
      // In excel, when multi range selected, only larger last range's entire row, keep other range selected.
      var lastSelection = selections[lastSelctionIndex];
      if (lastSelection) {
        lastSelection.col = -1;
        lastSelection.colCount = sheet.getColumnCount();
        sheet.repaint();
      }
      return true;
    }
    return false;
  },
};
Commands[SELECT_ENTIRE_COLUMN] = {
  canUndo: false,
  execute: function (context, options) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = SELECT_ENTIRE_COLUMN;
    if (sheet.editorStatus() === GC.Spread.Sheets.EditorStatus.ready) {
      var selections = sheet.getSelections(),
        lastSelctionIndex = selections.length - 1;
      // In excel, when multi range selected, only larger last range's entire column, keep other range selected.
      var lastSelection = selections[lastSelctionIndex];
      if (lastSelection) {
        lastSelection.row = -1;
        lastSelection.rowCount = sheet.getRowCount();
        sheet.repaint();
      }
      return true;
    }
    return false;
  },
};
Commands[GO_SHEET_BEGINNING] = {
  canUndo: false,
  execute: function (context, options) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = GO_SHEET_BEGINNING;
    if (sheet.editorStatus() === GC.Spread.Sheets.EditorStatus.ready) {
      sheet.suspendPaint();
      sheet.showCell(0, 0, GC.Spread.Sheets.VerticalPosition.nearest, GC.Spread.Sheets.HorizontalPosition.nearest);
      sheet.setSelection(0, 0, 1, 1);
      sheet.resumePaint();
      return true;
    }
    return false;
  },
};
Commands[GO_SHEET_BOTTOM_RIGHT] = {
  canUndo: false,
  execute: function (context, options) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = GO_SHEET_BOTTOM_RIGHT;
    if (sheet.editorStatus() === GC.Spread.Sheets.EditorStatus.ready) {
      var lastNonNullRow = sheet.getLastNonNullRow();
      var lastNonNullCol = sheet.getLastNonNullColumn();
      sheet.showCell(lastNonNullRow, lastNonNullCol, VerticalPosition.nearest, HorizontalPosition.nearest);
      sheet.setSelection(lastNonNullRow, lastNonNullCol, 1, 1);
      return true;
    }
    return false;
  },
};
function getPrecedentsOrDependentsFromSelections(sheet, isPrecedents) {
  var selections = sheet.getSelections();
  var result = [];
  selections.forEach(function (selection) {
    var row = selection.row,
      col = selection.col;
    var rowCount = selection.rowCount,
      colCount = selection.colCount;
    for (var i = row; i < row + rowCount; i++) {
      for (var j = col; j < col + colCount; j++) {
        if (isPrecedents) {
          result = result.concat(sheet.getPrecedents(i, j));
        } else {
          result = result.concat(sheet.getDependents(i, j));
        }
      }
    }
  });
  return result;
}
function isRangeAdjacentOrIntersect(range, compareRange, isRow) {
  var _a, _b;
  if (isRow) {
    if (range.row === compareRange.row && range.rowCount === compareRange.rowCount) {
      var _c = range.col < compareRange.col ? [range, compareRange] : [compareRange, range],
        leftRange = _c[0],
        rightRange = _c[1];
      var maxColIndex = leftRange.col + ((_a = leftRange.colCount) !== null && _a !== void 0 ? _a : 1),
        minColIndex = leftRange.col;
      if (rightRange.col >= minColIndex && rightRange.col <= maxColIndex) {
        return true;
      } else {
        return false;
      }
    }
  } else {
    if (range.col === compareRange.col && range.colCount === compareRange.colCount) {
      var _d = range.row < compareRange.row ? [range, compareRange] : [compareRange, range],
        topRange = _d[0],
        bottomRange = _d[1];
      var maxRowIndex = topRange.row + ((_b = topRange.rowCount) !== null && _b !== void 0 ? _b : 1),
        minRowIndex = topRange.row;
      if (bottomRange.row >= minRowIndex && bottomRange.row <= maxRowIndex) {
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
}
function getRangeAfterMerge(ranges) {
  var _a, _b, _c, _d;
  var len = ranges.length;
  if (len === 1) {
    return ranges;
  } else if (len === 0) {
    return [];
  } else {
    for (var i = 0; i < len; i++) {
      for (var j = i + 1; j < len; j++) {
        var range = ranges[i],
          compareRange = ranges[j];
        if (!compareRange || range.sheetName !== compareRange.sheetName) {
          break;
        } else {
          if (isRangeAdjacentOrIntersect(range, compareRange, true)) {
            var col = Math.min(range.col, compareRange.col);
            var endColAfterMerge = Math.max(
              range.col + ((_a = range.colCount) !== null && _a !== void 0 ? _a : 1),
              compareRange.col + ((_b = compareRange.colCount) !== null && _b !== void 0 ? _b : 1)
            );
            var colCount = endColAfterMerge - col;
            var newRange = {
              sheetName: range.sheetName,
              row: range.row,
              rowCount: range.rowCount,
              col: col,
              colCount: colCount,
            };
            ranges.splice(j, 1);
            ranges.splice(i, 1, newRange);
            break;
          } else if (isRangeAdjacentOrIntersect(range, compareRange, false)) {
            var row = Math.min(range.row, compareRange.row);
            var endRowAfterMerge = Math.max(
              range.row + ((_c = range.rowCount) !== null && _c !== void 0 ? _c : 1),
              compareRange.row + ((_d = compareRange.rowCount) !== null && _d !== void 0 ? _d : 1)
            );
            var rowCount = endRowAfterMerge - row;
            const newRange = {
              sheetName: range.sheetName,
              row: row,
              rowCount: rowCount,
              col: range.col,
              colCount: range.colCount,
            };
            ranges.splice(j, 1);
            ranges.splice(i, 1, newRange);
            break;
          }
        }
      }
    }
  }
  if (ranges.length === len) {
    return ranges;
  } else {
    return getRangeAfterMerge(ranges);
  }
}
function setSelectionForSheet(sheet, selectionInfos) {
  for (var i = 0; i < selectionInfos.length; i++) {
    var info = selectionInfos[i],
      row = info.row,
      col = info.col,
      rowCount = info.rowCount,
      colCount = info.colCount;
    if (i === 0) {
      sheet.setActiveCell(row, col);
      sheet.showCell(row, col, VerticalPosition.nearest, HorizontalPosition.nearest);
      if (rowCount > 1 || colCount > 1) {
        sheet.getSelections()[0].rowCount = rowCount;
        sheet.getSelections()[0].colCount = colCount;
      }
    } else {
      sheet.addSelection(row, col, rowCount, colCount);
    }
  }
}
function handlePrecedentOrDependent(context, currentSheetName, isPrecedent) {
  var sheet = context.getSheetFromName(currentSheetName);
  var infos = getPrecedentsOrDependentsFromSelections(sheet, isPrecedent);
  if (infos.length > 0 && sheet.editorStatus() === GC.Spread.Sheets.EditorStatus.ready) {
    sheet.suspendPaint();
    infos = getRangeAfterMerge(infos);
    var toSheetName = currentSheetName,
      selectionInfos = [];
    for (var i = 0; i < infos.length; i++) {
      var info = infos[i],
        sheetName = info.sheetName;
      if (i === 0) {
        toSheetName = sheetName;
      } else if (sheetName !== toSheetName) {
        continue;
      }
      selectionInfos.push(info);
    }
    // Ref to the first reference's sheet.
    var toSheet = sheet;
    if (toSheetName !== currentSheetName) {
      toSheet = context.getSheetFromName(toSheetName);
      context.setActiveSheet(toSheetName);
    }
    setSelectionForSheet(toSheet, selectionInfos);
    sheet.resumePaint();
    return true;
  }
  return false;
}
Commands[GO_TO_DEPENDENTS] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    return handlePrecedentOrDependent(context, options.sheetName, false /* isPrecedent */);
  },
};
Commands[GO_TO_PRECEDENTS] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    return handlePrecedentOrDependent(context, options.sheetName, true /* isPrecedent */);
  },
};
Commands[SELECT_VISIBLE_CELLS] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = SELECT_VISIBLE_CELLS;
    var visibleRanges = Util.getInvisibleRange(sheet);
    if (visibleRanges.length > 0 && sheet.editorStatus() === GC.Spread.Sheets.EditorStatus.ready) {
      sheet.suspendPaint();
      sheet.clearSelection();
      visibleRanges.forEach(function (visibleRange) {
        sheet.addSelection(visibleRange.row, visibleRange.col, visibleRange.rowCount, visibleRange.colCount);
      });
      sheet.resumePaint();
    }
    return true;
  },
};
Commands[SELECT_ALL] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName);
    options.cmd = SELECT_ALL;
    var rowCount = sheet.getRowCount(),
      colCount = sheet.getColumnCount();
    sheet.suspendPaint();
    sheet.clearSelection();
    if (sheet.editorStatus() === GC.Spread.Sheets.EditorStatus.ready) {
      sheet.setSelection(-1, -1, rowCount, colCount);
    }
    sheet.resumePaint();
    return true;
  },
};
Commands[SELECTION_HOME] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName),
      activeRowIndex = sheet.getActiveRowIndex(),
      activeColIndex = sheet.getActiveColumnIndex();
    var selNeedAdjust = Util.getNeedAdjustSelection(sheet.getSelections(), activeRowIndex, activeColIndex);
    if (selNeedAdjust !== null) {
      var activePosition = Util.getCellPositionInRangeByCol(selNeedAdjust, activeColIndex);
      if (activePosition === 1 /* ActiveCellPositionInRange.middle */ && selNeedAdjust.col === -1) {
        return true;
      }
      sheet.suspendPaint();
      var findResult = void 0,
        lastColIndex = void 0,
        nextVisibleColIndex = void 0;
      if (activePosition === 2 /* ActiveCellPositionInRange.end */) {
        lastColIndex = selNeedAdjust.col;
      } else if (activePosition === 0 /* ActiveCellPositionInRange.start */) {
        lastColIndex = selNeedAdjust.col + selNeedAdjust.colCount - 1;
      } else {
        lastColIndex = selNeedAdjust.col;
      }
      var startSearchIndex =
        activePosition === 0 /* ActiveCellPositionInRange.start */
          ? selNeedAdjust.col + selNeedAdjust.colCount - 1 - 1
          : selNeedAdjust.col - 1;
      nextVisibleColIndex = lastColIndex - 1;
      while (nextVisibleColIndex >= 0 && !sheet.getColumnVisible(nextVisibleColIndex)) {
        nextVisibleColIndex--;
      }
      var LastCellNonNull = sheet.getValue(activeRowIndex, lastColIndex) !== null;
      var nextCellNonNull = sheet.getValue(activeRowIndex, nextVisibleColIndex) !== null;
      var acrossCellsBlock = LastCellNonNull && nextCellNonNull;
      if (acrossCellsBlock) {
        findResult = Util.findFirstNotNullColIndexInRange(sheet, activeRowIndex, startSearchIndex);
      } else {
        findResult = Util.findPreviousNotNullColIndex(sheet, activeRowIndex, startSearchIndex);
      }
      if (activePosition === 2 /* ActiveCellPositionInRange.end */) {
        selNeedAdjust.colCount = activeColIndex - findResult + 1;
      } else if (activePosition === 0 /* ActiveCellPositionInRange.start */) {
        selNeedAdjust.colCount = Math.abs(findResult - activeColIndex) + 1;
      } else {
        selNeedAdjust.colCount = selNeedAdjust.col + selNeedAdjust.colCount - findResult;
      }
      if (findResult < activeColIndex) {
        selNeedAdjust.col = findResult;
      } else if (activePosition !== 1 /* ActiveCellPositionInRange.middle */) {
        selNeedAdjust.col = activeColIndex;
      }
      sheet.showCell(selNeedAdjust.col, activeColIndex, VerticalPosition.nearest, HorizontalPosition.nearest);
      sheet.resumePaint();
    }
    return true;
  },
};
Commands[SELECTION_END] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName),
      sheetColCount = sheet.getColumnCount(),
      activeRowIndex = sheet.getActiveRowIndex(),
      activeColIndex = sheet.getActiveColumnIndex();
    var selNeedAdjust = Util.getNeedAdjustSelection(sheet.getSelections(), activeRowIndex, activeColIndex);
    if (selNeedAdjust !== null) {
      var activePosition = Util.getCellPositionInRangeByCol(selNeedAdjust, activeColIndex);
      if (activePosition === 1 /* ActiveCellPositionInRange.middle */ && selNeedAdjust.col === -1) {
        return true;
      }
      sheet.suspendPaint();
      var findResult = void 0,
        lastColIndex = void 0,
        nextVisibleColIndex = void 0;
      if (activePosition === 2 /* ActiveCellPositionInRange.end */) {
        lastColIndex = selNeedAdjust.col;
      } else if (activePosition === 0 /* ActiveCellPositionInRange.start */) {
        lastColIndex = selNeedAdjust.col + selNeedAdjust.colCount - 1;
      } else {
        lastColIndex = selNeedAdjust.col + selNeedAdjust.colCount - 1;
      }
      var startSearchIndex =
        activePosition === 2 /* ActiveCellPositionInRange.end */
          ? selNeedAdjust.col + 1
          : selNeedAdjust.col + selNeedAdjust.colCount;
      nextVisibleColIndex = lastColIndex + 1;
      while (nextVisibleColIndex < sheetColCount && !sheet.getColumnVisible(nextVisibleColIndex)) {
        nextVisibleColIndex++;
      }
      var LastCellNonNull = sheet.getValue(activeRowIndex, lastColIndex) !== null;
      var nextCellNonNull = sheet.getValue(activeRowIndex, nextVisibleColIndex) !== null;
      var acrossCellsBlock = LastCellNonNull && nextCellNonNull;
      if (acrossCellsBlock) {
        findResult = Util.findLastNotNullColIndexInRange(sheet, activeRowIndex, startSearchIndex);
      } else {
        findResult = Util.findNextNotNullColIndex(sheet, activeRowIndex, startSearchIndex);
      }
      if (activePosition === 2 /* ActiveCellPositionInRange.end */) {
        selNeedAdjust.colCount = Math.abs(findResult - activeColIndex) + 1;
      } else if (activePosition === 0 /* ActiveCellPositionInRange.start */) {
        selNeedAdjust.colCount = findResult - activeColIndex + 1;
      } else {
        selNeedAdjust.colCount = findResult - selNeedAdjust.col + 1;
      }
      if (findResult < activeColIndex) {
        selNeedAdjust.col = findResult;
      } else if (activePosition !== 1 /* ActiveCellPositionInRange.middle */) {
        selNeedAdjust.col = activeColIndex;
      }
      sheet.showCell(
        selNeedAdjust.col + selNeedAdjust.colCount - 1,
        activeColIndex,
        VerticalPosition.nearest,
        HorizontalPosition.nearest
      );
      sheet.resumePaint();
    }
    return true;
  },
};
Commands[SELECTION_TOP] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName),
      activeRowIndex = sheet.getActiveRowIndex(),
      activeColIndex = sheet.getActiveColumnIndex();
    var selNeedAdjust = Util.getNeedAdjustSelection(sheet.getSelections(), activeRowIndex, activeColIndex);
    if (selNeedAdjust !== null) {
      var activePosition = Util.getCellPositionInRangeByRow(selNeedAdjust, activeRowIndex);
      if (activePosition === 1 /* ActiveCellPositionInRange.middle */ && selNeedAdjust.row === -1) {
        return true;
      }
      sheet.suspendPaint();
      var findResult = void 0,
        lastRowIndex = void 0,
        nextVisibleRowIndex = void 0;
      if (activePosition === 2 /* ActiveCellPositionInRange.end */) {
        lastRowIndex = selNeedAdjust.row;
      } else if (activePosition === 0 /* ActiveCellPositionInRange.start */) {
        lastRowIndex = selNeedAdjust.row + selNeedAdjust.rowCount - 1;
      } else {
        lastRowIndex = selNeedAdjust.row;
      }
      var startSearchIndex =
        activePosition === 0 /* ActiveCellPositionInRange.start */
          ? selNeedAdjust.row + selNeedAdjust.rowCount - 1 - 1
          : selNeedAdjust.row - 1;
      nextVisibleRowIndex = lastRowIndex - 1;
      while (nextVisibleRowIndex >= 0 && !sheet.getRowVisible(nextVisibleRowIndex)) {
        nextVisibleRowIndex--;
      }
      var LastCellNonNull = sheet.getValue(lastRowIndex, activeColIndex) !== null;
      var nextCellNonNull = sheet.getValue(nextVisibleRowIndex, activeColIndex) !== null;
      var acrossCellsBlock = LastCellNonNull && nextCellNonNull;
      if (acrossCellsBlock) {
        findResult = Util.findFirstNotNullRowIndexInRange(sheet, activeColIndex, startSearchIndex);
      } else {
        findResult = Util.findPreviousNotNullRowIndex(sheet, activeColIndex, startSearchIndex);
      }
      if (activePosition === 2 /* ActiveCellPositionInRange.end */) {
        selNeedAdjust.rowCount = activeRowIndex - findResult + 1;
      } else if (activePosition === 0 /* ActiveCellPositionInRange.start */) {
        selNeedAdjust.rowCount = Math.abs(findResult - activeRowIndex) + 1;
      } else {
        selNeedAdjust.rowCount = selNeedAdjust.row + selNeedAdjust.rowCount - findResult;
      }
      if (findResult < activeRowIndex) {
        selNeedAdjust.row = findResult;
      } else if (activePosition !== 1 /* ActiveCellPositionInRange.middle */) {
        selNeedAdjust.row = activeRowIndex;
      }
      sheet.showCell(selNeedAdjust.row, activeColIndex, VerticalPosition.nearest, HorizontalPosition.nearest);
      sheet.resumePaint();
    }
    return true;
  },
};
Commands[SELECTION_BOTTOM] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName),
      sheetRowCount = sheet.getRowCount(),
      activeRowIndex = sheet.getActiveRowIndex(),
      activeColIndex = sheet.getActiveColumnIndex();
    var selNeedAdjust = Util.getNeedAdjustSelection(sheet.getSelections(), activeRowIndex, activeColIndex);
    if (selNeedAdjust) {
      var activePosition = Util.getCellPositionInRangeByRow(selNeedAdjust, activeRowIndex);
      if (activePosition === 1 /* ActiveCellPositionInRange.middle */ && selNeedAdjust.row === -1) {
        return true;
      }
      sheet.suspendPaint();
      var findResult = void 0,
        lastRowIndex = void 0,
        nextVisibleRowIndex = void 0;
      if (activePosition === 2 /* ActiveCellPositionInRange.end */) {
        lastRowIndex = selNeedAdjust.row;
      } else if (activePosition === 0 /* ActiveCellPositionInRange.start */) {
        lastRowIndex = selNeedAdjust.row + selNeedAdjust.rowCount - 1;
      } else {
        lastRowIndex = selNeedAdjust.row + selNeedAdjust.rowCount - 1;
      }
      var startSearchIndex =
        activePosition === 2 /* ActiveCellPositionInRange.end */
          ? selNeedAdjust.row + 1
          : selNeedAdjust.row + selNeedAdjust.rowCount;
      nextVisibleRowIndex = lastRowIndex + 1;
      while (nextVisibleRowIndex < sheetRowCount && !sheet.getRowVisible(nextVisibleRowIndex)) {
        nextVisibleRowIndex++;
      }
      var LastCellNonNull = sheet.getValue(lastRowIndex, activeColIndex) !== null;
      var nextCellNonNull = sheet.getValue(nextVisibleRowIndex, activeColIndex) !== null;
      var acrossCellsBlock = LastCellNonNull && nextCellNonNull;
      if (acrossCellsBlock) {
        findResult = Util.findLastNotNullRowIndexInRange(sheet, activeColIndex, startSearchIndex);
      } else {
        findResult = Util.findNextNotNullRowIndex(sheet, activeColIndex, startSearchIndex);
      }
      if (activePosition === 2 /* ActiveCellPositionInRange.end */) {
        selNeedAdjust.rowCount = Math.abs(findResult - activeRowIndex) + 1;
      } else if (activePosition === 0 /* ActiveCellPositionInRange.start */) {
        selNeedAdjust.rowCount = findResult - activeRowIndex + 1;
      } else {
        selNeedAdjust.rowCount = findResult - selNeedAdjust.row + 1;
      }
      if (findResult < activeRowIndex) {
        selNeedAdjust.row = findResult;
      } else if (activePosition !== 1 /* ActiveCellPositionInRange.middle */) {
        selNeedAdjust.row = activeRowIndex;
      }
      sheet.showCell(
        selNeedAdjust.row + selNeedAdjust.rowCount - 1,
        activeColIndex,
        VerticalPosition.nearest,
        HorizontalPosition.nearest
      );
      sheet.resumePaint();
    }
    return true;
  },
};
Commands[GOTO_NEXT_TOP_NON_NULL_CELL] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName),
      activeRowIndex = sheet.getActiveRowIndex(),
      activeColIndex = sheet.getActiveColumnIndex();
    var stopIndex = 0,
      currentCellNonNull = sheet.getValue(activeRowIndex, activeColIndex) !== null;
    var rowIndex = activeRowIndex,
      colIndex = activeColIndex;
    if (rowIndex - 1 < stopIndex) {
      return true;
    }
    rowIndex--;
    while (rowIndex >= stopIndex && !sheet.getRowVisible(rowIndex)) {
      rowIndex--;
    }
    if (rowIndex < 0) {
      return true;
    }
    var acrossCellsBlock = currentCellNonNull && sheet.getValue(rowIndex, colIndex) !== null;
    if (acrossCellsBlock) {
      rowIndex = Util.findFirstNotNullRowIndexInRange(sheet, colIndex, rowIndex);
    } else {
      rowIndex = Util.findPreviousNotNullRowIndex(sheet, colIndex, rowIndex);
    }
    sheet.suspendPaint();
    sheet.setActiveCell(rowIndex, colIndex);
    sheet.showCell(rowIndex, colIndex, VerticalPosition.nearest, HorizontalPosition.nearest);
    sheet.resumePaint();
    return true;
  },
};
Commands[GOTO_NEXT_BOTTOM_NON_NULL_CELL] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName),
      rowCount = sheet.getRowCount(),
      activeRowIndex = sheet.getActiveRowIndex(),
      activeColIndex = sheet.getActiveColumnIndex();
    var stopIndex = sheet.getRowCount(),
      currentCellNonNull = sheet.getValue(activeRowIndex, activeColIndex) !== null;
    var rowIndex = activeRowIndex,
      colIndex = activeColIndex;
    if (rowIndex + 1 >= stopIndex) {
      return true;
    }
    rowIndex++;
    while (rowIndex < stopIndex && !sheet.getRowVisible(rowIndex)) {
      rowIndex++;
    }
    if (rowIndex >= rowCount) {
      return true;
    }
    var acrossCellsBlock = currentCellNonNull && sheet.getValue(rowIndex, colIndex) !== null;
    if (acrossCellsBlock) {
      rowIndex = Util.findLastNotNullRowIndexInRange(sheet, colIndex, rowIndex);
    } else {
      rowIndex = Util.findNextNotNullRowIndex(sheet, colIndex, rowIndex);
    }
    sheet.suspendPaint();
    sheet.setActiveCell(rowIndex, colIndex);
    sheet.showCell(rowIndex, colIndex, VerticalPosition.nearest, HorizontalPosition.nearest);
    sheet.resumePaint();
    return true;
  },
};
Commands[GOTO_NEXT_LEFT_NON_NULL_CELL] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName),
      activeRowIndex = sheet.getActiveRowIndex(),
      activeColIndex = sheet.getActiveColumnIndex();
    var stopIndex = 0,
      currentCellNonNull = sheet.getValue(activeRowIndex, activeColIndex) !== null;
    var rowIndex = activeRowIndex,
      colIndex = activeColIndex;
    if (colIndex - 1 < stopIndex) {
      return true;
    }
    colIndex--;
    while (colIndex >= stopIndex && !sheet.getColumnVisible(colIndex)) {
      colIndex--;
    }
    if (colIndex < 0) {
      return true;
    }
    var acrossCellsBlock = currentCellNonNull && sheet.getValue(rowIndex, colIndex) !== null;
    if (acrossCellsBlock) {
      colIndex = Util.findFirstNotNullColIndexInRange(sheet, rowIndex, colIndex);
    } else {
      colIndex = Util.findPreviousNotNullColIndex(sheet, rowIndex, colIndex);
    }
    sheet.suspendPaint();
    sheet.setActiveCell(rowIndex, colIndex);
    sheet.showCell(rowIndex, colIndex, VerticalPosition.nearest, HorizontalPosition.nearest);
    sheet.resumePaint();
    return true;
  },
};
Commands[GOTO_NEXT_RIGHT_NON_NULL_CELL] = {
  canUndo: false,
  execute: function (context, options, isUndo) {
    var sheet = context.getSheetFromName(options.sheetName),
      colCount = sheet.getColumnCount(),
      activeRowIndex = sheet.getActiveRowIndex(),
      activeColIndex = sheet.getActiveColumnIndex();
    var stopIndex = colCount,
      currentCellNonNull = sheet.getValue(activeRowIndex, activeColIndex) !== null;
    var rowIndex = activeRowIndex,
      colIndex = activeColIndex;
    if (colIndex + 1 >= stopIndex) {
      return true;
    }
    colIndex++;
    while (colIndex < stopIndex && !sheet.getColumnVisible(colIndex)) {
      colIndex++;
    }
    if (colIndex >= colCount) {
      return true;
    }
    var acrossCellsBlock = currentCellNonNull && sheet.getValue(rowIndex, colIndex) !== null;
    if (acrossCellsBlock) {
      colIndex = Util.findLastNotNullColIndexInRange(sheet, rowIndex, colIndex);
    } else {
      colIndex = Util.findNextNotNullColIndex(sheet, rowIndex, colIndex);
    }
    sheet.suspendPaint();
    sheet.setActiveCell(rowIndex, colIndex);
    sheet.showCell(rowIndex, colIndex, VerticalPosition.nearest, HorizontalPosition.nearest);
    sheet.resumePaint();
    return true;
  },
};
export function initShortcutAboutSelection(commands) {
  commands.register(
    SELECT_ENTIRE_ROW,
    Commands[SELECT_ENTIRE_ROW],
    32 /* SPACE*/,
    false /* ctrl */,
    true /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    SELECT_ENTIRE_COLUMN,
    Commands[SELECT_ENTIRE_COLUMN],
    32 /* SPACE */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    GO_SHEET_BEGINNING,
    Commands[GO_SHEET_BEGINNING],
    36 /* HOME */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    GO_SHEET_BOTTOM_RIGHT,
    Commands[GO_SHEET_BOTTOM_RIGHT],
    35 /* END */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    GO_TO_DEPENDENTS,
    Commands[GO_TO_DEPENDENTS],
    221 /* ] */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    GO_TO_PRECEDENTS,
    Commands[GO_TO_PRECEDENTS],
    219 /* [ */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    SELECT_ALL,
    Commands[SELECT_ALL],
    65 /* A */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  if (Util.isFirefox()) {
    commands.register(
      SELECT_VISIBLE_CELLS,
      Commands[SELECT_VISIBLE_CELLS],
      59 /* semicolon */,
      false /* ctrl */,
      false /* shift */,
      true /* alt */,
      false /* meta */
    );
  } else {
    commands.register(
      SELECT_VISIBLE_CELLS,
      Commands[SELECT_VISIBLE_CELLS],
      186 /* semicolon */,
      false /* ctrl */,
      false /* shift */,
      true /* alt */,
      false /* meta */
    );
  }
  commands.register(
    SELECTION_HOME,
    Commands[SELECTION_HOME],
    37 /* left */,
    true /* ctrl */,
    true /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    SELECTION_END,
    Commands[SELECTION_END],
    39 /* right */,
    true /* ctrl */,
    true /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    SELECTION_TOP,
    Commands[SELECTION_TOP],
    38 /* up */,
    true /* ctrl */,
    true /* shift */,
    false /* alt */,
    false /* meta */
  );
  commands.register(
    SELECTION_BOTTOM,
    Commands[SELECTION_BOTTOM],
    40 /* down */,
    true /* ctrl */,
    true /* shift */,
    false /* alt */,
    false /* meta */
  );
  if (commands.navigationTop) {
    commands.setShortcutKey('navigationTop');
  }
  commands.register(
    GOTO_NEXT_TOP_NON_NULL_CELL,
    Commands[GOTO_NEXT_TOP_NON_NULL_CELL],
    38 /* up */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  if (commands.navigationBottom) {
    commands.setShortcutKey('navigationBottom');
  }
  commands.register(
    GOTO_NEXT_BOTTOM_NON_NULL_CELL,
    Commands[GOTO_NEXT_BOTTOM_NON_NULL_CELL],
    40 /* down */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  if (commands.navigationHome2) {
    commands.setShortcutKey('navigationHome2');
  }
  commands.register(
    GOTO_NEXT_LEFT_NON_NULL_CELL,
    Commands[GOTO_NEXT_LEFT_NON_NULL_CELL],
    37 /* left */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
  if (commands.navigationEnd2) {
    commands.setShortcutKey('navigationEnd2');
  }
  commands.register(
    GOTO_NEXT_RIGHT_NON_NULL_CELL,
    Commands[GOTO_NEXT_RIGHT_NON_NULL_CELL],
    39 /* right */,
    true /* ctrl */,
    false /* shift */,
    false /* alt */,
    false /* meta */
  );
}
