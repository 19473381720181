import { DefaultGraphElementAttrs } from './ElementModel';
import { handleNameChange, restyleCell } from '../shared/restyleCell';
import DefaultElementInspector from '../DefaultElementInspector';
import { ElementType } from './ElementType';
import { MULTIMERGE } from './types/shared/typesConstants';

export class TransformationElementType extends ElementType {
  get inPorts() {
    return ['in'];
  }

  get outPorts() {
    return ['out'];
  }

  get inspectorComponent() {
    return DefaultElementInspector;
  }

  get graphElementAttrs() {
    return DefaultGraphElementAttrs;
  }

  createGraphInstance(stencilInstance) {
    const clone = stencilInstance.clone();
    const newAttrs = this.graphElementAttrs;

    clone.attr(newAttrs);
    clone.resize(newAttrs.rect.width, newAttrs.rect.height);

    this.addPortsToInstance(clone);

    return clone;
  }

  restoreInstance({ id, bounds, name }) {
    const stencilInstance = new this.stencilElementModel(this.stencilElementAttrs);

    const instance = stencilInstance.clone();
    instance.resize(bounds.width, bounds.height);
    instance.attr(this.graphElementAttrs);
    instance.position(bounds.left, bounds.top);
    instance.prop('id', id);
    instance.attr('.name/text', name);
    restyleCell(instance);
    handleNameChange(instance);
    this.addPortsToInstance(instance);

    return instance;
  }

  addPortsToInstance(instance) {
    if (this.inPorts) {
      this.inPorts.forEach(inPort => {
        instance.addInPort(inPort);
      });
    }

    if (this.outPorts) {
      this.outPorts.forEach(outPort => {
        instance.addOutPort(outPort);
      });
    }
  }

  canCreateLink(graph, magnet, model) {
    // Cannot create connections from passive (input) ports
    const isPassive = magnet.getAttribute('magnet') === 'passive';
    if (isPassive) {
      return false;
    }

    return true;
  }

  canAcceptLinkFrom(graph, model, magnet, sourceModel, sourceMagnet) {
    // Prevent circular links.
    if (TransformationElementType.isCircularLink(graph, sourceModel, model)) {
      return false;
    }

    // Only allow linking to input ports.
    if (magnet.getAttribute('port-group') !== 'in') {
      return false;
    }

    // Get the port for the target magnet
    const port = magnet.getAttribute('port');

    // Get all inbound links for this element
    const links = graph.getConnectedLinks(model, { inbound: true });

    // Get all inbound links for this port
    const portLinks = links.filter(link => link.get('target').port === port);

    /// For multimerge port, we allow multiple links but with a limit of 50
    if (model.attributes.type === MULTIMERGE) {
      return (
        portLinks.length < 50 || (portLinks.length === 1 && !portLinks.find(pl => pl.source().id === sourceModel.id))
      );
    }
    // Ensure there are no existing links to this port.  Note, there's a special case where there is currently
    // a link representing a preview of the link that we're attempting to connect.  If this is the case, we accept this
    // as zero existing links in order to prevent the "snap to link" feature from becoming choppy.
    return portLinks.length === 0 || (portLinks.length === 1 && portLinks[0].source().id === sourceModel.id);
  }

  static isCircularLink(graph, sourceModel, targetModel) {
    if (sourceModel === targetModel) {
      return true;
    }

    const predecessors = graph.getPredecessors(sourceModel, { inbound: true });
    return predecessors.indexOf(targetModel) >= 0;
  }
}
